* {
    box-sizing: border-box;
    font-family: Poppins,sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
.select_input {
    font-family: 'poppins' !important;
    color:#B5B6B6;
    font-weight: 300;
}
.table_head {
  color: #403e75 !important;
  font-family: 'poppins' !important;
  font-weight: 1000;
  background: #d9dcfe !important;
  font-size: 16px;
}

.accordion_detail {
    border-bottom: 1px solid grey; 
    display: flex; 
    justify-content: space-around
}
.accordion_detail > i {
    color: grey; 
    font-size: 22px; 
    margin-left: 8px
  
}

/* grip inputs - start */
.input_element {
    position: relative;
    width: 50%;
    height: 68%;
  }
  
  .input_element input,
  .input_element select {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-right: 30px;
    border: 2px solid var(--primaryColor);
    padding: 12px 20px;
  }
  
  .input_element label {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--primaryColor);
    background: white;
    transition: all 0.2s;
  }
  
  .input_element:hover label {
    top: -7px;
    color: var(--utilityColor);
  }
  
  .form_input_element:not(:placeholder-shown)~label,
  .form_input_element:focus~label {
    top: -7px;
    color: var(--utilityColor);
  }
  .form_select_element:not(:placeholder-shown)~label,
  .form_select_element:focus~label {
    top: -7px;
    color: var(--utilityColor);
  }
  
  /* grid inputs -end */
  