.quizBox {
  box-shadow: 1px 1px 14px 2px #00000029;
  border: 1px solid #e8e8e8;
  padding: 2vw;
  background-color: white;
  margin: 8px
}
.green_btn {
    color: #fff;
    background-color:#4CAF50;
    border-radius: 150px;
    border-color:#4CAF50;
    padding: 8px 21px;
    /* width:140px;
    height : 43px; */
  
  }

  .blue_btn{
    color: #fff;
    width: 150px;
    padding:7px;
    background-color: #403e75;
    border-radius: 150px;
    border-color: grey;
  
  }
  .blue_btn:hover{
    color:#fff;
    background-color: #403e75;
    /* border: 0; */
    top: -2;
    box-shadow: 0px 3px 5px #403e75;
  
  }
  .green_btn:hover {
    color: #fff;
    background-color:#4CAF50;
    /* border: 0; */
    /* top: -2; */
    /* box-shadow: 0px 3px 5px #78be20; */
  }
  .select_input {
    font-family: 'poppins' !important;
    color:#B5B6B6;
    font-weight: 300;
    font-size: 1vw !important;

}
.plan_links_items {
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  background-color: white;
  box-shadow: 1px 1px grey;
  height: 56px;
  width: 90%;
  margin: 7px;
  list-style-type: none;
}

.plan_links_items:hover {
  border-left-width: 8px;
  border-left-color: #403e75;
  box-shadow: 2px 1.5px 7px grey;
}

.plan_link_text {
  color : '#354052'
}

.plan_links_items:hover .plan_link_text {
  color: orange;
}

.plan_container_link {
  display: flex;
  text-decoration: none;
  margin: 10px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}