@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

* {
	/* font-family: sans-serif; */
	font-family: poppins;
}

.MuiTableRow-hover:hover {
	background: #ffffff !important;
	/*box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;*/
	border-left: 5px solid #111fee !important;
}

.MuiTableRow-hover .css-1ex1afd-MuiTableCell-root:hover {
	/* color: #fb9d2a; */
	/* color: #FD8C00; */
	/* font-weight: bold; */
	/*border-left: 5px solid #111fee;*/
}

/* * {
	-ms-overflow-style: none;
}
::-webkit-scrollbar {
	display: none;
} */

.css-10mzr36-MuiGrid-root {
	width: auto !important;
	margin-left: 0 !important;
}

.css-10mzr36-MuiGrid-root>.MuiGrid-item {
	padding-left: 0 !important;
}

.css-1ex1afd-MuiTableCell-root {
	line-height: 2.43 !important;
}

----------------- .vert-move {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}

.vert-move {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

.one {
	-webkit-animation-delay: 0.5s;
	-moz-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.css-10hburv-MuiTypography-root {
	text-overflow: ellipsis !important;
	overflow: hidden !important;
}

.cursor-pointer {
	cursor: pointer;
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.animate {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css-i4bv87-MuiSvgIcon-root {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	/* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
	font-size: 1.5rem;
	/* Removed border-radius property */
	background-color: white;
	/* Set your desired background color */
	padding-top: 0;
	/* Top padding */
	padding-right: 0;
	/* Right padding */
	padding-bottom: 0;
	/* Bottom padding */
	padding-left: 0;
	/* Left padding */
	border-radius: 50%;
}

.az_dd_hover {
	background-color: 'black';
	color: red;
	border: 1px solid initial;
	transition: border 0.3s ease;
}

.az_dd_hover:hover {
	border: 1px solid orange;
	color: black !important;
}

#az-Subtitle {
	color: orange;
}

#az_level1 {
	margin-top: "55px";
	padding-top: "33px";
	position: "absolute";
	right: "15px";
	left: "15px";
	width: "100%";
	display: "flex";
	flex-direction: "column";
}

.az_level2 {
	margin-top: "55px";
	padding-top: "33px";
	position: "absolute";
	right: "15px";
	left: "15px";
	width: "100%";
	display: "flex";
	flex-direction: "column";
}

.select-wrapper .select__option:hover {
	background-color: red;
	opacity: 0.7;
}

.select-wrapper .select__option--is-selected {
	background-color: red;
}

.az_cursor_pointer {
	cursor: pointer !important;
}

.az_create_school_button_container {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-right: 10px;
}

.az_home_navigation_section {
	display: flex;
	justify-content: space-between;
	position: fixed;
	padding: 15px 20px;
	top: 85px;
	z-index: 99;
	background: #f8f4fc;
	width: 84vw;
}

.az_navigation_content {
	font-size: 16px;
	cursor: pointer;
}

.az_navigation_text:hover {
	cursor: pointer;
	color: #ffa500;
	border-bottom: 2px solid #403E75;
}

.az_last_update_time {
	color: #1b1b1b;
	font: normal normal normal 12px/18px Poppins;
}

/* =========== Table style Start ============= */


.az_school_admin_table {
	height: calc(100vh - 240px);
	overflow-y: auto;
}

.az_table_head tr {
	position: sticky;
	top: 0px;
	z-index: 1;
	margin-top: 10px;
}

.az_table_head tr {
	background-color: #403E75 !important;
}

.az_table_head .az_checkbox {
	background-color: #ffffff;
	color: '#BDBDBD' !important;
	outline: 1px solid #BDBDBD !important;
	box-shadow: none !important;
}

.az_table_head .az_checkbox:hover {
	background-color: rgba(255, 165, 0, 0.05);
	color: #FFA500 !important;
	outline: 1px solid #FFA500 !important;
	box-shadow: none !important;
}

.az_table_head tr td,
.az_table_head tr th {
	color: #ffffff !important;
}

.az_table_body tr:nth-child(even) {
	background-color: #F5F5F8 !important;
}

.az_table_body tr td {
	color: #828282 !important;
}

.az_table_body tr:hover,
td {
	color: black !important;
}

.az_table_body tr:hover {
	border: 1.5px solid #FFA500 !important;
}

.az_checkbox_td {
	-webkit-appearance: none;
	height: 15px;
	width: 15px;
	background-color: #f8f4fc;
	border-radius: 3px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: #FFA500;
	position: relative;
	left: 14px;
	top: 12px;
}

.az_table_body tr .az_checkbox {
	background-color: #ffffff;
	color: '#BDBDBD' !important;
	outline: 1px solid #BDBDBD !important;
	box-shadow: none !important;
}

.az_table_body tr .az_checkbox:hover {
	background-color: rgba(255, 165, 0, 0.05);
	color: #FFA500 !important;
	outline: 1px solid #FFA500 !important;
	box-shadow: none !important;
}

.az_table_body tr td input[type="checkbox"]:checked {
	background-color: #FFA500 !important;
}

.az_table_body tr:hover td {
	background-color: #ffffff !important;
	color: inherit !important;
}

.az_tooltip {
	background-color: #FFA500 !important;
	color: #ffffff !important;
}

/* =========== Table style End ============= */

/* =========== input field style Start ============= */

.az_text_input {
	width: 100% !important;
	height: 90% !important;
	border-radius: 20px !important;
	border-color: #BDBDBD !important;
}

.az_text_input label:hover {
	color: #3D3D3D !important;
}

.az_text_input:focus-visible {
	outline: none !important;
}

/* .az_text_input:not(:placeholder-shown) <<< ======= if value inside the input also change border color */

.az_text_input:hover,
.az_text_input:focus {
	border: 1.5px solid #FFA500 !important;
}

.az_text_input:focus-within+label,
.az_text_input:focus+label,
.az_text_input:hover+label,
.az_text_input:not(:placeholder-shown)+label {
	color: #3D3D3D !important;
}

.az_text_input[type="text"] {
	color: #FFA500 !important;
}

/* =========== input field style End ============= */


/* =========== select field style Start ============= */

.az_phone_code {
	height: 60% !important;
	border-radius: 20px !important;
	border-color: #BDBDBD !important;
	color: #FFA500 !important;
}

.az_phone_code option {
	color: black !important;
}

.az_phone_code:focus-visible {
	outline: none !important;
}

.az_select_1 {
	border-radius: 20px !important;
	border-color: #BDBDBD !important;
	color: #FFA500 !important;
}

.az_select {
	height: 90% !important;
	border-radius: 20px !important;
	border-color: #BDBDBD !important;
	color: #FFA500 !important;
}

.az_select option,
.az_select_1 option {
	color: black !important;
}

.az_select:focus-visible,
.az_select_1:focus-visible {
	outline: none !important;
}

.az_select label:hover,
.az_select_1 label:hover {
	color: #3D3D3D !important;
}

.az_select:hover,
.az_select_1:hover {
	border: 1.5px solid #FFA500 !important;
}

.az_select:focus-within+label,
.az_select:focus+label,
.az_select:hover+label,
.az_select:not(:placeholder-shown)+label {
	color: #3D3D3D !important;
}

.az_select_1:focus-within+label,
.az_select_1:focus+label,
.az_select_1:hover+label,
.az_select_1:not(:placeholder-shown)+label {
	color: #3D3D3D !important;
}

.az_select option:checked,
.az_select_1 option:checked {
	background: #FFA500;
	color: #FFFFFF !important;
}

.az_select option:hover,
.az_select_1 option:hover {
	background: red !important;
}

.az_select_Add_student_Modal_select {
	height: 100% !important;
}

/* =========== select field style End ============= */

/* =========== input_radio style Start ============= */

.az_input_radio {
	box-shadow: 0px 0px 0px 1px #6d6d6d !important;
	font-size: 1em !important;
	width: 15px !important;
	height: 15px !important;
	border: 2px solid #fff !important;
	background-clip: border-box !important;
	border-radius: 50% !important;
	appearance: none !important;
	transition: background-color 0.3s, box-shadow 0.3s !important;
	vertical-align: middle !important;
}

.az_input_radio.on:checked {
	box-shadow: 0px 0px 0px 1px #FFA500 !important;
	background-color: #FFA500 !important;
}

.az_input_radio.off:checked {
	box-shadow: 0px 0px 0px 1px #eb0000 !important;
	background-color: #ff5151 !important;
}

input[type='radio']:disabled {
	pointer-events: none !important;
	box-shadow: 0px 0px 0px 1px #E0E0E0 !important;
}

/* =========== input_radio style End ============= */


/* =========== nav bar style Start ============= */

.az_nav_menu_li:hover .az_nav_span_text {
	color: #fff !important
}

/* =========== nav bar style End ============= */

/* =========== button style End ============= */

.az_confirm_btn {
	padding: 8px 32px;
	border-radius: 20px;
	background-color: #403E75;
	color: #fff;
	border: 2px solid #403E75;
}

.az_confirm_btn:hover {
	background-color: #2A2859;
}

.az_cancel_btn {
	padding: 8px 32px;
	border-radius: 20px;
	background-color: #fff;
	border: 2px solid #403E75;
	color: #333333;
}

.az_cancel_btn:hover {
	background-color: rgba(42, 40, 89, 15%);
}

/* =========== button style End ============= */

/* Bug fix  */

.az_add_staff_input_radio {
	/* box-shadow: 0px 0px 0px 1px #6d6d6d !important; */
	font-size: 1em !important;
	width: 18px !important;
	height: 18px !important;
	border: 2px solid #fff !important;
	background-clip: border-box !important;
	border-radius: 50% !important;
	/* appearance: none !important; */
	transition: background-color 0.3s, box-shadow 0.3s !important;
	vertical-align: middle !important;
}

.az_add_staff_input_radio .on:checked {
	box-shadow: 0px 0px 0px 1px #FFA500 !important;
	background-color: #FFA500 !important;
}

.rpv-core__text-layer {
	user-select: none !important;
}

.dashboard-table-container {
	margin: 25px 0px;
}

.dashboard-dropdown-list-header {
	display: flex;
	justify-content: space-between;
}

.dashboard-dropdown-list-header {
	margin-bottom: 15px;
}

.dropdown-list {
	display: flex;
	column-gap: 20px;
	align-items: center;
}

/* .dropdown-container {
	margin: 20px;
} */

.dropdown {
	padding: 8px;
	font-size: 16px;
	border-radius: 20px;
}

.dropdown option[selected] {
	background-color: red;
	font-weight: bold;
}

.dropdown option:hover {
	background-color: red;
	/* Hover background color */
}

#dashboard-search-input {
	/* background-image: url('/css/searchicon.png'); */
	background-position: 550px 12px;
	background-repeat: no-repeat;
	width: 100%;
	font-size: 16px;
	padding: 12px 20px 12px 40px;
	border: 1px solid #ddd;
	margin-bottom: 12px;
	border-radius: 25px;
}

.submit-btn {
	background-color: #ffa500;
	border: none;
	color: white;
	padding: 10px 30px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	border-radius: 20px;
}

.grade-process-btn {
	background-color: #403e75;
	border: none;
	color: white;
	padding: 10px 30px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	border-radius: 20px;
}

.dashboard-table {
	width: 100%;
	border-collapse: collapse;
}

.dashboard-table td,
.dashboard-table th {
	padding: 12px 15px;
	text-align: center;
	font-size: 16px;
}

.dashboard-table th {
	background-color: #403e75;
	color: #ffffff;
	text-wrap: nowrap;
}

/* .dashboard-table th[scope=row], td[scope=row]{
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 1;
} */

.dashboard-table tbody tr:nth-child(even) {
	background-color: rgb(245, 245, 248);
}

/*responsive*/

@media (max-width: 500px) {
	.dashboard-table thead {
		display: none;
	}

	.dashboard-table,
	.dashboard-table tbody,
	.dashboard-table tr,
	.dashboard-table td {
		display: block;
		width: 100%;
	}

	.dashboard-table tr {
		margin-bottom: 15px;
	}

	.dashboard-table td {
		padding-left: 50%;
		text-align: left;
		position: relative;
	}

	.dashboard-table td::before {
		content: attr(data-label);
		position: absolute;
		left: 0;
		width: 50%;
		padding-left: 15px;
		font-size: 15px;
		font-weight: bold;
		text-align: left;
	}
}

.queestion-text,
.queestion-text p {
	color: black !important;
	font-size: 0.88vw;
}

.queestion-text p span{
	color: black !important;
	font-size: 0.88vw !important;
	font-family: "Poppins";
}

.question-options,
.question-options p,
.sub-questions,
.sub-questions p {
	color: black !important;
	font-size: 0.88vw;
}

.question-options-main {
	margin-left: 75px !important;
}


.sub-qstns {
	padding-left: 42px;
}

.dashboard-pagination {
	display: inline-block;
}

.dashboard-pagination span {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	transition: background-color .3s;
}

.dashboard-pagination span.active {
	background-color: #ffa500;
	color: white;
}

.dashboard-pagination span:hover:not(.active) {
	background-color: #ddd;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
	background-color: #403E75;
	border-bottom: 1px solid #403E75;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: white;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
	border: none;
	border-bottom: 1px solid #403E75;
	background-color: white;
}

div[aria-describedby="rpv-core__tooltip-body-print"] button[aria-label="Print"] {
	display: none;
}

.ant-popover-content {
	height: 300px;
	overflow-y: scroll;
	scrollbar-width: thin;
}

.inbox-list-li p:first-child {
	color: black;
	font-Size: 16px;
	padding-bottom: 5px;
}

#loader {
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(13, 13, 13, 0.7);
	z-index: 9999;
	opacity: .7;
	/* display:none; */
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

#loader::after {
	content: '';
	display: block;
	position: absolute;
	left: 48%;
	top: 40%;
	width: 60px;
	height: 60px;
	border-style: solid;
	border-color: #fff;
	border-top-color: transparent;
	border-width: 5px;
	border-radius: 50%;
	-webkit-animation: spin .8s linear infinite;
	animation: spin .8s linear infinite;
}

.rpv-core__inner-pages {
	scrollbar-width: thin;
}

.ember-box {
	border-radius: 5px;
	box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
	padding: 15px;
	height: 98px;
	overflow: auto;
	scrollbar-width: thin;
	overflow-x: hidden;
	/* margin: 20px; */
}

.ember-card-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cs-chart-bar-pair {
	width: 142px;
}
/* .container-wrapper {
	display: flex;
	align-items: center;
  }
  
  .review-section {
	display: flex;
	gap: 12px;
	max-width: 600px;
	width: 100%;
	background: #4070f4;
	border-radius: 12px;
	padding: 30px;
	scroll-snap-type: x mandatory;
	overflow-x: auto;
	scroll-padding: 30px;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
	scroll-behavior: smooth;
  }
  
  .review-section::-webkit-scrollbar {
	width: 10px;
	height: 10px;
  }
  
  .review-section::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
  }
  
  .review-section::-webkit-scrollbar-thumb:hover {
	background: #555;
  }
  
  .review-section .card {
	display: flex;
	flex: 0 0 80%;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	border-radius: 12px;
	background: #fff;
	scroll-snap-align: start;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  
  .card .image {
	height: 150px;
	width: 150px;
	padding: 4px;
	background: #4070f4;
	border-radius: 50%;
  }
  
  .image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 50%;
	border: 5px solid #fff;
  }
  
  .card h2 {
	margin-top: 25px;
	color: #333;
	font-size: 22px;
	font-weight: 600;
  }
  
  .card p {
	margin-top: 4px;
	font-size: 18px;
	font-weight: 400;
	color: #333;
	text-align: center;
  }
  
  button.prev-button, button.next-button {
	background-color: #4070f4;
	color: white;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	border-radius: 5px;
	margin: 0 10px;
  }
  
  button.prev-button:disabled, button.next-button:disabled {
	background-color: #888;
	cursor: not-allowed;
  } */


  .carousel {
	position: relative;
	width: 300px;
	height: 250px;
	perspective: 950px;
	margin: auto;
	margin-top: 80px;
  }
  
  .card-container {
	width: 100%;
	height: 90%;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 1s;
  }
  
  .view-card {
	position: absolute;
	width: 100%;
	height: 90%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	backface-visibility: hidden;
  }
  
  .prev, .next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgb(64, 62, 117);;
	border: none;
	padding: 10px;
	cursor: pointer;
	color: white;
  }

  .view-btn {
	background-color: rgb(64, 62, 117);;
	border: none;
	padding: 7px 5px;
	cursor: pointer;
	color: white;
  }
  
  .prev {
	left: 0;
  }
  
  .next {
	right: 0;
  }
  
  