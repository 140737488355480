/* .main-container {
  min-width: 560px;
}
   */

/* aa  */
.branchselect_Dropdown {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
  text-align: center;
  padding: 0rem 1rem;
  cursor: pointer;
}

.branchselect_Dropdown_optipns {
  width: 220px;
  height: 35px;

}

/* aa  */


/* article.article-add-school-plan starts from here */
article.article_add_school_plan {
  width: 100%;
  position: absolute;
  top: 33px;
  height: 100%;
}

/* .Grid.school-name-branch  starts */

.Grid_school_name_branch {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  background-color: #f8f4fc;
}

button.btn_branch_name {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

.down_arrow_icon {
  padding-right: 10px;
}

.down_arrow_icon {
  padding-left: 10px;
}

.school_name {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  /* width: 100%; */
  height: 40px;
  column-gap: 2px;
  /* border-left: 17px solid var(--backgroundColor); */
  /* border-right: 17px solid var(--backgroundColor); */
  margin: 0px 10px 0px 10px;
}

@media only screen and (max-width: 768px) {
  .grid_container_header_plan {
    height: 94px;
  }

}

.grid_container_header_plan>div {
  background: white;
  padding: 10px;
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
  font-size: 0.9rem;
}

.item_plan2 {
  color: orange;
  border-bottom: 4px solid orange;
}

.grid_container_header_plan>div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* boards grid starts from here */

.boards_container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  gap: 50px;
  padding: 15px;
  padding-left: 35px;
  background-color: white;
  /* border-left: 17px solid var(--backgroundColor); */
  /* border-right: 17px solid var(--backgroundColor); */
  margin: 0px 10px 0px 10px;
}

.grid_item {
  display: grid;
  padding-right: 50px;
  row-gap: 5px;
}

.grid_item>label {
  color: orange;
}

.grid_item .board {
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.43;
  vertical-align: inherit;
  text-align: left;
  color: #707070;
}

.radio {
  position: relative;
  top: 10px;
  right: 75px;
}

/* table----section----------starts-------------- */

.plan_list_table {
  background: white;
  border: 0.01px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  width: 99%;
}

.plan_list_table::-webkit-scrollbar {
  display: none;
}

.table {
  width: 100%;
  text-align: center;
}

.table,
.table th,
.table td {
  border-collapse: collapse;
}

.table>tbody {
  background-color: #f8f4fc;
}

.table th,
.table td {
  padding: 5px;
  width: 10px;
}

.table thead {
  background-color: var(--headerColor);
  color: blue;
  height: 47px;
}

.table th i {
  font-size: 0.8rem;
}

.table>tbody tr {
  height: 47px;
  border: 1px groove var(--secondaryColor);
  background: white;
}

.table_row_section:hover td:first-child {
  color: blue;
  border-left: 5px solid blue;
}

.table_row_section:hover {
  box-shadow: 3px 3px 3px 3px #888888;
  background-color: #f8f4fc;
}

.table_row_section:hover .button {
  background-color: var(--utilityColor);
  color: white;
}

.table_section {
  width: 100%;
  height: auto;
  text-align: center;
  overflow-x: scroll;
}

.table_section,
.table_section th,
.table_section td {
  border-collapse: collapse;
}

.table_section>tbody {
  background-color: #f8f4fc;
}

.table_section th,
.table_section td {
  padding: 5px;
  width: 10px;
}

.table_section thead {
  background-color: var(--headerColor);
  color: blue;
  height: 47px;
}

.table_section th i {
  font-size: 0.8rem;
}

.table_section>tbody tr {
  height: 47px;
  border: 1px groove var(--secondaryColor);
  background: white;
}

.table_row_section:hover td:first-child {
  border-left: 5px solid blue;
}

.table_row_section:hover {
  box-shadow: 3px 3px 3px 3px #888888;
  background-color: #f8f4fc;
}

.table_row_section:hover .button {
  background-color: var(--utilityColor);
  color: white;
}

.section_list_table {
  height: 430px;
  background: white;
  /* border-radius: 20px; */
  /* border-left: 17px solid var(--backgroundColor); */
  /* border-right: 17px solid var(--backgroundColor); */
  /* border-bottom: 17px solid var(--backgroundColor); */
  margin: 0px 10px 0px 10px;
}

.button {
  height: 25px;
  width: 60px;
  border: none;
  border-radius: 20px;
  background-color: #fefefe;
  color: grey;
  outline: 1px solid grey;
}

.orange_button {
  height: 25px;
  width: 60px;
  border: none;
  border-radius: 20px;
  background-color: orange;
  color: white;
}

.orange_button_add {
  height: 25px;
  width: 60px;
  border: none;
  border-radius: 20px;
  background-color: orange;
  color: white;
}

td.cbse_table_head3 {
  width: 30%;
  font-size: 1.1vw;
}

td.cbse_table_head2 {
  width: 30%;
  font-size: 1vw;
}

td>input[type="text"] {
  width: 175px;
  height: 35px;
  border-radius: 5px;
  border: none;
  position: relative;
  outline: 0.2vw solid grey;
  padding-left: 10px;
}

/* =============== blue  checkbox starts============= */

.cbse_checkbox>wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox>input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
  left: 28px;
}

.cbse_checkbox>label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.cbse_checkbox>input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}

.cbse_checkbox>input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox>input[type="checkbox"]:checked {
  background-color: blue;
}

/* .cbse_checkbox > input[type="checkbox"]:checked::after {
  display: block;
} */

/* ===============Start - section dropdown for new Grade Section mapping========================== */

.table_data_section {
  padding: 6px 8px;
  width: 66%;
  border-radius: 6px;
  font-size: 0.9rem;
}

/* ===============End - section dropdown for new Grade Section mapping========================== */

/* ==============radio botton============ */

.radio>wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio>input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 0px;
}

.radio>label {
  color: gray;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 10px;
}

.radio>input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}

.radio>input[type="checkbox"]:hover {
  background-color: orange;
}

.radio>input[type="checkbox"]:checked::after {
  display: block;
}

.cbse_checkbox_active::after {
  display: block;
}

/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  height: 10px;
  background-color: orange;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  left: 26px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;
  border: 3px solid orange;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.active_slider {
  background-color: #d9dcfe;
}

input:focus+.active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked+.active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(-25px);
  border: 3px solid #d9dcfe;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */

tr.add_school_link {
  border-left: 5px solid blue;
  box-shadow: 0 1px 8px 1px grey;
}

.button {
  height: 25px;
  width: 60px;
  border: none;
  color: #354052;
}

.button :hover {
  color: orange;
}

.fa-regular.fa-circle-plus {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 20px;
}

.fa-light.fa-pen {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 20px;
}

i.fa-solid.fa-trash-can {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 20px;
}

/* orange-button===starts========= */

.orange_button_icon {
  height: 25px;
  width: 25px;
  border: none;
  border-radius: 20px;
  left: 60px;
  top: -6px;
  position: relative;
  background-color: orange;
  margin-right: -25px;
  color: white;
}

td.cbse_table_head3 {
  width: 30%;
}

td.cbse_table_head2 {
  width: 30%;
}

td>input[type="text"] {
  width: 175px;
  height: 35px;
  border-radius: 5px;
  border: none;
  position: relative;
  outline: 0.2vw solid grey;
  padding-left: 10px;
}

@media only screen and (max-width: 768px) {
  td>input[type="text"]{
    width: 150px;
    height: 25px;
  }
}

/* =============== blue  checkbox starts============= */

.cbse_checkbox>wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox>input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
  left: 28px;
}

.cbse_checkbox>label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.cbse_checkbox>input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}

.cbse_checkbox>input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox>input[type="checkbox"]:checked {
  background-color: blue;
}

.cbse_checkbox>input[type="checkbox"]:checked::after {
  display: block;
}

/* ==============radio botton============ */

.radio>wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio>input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 0px;
}

.radio>label {
  /* color: gray;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 10px; */
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.43;
  vertical-align: inherit;
  text-align: left;
  color: #707070;
}

@media only screen and (max-width: 768px) {
  .radio>label {
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.43;
    vertical-align: inherit;
    text-align: left;
    color: #707070;
  }

  .radio>input[type="checkbox"] {
    height: 6px;
    width: 6px;
  }
}

.radio>input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}
@media only screen and (max-width: 768px) {
  .radio>input[type="checkbox"]::after {
    font-size: 12px;
  }
}

/* .radio > input[type="checkbox"]:checked {
  background-color: blue;
} */

/* .radio > input[type="checkbox"]:checked::after {
  display: block;
} */

/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  height: 10px;
  border-radius: 4px;
  background-color: orange;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  left: 26px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;
  border: 3px solid orange;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.active_slider {
  background-color: #d9dcfe;
}

input:focus+.active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked+.active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(-25px);
  border: 3px solid #d9dcfe;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============popup starts============== */

.wrapper {
  display: inline-flex;
}

.wrapper .icon {
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon span {
  display: block;
  height: 40px;
  width: 40px;
  /* background: #fff; */
  border-radius: 50%;
  position: relative;
  z-index: 2;
  /* box-shadow: 0px 10px 10px rgba(0,0,0,0.1); */
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon span i {
  line-height: 50px;
  font-size: 18px;
  color: grey;
  font-weight: 900;
}

.wrapper .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 15px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover {
  background-color: #00000024;
  border-radius: 70px;
}

.wrapper .icon:hover .tooltip {
  top: -50px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover span {
  color: #fff;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper .add:hover i {
  color: orange;
}

.wrapper .edit:hover i {
  color: orange;
  /* box-shadow: 2px 2px 2px orange; */
}

.wrapper .remove:hover i {
  color: orange;
}

.blank {
  margin-left: 50px;
}

#orange_add {
  box-shadow: 2px 2px 2px orange;
}