/* article.article_add_school_plan starts from here */
article.article_add_school_plan {
    width: 100%;
    position: absolute;
    top: 25px;
    height: 100%;
}
/* .Grid.school_name_branch  starts */
.Grid_school_name_branch {
  display: flex;
  justify-content: 
  space-between;
  padding: 13px;
  background-color: #f8f4fc;
  }

.Grid .school_name_branch {
  display: flex;
  justify-content: 
  space-between;
  padding: 9px;
  margin-right: px;
}

button.btn_branch_name {
    width: 220px;
    height: 35px;
    border-radius: 15px;
    background-color: blue;
    color: #f8f4fc;
    border: none;
    
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}
.school_name {
    FONT-WEIGHT: 550;
    font-size: 0.9rem;
    color: #000000b8;
    padding-top: 10px;
    margin-left: 15px;
}

/* .Grid.school_name_branch ends here */
/* grid_container_header_plan starts from here */

.grid_container_header_plan {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    text-align: center;
    /* width: 100%; */
    height: 40px;
    grid-column-gap: 2px;
    column-gap: 2px;
    margin: 0px 10px 0px 10px;
    background: white;
    /* border-left: 15px solid var(--backgroundColor); */
    /* border-right: 45px solid var(--backgroundColor); */
}


.item_plan7{
    color: orange;
  font-size: 0.9rem;
  border-bottom: 4px solid orange;
  /* margin-right: 12px; */
  padding-top: 9px;
  box-shadow:0.1px 0.1px gray;
}
.grid_container_header_plan > div{
    font-size: 0.9rem;
  padding-top: 9px;
  box-shadow:0.1px 0.1px gray;
}

.grid_container_header_plan > div:hover{
  color: orange;
  border-bottom: 4px solid orange;
}

.plan_blank{
    background: white;
    border-left: 7px solid var(--backgroundColor);
    margin-right: 18px;
    color: white;
}

/* Logo starts from here */

.logo_part{
    height: 100%;
    background-color: white;
    margin: 0px 10px 0px 10px;
}
.upload_logo_container{
    display: grid;
    align-items: center;
    
}

.upload_logo_container > span{
      padding: 15px 10px 10px 20px;
        font-size: 0.9rem;
        display: flex;
        gap: 1rem;
}





/* upload input starts */
.custom_file_container > span{
      position: relative;
        top: -2rem;
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
}

    .custom_file_input {
          width: 8rem;
            margin: 0px 0px 0px 15px;
            color: transparent;
    }
  
    .custom_file_input::-webkit-file-upload-button {
      visibility: hidden;
    }
    .custom_file_input::-webkit-textfield-decoration-container {
      visibility: hidden;
    }

  
    .custom_file_input::before {
          content: 'Choose File';
            display: inline-block;
            background: linear-gradient(top, #f9f9f9, #e3e3e3);
            border-radius: 18px;
            padding: 11px 30px;
            outline: none;
            white-space: nowrap;
            cursor: pointer;
            font-weight: 700;
            font-size: 10pt;
            background: orange;
            color: white;
    }

  
  
    .custom_file_input:hover::before {
      border-color: black;
    }
  
    .custom_file_input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
/* upload input ends */

/*Input Label Choose File Btn Style*/
.chooseFile_btn_wrapper {
  position: relative;
  margin-bottom: 40px;
}

.chooseFile_btn_wrapper > img {
  position: absolute;
  top: 12px;
  left: 14px;
}

.chooseFile_btn {
  font-size: 13px;
  color: white;
  background-color: #FD8C00;
  border-radius: 25px;
  width: 123px;
  height: 37px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

/*Input Label Choose File Btn Style Hover Effect*/
.chooseFile_btn_wrapper {
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.chooseFile_btn_wrapper:hover, .chooseFile_btn_wrapper:focus, .chooseFile_btn_wrapper:active {
  bottom: 1px;
  box-shadow: 0 11px 11px -10px #FD8C0063;
}