.cqk__Quiz_Main_div_MSQ {
  width: 100%;
  height: 355px;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.9;
  padding: 15px;
  user-select: none;
}

/*-------| Quiz MSQ QUESTION Styles |-------*/
.cqk__Qstn_and_Ans_MSQ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.cqk__Qstn_div_MSQ {
  max-height: 400px;
  overflow: auto;
  text-align: justify;
  border-right: 1px solid #faf9ff;
  padding-right: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk__Qstn_div_MSQ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Qstn_div_MSQ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Qstn_div_MSQ::-webkit-scrollbar-track {
  background-color: white;
}
/*---| Scroll Bar Ends |---*/

.cqk__Msq_Head {
  font-size: 15px;
  opacity: 0.8;
}

.cqk__Qstn_content_1_MSQ {
  font-size: 13px;
  margin-top: 20px;
  line-height: 1.7;
  color: #333333;
  opacity: 0.9;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk__Qstn_content_1_MSQ::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Qstn_content_1_MSQ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Qstn_content_1_MSQ::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk__MSQ_img {
  width: 170px;
  height: 110px;
  margin-top: 10px;
}
/*-------| Quiz MSQ QUESTION Ends |-------*/

/*-------| Quiz MSQ ANSWER |-------*/
.cqk__Ans_div_MSQ {
  max-height: 300px;
  overflow-x: hidden;
}

/*---| Scroll Bar Starts |---*/
.cqk__Ans_div_MSQ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Ans_div_MSQ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Ans_div_MSQ::-webkit-scrollbar-track {
  background-color: white;
}
/*---| Scroll Bar Ends |---*/

.cqk__Head_MSQ {
  font-size: 16px;
  color: #1b1b1b;
}

.cqk__Ans1_MSQ {
  width: 550px;
  height: auto;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 10px;
  line-height: 24px;
  padding: 10px;
  border-radius: 5px;
}
/*-------| Quiz MSQ ANSWER Ends |-------*/
