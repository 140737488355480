.WholePagee {
  width: 100%;
  height: auto;
}

.TeacherDashWrapper {
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: 50px;
  padding-right: 0px;
}

.teacher-card-section {
  display: flex;
  justify-content: space-between;
}

.teacher-card {
  height: 160px;
  width: 32%;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 5px 10px 15px;
}

.teacher-count-card {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 5px;
}

.count-wrapper {
  display: flex;
  flex-direction: column;
}

.progress-wrapper {
  display: flex;
  gap: 15px;
}
/* .teacher-circle-wrap{
  padding-top: -5px;
} */

.card-sub-head {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  /* text-align: left; */
  margin-bottom: 6px;
}

.card-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  /* text-align: left; */
  margin-bottom: 6px;
  color: #060606;
}

.card-heading {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  /* text-align: left; */
  margin-bottom: 6px;
  color: #6a6a6a;
}

.dashboard-heading {
  color: #6a6a6a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 10px;
}

.count-card-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.count-card {
  border-radius: 10px;
  background-color: #fff;
  height: 80px;
}

.teacher-grade-count-label {
  color: #2d3962;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding-left: 20px;
}

.teacher-grade-count {
  color: #6a6a6a;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  padding-top: 8px;
  padding-left: 20px;
}

.circle-wrapper {
  margin-top: 1px;
}

.teacher-top-section-card {
  display: flex;
  padding: 20px 0px 0px 15px;
}

.teacher-bargraph-wrapper {
  margin-top: 30px;
}

.head-wrapper {
  display: flex;
  justify-content: space-between;
}

.box {
  float: left;
  height: 14px;
  width: 14px;
  margin-bottom: 10px;
  clear: both;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 5px;
}

.orange {
  background-color: #fa9825;
}

.blue {
  background-color: #4fd8bf;
}

.text-style {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  color: #6a6a6a;
}

.select-box {
  width: 200px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.modal-head {
  color: #4fcda8;
}

@media only screen and (max-width: 1024px) {
  .card-heading {
    font-size: 14px;
  }
  .card-text {
    font-size: 10px;
  }

  .card-sub-head {
    font-size: 12px;
  }

  .teacher-grade-count-label {
    font-size: 12px;
  }
}
