.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  padding: 30px 30px 30px 30px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 400px;
  height: 300px;
}

.main_content_teacherid p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content_teacherid span {
  display: block;
  width: 100px;
  margin: auto;
}
.main_content_teacherid img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_teacherid h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal_buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin: 25px 10px 10px 10px;
}

.ok_btn {
  background-color: orange;
  color: white;
  padding: 10px;
  border-radius: 35px;
  width: 75px;
  font-weight: bold;
  border: 0.1px solid orange;
  cursor: pointer;
  margin-top: -7px;
}

.ok_btn:hover {
  top: -7px;
  position: relative;
  box-shadow: 1px 2px 5px orange;
}

#ok_btn {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal_teacherid {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/
