.main_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

/* header -start */
.header {
  background: var(--primaryColor);
  /* width: 100%; */
  width: 101%;

  /* height: 100px; */
  height: 11.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_teacher {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 10px;
  align-items: center;
}
.teacher_badge {
  width: 2.7vw;
  height: 2.7vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
}
.teacher_profile {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: start;
  padding: 10px;
  cursor: pointer;
}
.profile_Name {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;
  color: #fffcfc;
}
.header_title {
  margin: 20px;
  font-size: 24px;
  color: #fffcfc;
  font-size: large;
  font-weight: normal;
}
.teacher_name {
  /*margin: 10px;*/
  font-size: 13px;
  color: #fffcfc;
  font-weight: bold;
}
/* header buttons - start */
.header_button_container {
  position: relative;
  margin: 20px;
}
.header_button {
  padding: 10px;
  border-radius: 20px;
  width: 153px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  outline: 0.1px inset rgba(128, 128, 128, 0.212);
}
.content_dev {
  margin-right: -24px;
}
.slider {
  background: var(--utilityColor);
  width: 156px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--backgroundColor);
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  transition: all 0.3s;
}
.slider_right {
  left: 128px;
}

.link_icon_superAdmin {
  align-self: center;
  justify-self: flex-start;
  padding: 5px 5px;
}

.superAdmin_icon {
  color: #fcfbf9 !important;
  font-size: 1.2vw;
}
.superAdmin_icon > i {
  padding: 2px;
  font-size: larger;
}

.superAdmin_link_text {
  justify-content: center;
  width: fit-content;
  font-size: 0.9em;
  margin: 5px 4px;
  padding-right: 40px;
}
/* header buttons -end */

/* header -end */

.triangle-bottom{
  width: 0;
  height: 0;
  margin: 30px auto;
  border-left:
    10px solid transparent;
  border-right:
    10px solid transparent;
    border-top:
    10px solid white;
  justify-content: center;
}

.triangle-up{
  width: 0;
  height: 0;
  margin: 30px auto;
  border-left:
    10px solid transparent;
  border-right:
    10px solid transparent;
    border-bottom:
    10px solid white;
  justify-content: center;
}

.down_Arrow {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
}
