.card {

    background: transparent linear-gradient(128deg, #4B498D 0%, #0F0B6A 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
}

.count-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px #0000000A;
}

.icon-bg {
    width: 58px;
    height: 58px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-bg-1 {
    background: transparent linear-gradient(180deg, #FFC11E 0%, #F85025 100%) 0% 0% no-repeat padding-box;
}

.icon-bg-2 {
    background: transparent linear-gradient(180deg, #4AA8F8 0%, #2340DC 100%) 0% 0% no-repeat padding-box;
}

.icon-bg-3 {
    background: transparent linear-gradient(180deg, #00D8B4 0%, #0079FA 100%) 0% 0% no-repeat padding-box;
}

.breadCrumbs {
    font-size: 12px;
    font-family: 'Poppins';
    color: '#7A7A7A';
    display: flex;
}

.last-update {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #1B1B1B;
}

.subjects {

    font: normal normal bold 20px/30px Poppins;
    letter-spacing: 0px;
    color: #2D3962;
    opacity: 1;
}

.range {

    font: normal normal normal 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.percent {

    font: normal normal medium 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.vs-card {
    border-radius: 5px;
    padding: 10px 0px
}

.vs-card-1 {
    background: transparent linear-gradient(20deg, #7517A6 0%, #8828E4 100%) 0% 0% no-repeat padding-box;

}

.vs-card-2 {
    background: transparent linear-gradient(20deg, #0093E7 0%, #00D1B9 100%) 0% 0% no-repeat padding-box;
}

.vs-card-3 {
    background: transparent linear-gradient(20deg, #264ADE 0%, #47A0F6 100%) 0% 0% no-repeat padding-box;
}

.vs-cardheading {
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.vs-cardSubheading {
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.vs-cardCount {
    font: normal normal bold 32px/48px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.vs {
    font: normal normal bold 22px/33px Poppins;
    letter-spacing: 0px;
    color: #29047D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tasks-cordinator {

    background: #fff;
    border-radius: 5px;
    height: 100%;
    padding: 10px 0px
}

.task-indicator {
    width: 3px;
    height: 40px;
    /* UI Properties */
    border-radius: 0px 3px 3px 0px;
}

.task-priority {
    width: 11px;
    height: 11px;
    border-radius: 12px;
    /* UI Properties */
}

.task-heading {
    font-family: Poppins;
    letter-spacing: 0px;
    color: #354052;
    font-size: 14px;
}

.task-subheading {
    font-family: Poppins;
    font-size: 12px;

    letter-spacing: 0px;
    color: #9E9E9E;
}

.task-priority-text {
    letter-spacing: 0px;
    color: #7E84A3;
    font-family: Poppins;
    font-size: 10px;
    text-transform: capitalize;
}

.task-header {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2D3962;
}

.tasks-bottom {
    display: flex;
    justify-content: center;
    width: '100%';
}

.tasks-bottom-text {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #9249E8;
    cursor: pointer;
    /* border-top: 1px solid #2F57EC; */

}



.sections-list{
    border-left: 1px solid #E1E4EF;
    height:100%;
    padding-left: 32px;
    overflow-y: auto;
    padding-right: 16px;

    
  
}


.section-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
    padding-top:10px;
}

.section-left{
    width: 60%;
}
.section-right{
    width: 40%;
}

.section-title{
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
letter-spacing: 0px;
color: #2D3962;
margin-bottom: 6px;
}
.section-text{
    font-size: 11px;
    font-family: Poppins;
    font-weight: 400;
letter-spacing: 0px;
color: #87879B;
margin-bottom: 2px;

}


.task-modal-container{
    background-color: #fff;
    width:60%;
    margin: 0 auto;
    margin-top: 10%;
    height:70vh;
    border:none
}

.task-modal-header {
    background:#545282;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 21px;
    color: #FFFFFF;

    
}

.tasks-title{
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
letter-spacing: 0px;
color: #FFFFFF;
}