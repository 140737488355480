.prncpl-dash-wrapper{
    width:80vw;
    /* height: 120vh; */
    display: flex;
    flex-direction: column;
    user-select: none;
    padding: 50px;
    padding-right: 0%;
    position: relative;
}

.principal-card-section {
    display: flex;
    justify-content: space-between;
  }
  
  .principal-card {
    height: 160px;
    width: 32%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px 5px 10px 15px;
  }
  
  .principal-count-card {
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 5px;
  }
  
  .count-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .progress-wrapper {
    display: flex;
    gap: 10px;
  }
  /* .principal-circle-wrap{
    padding-top: -5px;
  } */
  
  .card-sub-head-prncpl {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    /* text-align: left; */
    margin-bottom: 6px;
    color: #ee5f48;
    margin-top: 15px;
  }
  
  .card-text-prncpl {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    /* text-align: left; */
    margin-bottom: 6px;
    color: #060606;
  }
  .card-text-teacher{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 10px; /* 76.923% */
position: absolute;
top:22px;
right:15px
  }
  
  .card-heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    /* text-align: left; */
    margin-bottom: 6px;
    color: #6a6a6a;
  }
  
  .dashboard-heading {
    color: #6a6a6a;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 10px;
  }
  
  .count-card-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .count-card {
    border-radius: 10px;
    background-color: #fff;
    height: 80px;
  }
  
  .principal-grade-count-label {
    color: #2d3962;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding-left: 20px;
  }
  
  .principal-grade-count {
    color: #6a6a6a;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    padding-top: 8px;
    padding-left: 20px;
  }
  
  .circle-wrapper {
    margin-top: 1px;
  }
  
  .principal-top-section-card {
    display: flex;
    padding: 20px 0px 0px 15px;
  }
  
  .principal-bargraph-wrapper {
    margin-top: 30px;
  }
  
  .head-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .box {
    float: left;
    height: 14px;
    width: 14px;
    margin-bottom: 10px;
    clear: both;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
  }
  
  .orange {
    background-color: #fa9825;
  }
  
  .blue {
    background-color: #4fd8bf;
  }
  
  .text-style {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    color: #6a6a6a;
  }
  
  .select-box {
    width: 250px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    color: #000000;
  
  }
  
  .modal-head {
    color: #4fcda8;
  }
  

  .circle-wrapper {
    margin-top: 1px;
  }
  
  .teacher-top-section-card {
    display: flex;
    padding: 20px 0px 0px 15px;
  }
  
  .teacher-bargraph-wrapper {
    margin-top: 30px;
  }
  
  .head-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .box {
    float: left;
    height: 14px;
    width: 14px;
    margin-bottom: 10px;
    clear: both;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
  }
  
  .orange {
    background-color: #fa9825;
  }
  
  .blue {
    background-color: #4fd8bf;
  }
  
  .text-style {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    color: #6a6a6a;
  }


  .filtersSection{
    position: absolute;
    top:-10px;
    right:20px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .select-box-v2{
    width: 200px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
  }