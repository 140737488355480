article.article_teacherid {
  width: 100%;
  position: absolute;
  top: 38px;
  height: 100%;
  background-color: #f8f4fc;
  outline: 3px solid #f8f4fc;
}

/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_teacherid {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_teacherid {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

.teacher_id_text {
  margin: 5px 5px;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name_teacherid {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_teacherid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 100%;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}

.grid_container_header_plan_teacherid > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan3_teacherid {
  color: orange;
  border-bottom: 4px solid orange;
}

.grid_container_header_plan_teacherid > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */

/* =============================teachers-list-starts-===================== */
/* .teacher_list_teacherid {
  border-right: 10px solid #f8f4fc;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  background: white;
  border-left: 10px solid #f8f4fc;
  padding-top: 11px;
  padding-bottom: 10px;
} */

.teacher_list_teacherid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 10px;
  padding: 18px 10px;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}
#search_button_teacherid {
  border: none;
  font-size: 20px;
  background: white;
  color: orange;
}

.button_text {
  margin: 5px 5px;
}

i.fa-solid.fa-chalkboard-user {
  padding-right: 15px;
}

i.fa-solid.fa-magnifying-glass {
  color: orange;
  position: relative;
  top: 8px;
  font-size: 22px;
  background: white;
}

.create_button_teacher_teacherid {
  width: 180px;
  height: 35px;
  border-radius: 20px;
  color: white;
  background: #4d9f4d;
  border: none;
  float: right;
  position: relative;
  right: 0px;
}

.create_button_teacher_teacherid:hover {
  top: 2px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.list3_teacherid {
  display: flex;
  align-items: center;
  gap: 1rem;
}

i.fa-solid.fa-circle-plus {
  padding-right: 10px;
}

/* ====================table starts from here=================== */

.div_table_teacher_list_teacherid {
  height: 443px;
  background: white;
  margin: 0px 10px 10px 10px;
  overflow: auto;
  /* border-radius: 5px; */
}

.table_section_teacher_list_teacherid {
  width: 82vw;
  text-align: center;
  border-collapse: collapse;

  border: 1px groove #8080802e;
  font-size: 0.9rem;
}

.table_section_teacher_list_teacherid > thead {
  color: blue;
  background: var(--headerColor);
  height: 47px;
  font-size: 0.9rem;
  font-weight: normal;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}

.userId {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}

/* td {
  text-align: center;

} */

.table_section_teacher_list_teacherid > tbody {
  background-color: white;
  color: var(--primaryColor);
}

.table_section_teacher_list_teacherid > tbody > tr {
  /* height: 40px; */
  border-left: 10px solid white;
  border: 1px groove #8080802e;
}

.table_body_section_teacher_list_teacherid :hover td:first-child {
  border-left: 5px solid blue;
}

.table_body_section_teacher_list_teacherid:hover {
  box-shadow: 1px 0px 7px 1px grey;
  border-left: 10px solid blue;
  /* padding-left: 10px solid blue; */
}

.table_body_section_teacher_list_teacherid:hover
  .list_body_table_yellow_teacher_list_teacherid {
  color: orange;
}

.body_section_teacher_list_teacherid tr:hover td:nth-child(3){
  color: #ff9100;
}


.blank_teacherid {
  height: 350px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  border-bottom: 10px solid #f8f4fc;
  outline: 3px solid #f8f4fc;
}

.list_body_table_yellow_teacher_list_teacherid {
  text-align: start !important;
  width: 193px;
  /* padding-left: 15px; */
}

.list_role {
  text-align: start !important;
  width: 140px !important;
  padding-left: 36px;
}

/* =================icon popup  starts ============= */
.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  padding: 43px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 400px;
  z-index: 1;
}

.main_content_teacherid p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content_teacherid span {
  display: block;
  width: 100px;
  margin: auto;
}

.main_content_teacherid img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_teacherid h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal-buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
}

.choosefile_button-2_teacherid {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 16px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

#choose_button_id_2_teacherid {
  background-color: #ff9100;
  color: white;
}

.choosefile_button_1_teacherid {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 14px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: 0.1px solid;
  cursor: pointer;
}

#choose_button_id_1_teacherid {
  background-color: orange;
  color: white;
  width: 70px;
  height: 35px;
  border: none;
  border-radius: 20px;
}

/* =================popup starts ============================ */

.wrapper_teacherid {
  display: inline-flex;
}

.wrapper_teacherid .icon {
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span {
  display: block;
  height: 40px;
  width: 40px;
  position: relative;
  /* z-index: 2; */
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span i {
  line-height: 50px;
  font-size: 1vw;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.wrapper_teacherid .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 20px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon:hover span {
  color: #fff;
}

.wrapper_teacherid .icon:hover span,
.wrapper_teacherid .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper_teacherid .change-password:hover i {
  color: orange;
}

.wrapper_teacherid .edit:hover i {
  color: orange;
  /* box-shadow: 2px 2px 2px orange; */
}

.wrapper_teacherid .remove:hover i {
  color: orange;
  /* box-shadow: 2px 2px 2px orange; */
}

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */

.plan_link_icon16 {
  color: blue;
  padding: 1px -8px;
  border-radius: 4px;
  border-style: inset;
  box-shadow: 0.1px 0.1px;
  width: 17px;
}

.plan_link_icon16 > input {
  width: 20px;
  height: 1px;
}

.plan_link_icon16 > input:hover {
  left: 5px;
  right: 5px;
}

.plan_links_items16:hover {
  border-left-width: 8px;
  border-left-color: blue;
}

.plan_container_link16 {
  display: flex;
  text-decoration: none;
  margin: 10px 12px;
  height: 19px;
  width: 16px;
}

.text_staff {
  margin: 0px 0px 0px 10px;
}

/*==========pagination start==========*/

.pagination {
  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  width: 83vw;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/
