.create_branch_list {
	height: 60%;
	border-bottom: none;
	border-radius: 10px;
	width: 82vw;
	background: white;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: scroll;
}

header {
	background: var(--headerColor);
	width: 100%;
	height: 92px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.table,
.table th {
	border-collapse: collapse;
	/* width: 1097px; */
	font-size: small;
	/* height: 110%; */
	font-size: 0.9rem;
}

.table > tbody > td {
	height: 35px;
}
.data_body {
	background: white;
	color: grey;
}

.input_grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 15px;
	height: 140px;
	margin-top: 30px;
	margin-left: 20px;
}

.radio_lable label {
	color: #ff9100;
	padding-left: 12px;
	font-weight: bold;
}

.school_logo_jpg {
	grid-row: 1/3;
}

.radio_button_1 input {
	padding-top: 0px;
	border-radius: 15px;
}
.radio_button_2 input {
	padding-top: 0px;
	margin-left: 45px;

	border-radius: 15px;
}
.radio_button_2 {
	margin-left: 3%;

	color: black;
}

.radio_button_1 {
	margin-left: 3%;
	color: black;
}
.radio_container {
	margin-top: 3px;
}
#button_id {
	/* float: right; */
	background-color: rgb(40, 40, 212);
	font-weight: 400;
	color: var(--backgroundColor);
}
.add_school_title {
	margin: 10px 0px;
	font-weight: bold;
}

.education_container {
	background-color: aquamarine;
}

.edit_schools {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	font-size: smaller;
	color: #ff9100;
	background-color: rgba(225, 226, 245, 0);
	padding-top: 0px;
	border-radius: 6px;
}

.school_logo {
	grid-row: 1 / 3;
	width: 75px;
}

.schoolName {
	font-size: 0.9rem;
	font-weight: bold;
}

.cityName {
	font-size: 0.9rem;
	font-weight: bold;
}

.pocEmail {
	font-size: 0.9rem;
	font-weight: bold;
}

.website {
	font-size: 0.9rem;
	font-weight: bold;
}

.SignatoryName {
	font-size: 0.9rem;
	font-weight: bold;
}

.SignatoryRole {
	font-size: 0.9rem;
	font-weight: bold;
}

.phoneNumber {
	font-size: 0.9rem;
	font-weight: bold;
}
th.col_size_add_branch_13 {
	text-align: center;
}

.schoolType {
	font-size: 0.9rem;
	font-weight: bold;
}

p {
	color: rgba(14, 13, 13, 0.63);
}

.artical_button_cancel {
	background: white;
	color: gray;

	padding: 10px;
	border-radius: 20px;
	width: 140px;
	font-weight: 400;
	border: 2px solid grey;
	text-transform: uppercase;
	cursor: pointer;
	/* margin-right: 15px; */
}
.artical_button_container :hover {
	background: grey;
	color: white;
}
#button_id:hover {
	top: -7px;
	box-shadow: 0px 3px 5px rgb(0 0 255 / 40%);
}

.school_logo_jpg img {
	width: 160px;
	padding-bottom: 25px;
}

.edit_button {
	margin-top: 2px;
	margin-left: 122%;
	color: white;
	background-color: #ff9100;

	height: 45%;
	min-width: 105px;
	border-radius: 30px;
	border: none;
	font-size: smaller;
}
.taken_width {
	background-color: #f6f6fc;
	padding-top: 0px;
	border: 0.1px solid rgba(128, 128, 128, 0.233);
	border-radius: 4px;
	box-shadow: 0.1px 0.1px;
	margin-top: 10px;
}
.mobile_email span {
	margin: 0px 10px;
}
.mobile_email label {
	color: black;
	padding-bottom: 20px;
}
.mobile_email span {
	text-decoration: none;
}
.mobile_email span img {
	position: relative;
	top: 5px;
}

tbody p {
	text-align: center;
}

.dashboard {
	height: 91%;
	background-color: #f6f6fc;
	min-width: fit-content;
	min-height: fit-content;
	display: flex;
	position: relative;
}

.edit_schools > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 16px;
	width: 100%;
	align-items: start;
	margin: 0.2rem 0.2rem;
}

.edit_schools > div > label {
	white-space: nowrap;
	width: 9rem;
	overflow: scroll;
	text-overflow: clip;
	margin: 0px 10px;
}

.edit_schools > div > p {
	white-space: nowrap;
	overflow: scroll;
	text-overflow: clip;
	margin: 0px 10px;
}
.radio_button_2 > img {
	margin-left: 15%;
}

.radio_button_2 > label {
	padding-top: 15%;
}

.main_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 1110px;
}

.dashboard {
	height: 91%;
}

.add_schools {
	margin: 35px 16px 0px 16px;
	width: 82vw;
	height: 87%;
}
.add_school_title {
	margin: 10px 0px;
}
/* .input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 99%;
  height: 166px;
  margin-top: 30px;
  margin-left: 20px;
} */
/* grip inputs - start */
.input_element {
	position: relative;
	/* width: 95%; */
	height: 50px;
}
.input_board {
	position: relative;
	/* width: 95%; */
	height: 50px;
}
.input_element input,
.input_element select {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	border: 1px solid var(--primaryColor);
	padding: 0 20px;
}
.input_board input,
.input_board select {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	border: 1px solid var(--primaryColor);
	padding: 0 20px;
}

.input_element label {
	position: absolute;
	left: 15px;
	/* top: 19px; */
	top: 15px;
	/* font-size: 1vw; */
	font-size: 13px;
	/* color: var(--primaryColor); */
	background: white;
	opacity: 1;
	transition: all 0.2s;
	/* top: -7px; */
	color: #b5b6b6;
	padding-right: 35px;
	/* color: var(--utilityColor); */
}

.input_board label {
	position: absolute;
	left: 15px;
	/* top: 19px; */
	top: -7px;
	/* font-size: 1vw; */
	font-size: 13px;
	/* color: var(--primaryColor); */
	background: white;
	opacity: 1;
	transition: all 0.2s;
	/* top: -7px; */
	color: #b5b6b6;
	padding-right: 35px;
	/* color: var(--utilityColor); */
}
/* .input_element:hover label {
    top: -7px;
    color: var(--utilityColor);
  } */
.input_element:hover label {
	top: -7px;
	color: #fd8c00 !important;
	padding-right: 0;
}
.input_board:hover label {
	top: -7px;
	color: #fd8c00 !important;
	padding-right: 0;
}

/* option:hover, */
/* option:focus, */

/* select:focus,
  select:active ~ label {
    background: black;
  } */

.form_input_element:not(:placeholder-shown) ~ label,
.form_input_element:focus ~ label {
	top: -7px;
	/* color: var(--utilityColor); */
	color: #fd8c00;
	padding-right: 0;
}
.form_input_board:not(:placeholder-shown) ~ label,
.form_input_board:focus ~ label {
	top: -7px;
	/* color: var(--utilityColor); */
	color: #fd8c00;
	padding-right: 0;
}

.form_select_element:valid + label,
.form_select_element option:checked {
	top: -7px;
	color: var(--utilityColor);
	padding-right: 0;
}
.form_select_board:valid + label,
.form_selec_board option:checked {
	top: -7px;
	color: var(--utilityColor);
	padding-right: 0;
}

.std_cat {
	visibility: hidden;
}

/* .form_select_element :active label {
  display: none;
} */

/* .form_input_element:not(:placeholder-shown) #city_name_id,
.option:active #city_name_id,
.option:checked #city_name_id {
  top: -7px !important;
} */

/* =================radio starts ============= */
.cbse_radio {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid orange;
	border-radius: 50%;
	outline: none;
	box-shadow: 0 0 5px 0px orange inset;
}

.cbse_radio:hover {
	box-shadow: 0 0 5px 0px orange inset;
}

.cbse_radio:before {
	content: "";
	display: block;
	width: 60%;
	height: 60%;
	margin: 20% auto;
	border-radius: 50%;
}
/* .cbse_radio:checked:before {
    background: white;
    position: relative;
    box-shadow: inset 0px 0px 0 4px orange;
    top: -3px;
    width: 17px;
    height: 17px;
  } */
.cbse_radio:before {
	background: white;
	position: relative;
	box-shadow: inset 0px 0px 0 4px orange;
	top: -3px;
	width: 17px !important;
	height: 17px !important;
}

/* =============radio button ends ============== */

/* grid inputs -end */

.upload_logo_container {
	display: flex;
	flex-direction: column;
}
.upload_logo_container span {
	justify-self: center;
	margin: 5px 17px;
	font-weight: 500;
}
.artical_button_container {
	display: flex;
	justify-content: flex-end;
	gap: 2rem;
}

.artical_button {
	background-color: var(--backgroundColor);
	color: var(--primaryColor);
	padding: 10px;
	border-radius: 20px;
	width: 140px;
	font-weight: bold;
	border: none;
	text-transform: uppercase;
	cursor: pointer;
	margin-right: 15px;
}
/*
#button_id {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
  position: absolute;
  margin: 15px;
} */
/* #button_id:hover {
  top: -56px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
} */

.add_schools_form {
	background-color: white;
	padding-top: 2px;
	border: 0.1px solid rgba(128, 128, 128, 0.233);

	border-radius: 15px;
	width: 82vw;
	height: 215px;
}
.add_schools_btn {
	height: 80px;
	display: flex;
	justify-content: space-between;
}

.choosefile_button {
	background-color: var(--backgroundColor);
	color: var(--primaryColor);
	padding: 10px;
	border-radius: 20px;
	width: 140px;
	font-weight: bold;
	border: none;
	cursor: pointer;
	margin-left: 15px;
}

.choosefile_button_container {
	margin: 20px;
}
#choose_button_id {
	background-color: #ff9100;
	color: var(--backgroundColor);
}
.school_list_container {
	margin: 15px 0px 15px 0;
}
.magnifying_glass_icon {
	float: right;
	margin-right: 35px;
	font-size: 1.2rem;
	color: var(--primaryColor);
}

.school_list_table {
	background: white;
	height: 40%;
	border: 0.1px solid rgba(128, 128, 128, 0.233);
	border-bottom: none;
	border-radius: 10px 10px 0px 0;
	width: 99%;
	overflow: auto;
	/* IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/* Chrome, Safari and Opera */
.school_list_table::-webkit-scrollbar {
	display: none;
}

.table {
	width: 100%;
	/* height: 60%; */
	text-align: center;
	border-radius: 2px;
	border-radius: 2px;
}
.table,
.table th,
.table td {
	border-bottom: 1px solid rgba(141, 137, 137, 0.281);
	border-collapse: collapse;
	font-size: 0.9rem;
}
.table th,
.table td {
	padding: 5px;
	font-weight: normal;
}
.table thead {
	background-color: var(--headerColor);
	position: sticky;
	top: 0;
	color: blue;
	z-index: 1;
}
.table th i {
	position: relative;
	left: 2px;
}
.table tr {
	height: 47px;
}

.name {
	white-space: nowrap;
	width: 7rem;
	overflow: clip;
	text-overflow: clip;
	/* margin: 0px 10px; */
	text-align: left;
}

.create_branch_btn {
	width: 65%;
	border: 2px solid rgba(128, 128, 128, 0.233);
	border-radius: 15px;
	padding: 5px;
	background: none;
}

.data_row:hover .circle i {
	background-color: var(--utilityColor);
	border-radius: 50%;
	padding: 7px;
	color: white;
}
i.fa.fa-pencil {
	font-size: medium;
}
/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 29px;
}

.active_switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.active_slider {
	position: absolute;
	cursor: pointer;
	top: 10px;
	left: 0;
	right: 0;
	bottom: 0;
	width: 46px;
	border-radius: 5px;
	height: 10px;

	background-color: #d9dcfe;
	transition: 0.4s;
}

.active_slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 14px;
	right: 25px;
	border-radius: 15px;
	bottom: 0;
	top: -6px;

	border: 3px solid #d9dcfe;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .active_slider {
	background-color: orange;
}

input:focus + .active_slider {
	box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
	-webkit-transform: scale3d;
	-ms-transform: translateX(26px);
	transform: translateX(25px);
	border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 0%;
}

/* ============================================================================== */
/*==========pagination start==========*/

.pagination {
	background: white;
	box-shadow: 1px 2px 2px var(--fadedColor);
	border-radius: 5px;
	width:82vw;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pagination a {
	color: #888888;
	padding: 3px 6px;
	text-decoration: none;
	transition: background-color 0.3s;
}

.pagination a span {
	font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
	background-color: dodgerblue;
	color: white;
	float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
	background-color: #ddd;
}

.pagination_active:hover:not(.active) {
	background-color: blue;
}

.pagination_active {
	color: black;
	border: none;
	padding: 3px 6px;
	text-decoration: none;
	transition: background-color 0.3s;
}

.rows {
	font-size: 0.9rem;
	color: #888888;
	padding-left: 10px;
}

.rows > select {
	text-align: center;
	margin: 5px;
	height: 25px;
	color: #888888;
	border-color: #888888;
	border-radius: 5px;
}

/*==========pagination End==========*/

/*addbranch action edit icon*/
.circle img {
	padding: 3px;
}

.circle img:hover {
	background: #fd8c00;
	border-radius: 50%;
}
