* {
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
/* modal  start*/
.flex_container {
    display: flex;
    background-color: hsla(0, 0%, 0%, 0.5);
    align-items: center;
    justify-content: center;
  }

  .main_container{
    width : 500px ;
    height : 250px; 
    border-radius: 20px;
    background-color: white;
  }
  .main_container p {
    font-size: 0.5em;
    color: orange;
    display: flex;
    justify-content: center;
  }

  #modal_id {
     /* display: none; */
     position: fixed;
     z-index: 1;
     left: 0;
     top: 0;
     overflow: auto;
     width: 100%;
     height: 100%;
     background-color: rgb(0, 0, 0); /* Fallback color */
     background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

   /* modal end */

   .tablex_cellx {
    color : darkgray ;
    border-right : 1px solid lightgrey ;
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box  ;
    transition: 0.7s;
    font-family: 'poppins' !important;


  }
  .tablex_cellx:hover{
    color : blue ; 
    border-right : 1px solid lightgrey; 
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box

  }

  .table_cell {
    margin: 8px 8px ;
    color : darkgray ;
    border-right : 1px solid lightgrey ;
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box  ;
    transition: 0.7s;
    font-family: 'poppins' !important;


  }
  .table_cell:hover {
    margin: 8px 8px ;
    color : blue ; 
    border-right : 1px solid lightgrey; 
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box

  }

  .table_cell_cotree {
    /* margin: 8px 8px ; */
    padding:8px;
    color : darkgray ;
    border-right : 1px solid lightgrey ;
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box  ;
    transition: 0.7s;
    font-family: 'poppins' !important;


  }
  .table_cell_cotree:hover {
    /* margin: 8px 8px ; */
    color : blue ; 
    border-right : 1px solid lightgrey; 
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box

  }
  .table_cell_cotree:focus {
    /* margin: 8px 8px ; */
    color : blue ; 
    border-right : 1px solid lightgrey; 
    position : relative ;
    background: transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box

  }


  .table_cell > i {
    margin-left: 175px;
    color: darkgray;
    position : absolute;
    right : 25px;
    background-color: white;
    padding: 6px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;

  }
  .table_cell > i:hover {
    /* margin-left: 175px; */
    color: white;
    /* position : absolute; */
    /* right : 25px; */
    background-color: #FD8C00;
    /* padding: 6px; */
    box-shadow: 0px 3px 6px #00000029;
    /* border-radius: 50% */

  }
 
  .table_head {
    margin: 8px 8px ;
    color : white  !important;
    font-family: 'poppins' , sans-serif !important;
    border-right : 1px solid lightgrey;

  }
  .drop_down_input {
    width : '16vw'
  }

  /* pagination */
.pagination {
  background: white;
  box-shadow: 1px 2px 2px grey;
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}
.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}