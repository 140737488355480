.cqk_Quiz_Main__div {
  width: 100%;
  height: 355px;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.9;
  padding: 31px;
  user-select: none;
}

/*-------| Quiz FIB QUESTION Styles |-------*/
.cqk_Qstn_and__Ans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.cqk_Qstn__div {
  max-height: 400px;
  overflow: auto;
  text-align: justify;
  border-right: 1px solid #faf9ff;
  padding-right: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk_Qstn__div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_Qstn__div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_Qstn__div::-webkit-scrollbar-track {
  background-color: white;
}

/*---| Scroll Bar Ends |---*/

.cqk_Fib_Head {
  font-size: 16px;
}

.cqk_Fib_Qstn_Img {
  width: 170px;
  height: 110px;
  margin-top: 20px;
}

.Paragraph_title {
  font-size: 18px;
}
.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

.cqk_Qstn_content__1 {
  /* 13px fontsize */
  font-size: 13px;
  margin-top: 20px;
  line-height: 1.7;
  color: #333333;
  opacity: 0.9;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk_Qstn_content__1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_Qstn_content__1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_Qstn_content__1::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

/*-------| Quiz FIB QUESTION Style Ends |-------*/

/*-------| Quiz FIB ANSWER Styles |-------*/
.cqk_Ans__div {
  max-height: 300px;
  overflow-x: hidden;
}

/*---| Scroll Bar Starts |---*/
.cqk_Ans__div::-webkit-scrollbar {
  width: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_Ans__div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_Ans__div::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.cqk__Head {
  font-size: 16px;
  color: #1b1b1b;
}

.cqk_Option_and_Ans {
  display: flex;
  align-items: baseline;
  gap: 40px;
  margin-left: 20px;
}

.cqk_Option_and_Ans>input {
  box-shadow: 0px 3px 6px #00000029;
}

.cqk_Option {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  width: 14px;
  height: 28px;
}

.cqk_Ans_div {
  width: 500px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  border: 1px solid #dbdee6;
  border-radius: 5px;
}

.tick_And_Cancel_Icon {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.CorrectAnsLabel {
  margin: 8px 10px 0 100px;
  font-size: 13px;
  font-weight: bold;
  text-align: justify;
}

/*-------| Quiz FIB ANSWER Styles Ends |-------*/