.Quiz_Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Title_And_Time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 35rem;
  margin: 20px 0 20px 0;
}

.Title {
  font-size: 14px;
}

.Time_OutOf {
  display: flex;
  align-items: flex-end;
}

.OutOf {
  margin: 20px 25px 0 0;
  font-size: 15px;
  color: #093eb5;
  opacity: 0.9;
  font-weight: bold;
}

.Time {
  font-size: 14px;
  opacity: 0.8;
  letter-spacing: 0.4px;
}

/*-------| Quiz (Nxt Prev Submit) Button Styles |------*/
.BtnsDiv {
  display: flex;
  justify-content: flex-end;
  user-select: none;
}

.QstnBtns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  border: 1px solid #faf9ff;
  background-color: #f8f4fc;
  padding: 20px;
}

.PrevBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #9ea3d4;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.PrevBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.az-exit-btn-container {
  width: 100%;
  text-align: right;
  /* Align the content to the right */
}

.ExitBtn {
  display: flex;
  justify-content: center;
  align-items: right;
  padding: 10px 20px;
  background-color: #19c183;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.ExitBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.NxtBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 10px 10px;
  background-color: #fd8c00;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.NxtBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.SubmitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fd8c00;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
  width: 135px;
  height: 40px;
}

.SubmitBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

/*-------| Quiz (Nxt Prev Submit) Button Style Ends |------*/

/*-------| Quiz Question Pagination Bubble Styles |-------*/
.Qstn_Bubble_div {
  margin: 0 0 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
  user-select: none;
}

.Bubble_and_img {
  width: 1179px;
  height: auto;
  display: flex;
  padding: 8px;
  gap: 50px;
}

.Qstn_Bubble_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
}

.Qstn_Bubble_img img {
  width: 55px;
  height: 87px;
}

.Qstn_Bubble_img h6 {
  color: #1b1b1b;
  font-size: 13px;
  margin-left: 15px;
}

.Qstn_Bubble_img span {
  display: flex;
  flex-direction: column;
}

.Bubbles {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 15px;
  align-content: center;
}

.Bubbles>.Bubble>div:nth-child(-n + 25) {
  grid-column: span 25;
}

.Bubbles>.Bubble>div:nth-child(n + 26) {
  grid-row: 2;
  grid-column: span 25;
}

.Bubble {
  color: #ffffff;
  font-size: 16px;
  background-color: #00ce95;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 3;
  box-shadow: 0 3px black;
  cursor: pointer;
}

.Bubble:active {
  background-color: #3e8e41;
  box-shadow: 0 5px;
  transform: translateY(4px);
}

/*-------| Quiz Question Pagination Bubble Style Ends |-------*/

/*-------| Quiz Question Info Bubbles Styles |------*/
.Info_Bubbles {
  display: flex;
  gap: 45px;
  font-size: 12px;
  color: #1b1b1b;
  opacity: 0.9;
  padding: 0 0 20px 30px;
  user-select: none;
}

.bubble_1 {
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.9;
}

.bubble_2 {
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.9;
}

.circle_1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #2fb44d;
  display: flex;
}

.circle_2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #bababa;
  display: flex;
}

/*-------| Quiz Question Info Bubbles Style Ends |------*/

/*-------| General Instructions Modal Styles |-------*/
.Modal_Head {
  display: flex;
  justify-content: space-between;
  background-color: #545282;
  padding: 15px;
}

.Modal_title {
  font-size: 18px;
  color: #ffffff;
  opacity: 0.9;
}

.Close_btn {
  color: #ffffff;
}

.modal_contents {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100% - 120px);
}

.modal_content_1 {
  font-size: 16px;
  color: #333333;
  font-weight: normal;
  opacity: 0.9;
  margin-bottom: 10px;
}

.modal_content_2 {
  font-size: 16px;
  color: #333333;
  font-weight: normal;
  opacity: 0.9;
}

.modal_content_2 span {
  color: #333333;
  font-weight: bold;
}

.modal_content_3 {
  margin-top: 30px;
  font-size: 14px;
  color: #333333;
  font-weight: normal;
  opacity: 0.9;
}

/* .Modal_Footer {
  margin-top: 250px;
} */

.Modal_Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px;
}

.Modal_Footer_Btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  gap: 15px;
  margin-top: 10px;
}

.Cancel_Btn button {
  font-size: 13px;
  background-color: #fd8c00;
  color: #ffffff;
  opacity: 0.9;
  width: 92px;
  height: 32px;
  border: none;
  border-radius: 2px;
}

.Start_Btn button {
  font-size: 13px;
  background-color: #00ad34;
  color: #ffffff;
  opacity: 0.9;
  width: 92px;
  height: 32px;
  border: none;
  border-radius: 2px;
}

.U_line {
  width: 100%;
  border-top: 1px solid #f2f2f2;
  opacity: 0.9;
}

/*-------| General Instructions Modal Style Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Title_And_Time {
    gap: 36.4vw;
    margin: 1.3vw 0 1.3vw 0;
  }

  .Title {
    font-size: 1.3vw;
  }

  .OutOf {
    margin: 1.3vw 1.62vw 0 0;
    font-size: 1.3vw;
  }

  .Time {
    font-size: 1.3vw;
  }

  .QstnBtns {
    margin-top: 0.32vw;
    padding: 1.5vw;
  }

  .PrevBtn {
    width: 17vw;
    height: 4vw;
    margin-right: 1.3vw;
  }

  .PrevBtn img {
    width: 1.4vw;
    height: 1.66vh;
  }

  .PrevBtn h6 {
    font-size: 1.3vw;
  }

  .NxtBtn {
    width: 14vw;
    height: 4vw;
    margin-right: 1.3vw;
  }

  .NxtBtn img {
    width: 1.4vw;
    height: 1.66vh;
  }

  .NxtBtn h6 {
    font-size: 1.3vw;
  }

  .SubmitBtn {
    margin-right: 1.3vw;
    width: 11vw;
    height: 4vw;
  }

  .SubmitBtn h6 {
    font-size: 1.3vw;
  }

  .Qstn_Bubble_div {
    margin: 0 0 1.5vw 0;
  }

  .Bubble_and_img {
    width: 90.5vw;
    height: auto;
    gap: 4.55vw;
  }

  .Qstn_Bubble_img {
    margin: 0 0 0 1.8vw;
  }

  .Qstn_Bubble_img img {
    width: 3.6vw;
    height: 12vh;
  }

  .Qstn_Bubble_img h6 {
    font-size: 1.3vw;
    margin-left: 2vw;
  }

  .Bubbles {
    gap: 1.2vw;
  }

  .Bubble {
    font-size: 1.5vw;
    border-radius: 4.3vw;
    width: 2em;
    height: 2em;
  }

  .Info_Bubbles {
    gap: 3.2vw;
    font-size: 1.3vw;
    padding: 0 0 2.35vw 2vw;
  }

  .bubble_1 {
    gap: 0.2vw;
  }

  .bubble_2 {
    gap: 0.2vw;
  }

  .circle_1 {
    width: 12px;
    height: 12px;
  }

  .circle_2 {
    width: 12px;
    height: 12px;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1024px) and (max-width: 1430px) {
  .Title_And_Time {
    gap: 34.4vw;
    margin: 2vw 0 2vw 0;
  }

  .Title {
    font-size: 1.7vw;
  }

  .OutOf {
    margin: 1.5vw 1.72vw 0 0;
    font-size: 1.1vw;
  }

  .Time {
    font-size: 1.1vw;
  }

  .QstnBtns {
    margin-top: 0.52vw;
    padding: 1.5vw;
  }

  .PrevBtn {
    width: 15vw;
    height: 3.5vw;
    margin-right: 1.3vw;
  }

  .PrevBtn img {
    width: 1.8vw;
    height: 2vh;
  }

  .PrevBtn h6 {
    font-size: 1.2vw;
  }

  .NxtBtn {
    width: 14vw;
    height: 3.5vw;
    margin-right: 1.3vw;
  }

  .NxtBtn img {
    width: 1.8vw;
    height: 2vh;
  }

  .NxtBtn h6 {
    font-size: 1.2vw;
  }

  .SubmitBtn {
    margin-right: 1.3vw;
    width: 10vw;
    height: 3.5vw;
  }

  .SubmitBtn h6 {
    font-size: 1.2vw;
  }

  .Qstn_Bubble_div {
    margin: 0 0 1.7vw 0;
  }

  .Bubble_and_img {
    width: 90.5vw;
    height: auto;
    gap: 4.55vw;
  }

  .Qstn_Bubble_img {
    margin: 0 0 0 2vw;
  }

  .Qstn_Bubble_img img {
    width: 4.6vw;
    height: 13vh;
  }

  .Qstn_Bubble_img h6 {
    font-size: 1.3vw;
    margin-left: 2.2vw;
  }

  .Bubbles {
    gap: 0.9vw;
  }

  .Bubble {
    font-size: 1.2vw;
    border-radius: 4.3vw;
    width: 2em;
    height: 2em;
  }

  .Info_Bubbles {
    gap: 3.4vw;
    font-size: 1.1vw;
    padding: 0 0 2.45vw 2.2vw;
  }

  .bubble_1 {
    gap: 0.3vw;
  }

  .bubble_2 {
    gap: 0.3vw;
  }

  .circle_1 {
    width: 13px;
    height: 13px;
  }

  .circle_2 {
    width: 13px;
    height: 13px;
  }
}