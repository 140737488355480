* {
    box-sizing: border-box;
    font-family: Poppins,sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
.icon {
    margin: 0px 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
.icon span i {
    line-height: 50px;
      font-size: 25px;
      color: grey;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
}
.icon span {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
.icon .tooltip {
    position: absolute;
    top: 0;
    width: 70px;
    z-index: 1;
    background: #fff;
    color: gray;
    /* padding: 9px 5px; */
    font-size: 10px;
    font-weight: 500;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .icon:hover .tooltip {
    top: -35px;
    opacity: 1;
    pointer-events: auto;
  }
  
.icon .tooltip:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .icon:hover span  i{
    color: #ff000099;
  }
  .icon:hover span , .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  }

  .table_head {
    color: blue !important;
    font-family: 'poppins' !important;
    font-weight: 600;
    background: #d9dcfe !important;

}
.table_cell {
    font-family: 'poppins' !important;
}
.pencil_icon  > i{
    font-size: 24px;
    margin-right: 20px;
    background-color: orange;
    padding: 1px 7px;
    border-radius: 50%;
    /* margin-bottom: 5px; */
    padding-bottom: 7px;
    color: white;
}