.horizontal-scroll-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroll-content {
  display: flex;
  align-items: center;
  column-gap: 4;
  width: 100%;
  margin-top: 16px;
  overflow-x: auto;
  height: 90%;
  margin: 0 auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.scroll-content::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit browsers */
}

.scroll-button {
  position: absolute;
  top: 40%;
  bottom: 0;
  border: none;
  cursor: pointer;
  background: none;
  width: 24px;
  height: 30px;
}

.scrl-btn-left {
  left: -30px;
}

.scrl-btn-left img {
  transform: rotate(-180deg);
}

.scrl-btn-right {
  right: -30px;
  /* transform: rotate(180deg); */
}
