.bp-level-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  margin-left: 20px;
  margin-right: 40px;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.sp-tabhead {
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  background: #fff;
  padding: 20px;
  width: 469px;
  height: 67px;
  border-radius: 8px 8px 0px 0px;
  margin-left: 20px;
  padding-bottom: 0;

}

.sp-tab {
  width: 50%;
  color: #000;
  cursor: pointer;
}

.sp-tab p{
  color: #000;
  text-align: center;
  color: rgba(27, 27, 27, 0.54);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width:80%;
  margin:0 auto;
  padding-top: 6px;
  padding-bottom: 5px;
  border-radius: 5px 5px 0px 0px;
}

.sp-active-tab {
  border-bottom: 3px solid #001AF3;

}

.sp-active-tab p{
  color: #fff;
  font-weight: 700;
  background-color: #001AF3;

}