* {
  box-sizing: border-box;
  font-family: Poppins;
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  flex-direction: row;
}
.shadow_container {
  box-shadow: 0px 2px 4px 2px #808080a1;
  margin-inline: 10px;
  border-radius: 11px;
  width: 50%;
  border-right: 1px solid #e8e8e8;
}
.orangeBorder_btn {
  padding: 7px 14px;
  margin: 3px 5px;
  color: #fd8c00;
  background-color: white;
  border: 1px solid #fd8c00;
  border-radius: 21px;
  background-color: transparent;
}

.orange_btn {
  padding: 7px 14px;
  margin: 3px 5px;
  color: white;
  background-color: #fd8c00;
  border: 1px solid #fd8c00;
  border-radius: 21px;
  position: relative;
  top: 0;
  transition: top ease 0.2s;
}
.orange_btn:hover {
  top: -2px;
  box-shadow: 2p 2px 4px orange;
  /* color : white ;
  background-color: #FD8C00; */
}
.grey_btn {
  padding: 7px 14px;
  margin: 3px 5px;
  color: #7a7a7a;
  background-color: white;
  border: 1px solid #7a7a7a;
  border-radius: 21px;
}

.grey_btn:hover {
  background-color: #7a7a7a;
  color: white;
}

/* modal  */
.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.main_container {
  width: 500px;
  height: 250px;
  border-radius: 20px;
  background-color: white;
}
.main_container p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

#modal_id {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.table_head {
  color: blue !important;
  font-family: "poppins" !important;
  font-weight: 600;
  background: #d9dcfe !important;
}
.table_cell {
  font-family: "poppins" !important;
}
/* pagination */
.pagination {
  background: white;
  box-shadow: 1px 2px 2px grey;
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}
.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/* btns  */

.green_btn {
  color: #fff;
  background-color: #4caf50;
  border-radius: 150px;
  border-color: #4caf50;
  padding: 8px 21px;
  /* width:140px;
height : 43px; */
}
.green_btn:hover {
  color: #fff;
  background-color: #4caf50;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #78be20;
}
.red_btn {
  color: #fff;
  background-color: #ef0f0f;
  border-radius: 150px;
  border-color: #ef0f0f;
  padding: 8px 21px;
}
.red_btn:hover {
  color: #fff;
  background-color: #ef0f0f;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #e57373;
}
.white_btn {
  color: #7a7a7a;
  background-color: white;
  border-radius: 150px;
  border: 1px solid #7a7a7a;
  /* // width : 100px, */
  padding: 7px;
  min-width: 100px;
  font-size: 16px;
  /* height : 32px; */
  margin: 3px auto;
  /* // padding: 8px 21px, */
}
.white_btn:hover {
  color: black;
  background-color: white;
  border: 1px solid #7a7a7a;
  top: -2;
  box-shadow: 0px 3px 5px grey;
}
.blue_btn {
  color: #fff;
  width: 150px;
  padding: 7px;
  background-color: blue;
  border-radius: 150px;
  border-color: grey;
}
.blue_btn:hover {
  color: #fff;
  background-color: blue;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px blue;
}

.select_input {
  font-family: "poppins" !important;
  color: #b5b6b6;
  font-weight: 300;
  font-size: 1.2vw !important;
}
/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* link  */
.plan_container_link > .plan_link_text {
  color: #354052;
}

.plan_container_link {
  display: flex;
  text-decoration: none;
  margin: 10px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}

.plan_links_items {
  list-style-type: none;
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  background-color: white;
  box-shadow: 1px 1px grey;
  margin: 6px;
}

.plan_links_items:hover {
  border-left-width: 8px;
  border-left-color: #403e75;
  box-shadow: 2px 1.5px 7px grey;
}

.plan_links_items:hover .plan_link_text {
  color: orange;
}

.plan_link_text {
  color: black;
  margin: 5px 4px;
  width: fit-content;
  font-size: 0.8rem;
  padding-left: 20px;
}

i.fa.fa-angle-down {
  padding: 10px;
}

/* plan unorder listends  ends here */
.select_input {
  font-family: "poppins" !important;
  color: #b5b6b6;
  font-weight: 300;
  font-size: 1.2vw !important;
}

.az_card_img {
  top: '90%';
  left: '30%';
  text-align: 'center';
  padding: '1px';
  box-sizing: 'border-box';
  font-size: "10px" !important;
}

.az_card {
  width: '200px';
  height: '332px';
  border: '1px solid #ddd';
  border-radius: '8px';
  overflow: 'hidden';
  margin: '16px';
  position: 'relative';
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
  background-color: "white"
}