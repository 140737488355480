.upload-assessment-title {
  color: #1b1b1b;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upload-assessment-form-container {
  height: 300px;
  border-radius: 18px;
  background-color: #ffffff;
  margin-top: 10px;
  width: 100%;
  position: relative;
}

.button-submit-update {
  border-radius: 12px;
  border: none;
  color: #ffffff;
  width: 145px;
  height: 45px;
  background-color: #006cec;
}

.button-cancel-assessment {
  border-radius: 12px;
  border: 1px solid #7e7e7e;
  color: #7e7e7e;
  width: 145px;
  height: 45px;
  margin-right: 10px;
  background-color: #fff;
}

.image-button-upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #001af3;
  color: #fff;
  padding: 6px 10px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 220px;
}

.MuiInputLabel-root.Mui-required {
  display: flex;
  flex-direction: row-reverse;
}
