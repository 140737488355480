.scrollable-section {
    -ms-overflow-style: scroll !important;
    /* For modern browsers */
    overflow-y: scroll !important;
    /* For modern browsers */
}

/* Optionally hide the default WebKit scrollbar appearance */
.scrollable-section::-webkit-scrollbar {
    width: 12px !important;
    /* Adjust as needed */
}

/* Optionally style the thumb and track in WebKit browsers */
.scrollable-section::-webkit-scrollbar-thumb {
    background-color: #e9e5e5 !important;
}

.scrollable-section::-webkit-scrollbar-track {
    background-color: #fbfafa !important;
}

.fullpageWhite {
    background-color: white;
}

.Paragraph_title {
    font-size: 18px;
}

.Paragraph {
    margin-top: 1px;
    text-align: justify;
    font-size: 16px;
    margin-bottom: 2px;
}

.question_paper_font {
    font-family: "Calibri";
    font-weight: bold;
    font-size: 1.18vw;
}

.question_paper_font_new {
    font-family: "Calibri";
    font-weight: bold;
    font-size: 1.23vw;
}

.question_paper_instructions {
    font-family: "Calibri";
    font-style: italic;
    font-size: 1.18vw;
    font-weight: bold;
}

.question_paper_instructions_list{
    font-family: "Calibri";
    font-size: 1.03vw;
    font-weight: 500;
    color: black !important;
}

.question_paper_font_text{
    font-family: "Calibri";
    font-weight: bold;
    font-size: 1.082vw;
}

.question_container{
    padding: 10px;
    /* border: 2px solid black; */
}
.instructions_container{
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    padding: 30px;
}

.unordered_list_format{
    margin-top: 10px;
    list-style-type: none;
    font-size: 1.03vw;
}

.section {
    page-break-inside: avoid;
    margin-bottom: 20px;
  }
  
  .section-heading {
    page-break-before: auto;
    padding-top: 5px;
}

.page-break {
    page-break-before: always;
}

@media print {
    .section {
      page-break-inside: avoid;
    }

    .section-heading {
        page-break-before: auto;
        padding-top: 5px;
    }

    .page-break {
        page-break-before: always;
    }
}