* {
    box-sizing: border-box;
    font-family: "Poppins",sans-serif;
    margin: 0;
    padding: 0;
}

.first_box::after {
    content: '';
    display: block;
    height: 60px;
    width: 5px;
    color: grey;

}
.link_text {
    color : green ;
    font-size: 68px;;
}
.fSize {
    font-size: xx-large;
    color: green;
}
.white{
    color : green;
    text-transform: capitalize;
}
.white_box {
    top: 271px;
    left: 251px;
    width: 1140px;
    height: 685px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 6px;
    opacity: 1;
}