@import url('https://fonts.googleapis.com/css2?family=Itim&family=Poppins:wght@500&display=swap');

* {
  /* font-family: 'Poppins', sans-serif; */
  font-family: sans-serif;


}

.App {

  font-family: sans-serif;
  text-align: center;

}

.file-viewer-style {
  border: 10px solid green;
  width: 50%;
  height: 100%;
}

.Icon {
  position: relative;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationCount {
  position: absolute;
  top: -5px;
  right: -2px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0px 2px;
  font-size: 10px;
}

.dropDown {
  position: absolute;
  top: 70px;
  right: 60px;
  width: 350px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  background-color: rgb(64, 62, 117);

}

.dropDown h3 {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;

}

.notificationContent {
  max-height: 300px;
  overflow-y: auto;
}

.notificationMain {
  flex: 1;
  background-color: #fff;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-radius: 5px;

}

.notificationMain h5 {
  font-size: 14px;
}

.notificationMain .text {
  font-size: 10px;
  white-space: nowrap;
  /* Prevent line breaks */
  overflow: hidden;
  /* Hide overflow */
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.notificationDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationDetails p {
  font-size: 10px;
}

/*---------------- Show Marking --------------------*/
.answerBg {
  background-color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 20px;
  padding: 5px 20px;
  border-radius: 10px;
  width: 50%;
}

.answer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000000;

}

.options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
}


/*--------  Subjective Papers DropDowns CSS   ----------*/
.Subjective-box {
  min-width: 80% !important;
  width: 40% !important;
  max-height: 80% !important;
  padding: 10px !important;
  overflow-y: auto !important;
}

.Subjective-box::-webkit-scrollbar {
  width: 10px !important;
}

.Subjective-box::-webkit-scrollbar-track {
  background: #ccc !important;
}

.Subjective-box::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.Subjective-box::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}



.inputBlue {
  width: 100px !important;
  background-color: red !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 0 1px 0 !important;
  font-size: 12px !important;
}

.css-rcnv8v-MuiFormControl-root {
  min-width: 100px !important;
  height: 35px !important;
}

#demo-simple-select-label {
  font-size: 13px !important;
  padding-bottom: 10px !important;

}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0 !important;
}

.css-10hburv-MuiTypography-root {
  padding: 0 10px !important;
  font-size: 10px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 10px !important;
}

.css-1oaazs1-MuiSvgIcon-root-MuiSelect-icon {
  font-size: 14px !important;
  margin-left: 2px !important;
}

.screenScroll {
  overflow-y: auto !important;
  height: 100% !important;
}

.screenScroll::-webkit-scrollbar {
  width: 5px !important;
}

.screenScroll::-webkit-scrollbar-track {
  background: transparent !important;
}

.screenScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.screenScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


.screenScrollCordinator {
  overflow-y: auto !important;
  height: 100% !important;
}

.screenScrollCordinator::-webkit-scrollbar {
  width: 8px !important;
}

.screenScrollCordinator::-webkit-scrollbar-track {
  background: transparent !important;
}

.screenScrollCordinator::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.screenScrollCordinator::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.dashboard_section {
  background: var(--backgroundColor);
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  /* overflow-x: hidden; */
}

.modalScroll {
  overflow-y: auto !important;
  height: 100% !important;
}

.modalScroll::-webkit-scrollbar {
  width: 8px !important;
}

.modalScroll::-webkit-scrollbar-track {
  background: #ccc !important;
}

.modalScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.modalScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.screenScroll {
  overflow-y: auto !important;
  height: 100% !important;
}

.screenScroll::-webkit-scrollbar {
  width: 5px !important;
}

.screenScroll::-webkit-scrollbar-track {
  background: transparent !important;
}

.screenScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.screenScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.screenScrollCordinator {
  overflow-y: auto !important;
  height: 100% !important;
}

.screenScrollCordinator::-webkit-scrollbar {
  width: 8px !important;
}

.screenScrollCordinator::-webkit-scrollbar-track {
  background: transparent !important;
}

.screenScrollCordinator::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.screenScrollCordinator::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


.viewScroll {
  overflow-y: auto !important;
  /* height: 100% !important; */
}

.viewScroll::-webkit-scrollbar {
  width: 10px !important;
}

.viewScroll::-webkit-scrollbar-track {
  background: transparent !important;
}

.viewScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}

#text-field-subjective ::placeholder {
  font-size: 13px !important;
}

.text-field-subjective ::placeholder {
  font-size: 13px !important;
}

.viewScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.modalScroll {
  overflow-y: auto !important;
  height: 100% !important;
}

.modalScroll::-webkit-scrollbar {
  width: 8px !important;
}

.modalScroll::-webkit-scrollbar-track {
  background: #ccc !important;
}

.modalScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.modalScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


/* ----------------------------------------- upskill css ---------------------------- */
.upskillHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 0 10px;
}

.upskillHeader h1 {
  font-size: 18px;
  font-weight: 400;
}

.addCourseBtn {
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  background-color: #403e75;
  color: #fff;
}

.addCourseBtn:hover {
  background-color: #2a294e;

}

.tableData {
  margin: 10px 15px;
  position: relative;
}

.tableData table {
  border-collapse: collapse;
  border-radius: 10px;
  display: block;
  width: 100% !important;
}

.tableData table thead tr th {
  font-size: 12px;
  text-align: center !important;
  padding: 15px 10px;
  color: #fff;
  font-weight: 400;
  border: none;
  width: 130px !important;
  white-space: nowrap;
}

.tableData table tbody tr td {
  font-size: 12px;
  text-align: center !important;
  padding: 15px 10px;
  color: #fff;
  font-weight: 400;
  border: none;
  width: 130px !important;
  white-space: nowrap;
}

.tableData table tbody tr:nth-child(odd) {
  background-color: #edebeb78 !important;
  width: 100%;
}

.tableheaddiv {
  background-color: #403e75;
  border-radius: 10px 10px 0 0;

}

.tableBodyDiv {
  width: 100%;
}

.tableData tbody tr:last-child td {
  border-bottom: none !important;
}

.tableData tbody tr:hover {
  border-bottom: none !important;
  outline: none !important;
}

.buttonContainer {
  position: relative;
  display: inline-block;
}

.viewButton {
  color: #fff;
  background-color: #ffa500;
  border: 1px solid #ffa500;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.viewButton:hover {
  background-color: #fff;
  color: #ffa500;

}

.editButton {
  color: #fff;
  background-color: #403e75;
  border: 1px solid #403e75;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.editButton:hover {
  background-color: #fff;
  color: #403e75;

}

.viewButton .viewLabel {
  position: absolute;
  font-size: 10px !important;
  background-color: #2f2e2e4e;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  top: -20px;
  left: 20px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.viewButton:hover .viewLabel {
  opacity: 1;
}

.editButton .editLabel {
  position: absolute;
  font-size: 10px !important;
  background-color: #2f2e2e4e;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  top: -20px;
  left: 20px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.editButton:hover .editLabel {
  opacity: 1;
}

.tableData table tbody tr:hover {
  box-shadow: 0px 0px !important;
}

.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.toolTip {
  position: absolute;
  z-index: 9999;
  background-color: #edebeb;
  padding: 5px;
  left: 0;
  font-size: 12px !important;
  top: 5px;
  max-width: 150px;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}



.dateSpan {
  font-size: 10px !important;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: max-content !important;
}


/* -----------------------------upskills --------- add course ------------------------------------ */
.addCourseForm {
  margin: 10px 20px;
}

.inputDiv {
  padding: 10px;
}

.inputDiv label {
  font-size: 0.6em !important;
  font-weight: 300;
}

.inputDiv input {
  width: 100%;
  padding: 10px 20px !important;
  border-radius: 30px;
  border: 1px solid grey;
  outline: none;
  color: #000000;
  background-color: transparent !important;
}

.inputDiv input:focus {
  color: #ffa500 !important;
  border: 1px solid #ffa500;
}

.inputDiv input:hover {
  border: 1px solid #ffa500;
}

.inputDiv {
  position: relative;
}

.inputLabel {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  font-size: 16px;
  margin-left: 30px;
  margin-top: 3px;
  background-color: #f8f4fc !important;
  transition: all 0.3s ease;
}

.inputDiv textarea {
  height: 100px;
  width: 100%;
  padding: 10px 20px !important;
  border-radius: 20px;
  border: 1px solid grey;
  outline: none;
  color: #000000;
  background-color: transparent !important;
  resize: none;
}

.inputDiv textarea:focus {
  color: #ffa500 !important;
  border: 1px solid #ffa500;
}

.inputDiv textarea:hover {
  border: 1px solid #ffa500;
}

.addCBtn {
  padding: 10px 20px;
  border: none;
  background-color: #403e75;
  color: #fff;
  border-radius: 10px;
}

.cancelCBtn {
  padding: 10px 20px;
  border: none;
  background-color: #ffa500;
  color: #fff;
  border-radius: 10px;
}

.btnGrid {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  left: 80%;
}

.noCourse {
  text-align: center;
  margin: 10px;
  font-weight: 300;
}

/* -----------------------------viewModal ---------------------------------- */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

}

.viewModal {
  background-color: rgb(163, 162, 199) !important;
  margin: 50px;
  width: 50%;
  top: 100px;
  left: 300px;
  position: relative;
  place-items: center;
  border-radius: 20px;
  padding: 10px;
  z-index: 1100;
}

.modalContent {
  position: relative;
}

.modalContent span {
  position: absolute;
  top: -30px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
}

.viewImage img {
  width: auto;
  height: 200px;
  border-radius: 20px;
  margin: 10px;
}

.viewImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ccc !important;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.viewHeader h2 {
  font-weight: 400 !important;
  font-size: 20px !important;
}

.viewHeader p {
  font-size: 14px !important;
  color: red !important;
}

.viewBody {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.viewBody p {
  padding: 10px 20px;
  background-color: #ccc;
  border-radius: 10px;
}

.viewFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.viewFooter p {
  font-size: 12px;
  color: #000000;
  padding: 0 30px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .prevNext {
  padding: 5px;
  width: 80px;
  border: none;
  border-radius: 30px;
  background-color: #403e75;
  color: #fff;
  margin: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
}

.pagination .disabled {
  background-color: #9c9bb9;
}

.pagination .prevNext svg {
  font-size: 1em;
  background-color: transparent !important;
}

.pagination .activePage {
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-size: 0.6em;
  background-color: #403e75;

}

.pagination .inactivePage {
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 0.6em;
  border-radius: 50px;
  border: none;

}

/* -----------------------------------------modalStartQuiz------------------------ */
.modalBox .backButton {
  background-color: #403e75 !important;
  padding: 10px 30px !important;
  border: 2px solid #403e75 !important;
  border-radius: 50px !important;
  color: #fff;
  margin: 10px 10px;
}

.modalBox .backButton:hover {
  background-color: #fff !important;
  color: #403e75 !important;
}

.modalBox .startButton {
  background-color: #e7ab3c !important;
  padding: 10px 30px !important;
  border: 2px solid #e7ab3c !important;
  border-radius: 50px !important;
  color: #fff;
  margin: 10px 10px;
}

.modalBox .startButton:hover {
  background-color: #fff !important;
  color: #e7ab3c !important;
}

.modalBox .buttonDiv {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center;
  width: 100% !important;
}

.resumeType {
  display: flex;
  align-items: center !important;
  justify-content: center !important;

}

.resumeModalBox {
  display: flex !important;
  align-items: center !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
}

.modalBox {
  position: absolute;
  top: 10%;
  left: 20%;
  /* -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  /* width: 600px; */
  width: 60% !important;
  height: 500px;
  border-radius: 10px;
  border: none !important;
  background-color: #d7d6eb !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

.modal_contents::-webkit-scrollbar {
  width: 5px;
}

.modal_contents::-webkit-scrollbar-track {
  background: transparent !important;
}

.modal_contents::-webkit-scrollbar-thumb {
  background: #888;
}

.modal_contents::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal_content_3 {
  padding: 20px !important;
  margin: 0 20px !important;
}

.modalBox2 .backButton {
  background-color: #403e75 !important;
  padding: 10px 30px !important;
  border: 2px solid #403e75 !important;
  border-radius: 50px !important;
  color: #fff;
  margin: 10px 10px;
}

.modalBox2 .backButton:hover {
  background-color: #fff !important;
  color: #403e75 !important;
}

.modalBox2 .startButton {
  background-color: #e7ab3c !important;
  padding: 10px 30px !important;
  border: 2px solid #e7ab3c !important;
  border-radius: 50px !important;
  color: #fff;
  margin: 10px 10px;
}

.modalBox2 .startButton:hover {
  background-color: #fff !important;
  color: #e7ab3c !important;
}

.modalBox2 .buttonDiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 100% !important;
}

.resumeType {
  display: flex;
  align-items: center !important;

}


.modalBox2 {
  position: absolute;
  top: 30%;
  left: 30%;
  /* -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  width: 500px;
  /* width: 60% !important; */
  border-radius: 10px;
  border: none !important;
  background-color: #d7d6eb !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}


.boxModal {
  z-index: 1000 !important;
}



/* -----------------------------------QuizComponent---------------------------------- */



.linkExit {
  text-decoration: none !important;
}

.quizz {
  width: 100%;
  height: max-content !important;
  background-color: #f8f4fc !important;
}

.quizHeader {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #403e75;
  padding: 10px 20px;
  text-align: center;
  height: 100%;
  /* gap: 70vh; */
  color: #fff;
}

.gridQuizHead {
  text-align: left !important;
}

.gridQuizHead1 {
  text-align: right !important;
}


.quizHeader span {
  font-size: 14px;
}

.quizHeader h1 {
  font-size: 1em;
}

.quizz svg {
  background: transparent;
}

.quizHeader span svg {
  margin: 0 10px;
}

.quizHeader .timerClass {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quizz .OutOff {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  margin: 0 60px;
  font-weight: 400;
}

.attemDiv {
  display: flex;
  justify-content: center;
}

.attemDiv .diffAttem {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #555;
}

.diffAttem h3 {
  font-size: 16px !important;
}

.attemDiv .attem {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  color: #888;
}

.attemDiv .unattem {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  color: #888;

}

.attemDiv .partialattend {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  color: #888;

}

.attemDiv .partialattend svg {
  color: #f5c978;
  font-size: 20px;
}

.attemDiv .attem svg {
  color: rgba(5, 175, 116, 0.89);
  font-size: 20px;
}

.attemDiv .unattem svg {
  color: rgb(146, 141, 141);
  font-size: 20px;
}

.quizQuestion {
  margin: 10px 40px !important;
  max-height: 100% !important;
  height: 70vh;
  margin: 10px;
}

.quesTimer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 20px;
}

.questionGrid {
  margin: 0px 30px !important;
  max-height: 100% !important;
  height: 60vh !important;
  overflow: auto !important;
}

.questionGrid::-webkit-scrollbar {
  width: 5px;
}

.questionGrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.questionGrid::-webkit-scrollbar-thumb {
  background: #888;
}

.questionGrid::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.questionGrid p {
  color: #000000 !important;
  font-size: 1em !important;
}

.questionGrid .smallP {
  font-size: 12px !important;
}

.questionImage {
  margin: 10px 30px;
  cursor: pointer;
}

.questionImage img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

.optionGrid {
  margin: 0px 50px !important;
  max-height: 100% !important;
  overflow: auto !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.optionGrid label {
  border: 2px solid #ffa500;
  padding: 0px 20px;
  border-radius: 20px;
  font-size: 0.7em;
  color: #555;
  cursor: pointer;
}

.optionGrid label:hover {
  background-color: #ffa500;
  color: #fff;
}

.optionGrid .selected {
  background-color: #e7ab3c;
  border: 2px solid #e7ab3c;
  color: #fff;
}

.optionGridfill {
  margin: 0px 50px !important;
  max-height: 100% !important;
  /* height: 60vh !important; */
  overflow: auto !important;
  position: relative;
}

.optionGridfill .inputWithLabel input {
  padding: 5px 30px;
  width: 100%;
  font-size: 0.7em;
  border-radius: 50px;
  border: 2px solid #ffa500;
  outline: none;
  background-color: transparent;
}

.optionGridfill .inputWithLabel input:focus {
  background: transparent !important;
}

.optionGridfill .inputWithLabel .answerLabel {
  position: relative;
  top: 10px;
  padding: 0 5px;
  font-size: 0.8em;
  margin-left: 30px;
  margin-top: 3px;
  background-color: #f8f4fc !important;
  transition: all 0.3s ease;
}

.questionContainerCase {
  margin: 0px 30px !important;
  max-height: 100% !important;
  height: 70vh !important;
  overflow: auto !important;
}

.questionContainerCase::-webkit-scrollbar {
  width: 5px;
}

.questionContainerCase::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.questionContainerCase::-webkit-scrollbar-thumb {
  background: #888;
}

.questionContainerCase::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.questionContainerCase h2 {
  font-size: 1.2em !important;
}

.questionContainerCase p {
  color: #000000;
}

.optionnn {
  margin-bottom: 10px;
}

.optionGridCase {
  margin: 0px 30px !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px !important;
}

.optionGridCase label {
  border: 2px solid #ffa500;
  padding: 0px 20px;
  border-radius: 20px;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  color: #555;
  cursor: pointer;
}

.optionGridCase label:hover {
  background-color: #ffa500;
  color: #fff;
}

.optionGridCase .selected {
  background-color: #e7ab3c;
  border: 2px solid #e7ab3c;
  color: #fff;
}

.optionGridCasefill {
  margin: 0px 30px !important;
  max-height: 100% !important;
  position: relative;
}

.optionGridCasefill .inputWithLabel {
  margin-top: 0px;
}

.optionGridCasefill .inputWithLabel input {
  padding: 5px 30px;
  width: 100%;
  font-size: 0.7em;
  border-radius: 50px;
  border: 2px solid #ffa500;
  outline: none;
  background-color: transparent;
}

.optionGridCasefill .inputWithLabel input:focus {
  background-color: transparent;
}

.optionGridCasefill .inputWithLabel .answerLabel {
  position: relative;
  top: 10px;
  padding: 0 5px;
  font-size: 0.8em;
  margin-left: 30px;
  margin-top: 3px;
  background-color: #f8f4fc !important;
  transition: all 0.3s ease;
}

.customRadio .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
}

.optionGrid .selected .customRadio .MuiSvgIcon-root,
.optionGrid .selected .customCheckbox .MuiSvgIcon-root,
.optionGridCase .selected .customRadio .MuiSvgIcon-root,
.optionGridCase .selected .customCheckbox .MuiSvgIcon-root {
  color: #fff !important;
}

.customCheckbox .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
}

.quizBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f4fc !important;
  padding-top: 10px !important;
}

.questionPagination {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 20px;
}

.questionPagination .bubbleImgDiv {
  display: flex;
  align-items: center;
}

.questionPagination .bubbleImgDiv img {
  width: 50px !important;
}

.questionPagination .prevBtn {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  border: 2px solid #403e75;
  background-color: #403e75;
  color: #fff;
  text-align: center;
}

.questionPagination .disabled {
  background-color: #7f7e9e;
  border: 2px solid #7f7e9e;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.questionPagination .prevBtn:hover {
  background-color: #fff;
  color: #403e75;
}

.questionPagination .nextBtn {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  border: 2px solid #ffa500;
  background-color: rgb(255, 167, 0);
  color: #fff;
  text-align: center;
}

.questionPagination .nextBtn:hover {
  background-color: #fff;
  color: rgb(255, 167, 0);
}

.questionPagination .numberBtnGrp button {
  padding: 5px 10px;
  /* width: 20px; */
  background-color: rgb(146, 141, 141);
  border: none;
  font-size: 0.7em;
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  border: 2px solid rgb(146, 141, 141);
}

.questionPagination .numberBtnGrp .active {
  background-color: #fff !important;
  color: rgb(146, 141, 141) !important;
  border: 2px solid rgb(146, 141, 141) !important;

}

.questionPagination .numberBtnGrp .answeredPage {
  background-color: rgba(5, 175, 116, 0.89);
  border: 2px solid rgba(5, 175, 116, 0.89);
  color: #fff;
}

.questionPagination .numberBtnGrp .partiallyAnswered {
  background-color: #f5c978;
  border: 2px solid #f5c978;
  color: #fff;
}

.questionPagination .numberBtnGrp button:hover {
  background-color: #ffa500;
  border: 2px solid #ffa500;
  color: #fff;
}

/* .quizSubmit{
  display: flex;
  justify-content: flex-end;
  margin: 10px 20px;
} */
.submitBtn {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  border: 2px solid #ffa500;
  background-color: rgb(255, 167, 0);
  color: #fff;
  text-align: center;
  gap: 10px;


}

.submitBtn:hover {
  background-color: #fff;
  color: #ffa500;
}

.submitPopUpBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitPopUp {
  background-color: white;
  width: 50%;
  height: 50vh;
  padding: 20px;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.submitPopUpPreview {
  background-color: white;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
}

.submitPopUp h3 {
  color: #403e75;
  padding: 40px;
}

.submitPopUp .buttonDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  gap: 50px;
}


.submitPopUp button {
  border: none;
  padding: 10px 40px;
  border-radius: 50px;
}

.submitPopUp .buttonDiv .cancel {
  background-color: #403e75;
  color: #fff;
  border: 2px solid #403e75;
}

.submitPopUp .buttonDiv .confirm {
  background-color: #e7ab3c;
  border: 2px solid #e7ab3c;
  color: #fff;
}

.submitPopUp .buttonDiv .confirm:hover {
  background-color: #fff;
  color: #e7ab3c;
}

.submitPopUp .buttonDiv .cancel:hover {
  background-color: #fff;
  color: #403e75;
}

.exitBtnn a {
  text-decoration: none;
  color: #fff;
}

.quiz {
  position: relative;
  z-index: 1;
  /* Ensure the quiz content is below the overlay */
}

.alertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Ensure the overlay is on top */
}

.alertPopUp {
  background: white;
  width: 80vh;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: red;
}

.alertPopUp button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #403e75;
  border: 2px solid #403e75;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.alertPopUp button:hover {
  background: #fff;
  color: #403e75;
}
.examOverHead{
  font-size: 35px;
  color: #403e75;
}
.examOverContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  /* box-shadow: 10px 10px 10px rgba(0, 0, 1, 1); */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
}
.overIcon svg{
  font-size: 50px;
}
.examOverText{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.examOverExit {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  border: 2px solid #ffa500;
  background-color: rgb(255, 167, 0);
  color: #fff;
  text-align: center;
  margin-top: 20px;
  gap: 10px;
}

.examOverExit:hover {
  background-color: #fff;
  color: #ffa500;
}


/* ------media query for quiz component ------------- */
@media screen and (max-width: 968px) {
  .quizQuestion {
    height: 100% !important;

  }

  .questionGrid {
    overflow: visible !important;
    height: 100% !important;
  }

  .questionGrid::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar */
  }

  .questionContainerCase {
    overflow: visible !important;
    height: 100% !important;
  }

  .quizBottom {
    position: static;

  }

  .questionPagination {
    justify-content: space-between;
  }

  .questionContainerCase::-webkit-scrollbar {
    display: none !important;
    /* Hide scrollbar */
  }

  .questionPagination .numberBtnGrp {
    display: none;
  }
}







/*----------------------- report --------------------------------*/
.explain {
  margin-top: 20px !important;
  font-size: 12px !important;
}


/* --------------- drop down ui --------------------------- */

.dropdownSelect {
  background-color: #fff !important;
  border-radius: 100px !important;
  font-size: 10px !important;
}

.menuDiv {
  width: 100% !important;
}


/* ----------------------- cursor style ------------------------*/
/* hand */
.cursorPointer{
  cursor: pointer !important;
}
/* arrow */
.cursorDefault{
  cursor: default !important;
}
/* text or I */
.cursorText{
  cursor: text !important;
}
.fa-info-circle{
  cursor: pointer;
}


/* --------------------ckeditor css ----------------------- */

.ck.ck-toolbar > .ck-toolbar__items{
  font-size: 10px;
}
.ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar{
  background: rgba(221, 221, 221, 0.74);
}
.ck.ck-menu-bar__menu.ck-menu-bar__menu_top-level > .ck-menu-bar__menu__button{
  background: rgba(221, 221, 221, 0.74);
}
.ck.ck-editor__main > .ck-editor__editable{
  height: 60vh;
   overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; 
  padding: 30px !important ;
}
.htmlCkEditor.ck .ck-editor__main{
   height: 60vh;
   width: 180vh;
}

.htmlCkEditor .ck-source-editing-area{
   width: 180vh;
   height: 60vh;
}
.questionBankCkeditor .ck .ck-editor__main{
   height: 60vh;
   /* width: 50vh; */
}
.ck-source-editing-area{
  height: 60vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; 
}
.htmlCkEditor .ck-source-editing-area textarea{
  position: static !important; 
  overflow-y: auto !important;

}
.fileHandleMenu{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 2px;
  border: 0.5px solid hsl(220, 6%, 81%);
  border-top: none;
}
.fileHandleMenu button{
  margin: 0 2px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 3px;
  border-radius: 2px;
  background: rgba(221, 221, 221, 0.74);
  cursor: default;
}
.fileHandleMenu button:hover{
  background-color: rgb(204, 202, 202);
}
.closeIconCk{
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.ck.ck-content h3.category {
  font-family: 'Bebas Neue';
  font-size: 20px;
  font-weight: bold;
  color: red;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}
 
.ck.ck-content h2.document-title {
  font-family: 'Bebas Neue';
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}
 .columns-2 {
  column-count: 2;  
  column-gap: 100px; /* Adjust gap between columns */
  text-align: justify !important;
  padding: 109px !important;
}
 
 
.columns-3 {
  column-count: 3;  
  column-gap: 60px; /* Adjust gap between columns */
  text-align: justify !important;
  padding: 109px !important;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(135deg, var(--background-color) 0%, var(--background-color) var(--background-size), transparent var(--background-size)), linear-gradient(135deg, transparent calc(100% - var(--background-size)), var(--background-color) calc(100% - var(--background-size)), var(--background-color));
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}
 
.ck.ck-content blockquote.side-quote {
  font-family: 'Bebas Neue';
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}
 
.ck-content span.marker{
  background: yellow;
}
 
.ck-content span.spoiler {
  background: #000;
  color: #000;
}
 
.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}
