article.article_32 {
  width: 100%;
  position: absolute;
  top: 25px;
  height: 100%;
  background-color: #f8f4fc;
  outline: 3px solid #f8f4fc;
  /* font-size: 0.8rem; */
}

/* .Grid.school-name-branch  starts */

.Grid.school_name_branch_32 {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  /* background: rgba(211, 211, 211, 0.4); */
}

select.btn_branch_name_32 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
  float: right;

  position: relative;
  margin-top: -20px;
}

#button_position {
  margin-top: 10px;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name_32 {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  /* padding-top: 10px; */
  /* margin-left: 15px; */
  padding: 10px;
  margin: 12px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_32 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 100%;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}

.grid_container_header_plan_32 > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan4_32 {
  color: orange;
  border-bottom: 4px solid orange;
}

.grid_container_header_plan_32 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */
.create_teacher_32 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  outline: 3px solid #f8f4fc;
  align-items: center;
  gap: 20px;
}

.teacher1_32 {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  gap: 5px;
}

.teacher2_32 {
  grid-column: 4;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: auto auto auto;
}

.create_HOD_box1_32 {
  display: flex;
  gap: 30px;
}

.input_element_teacher_32 {
  position: relative;
  width: 95%;
  height: 68%;
}

.input_element_teacher_32 input,
.input_element_teacher_32 select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 0 20px;
}

.input_element_teacher_32 label {
  position: absolute;
  left: 15px;
  top: 19px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

.input_element_teacher_32:hover label {
  top: -7px;
  color: var(--utilityColor);
}

.form_input_element_teacher_32:not(:placeholder-shown) ~ label,
.form_input_element_teacher_32:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

p.remove_32 {
  font-size: x-small;
  padding-bottom: 10px;
}

#submit_32 {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10p;
  border: none;
}

.teacher5_32 {
  padding-right: 100px;
  padding-top: 18px;
  padding-bottom: 13px;
}

.create_button_teacher_32 {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background: blue;
  float: right;
  position: relative;
  right: 44px;
}

.create_button_teacher_32:hover {
  top: 2px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.btn_branch_name_32 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
  float: right;
}

.grid_container_col {
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
  background-color: white;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.grid_item_col1 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.grid_item_col2 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.input_element_32 input,
.input_element_32 select {
  width: 100%;
  /* height: 100%; */
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 24px 0 11px 16px;
  margin-top: -11px;
}

.input_element_32 {
  position: relative;
  width: 95%;
  height: 68%;
}

.input_element_32 label {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

.input_element_32:hover label {
  top: -7px;
  color: var(--utilityColor);
}

.form_input_element_32:not(:placeholder-shown) ~ label,
.form_input_element_32:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

.grid_container_row {
  display: grid;
}

.grid_item_row {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid_container_row_1 {
  display: grid;
}

.grid_item_row_1 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.radio {
  position: relative;
  top: 10px;
  right: 75px;
}

.radio > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 50px;
  top: 20px;
}

.radio > label {
  color: gray;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 73px;
}

.radio > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}

.radio > input[type="checkbox"]:checked {
  background-color: blue;
}

.radio > input[type="checkbox"]:checked::after {
  display: block;
}

.cbse_checkbox > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: #FFA500;
  position: relative;

  left: 14px;
  top: 12px;
}

.cbse_checkbox > label {
  color: gray;
  padding-left: 796px;
  position: relative;
  top: -122px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 19px;
}

.cbse_checkbox > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}

.cbse_checkbox > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox > input[type="checkbox"]:checked {
  background-color: #FFA500;
}

.cbse_checkbox > input[type="checkbox"]:checked::after {
  display: block;
}

.table_section {
  width: 84vw;
  text-align: center;
  font-size: 0.9rem;
}

.table_section td {
  border-collapse: collapse;
}

.table_section,
.table_section th,
.table_section td {
  border-collapse: collapse;
}

.table_section th,
.table_section td {
  padding: 0px;
  /* width: 10px; */
}

.table_section td i {
  position: relative;
  left: 5px;
}

.table_section thead {
  background-color: var(--headerColor);
  color: blue;
  font-weight: normal !important;
}

.table_section thead td {
  border-left: 10px;
}

td.data_32 {
  color: gray;
  font-size: 0.9rem;
}

td.data1_32 {
  color: gray;
  font-size: 0.9rem;
}

td.data2_32 {
  color: gray;
  font-size: 0.9rem;
}

.table_section th i {
  font-size: 0.8rem;

  padding: 5px !important;
}

.table_section tr {
  height: 47px;
  border: 1px groove #8080802e;
}

.data_row:hover td:first-child {
  border-left: 10px solid blue;
}

.data_row td:first-child {
  border-left: 10px !important;
  color: var(--primaryColor);
}

tbody tr:hover td.UserName {
  color: orange;
}

.wrapdata {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  /* margin: 0px -23px 0px 10px; */
  text-align: left;
}
.userId {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}

.Email32 {
  display: none;
}

.cbse_checkbox_thead > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox_thead > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
  left: 28px;
}

.cbse_checkbox_thead > label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.cbse_checkbox_thead > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
  left: 29px;
  top: 10px;
}

.cbse_checkbox_thead > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked {
  background-color: blue;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked::after {
  display: block;
}

.schoollist_32 {
  padding: 10px;
  display: flex;
  background-color: white;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
}

#data_row_hover :hover {
  box-shadow: 2px 2px solid grey;
}

.schoollist_dropdown {
  display: flex;
  align-items: baseline;
  gap: 2rem;
  max-height: 100%;
}

.Dropdown > select {
  color: orange;
  /* padding: 10px 20px; */
  border: 2px solid orange;
  border-radius: 20px;
  background: white;
  width: 108px;
  height: 32px;
}

.Dropdown :hover {
  color: white;
  background: orange;
}

.section_list_table {
  margin: 0px 10px 0px 10px;
  overflow: auto;
  height: 68%;
  background: white;
}

.school_list_btn_container_32 {
  display: flex;
  align-items: center;
  /* width: 438px; */
  position: relative;
  padding-left: 690px;
  /* top: -19px; */
}

.school_list_search_container_32 {
  display: flex;
  gap: 25px;
  width: 247px;
  height: 32px;
}
.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: #b5b6b6;
}

.school_list_search_container span {
  position: absolute;
  top: 7px;
  left: 215px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

.add_school_button_32:hover {
  top: -2px;
  box-shadow: 0px 3px 5px rgba(0, 128, 0, 0.5);
}

.add_school_button_32 {
  background-color: rgba(0, 128, 0, 0.698);
  position: relative;
  width: 151px;
  color: white;
  border-radius: 18px;
  border: none;
  text-align: center;
  padding: 8px;
}

/*==========pagination start==========*/

.pagination {
  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/
/* =================popup starts ============================ */

.wrapper_teacherid {
  display: inline-flex;
}

.wrapper_teacherid .icon {
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span {
  display: block;
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span i {
  line-height: 50px;
  font-size: 25px;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.wrapper_teacherid .icon .tooltip {
  position: absolute;
  top: 0;
  width: 145px;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 20px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon:hover .tooltip {
  top: -35px;
  opacity: 1;
  pointer-events: auto;
}
.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon:hover span {
  color: #fff;
}
.wrapper_teacherid .icon:hover span,
.wrapper_teacherid .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper_teacherid .change-password:hover i {
  color: orange;
}

.wrapper_teacherid .edit:hover i {
  color: orange;
  /* box-shadow: 2px 2px 2px orange; */
}

.wrapper_teacherid .remove:hover i {
  color: orange;
  /* box-shadow: 2px 2px 2px orange; */
}

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */
