.video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 450px;
  }
  
  .player {
    width: 90%;
    max-width: 50rem;
    border: 0.1rem solid black;
    position: relative;
    font-size: 0;
    overflow: hidden;
  }
  
  /*-------| Video-Player When Full-Screen Starts |-------*/
  .player:fullscreen {
    max-width: none;
    width: 100%;
  }
  
  .player:-webkit-full-screen {
    /*CHROME*/
    max-width: none;
    width: 100%;
  }
  
  .player:-moz-full-screen {
    /*FIREFOX*/
    max-width: none;
    width: 100%;
  }
  /*-------| Video-Player When Full-Screen Ends |-------*/
  
  .player_video {
    width: 100%;
    cursor: pointer;
  }
  
  .player_button {
    max-width: 50px;
    padding: 0;
    font-size: 1rem;
    text-align: center;
    /* color: white; */
    line-height: 1;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  
  .player_controls > * {
    flex: 1;
  } 
  
  .player_controls {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; 
    position: absolute;
    padding: 0 1.5rem;
    width: 100%;
    bottom: 0;
    transform: translateY(100%) translateX(-5px); 
    transition: all 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .player:hover .player_controls {
    transform: translateY(0);
  }
  
  .left_Icons{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .vol_and_range{
    display: flex;
    align-items: center;
    }
  
  .player_slider {
    width: 0.75rem;
    height: 3rem;
  }
  
  /*-------| Input type="range" style Starts |-------*/
  input[type='range'] {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    width: 50%;
    margin: 0 20px;
  }
  
  input[type='range']:focus {
    outline: none;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: white;
    border-radius: 50px;
    border: 0.2px solid black;
  }
  
  input[type='range']::-webkit-slider-thumb {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  /*-------| Input type="range" style Ends |-------*/
  
  .right_icons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .time {
    text-align: right;
    position: relative;
    padding-left: 0.5em;
    margin-right: 1em;
    color: white;
    font-size: 0.8rem;
    user-select: none;
  }
  
  /*-------| Media Query Starts |-------*/
  @media (max-width: 827px) {
   
    button + button {
      margin-left: 0;
    }
  
    .time {
      padding-left: 0;
      margin-right: 0.5em;
    }
  
    
    .player {
      width: 80%;
    }
  }
  
  @media (max-width: 600px) {
    .player {
      width: 95%;
    }
  }
  /*-------| Media Query Ends |-------*/