.msg-modal-body {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  padding-right: 17px;
  position: relative;
}

.msg-modal-title {
  color: #51bf9f;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}

.msg-modal-content {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  /* 142.857% */
}

.msg-modal-close-btn {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.grade-modal-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(3, 3, 3);
}

.grade-modal-headv2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(3, 3, 3);
  margin-bottom: 20px;
}

.grade-modal-head-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grade-table-wrap {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.grade-table {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  align-items: left;
}

.grade-table-v2 {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 10px;
  align-items: center;

}

.grade-table-content {
  color: #3f5398;
  font-size: 15px;
}

.grade-table-content-v2 {
  color: #3F5398;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 133.333% */
  /* 133.333% */
}

.student-label {
  color: #6A6A6A;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 77.778% */
}

.label-index {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-right: 6px;
  /* 100% */
}

.label-grade-modal {
  color: #2D3962;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
}

.grade-table-bottom-label {
  color: #2D3962;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
}