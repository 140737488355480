article.article_csp34 {
  width: 83vw;
  position: absolute;
  top: 38px;
  height: 100%;
  background-color: #f8f4fc;
  outline: 3px solid #f8f4fc;
}
/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_csp34 {
  display: flex;
  justify-content: space-between;
  padding: 13px; /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_csp34 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}
.school_name_csp34 {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_csp34 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 83vw;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}
.grid_container_header_plan_csp34 > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan5_csp34 {
  color: orange;
  border-bottom: 4px solid orange;
}
.grid_container_header_plan_csp34 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */
.create_teacher_csp34 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  outline: 3px solid #f8f4fc;
  align-items: center;
  gap: 20px;
}

.teacher1_csp34 {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  gap: 5px;
}

.teacher2_csp34 {
  grid-column: 4;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: auto auto auto;
}

.create_HOD_box1_csp34 {
  display: flex;
  gap: 30px;
}

.input_element_teacher_csp34 {
  position: relative;
  width: 95%;
  height: 68%;
}
.input_element_teacher_csp34 input,
.input_element_teacher_csp34 select {
  width: 100%;
  /* height: 100%; */
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 15px 0 12px 16px;
  margin-top: -11px;
}

.input_element_teacher_csp34 label {
  position: absolute;
  left: 15px;
  top: -10px;
  font-size: 0.8rem;
  font-weight: bold;
  background: white;
  transition: all 0.2s;
}
/* .input_element_teacher_csp34:hover label {
    top: -7px;
    color: var(--utilityColor);
  } */
.form_input_element_teacher_csp34:not(:placeholder-shown) ~ label,
.form_input_element_teacher_csp34:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

p.remove_csp34 {
  font-size: x-small;
  padding-bottom: 10px;
}

.grid_item_col3 {
  display: grid;
  grid-template-rows: auto auto auto;
  align-content: center;
  gap: 50px;
}
.radio_grid {
  display: grid;
  grid-template-columns: auto auto;
}

#submit_csp34 {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10p;
  border: none;
}

.teacher5_csp34 {
  padding-right: 100px;
  padding-top: 18px;
  padding-bottom: 13px;
}

.create_button_teacher_csp34 {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background: blue;
  float: right;
  position: relative;
  right: 44px;
}

.create_button_teacher_csp34:hover {
  top: 2px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.btn_branch_name_csp34 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

.grid_container_col {
  display: grid;
  grid-column-gap: 11px;
  column-gap: 11px;
  grid-template-columns: 1fr 0fr 1fr 1fr;
  background-color: white;
  padding: 16px;
  margin: 0px 10px 0px 10px;
  border-bottom: 1px solid gray;
}

.grid_item_col1 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.grid_item_col2 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.grid_content_label {
  position: relative;
  /* top: 93px; */
  top: 105px;
  left: 10px;
  /* font-weight: 700; */
}

.input_element_csp34 input,
.input_element_csp34 select {
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--primaryColor);
  padding: 15px 0 12px 16px;
  /* margin-top: -11px; */
}

.input_element_csp34 {
  position: relative;
  width: 95%;
  height: 68%;
}

/* .input_element_csp34 label {
  position: absolute;
  left: 15px;
  top: -9px;
  font-size: 0.8rem;
  font-weight: bold;
  background: white;
  transition: all 0.2s;
} */

.input_element_csp34 label {
  position: absolute;
  left: 15px;
  top: 20px;
  font-size: 13px;
  color: #b5b6b6;
  opacity: 1;
  background: white;
  transition: all 0.2s;
  padding-right: 15px;
}

.input_element_csp34:hover label {
  top: -7px;
  /* color: var(--utilityColor); */
  color: #fd8c00;
  padding-right: 0;
}
/* .form_input_element_csp34:not(:placeholder-shown) ~ label,
.form_input_element_csp34:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
} */

.form_select_element_csp34:valid + label,
.form_select_element_csp34 option:checked {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.std_cat {
  visibility: hidden;
}

.schoollist_head_csp34 {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  background: white;
  padding: 5px;
}

.grid_container_row {
  display: grid;
}
.grid_item_row {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
  display: grid;
  margin-left: 45px;
}

.grid_container_row_1 {
  display: grid;
}

.grid_item_row_1 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.radio {
  position: relative;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

.radio > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio > input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
}

.radio > label {
  color: gray;
  font-size: 0.9rem;
  font-family: sans-serif;
  font-weight: 500;
  padding-left: 10px;
}

.radio > input[type="radio"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}

.radio > input[type="radio"]:checked {
  background-color: blue;
}

.radio > input[type="radio"]:checked::after {
  display: block;
}
.cbse_checkbox {
  display: flex;
  justify-content: start;
  margin-left: 70px;
  position: relative;
  grid-gap: 13px;
  gap: 15px;
  align-items: center;
}

.cbse_checkbox > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: 1px solid #BDBDBD;
  color: #FFA500;
  position: relative;
}

.cbse_checkbox > label {
  color: gray;
  font-size: 0.9rem;
  font-family: sans-serif;
  font-weight: 500;
  padding: 0px;
}

.cbse_checkbox > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 15px;
  color: white;
  display: none;
}
.cbse_checkbox > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox > input[type="checkbox"]:checked {
  background-color: #FFA500;
}

.cbse_checkbox > input[type="checkbox"]:checked::after {
  display: block;
}

.table_section {
  width: 82vw;
  text-align: center;
}

.table_section td {
  border-collapse: collapse;
}

.table_section,
.table_section th,
.table_section td {
  border-collapse: collapse;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}

.userId {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}

.table_section th,
.table_section td {
  padding: 5px;
  width: 10px;
  font-size: 0.9rem;
  font-weight: normal !important;
}
.cbse_table_head2 {
  width: 15% !important;
}

.table_section thead {
  background-color: var(--headerColor);
  color: blue;
}

.data_row:hover td:first-child {
  border-left: 5px solid blue;
}
.data_row:focus td:first-child {
  border-left: 5px solid blue;
}

/* .data_row  {
    border-left: 10px solid white ;
  } */

.table_head_section th:first-child {
  padding-left: 10px !important;
}
td.data_csp34 {
  color: gray;
  font-size: 0.9rem;
}
td.data1_csp34 {
  color: gray;
  font-size: 0.9rem;
}
td.data2_csp34 {
  color: gray;
  font-size: 0.9rem;
}
/* .data_row:hover td:first-child {
  border-left: 10px solid blue;
}
.data_row  {
  border-left: 10px solid white !important ;
} */

tbody tr:hover td.UserName {
  color: orange;
}
.table_section th i {
  font-size: 0.9rem;
  position: relative;
  left: 4px;
}
.table_section tr {
  height: 47px;
  border: 1px groove #8080802e;
}

.cbse_checkbox_thead > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox_thead > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 1px solid;
  color: blue;
  position: relative;
  /* left: 28px; */
}

.cbse_checkbox_thead > label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.cbse_checkbox_thead > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}
.cbse_checkbox_thead > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked {
  background-color: #FFA500;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked::after {
  display: block;
}

.schoollist_csp34 {
  background-color: white;
  height: 44%;
  margin: 0px 10px 0px 10px;
  overflow: scroll;
}
.section-list-table {
  margin-top: -32px;
  margin-left: 10px;
  margin-right: 10px;
}

tbody tr:hover td.data1_csp34 {
  color: orange;
}

td.data2_csp34 {
  color: orange;
}

tbody tr:hover {
  box-shadow: 2px 2px gray;
}

.artical_button_container_csp34 {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.artical_button_csp34 {
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}
#button_id_csp34 {
  float: right;
  background-color: rgb(40, 40, 212);
  color: var(--backgroundColor);
  position: absolute;
  top: -75px;
  margin-left: 1118px;
}
#button_id_csp34:hover {
  top: -78px;
  box-shadow: 0px 3px 5px rgb(0 0 255 / 40%);
}

/* .school_list_btn_container_csp34 {
      display: flex;
      align-items: center;
      
      position: relative;
      padding-left: 818px;
      top: -19px;
    } */
.school_list_search_container_csp34 {
  width: 250px;
  position: relative;
}
.school_list_search_container_csp34 input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}
.school_list_search_container_csp34 input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container_csp34 span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}
.i_button {
  color: orange;
}
.arrow_icon {
  color: orange;
  display: flex;
  align-items: center;
  font-size: xx-large;
}

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */
/*==========pagination start==========*/

.pagination {
  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

.wrapper_teacherid .icon .tooltip {
  position: absolute;
  bottom: 100%;
  width: 145px;
  color: gray;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);

  transform: translateX(-50%);
  background: #fff;
  padding: 9px 20px;
  font-size: 8px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: center;
}

.wrapper_teacherid .icon:hover .tooltip {
  opacity: 1;
}

/*==========pagination End==========*/
