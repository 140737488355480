
/* Create a full-width and full-height container */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
}

/* Create a spinning loader element */
.loader {
    border: 5px solid #f3f3f3; /* Light gray border */
    border-top: 5px solid #3498db; /* Blue border (adjust the color as needed) */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Rotate the element */
}

/* Keyframes animation for spinning */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
