.Cqk_Quiz_Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Cqk_Title_And_Time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 35rem;
  margin: 20px 0 20px 0;
}

.Cqk_OutOf {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #093eb5;
  opacity: 0.9;
  font-weight: bold;
}

/*-------| Quiz (Nxt Prev Submit) Button Styles |------*/
.Cqk_BtnsDiv {
  display: flex;
  justify-content: flex-end;
  user-select: none;
}

.Cqk_QstnBtns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 5px; */
  border: 1px solid #faf9ff;
  background-color: #fcfcff;
  padding: 20px;
}

.TimeTakenLabel {
  font-size: 15px;
  font-weight: bold;
  margin-right: 20px;
}

.Cqk_ExitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #2aa571;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.Cqk_ExitBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.Cqk_PrevBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #9ea3d4;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.Cqk_PrevBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.Cqk_NxtBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 10px 10px;
  background-color: #fd8c00;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.Cqk_NxtBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.Cqk_SubmitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fd8c00;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
  width: 135px;
  height: 40px;
}

.Cqk_SubmitBtn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}
/*-------| Quiz (Nxt Prev Submit) Button Style Ends |------*/

/*-------| Quiz Question Pagination Bubble Styles |-------*/
.Cqk_Qstn_Bubble_div {
  margin: 0 0 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
  user-select: none;
}

.Cqk_Bubble_and_img {
  width: 1179px;
  height: auto;
  display: flex;
  padding: 8px;
  gap: 50px;
}

.Cqk_Qstn_Bubble_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
}

.Cqk_Qstn_Bubble_img img {
  width: 55px;
  height: 87px;
}

.Cqk_Qstn_Bubble_img h6 {
  color: #1b1b1b;
  font-size: 13px;
  margin-left: 15px;
}

.Cqk_Qstn_Bubble_img span {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.Cqk_Bubbles {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 15px;
  align-content: center;
}

.Cqk_Bubbles > .Cqk_Bubble > div:nth-child(-n + 25) {
  grid-column: span 25;
}

.Cqk_Bubbles > .Cqk_Bubble > div:nth-child(n + 26) {
  grid-row: 2;
  grid-column: span 25;
}

.Cqk_Bubble {
  color: #ffffff;
  font-size: 16px;
  background-color: #00ce95;
  /* border-radius: 50px; */
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 3;
  /* box-shadow: 0 3px black; */
  cursor: pointer;
}

.Cqk_Bubble:active {
  background-color: #3e8e41;
  /* box-shadow: 0 5px; */
  transform: translateY(4px);
}
/*-------| Quiz Question Pagination Bubble Style Ends |-------*/

/*-------| Quiz Question Info Bubbles Styles |------*/
.Cqk_Info_Bubbles {
  display: flex;
  gap: 25px;
  font-size: 12px;
  color: #1b1b1b;
  padding: 0 0 20px 30px;
  user-select: none;
}

.Cqk_bubble_1,
.Cqk_bubble_2,
.Cqk_bubble_3 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Cqk_circle_1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #2fb44d;
  display: flex;
}

.Cqk_circle_2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #bababa;
  display: flex;
}

.Cqk_circle_3 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #eb3e68;
  display: flex;
}
/*-------| Quiz Question Info Bubbles Style Ends |------*/
