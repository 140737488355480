.msg-modal-body {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.msg-modal-title {
  color: #001af3;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.msg-modal-content {
  color: #1b1b1b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.msg-modal-close-btn {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.btn-container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-modal {
  width: 146px;
  height: 50px;
  border-radius: 45px;
  background: #faa220;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
