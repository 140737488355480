.cqk_MTF_dd_Box {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  user-select: none;
  overflow: auto;
}

.cqk_qstn_img {
  display: flex;
  justify-content: space-between;
  gap: 10vw;
  overflow-x: hidden;
  max-height: 90px;
  padding-right: 20px;
}

/*-----| Scroll Bar Starts |-----*/
.cqk_qstn_img::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_qstn_img::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_qstn_img::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Starts |-----*/

.cqk_main_qstn {
  font-size: 1vw;
  text-align: justify;
}

.cqk_MTF_dd_img {
  width: 80px;
  height: 90px;
  margin-right: 20px;
}

.cqk_MTF_dd_Div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.cqk_MTF_dd_Qstn_And_Field_div {
  overflow-x: hidden;
  width: 800px;
  max-height: 500px;
}

/*-----| Scroll Bar Starts |-----*/
.cqk_MTF_dd_Qstn_And_Field_div::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_MTF_dd_Qstn_And_Field_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_MTF_dd_Qstn_And_Field_div::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

/*------------------| Qstn And Field Styles |-----------------*/
.cqk_MTF_dd_Fields_1 {
  display: flex;
  justify-content: space-evenly;
  width: 800px;
  margin-top: 20px;
}

.cqk_MTF_dd_Qstn_Field_1_B1 {
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #dbdee6;
  border-radius: 5px;
  position: sticky;
}

.cqk_MTF_dd_Qstn_Field_1_B1>span {
  max-height: 60px;
  padding: 5px 10px 5px;
  overflow: scroll;
}

.cqk_MTF_dd_Qstn_Field_1_B1::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5px dashed #707070;
  opacity: 0.7;
}

.cqk_MTF_dd_Qstn_Field_1_B1::after {
  left: 16vw;
  width: 4.5vw;
}

.cqk_MTF_dd_Qstn_Field_1_B2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  border-radius: 5px;
  position: sticky;
  /*To give gap in between the dash border*/
  background: linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  /* background-size: 10px 1px, 1px 10px; */
  background-size: 0.6vw 0.06vw, 0.06vw 0.6vw;
}

/*------------------| Qstn And Field Styles Ends |-----------------*/

/*-----| Answer Box Styles |-----*/
.cqk_MTF_dd_Ans {
  overflow-x: hidden;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Paragraph_title {
  font-size: 18px;
}

.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

/*-----| Scroll Bar Starts |-----*/
.cqk_MTF_dd_Ans::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_MTF_dd_Ans::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_MTF_dd_Ans::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.cqk_MTF_dd_Ans_Field_1 {
  max-height: 60px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 29px;
  margin-top: 20px;
  border: 1px solid #dbdee6;
  border-radius: 5px;
  overflow: scroll;
}

.cqk_MTF_dd_Ans_Field_1>span {
  max-height: 60px;
}

/*-----| Answer Box Styles Ends |-----*/
