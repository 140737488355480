article.article_31 {
  width: 100%;
  position: absolute;
  top: 38px;
  height: 100%;
  /* background-color: #f8f4fc; */
  background-color: white;
  /* outline: 3px solid #f8f4fc; */
}

/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_31 {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_31 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
  float: right;
  position: relative;
  /* margin-right: 17px; */
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name_31 {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_31 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 100%;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}

.grid_container_header_plan_31 > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan4_31 {
  color: orange;
  border-bottom: 4px solid orange;
}

.grid_container_header_plan_31 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

.add_schools_31 {
  margin: 35px 0px 0px 16px;
  width: 100%;
  height: 87%;
}

.add_school_title_31 {
  margin: 10px 0px;
}

.input_grid_31 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  margin: 10px;
  gap: 1.5rem;
  /* margin: 9px; */
}

/* el button start*/

.input_element_31_cancel input,
.input_element_31_cancel select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid gray;
  padding: 4px 26px 12px 10p;
}

#button_id_31_cancel {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
  position: absolute;
}

.artical_button_31_cancel {
  background-color: white;
  color: grey;
  border: none;
  outline: 1px solid grey;
  padding: 8px;
  border-radius: 20px;
  width: 53%;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  top: 56px;
  right: -90%;
}

.artical_button_31_cancel:hover {
  border-block-color: gray;
  color: white;
  background-color: gray;
}

/* grip inputs - start */

/* .Input_container {
  margin: 0px 10px 0px 10px;
  background: white;
  padding-top: 10px;
  border: 0.6px solid rgba(128, 128, 128, 0.233);
} */

/* .input_element_31 { */
/* position: relative; */
/* margin: 8px; */
/* } */

/* .input_element_31 input,
.input_element_31 select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid gray;
  padding: 0rem 1rem;
} */

/* .input_element_31 label {
  position: absolute;
  left: 15px; */
/*top: 19px;*/
/* font-size: 0.8rem;
  font-weight: bold; */
/*color: var(--primaryColor); */
/* background: white;
  transition: all 0.2s;
  top: -8px;
  color: var(--utilityColor);
} */

/* .input_element_31:hover label {
    top: -7px;
    color: var(--utilityColor);
  } */
/* .form_input_element_31:not(:placeholder-shown) ~ label,
  .form_input_element_31:focus ~ label {
    top: -7px;
    color: var(--utilityColor);
  } */

.input_element_31 p {
  visibility: hidden;
}

.input_element_31:hover p {
  visibility: visible;
}

/* .form_input_element_31:not(:placeholder-shown) ~ p,
.form_input_element_31:focus ~ p {
  visibility: visible;
} */

/* grid inputs -end */

/*------ Latest Input Form ------*/
/* grip inputs - start */
.input_element_31 {
  position: relative;
  width: 95%;
  height: 100%;
  /* top: 10px; */
}

.input_element_31 input,
.input_element_31 select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--primaryColor);
  padding: 0 20px;
}

.input_element_31 label {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 13px;
  color: #b5b6b6;
  opacity: 1;
  background: white;
  transition: all 0.2s;
  padding-right: 45px;
}

.input_element_31:hover label {
  top: -7px;
  color: #fd8c00;
  padding-right: 0;
}

.form_input_element_31:not(:placeholder-shown) ~ label,
.form_input_element_31:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}
/* .form_select_element_31:not(:placeholder-shown)~label,
.form_select_element_31:focus~label {
  top: -7px;
  color: var(--utilityColor);
} */
.form_select_element_31:valid + label,
.form_select_element_31 option:checked {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.std_cat {
  visibility: hidden;
}

.form_select_element_31[type="date"] {
  font-size: 13px;
}

/* grid inputs -end */

.upload_logo_container_31 {
  display: flex;
  flex-direction: column;
}

.upload_logo_container_31 span {
  justify-self: center;
  margin: 5px 17px;
  font-weight: 500;
}

.artical_button_container_31 {
  position: relative;
}

.artical_button_31 {
  background-color: #403E75;
  color: #f6f6fc;
  padding: 10px;
  border-radius: 20px;
  width: 53%;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  top: 54px;
  right: -45%;
}

.artical_button_container_31:hover {
  top: -4px;
}

#button_id_31 {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
  position: absolute;
}

#button_id_31:hover {
  top: -50px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

/* .add_schools_form_31 {
    background-color: white;
    padding-top: 2px;
    border: 0.1px solid rgba(128, 128, 128, 0.233);
    border-radius: 10px;
    margin: 10px 10px 0px 10px;
  } */
.add_schools_btn_31 {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.choosefile_button_31 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 101px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: 33px;
}

.choosefile_button_container_31 {
  margin: 20px;
}

#choose_button_id_31 {
  background-color: #ff9100;
  color: var(--backgroundColor);
}

.school_list_container_31 {
  margin: 28px 0px 15px 0;
}

.magnifying_glass_icon_31 {
  float: right;
  margin-right: 35px;
  font-size: 1.2rem;
  color: var(--primaryColor);
}

.school_list_table_31 {
  background: white;
  height: 60%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  /* width: 99%; */
  overflow: auto;
  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari and Opera */
.school_list_table_31::-webkit-scrollbar {
  display: none;
}

.add_schools_btn_31 {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.upload_logo_container_31 {
  display: flex;
  flex-direction: column;
}

.upload_logo_container_31 span {
  justify-self: center;
  margin: 1px 36px;
  position: relative;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: ui-serif;
}

.choosefile_button_31 {
  color: white;
  background-color: var(--utilityColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: 19px;
  top: -76px;
  position: relative;
}

.choosefile_button_container_31 {
  margin: 20px;
}

#choose_button_id_31 {
  background-color: #ff9100;
  color: var(--backgroundColor);
}

#button_id_31 {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
  position: absolute;
  top: 20px;
}

#button_id_31:hover {
  top: 18px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.schoollist_31 {
  padding: 10px;
  margin: 0px 10px 0px 10px;
  background-color: white;
  position: relative;
  border-radius: 9px;
}

.create_branch_list_31 {
  height: 28%;

  border-bottom: none;
  border-radius: 10px;
  width: 100%;

  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.section_list_table {
  background: white;
  height: 70%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  width: 82vw;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 4px 0px 0px 7px;
}

/* Chrome, Safari and Opera */
.section_list_table::-webkit-scrollbar {
  display: none;
}

.table_section {
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 2px;
  border-radius: 2px;
}

.table_section,
.table_section th,
.table_section td {
  border-bottom: 1px solid rgba(141, 137, 137, 0.281);
  border-collapse: collapse;
}

.table_section th,
.table_section td {
  font-size: 0.9rem;
  font-weight: 100;
}

.table_section thead {
  background-color: var(--headerColor);
  position: sticky;
  top: 0;
  color: blue;
  z-index: 1;
}

.table_section th i {
  font-size: 0.8rem;
}

.table_section tr {
  height: 47px;
}

.data_row:hover {
  box-shadow: 3px 3px 3px 3px #888888;
  background-color: #f8f4fc;
}

/* tbody tr:hover td:nth-child(2) {
  color: #ff9100;
} */
.wrapdata:hover:nth-child(3) {
  color: #ff9100;
}

.wrapdata {
  /* white-space: nowrap; */
  width: 20px;
  overflow: overlay;
  text-overflow: clip;
  /* margin: 0px -23px 0px 10px; */
  /* text-align: left; */
}
.name {
  white-space: nowrap;
  width: 10rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}

.table_section tr:hover td:last-child {
  /*background-color: var(--utilityColor);*/
  color: white;
}

.table_section tr:hover .name {
  color: orange;
}

/* ==============popup starts================ */
.wrapper_teacherid {
  display: flex;
  /* justify-content: flex-start; */
  width: 110px;
  /* margin-left: 10px; */
  /* margin-bottom: 5px; */
  align-items: baseline;
  align-items: center;
  /* margin-top: -15px; */
}

.wrapper_teacherid .icon {
  margin: 0px 0px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* z-index: 2; */
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span {
  display: block;
  height: 40px;
  width: 32px;
  border-radius: 50%;
  position: relative;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon span i {
  line-height: 50px;
  font-size: 25px;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
}

.wrapper_teacherid .icon .tooltip {
  position: absolute;
  top: 0;
  width: 120px;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 20px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon:hover .tooltip {
  top: -35px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #FFA500;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_teacherid .icon:hover span {
  color: #fff;
}

.wrapper_teacherid .icon:hover span,
.wrapper_teacherid .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper_teacherid .add:hover i {
  color: orange;
}

.wrapper_teacherid .edit:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.wrapper_teacherid .remove:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.blank {
  margin-left: 50px;
}

#orange-add {
  box-shadow: 2px 2px 2px orange;
}

/* ===============custom-file-input starts================= */

.custom_file_input {
  width: 20rem;
  margin-left: 10px;
  padding: 0.5rem;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom_file_input::before {
  content: "Choose File";
  display: inline-block;
  /*background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid #999;
  border-radius: 15px;
  padding: 5px 24px;

  outline: none;

  white-space: nowrap;

  cursor: pointer;

  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}

.custom_file_input:hover::before {
  border-color: black;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

#submit_teacherid {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10p;
  border: none;
}

.error_box {
  color: red;
}

/* modal CSS - start*/
.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  padding: 30px 30px 30px 30px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 400px;
  height: 300px;
}

.main_content_teacherid p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content_teacherid span {
  display: block;
  width: 100px;
  margin: auto;
}

.main_content_teacherid img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_teacherid h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal_buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin: 25px 10px 10px 10px;
}

.ok_btn {
  background-color: orange;
  color: white;
  padding: 10px;
  border-radius: 35px;
  width: 75px;
  font-weight: bold;
  border: 0.1px solid orange;
  cursor: pointer;
}

.ok_btn:hover {
  top: -7px;
  position: relative;
  box-shadow: 1px 2px 5px orange;
}

#ok_btn {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal_teacherid {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* modal CSS - end*/
.list2_teacherid {
  border: 1px solid grey;
  border-radius: 20px;
  padding-inline: 16px;
  margin: 6px;
}

.input_element_31 p {
  font-size: 0.5rem;
  font-weight: bold;
  color: gray;
  min-width: max-content;
}

.input_list2_teacherid {
  height: 33px;
  width: 15rem;
  border: none;
  outline: none;
  background: white;
}

#search_button_teacherid {
  border: none;
  font-size: 20px;
  background: white;
  color: orange;
}
.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container button {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
  border: none;
  background-color: transparent;
}

.teacher_list_teacherid {
  /* border-right: 10px solid #f8f4fc;  */
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  background: white;
  margin: 0px 10px;
  border-top: 1px solid #f8f4fc;
}

.alert {
  color: red;
}

.logo {
  color: red;
}

.logo2 {
  color: red;
}

.hr {
  margin: 0px 10px -1px 11px;
}

/* Pagination part start */

.pagination {
  align-items: center;
  /* overflow: auto; */
  border: 1.1px solid rgba(128, 128, 128, 0.233);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background: white;
  height: 35px;
  margin: 0px 10px 0px 10px;
  border-radius: 4px;
}

.pagination a {
  color: #888888;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

.pagination_35 {
  float: right;
}

/* Pagination End */

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}
.cbse_table_head2 {
  width: 100px !important;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */

/*Input Label Choose File Btn Style*/
.chooseFile_btn_wrapper {
  position: relative;
  margin-bottom: 20px;
  margin-left: 10px;
}

.chooseFile_btn_wrapper > img {
  position: absolute;
  top: 12px;
  left: 14px;
}

.chooseFile_btn {
  font-size: 13px;
  color: white;
  background-color: #fd8c00;
  border-radius: 25px;
  width: 123px;
  height: 37px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

/*Input Label Choose File Btn Style Hover Effect*/
.chooseFile_btn_wrapper {
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.chooseFile_btn_wrapper:hover,
.chooseFile_btn_wrapper:focus,
.chooseFile_btn_wrapper:active {
  bottom: 1px;
  box-shadow: 0 11px 11px -10px #fd8c0063;
}
