.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container > div {
  background-color: white;
  padding: 43px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 500px;
}

.main_content p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content span {
  display: block;
  width: 100px;
  margin: auto;
}
.main_content img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal_buttons {
  display: flex;
  justify-content: space-around;
}

.choosefile_button_2 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 16px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

#choose_button_id_2 {
  background-color: #ff9100;
  color: white;
}

.choosefile_button_1 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 14px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: 0.1px solid;
  cursor: pointer;
}

#choose_button_id_1 {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/
