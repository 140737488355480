* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  flex-direction: row;
}

.radio_active:before {
  background: white;
  position: relative;
  box-shadow: inset 0px 0px 0 4px orange;
  top: -3px;
  width: 17px !important;
  height: 17px !important;
}

/* =================radio starts ============= */
.radio_funcionality > input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid orange;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality > input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality > input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.myText {
  color: green;
  font-size: 69px;
}

/* first row start */
.left_row {
  font-size: 22px;
  display: flex;
  margin-left: 15px;
}
.left_row > i {
  margin: auto;
}
.left_row > i:first-child {
  font-size: 32px;
}
.left_row > p {
  margin: auto;
}
/* row end  */

/* upload input starts */
.upload_logo_container > span {
  padding: 0px 10px 10px 20px;
  font-size: 0.9rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.custom_file_container > span {
  position: relative;
  top: -2rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}
.custom_file_container > span > span > a {
  cursor: pointer;
}

.custom_file_input {
  width: 8rem;
  margin: -10px 0px 0px 15px;
  color: transparent;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom_file_input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

.custom_file_input::before {
  content: "Choose File";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 18px;
  padding: 11px 30px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}

.custom_file_input:hover::before {
  border-color: black;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.select_input {
  font-family: "poppins" !important;
  color: #b5b6b6;
  font-weight: 300;
}
.table_head {
  color: blue !important;
  font-family: "poppins" !important;
  font-weight: 600;
  background: #d9dcfe !important;
}
.accordion_detail {
  /* border-bottom: 1px solid grey;  */
  box-shadow: 0px 2px 3px #00000029;
  display: flex;
  justify-content: space-around;
}
.accordion_detail > i {
  color: grey;
  font-size: 22px;
  margin-left: 8px;
}

/* btns */
.orange_btn {
  padding: 7px 14px;
  margin: auto 5px;
  color: #fd8c00;
  border: 1px solid #fd8c00;
  border-radius: 6px;
  background-color: transparent;
}
.orange_btn_disabled {
  padding: 7px 14px;
  margin: auto 5px;
  color: #fd8c00;
  border: 1px solid #fd8c00;
  border-radius: 6px;
  background-color: transparent;
}

.orange_btn_disabled_grey {
  padding: 7px 14px;
  margin: auto 5px;
  border: none;
  color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  background-color: #cccccc;
}

.orange_btn:hover {
  color: white;
  background-color: #fd8c00;
}
