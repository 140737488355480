.tabs {
    font-family: Arial, sans-serif;
  }
  
  .tab-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #f1f1f1;
    transition: background-color 0.3s;
  }
  
  .tab-buttons button.active {
    background-color: #ffa500;
    border-bottom: 4px solid #403e75;
    color: white;
  }
  
  .tab-content {
    /* border: 1px solid #ccc; */
    padding: 10px 0;
  }

  .tab-buttons {
    background-color: #f1f1f1;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    /* color:black ; */
  }
  