.MTF_DL_Div {
  width: 100%;
  /* height: 45vw; */
  max-height: 29vw;
  overflow: scroll;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.MTF_DL_Div p {
  font-size: 20px;
}

.MTF_DL_Cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
  max-height: 400px;
  scrollbar-width: thin;
}

.Qstns_Cards {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.Ans_Cards {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px;
}

.QstnCarD,
.AnsCard {
  border: 1px solid;
  width: 350px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 25px;
  border-radius: 5px;
  color: #1b1b1b;
}

/* .btn_dIv {
  display: flex;
  justify-content: flex-end;
}

.QstN__Btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  border: 1px solid #faf9ff;
  background-color: #f8f4fc;
  width: 666px;
  height: 85px;
}

.ExiT__btn {
  background-color: #2aa571;
  padding: 10px;
  margin-right: 20px;
}

.ExiT__btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.PreV__btn {
  display: flex;
  padding: 10px;
  background-color: #9ea3d4;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.PreV__btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.NxT__btn {
  display: flex;
  padding: 10px 5px 10px 10px;
  background-color: #fd8c00;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.NxT__btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
} */
.react-flow__handle {
  width: 13px;
  /* Adjust the width as needed */
  height: 13px;
  /* Adjust the height as needed */
  border-radius: 50%;
  /* Make it round */
}

.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

.react-flow__controls-button react-flow__controls-interactive {
  display: none;
}