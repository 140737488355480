/* // bar graph  */
.bar-chart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.round-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #c8c9d6;
  cursor: pointer;
}

.bar-chart {
  display: flex;
  align-items: center;
  column-gap: 4;
  width: 90%;
  margin-top: 16px;
  overflow-x: auto;
  height: 90%;
  margin: 0 auto;
  scroll-behavior: smooth;
  gap:30px;
}

.cs-chart-bar-pair {
  margin-right: 20px;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.cs-chart-bar-pair-inner {
  display: flex;
  align-items: flex-end;
  height: 178px;
  width: 62px;
  justify-content: center;
  padding: 10px;
  padding-bottom: 0px;
}

.cs-chart-pair-label {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -17px;
  text-align: left;
  width: 100px;

  color: #060606;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  padding-left: 3px;
  /* 100% */
}

.bar {
  width: 13px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}

.donut {
  position: relative;
  margin-right: 2px;
}

.bar-label {
  color: #060606;
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  position: absolute;
  top: -12px;

  /* 125% */
}
.scrl-container-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrl-container-legend {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-right: 10px;
}
.scrl-container-indicator {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.scrl-container-sub-heading {
  color: #6a6a6a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
}

.clr-box {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}
.indicator-label {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.ember-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50%;
}
