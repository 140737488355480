/* dashboard - end */

article.article_35 {
  width: 100%;
  position: absolute;
  top: 25px;
  /* height: 100%; */
  background-color: #f8f4fc;
  outline: 3px solid #f8f4fc;
  overflow: scroll;
}
/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_35 {
  display: flex;
  justify-content: space-between;
  padding: 13px; /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_35 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
  float: right;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}
.school_name_35 {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_35 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 100%;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}
.grid_container_header_plan_35 > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan6_35 {
  color: orange;
  border-bottom: 4px solid orange;
}
.grid_container_header_plan_35 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */
.create_teacher_35 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  outline: 3px solid #f8f4fc;
  align-items: center;
  gap: 20px;
}

.teacher1_35 {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  gap: 5px;
}

.teacher2_35 {
  grid-column: 4;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: auto auto auto;
}

.create_HOD_box1_35 {
  display: flex;
  gap: 30px;
}

.input_element_teacher_35 {
  position: relative;
  width: 100%;
}
.input_element_teacher_35 input,
.input_element_teacher_35 select {
  width: 95%;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  margin: 5px;
}

.input_element_teacher_35 label {
  position: absolute;
  left: 17px;
  top: 6px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
  top: -3px;
  color: var(--utilityColor);
}
/* .input_element_teacher_35:hover label {
    top: -7px;
    color: var(--utilityColor);
  } */
/* .form_input_element_teacher_35:not(:placeholder-shown) ~ label,
  .form_input_element_teacher_35:focus ~ label {
    top: -7px;
    color: var(--utilityColor);
  }
   */
p.remove_35 {
  font-size: x-small;
  padding-bottom: 10px;
}

#submit_35 {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10px;
  border: none;
}

.teacher5_35 {
  padding-right: 100px;
  padding-top: 18px;
  padding-bottom: 13px;
}

.create_button_teacher_35 {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background: blue;
  float: right;
  position: relative;
  right: 44px;
}

.create_button_teacher_35:hover {
  top: 2px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.btn_branch_name_35 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

.grid_container_col {
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
  background-color: white;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.grid_item_col1 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.grid_item_col2 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 15px;

  box-shadow: 0px 0px 2px 0px grey;
  text-align: left;
  color: orange;
}

.input_element_35 input,
.input_element_35 select {
  width: 100%;
  /* height: 100%; */
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 24px 0 11px 16px;
  margin-top: -11px;
}

.input_element_35 {
  position: relative;
  width: 95%;
  height: 68%;
}

.input_element_35 label {
  position: absolute;

  top: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

/* .input_element_35:hover label {
    top: -17px;
    color: var(--utilityColor);
  } */
.form_input_element_35:not(:placeholder-shown) ~ label,
.form_input_element_35:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

.grid_container_row {
  display: grid;
}
.grid_item_row {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.grid_container_row_1 {
  display: grid;
}

.grid_item_row_1 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.radio {
  position: relative;
  top: 10px;
  right: 75px;
}

.radio > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radio > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 50px;
  top: 20px;
}

.radio > label {
  color: gray;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 73px;
}

.radio > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}

.radio > input[type="checkbox"]:checked {
  background-color: blue;
}

.radio > input[type="checkbox"]:checked::after {
  display: block;
}

.cbse_checkbox > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
  left: 767px;
  top: -104px;
}

.cbse_checkbox > label {
  color: gray;
  padding-left: 796px;
  position: relative;
  top: -122px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 792px;
}

.cbse_checkbox > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}
.cbse_checkbox > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox > input[type="checkbox"]:checked {
  background-color: blue;
}

.cbse_checkbox > input[type="checkbox"]:checked::after {
  display: block;
}

.table_section {
  width: 100%;
  text-align: center;
}

.table_section td {
  border-collapse: collapse;
  color: var(--primaryColor);
}

.table_section,
.table_section th,
.table_section td {
  border-collapse: collapse;
}

.table_section th,
.table_section td {
  /* width: 10px; */
  font-size: 0.9rem;
  padding: 3px;
  font-weight: normal !important;
}

.table_section thead {
  background-color: var(--headerColor);
  color: blue;
}
td.data_35 {
  color: gray;
  font-size: 0.9rem;
}
td.data1_35 {
  color: gray;
  font-size: 0.9rem;
}
td.data2_35 {
  color: gray;
  font-size: 0.9rem;
}
.table_section th i {
  font-size: 0.9rem;
  position: relative;
  left: 4px;
}
.table_section tr {
  height: 47px;
  border: 1px groove #8080802e;
  font-size: 0.9rem;
}

.data_row:hover td:first-child {
  border-left: 10px solid blue;
}
.data_row {
  border-left: 10px solid white;
}
tbody tr:hover td.cbse_table_headToken {
  color: orange;
}

.cbse_checkbox_thead > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cbse_checkbox_thead > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
  left: 28px;
}

.cbse_checkbox_thead > label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.cbse_checkbox_thead > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 12px;
  color: white;
  display: none;
}
.cbse_checkbox_thead > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked {
  background-color: blue;
}

.cbse_checkbox_thead > input[type="checkbox"]:checked::after {
  display: block;
}

.schoollist_35 {
  background-color: white;
  margin: 0px 10px 0px 10px;
  padding: 0px 10px 0px 10px;
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}

.section-list-table {
  margin-top: -32px;
  margin-left: 10px;
  margin-right: 10px;
}

tbody tr:hover td.data1_35 {
  color: orange;
}

tbody tr:hover td.data2_35 {
  color: orange;
}

tbody tr:hover {
  box-shadow: 2px 2px gray;
}

.section_list_table {
  margin: 0px 10px 0px 10px;
  height: 20rem;
  background: white;
  overflow: auto;
}
.cbse_table_head1 {
  width: 40px;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: scroll;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}

.wrapdata_td {
  white-space: nowrap;
  width: 7rem;
  overflow: scroll;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}

.school_list_btn_container_35 {
  display: flex;
  align-items: center;
  position: relative;
}
.teacher_list_teacherid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 10px;
  padding: 7px 10px;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
  cursor: pointer;
}

.school_list_search_container_35 span i {
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
}

.grid_item_35 {
  background-color: rgba(255, 255, 255, 0.8);
  grid-area: span;
  grid-row-start: auto;
  box-shadow: 1px 1px var(--utilityColor);
  padding: 15px;
  font-size: 18px;
  text-align: -webkit-left;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-radius: 5px;
  margin: 0px 10px 0 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.column_35 {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.link_icon_item1_35 {
  font-size: 2rem;
}
.link_icon_item_35 {
  color: #ff9100;
}

.school_data_35 {
  display: flex;
  flex-direction: column;
}
.school_data_35 span:first-child {
  font-weight: bold;
}
.school_data_35 span:last-child {
  color: rgba(128, 128, 128, 0.753);
}

.input_element_35 {
  position: relative;
  width: 95%;
  height: 68%;
}
.input_element_35 input,
.input_element_35 select {
  width: 95%;
  height: 40px;
  padding: 0px 20px;
  border-radius: 4px;
  border: 1px solid gray;
  margin: 2px 10px 0px 10px;
}

.input_element_35 label {
  position: absolute;
  left: 20px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
  top: -6px;
  color: var(--utilityColor);
}
/* padding-left: 76px;
    }
    .input_element_35:hover label {
      top: -7px;
      color: var(--utilityColor);*/

.pagination {
  background: white;
  background: white;
  height: 35px;
  margin: 0px 10px 0px 10px;
  border-radius: 4px;
}

.pagination_35 {
  float: right;
}
a.active_35 {
  color: white;
  background-color: #0000ffc7;
}

.artical_button_35 {
  background-color: #111fee;
  color: white;
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  /* margin-right: -2px; */
  margin-left: 100%;
  top: 20px;
  margin-top: 12px;
}

#button_id_35 {
  background-color: rgb(40, 40, 212);
  color: var(--backgroundColor);
  position: absolute;
  top: 13px;
}

#button_id_35:hover {
  top: 9px;
  box-shadow: 0px 3px 5px rgb(0 0 255 / 40%);
}

.grid_item_2_35 {
  background-color: rgba(255, 255, 255, 0.8);
  grid-row-start: auto;
  box-shadow: 1px 1px var(--utilityColor);
  text-align: -webkit-left;
  height: 160px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  margin: 0px 10px 100px 10px;
  /* display: flex; */
  /* justify-content: space-evenly; */
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  align-items: center;
  border-radius: 10px;
}
i.fa-search {
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
}
/* .input_box {
  height: 150px;
} */

.error_box {
  display: block;
  /* position: relative; */
  /* top: -50px; */
  /* margin: 0px 30px; */
  color: red;
}

.artical_button_container1 {
  /* position: relative; */
  left: -326px;
  top: -18px;
}

.artical_button1 {
  background-color: #111fee;
  color: #111fee;
  padding: 10px;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  /* margin-right: -2px; */
  /* margin-left: 100%; */
  top: 20px;
  /* margin: 12px 0px 0px 158px; */
}

#button_id1 {
  background-color: blue;
  color: white;
  /* position: absolute; */
  top: 13px;
}

#button_id1:hover {
  top: 9px;

  color: #f8f4fc;
}

.artical_button_container1_335 {
  display: flex;
  gap: 1rem;
  align-items: end;
  margin-right: 10%;
}

.artical_button1_335 {
  background-color: #111fee;
  color: white;
  padding: 10px;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  /* margin-right: -2px; */
  /* margin-left: 100%; */
  top: 20px;
  margin-top: 12px;
}

#button_id1_335 {
  background-color: white;
  color: gray;
  /* position: absolute; */
  top: 13px;
  border: 1px solid gray;
}

#button_id1_335:hover {
  top: 9px;
  box-shadow: 0px 3px 5px rgb(86, 81, 81);
  background-color: gray;
  color: #f8f4fc;
}

/* Pagination links */
.pagination {
  align-items: center;

  border: 1.1px solid rgba(128, 128, 128, 0.233);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background: white;
  height: 35px;
  margin: 0px 10px 0px 10px;
  border-radius: 4px;
}
.pagination a {
  color: #888888;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}
.pagination_active {
  color: black;
  border: none;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}
.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}
