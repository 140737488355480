.Grid_school_name_branch_teacherid {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_teacherid {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}
.branchselect_Dropdown {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: #403e75;
  color: #f8f4fc;
  border: none;
  text-align: center;
  padding: 0rem 1rem;
  cursor: pointer;
}

.branchselect_Dropdown_optipns {
  width: 220px;
  height: 35px;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name_teacherid {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
