.MTF_dd_Box {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.qstn_img {
  display: flex;
  justify-content: space-between;
  gap: 10vw;
  overflow-x: hidden;
  max-height: 90px;
  padding-right: 20px;
}

.AnswerSelected {
  opacity: 0.5;
  cursor: not-allowed;
  user-select: none;
  /* background-color: rgb(192, 196, 188); */
}

/*-----| Scroll Bar Starts |-----*/
.qstn_img::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.qstn_img::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.qstn_img::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Starts |-----*/

.main_qstn {
  font-size: 0.8vw;
  text-align: justify;
}

.MTF_dd_img {
  width: 80px;
  height: 90px;
  margin-right: 20px;
}

.MTF_dd_Div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
}

.MTF_dd_Qstn_And_Field_div {
  overflow-x: hidden;
  width: 800px;
  max-height: 500px;
}

/*-----| Scroll Bar Starts |-----*/
.MTF_dd_Qstn_And_Field_div::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.MTF_dd_Qstn_And_Field_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.MTF_dd_Qstn_And_Field_div::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

/*------------------| Qstn And Field Styles |-----------------*/
.MTF_dd_Fields_1 {
  display: flex;
  justify-content: space-evenly;
  width: 800px;
}

.MTF_dd_Qstn_Field_1_B1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  border: 0.5px solid #dbdee6;
  border-radius: 5px;
  position: sticky;
}

.MTF_dd_Qstn_Field_1_B1::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5px dashed #707070;
  opacity: 0.7;
}

.MTF_dd_Qstn_Field_1_B1::after {
  /* right: -69px; */
  left: 16vw;
  width: 4.5vw;
}

.MTF_dd_Qstn_Field_1_B2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  border-radius: 5px;
  position: sticky;
  /*To give gap in between the dash border*/
  background: linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  /* background-size: 10px 1px, 1px 10px; */
  background-size: 0.6vw 0.06vw, 0.06vw 0.6vw;
}

/*------------------| Qstn And Field Styles Ends |-----------------*/

/*------|Drag & Dropped Ans Styles |------*/
.AnsWithBtn {
  display: flex;
  /* align-items: center; */
  border: 1px solid #939598;
  border-radius: 10px;
  /* padding-left: 15px; */
}

.ans_Cancel_Btn {
  width: 2vw !important;
  height: 3vh !important;
}

.AnsWithBtn--short {
  max-width: 11vw;
}

.AnsWithBtn--medium {
  max-width: 100%;
}

.AnsWithBtn--long {
  max-width: 100%;
  max-height: 100%;
}

/*------|Drag & Dropped Ans Style Ends |------*/

/*-----| Answer Box Styles |-----*/
.MTF_dd_Ans {
  overflow-x: hidden;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*-----| Scroll Bar Starts |-----*/
.MTF_dd_Ans::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.MTF_dd_Ans::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.MTF_dd_Ans::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.MTF_dd_Ans_Field_1 {
  height: auto;
  border: 1px solid #dbdee6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 29px;
}

/*-----| Answer Box Styles Ends |-----*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .qstn_img {
    gap: 10vw;
    max-height: 60px;
  }

  .main_qstn {
    font-size: 1.5vw;
  }

  .MTF_dd_img {
    width: 9.8vw;
    height: 13vh;
  }

  .MTF_dd_Div {
    margin-top: 2vw;
  }

  .MTF_dd_Box {
    padding: 3.5vw;
  }

  .MTF_dd_Qstn_And_Field_div {
    width: 52vw;
    max-height: 50.25vh;
  }

  .MTF_dd_Fields_1 {
    width: 50vw;
    height: 7vh;
    margin-top: 1vw;
  }

  .MTF_dd_Qstn_Field_1_B1 {
    width: 15vw;
    max-height: 6.31vh;
    font-size: 1.2vw;
    padding: 1.5vw;
  }

  .MTF_dd_Qstn_Field_1_B2 {
    width: 22.78vw;
    /* height: 6.31vh; */
    padding: 1.5vw;
  }

  .AnsWithBtn {
    font-size: 1.5vw;
    border-radius: 0.8vw;
  }

  .MTF_dd_Qstn_Field_1_B1::after {
    left: 15vw;
    width: 3.8vw;
  }

  .dd_placeHolder {
    font-size: 1.5vw;
  }

  .MTF_dd_Ans {
    max-height: 50.25vh;
    gap: 1vw;
  }

  .MTF_dd_Ans_Field_1 {
    width: 19.53vw;
    /* height: 6.51vh; */
    font-size: 1.5vw;
    padding: 1.5vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1024px) and (max-width: 1430px) {
  .qstn_img {
    gap: 10vw;
    max-height: 65px;
  }

  .main_qstn {
    font-size: 1.2vw;
  }

  .MTF_dd_img {
    width: 9.8vw;
    height: 13vh;
  }

  .MTF_dd_Div {
    margin-top: 1.9vw;
  }

  .MTF_dd_Box {
    padding: 3.7vw;
  }

  .MTF_dd_Qstn_And_Field_div {
    width: 51vw;
    max-height: 45.25vh;
  }

  .MTF_dd_Fields_1 {
    width: 49vw;
    height: 7vh;
    margin-top: 2vw;
  }

  .MTF_dd_Qstn_Field_1_B1 {
    width: 15vw;
    height: 8.31vh;
    font-size: 1vw;
    padding: 1vw;
  }

  .MTF_dd_Qstn_Field_1_B2 {
    width: 22.78vw;
    /* height: 8.31vh; */
    padding: 1vw;
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .AnsWithBtn {
    font-size: 1.2vw;
    border-radius: 0.8vw;
  }

  .MTF_dd_Qstn_Field_1_B1::after {
    left: 15vw;
    width: 3.8vw;
  }

  .dd_placeHolder {
    font-size: 1.2vw;
  }

  .MTF_dd_Ans {
    max-height: 45.25vh;
    gap: 0.7vw;
  }

  .MTF_dd_Ans_Field_1 {
    width: 19.53vw;
    /* height: 8.51vh; */
    font-size: 1.2vw;
    padding: 1vw;
  }
}

@media screen and (min-width: 1430px) and (max-width: 1500px) {
  .qstn_img {
    gap: 10vw;
    max-height: 70px;
  }

  .main_qstn {
    font-size: 1vw;
  }

  .MTF_dd_img {
    width: 9.8vw;
    height: 13vh;
  }

  .MTF_dd_Div {
    margin-top: 1.8vw;
  }

  .MTF_dd_Box {
    width: 100%;
    height: 100%;
    padding: 3.7vw;
  }

  .MTF_dd_Qstn_And_Field_div {
    width: 51vw;
    max-height: 48.25vh;
  }

  .MTF_dd_Fields_1 {
    width: 49vw;
    height: 7vh;
    margin-top: 1.5vw;
  }

  .MTF_dd_Qstn_Field_1_B1 {
    width: 15vw;
    height: 8.21vh;
    font-size: 0.8vw;
    padding: 0.9vw;
  }

  .MTF_dd_Qstn_Field_1_B2 {
    width: 22.78vw;
    /* height: 8.21vh; */
  }

  .AnsWithBtn {
    font-size: 1.3vw;
    border-radius: 0.8vw;
  }

  .MTF_dd_Qstn_Field_1_B1::after {
    left: 15vw;
    width: 3.8vw;
  }

  .dd_placeHolder {
    font-size: 1vw;
  }

  .MTF_dd_Ans {
    max-height: 48.25vh;
    gap: 1vw;
  }

  .MTF_dd_Ans_Field_1 {
    width: 19.53vw;
    /* height: 8.51vh; */
    font-size: 1vw;
    padding: 1vw;
  }
}

/*----------| Media Query for Extra Large Screens |-----------*/
@media screen and (min-width: 1500px) and (max-width: 3072px) {
  .MTF_dd_Box {
    padding: 1.7vw;
  }

  .MTF_dd_Qstn_And_Field_div {
    width: 51vw;
    max-height: 54vh;
  }

  .MTF_dd_Fields_1 {
    width: 49vw;
    margin-top: 1vw;
  }

  .MTF_dd_Qstn_Field_1_B1 {
    width: 15vw;
    max-height: 9.31vh;
    font-size: 1vw;
  }

  .MTF_dd_Qstn_Field_1_B2 {
    width: 22.78vw;
    /* height: 7.31vh; */
  }

  .AnsWithBtn {
    font-size: 1.1vw;
    border-radius: 0.8vw;
  }

  .MTF_dd_Qstn_Field_1_B1::after {
    left: 15vw;
    width: 3.62vw;
  }

  .dd_placeHolder {
    font-size: 1.1vw;
  }

  .MTF_dd_Ans {
    max-height: 54vh;
    gap: 1.2vw;
  }

  .MTF_dd_Ans_Field_1 {
    width: 19.53vw;
    /* height: 7.51vh; */
    font-size: 1.1vw;
  }
}

.Paragraph_title {
  font-size: 18px;
}

.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

.cqk_MTF_dd_Qstn_Field_1_B2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: auto;
  border-radius: 5px;
  position: sticky;
  /*To give gap in between the dash border*/
  background: linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  /* background-size: 10px 1px, 1px 10px; */
  background-size: 0.6vw 0.06vw, 0.06vw 0.6vw;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.content {
  width: 45px; /* Adjust width as per your requirement */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.az-content-mtf p {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.az-ans-length {
  display: flex;
  justify-content: center;
}