.casestudy_class_average {
  width: 100%;
  padding: 10px;
  display: flex;
  background-color: #9cf7f0;
  border-radius: 5px;
}

.class_average {
  display: flex;
}

.custom-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: 40px;
  margin-top: 10px;
  align-items: baseline;
  }

.custom-list.mtf-list {
  gap: 20px;
}

.custom-list li {
  font-size: 12px;
  color: #222;
}

.custom-list li.active {
  background: #3AC869 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 4px 7px;
  color: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.modal-content img {
  width: 100%;
}

  .close-icon {
    position: absolute;
    top: -3px;
    right: 4px;
    font-size: 20px;
    cursor: pointer;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 13px !important;
    color: #FD8C00 !important;
  }
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 14px !important;
    color: #2D3962 !important;
    font-weight: 500 !important;
  }
  .thermometer .thermometer__draw-a:after{
    box-shadow: none !important;
  }
  .thermometer .thermometer__draw-a{
    box-shadow: none !important;
  }
  .thermometer .thermometer__bg-color{
    background: #01CC9B !important;
  }
  .thermometer .thermometer__draw-b:before{
    background: #fff !important;
  }
  .thermometer .thermometer__draw-b:after{
    background: #01CC9B !important;
  }
  .thermometer .thermometer__meter{
    width: 14px;
  }
  .thermometer ul.thermometer__statistics{
    left: 5px !important;
  }
  .thermometer--normal .thermometer__draw-b:after {
       width: 20px !important;
    height: 20px !important;
    top: 6px !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 15px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 14px !important;
  top: -4px !important;
}
.css-safhly-MuiFormControl-root{
  min-width: 9.8vw !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 0px !important;
}
.css-1tv0ec6-MuiFormControl-root{
  min-width: 10.3vw !important;
}
.css-tudw80-MuiFormControl-root .MuiInputBase-root{
  height: 38px !important;
}
.css-1n4rt4-MuiFormControl-root .MuiInputBase-root{
  height: 38px !important;
}
.css-1lufxmk-MuiPaper-root{
  border: 1px solid #BEBEBE !important;
  box-shadow: 0px 3px 6px #00000014 !important;
}
.navbar_nav_links__jIXPF li span{
  font-size: 13px !important;
}
.dashboardContent_dashboard_link__K9ujk{
  display: flex;
  gap: 5px;
  margin-top: 10px;
}
.mainContainer_header_title__c9MeK{
  font-size: 22px !important;
  font-weight: 600 !important;
}
.mainContainer_teacher_name__PW5yi{
  font-size: 15px !important;
}
.dashboardContent_dashboard_link__K9ujk{
  font-size: 12px !important;
}
