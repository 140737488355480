.chart-card {
    height: 250px;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
}

.chart-card-inner {
    width: 194px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
}

.chart-label {
    color: #060606;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 100% */

}

.chart-card-title {
    color: #3268E7;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.chart-card-subtitle{
    color: #000000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
}