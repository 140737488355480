.sheet-table-img-wrap {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.sheet-table-page-head {
  font-family: Poppins;
  color: #1b1b1b;
  font-size: 13px;
  font-style: bold;
  font-weight: 500;
  line-height: 19.5px;
  padding-left: 10px;
}

.sheet-table-wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.sheet-table-content {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  /* margin-top: 10px; */
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.sheet-table-head {
  background-color: #b2c3ff;
  text-align: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  font-family: Poppins;
  color: #1b1b1b;
  font-size: 13px;
  font-weight: 600;
  height: 50px;
  padding-top: 10px;
  text-align: center;
}

.sheet-table-body {
  font-family: Poppins;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background-color: #ecebff;
  height: 40px;
  text-align: center;
  padding-top: 10px;
}

.input-text {
  border: none;
  width: 100%;
  padding: 5px;
  margin-top: -10px;
  text-align: center;
  background-color: #ecebff;
  outline: none;
}

.sheet-button {
  border-radius: 9px;
  background: #001af3;
  width: 170px;
  height: 40px;
  flex-shrink: 0;
  border: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-wrapper {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-top: 15px;
}

.filter-wrapper {
  margin-left: 20px;
  margin-right: 20px;
}

.blue-print-wrap {
  margin-top: 5px;
  width: 100%;
}

.blue-print-form-container {
  height: 350px;
  border-radius: 18px;
  background-color: #ffffff;
  margin-top: 10px;
  width: 100%;
  position: relative;
}

.image-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #001af3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 220px;
}
.image-button img {
  height: 20px;
  margin-left: 30px;
}

.btn-text {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button-cancel {
  border-radius: 12px;
  border: 1px solid #7e7e7e;
  color: #7e7e7e;
  width: 145px;
  height: 45px;
  margin-right: 10px;
}

.button-submit {
  border-radius: 12px;
  border: none;
  color: #ffffff;
  width: 145px;
  height: 45px;
  background-color: #faa220;
}

.form-wrapper {
  padding-left: 50px;
  padding-top: 30px;
}

.file-upload-text {
  color: #001af3;
  text-decoration: underline;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 120px;
  height: 35px;
  color: white;
  font-size: 12px;
  background-color: #7a73ff;
  border-radius: 10px;
  padding-top: 5px;
  top: -40px;
  left: -90px;
  /* padding: 10px 15px 10px 15px; */
}

.tooltip-text::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #7a73ff;
  left: 50px;
  top: 30px;
  padding: 5px;
  z-index: 1;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

.reject-button-blue-print {
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1px solid #f00;
  color: #f00;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

}

.cancel-button-blue-print {
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1px solid #ccc;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}


.approve-button-blue-print {
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #1fbc05;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;

}


.modal-text{
  color: rgba(27, 27, 27, 0.37);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
color:'red'
}