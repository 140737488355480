
  
  .mainContainer{
    width: 100%;
    margin: 15px;
    overflow: scroll;
    font-family: Poppins;
}
  .link_text {
    /* margin-right: 5px !important; */
    font-size: 12px;
    color: rgb(122, 122, 122);;
  }
  .schoolContainer{
    display: flex;
   flex: 1;
   ;  
    /* height: 120px */
}
  

  .totalNoText{
    margin-top: 10px;
  }

  .boardsContainer{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-gap: 20px;
    flex:1;  
    /* align-items: center; */
}

  .boardBox{
    justify-content: space-between;
    height:280px;
    width: 85%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 5px ;
    overflow: scroll;
  }

  .applicationBox1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 14.5rem;
    width: 78%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
  }
  .applicationBox2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 14.5rem;
    width: 78%;;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
  }

  .schoolOverviewContainer{
    display: flex;
    flex: 1;  
    height: 300px;
    background-color: white;
    border-radius: 10px;
    overflow-y: hidden;
  }
  .schoolRow{
    display: flex;
    margin-bottom: 10px;
    box-sizing: border-box;
    flex: 1;
  }

  .overviewText{
    padding: 15px 5px 0px 25px;
  }

  .school{
    background-color: #eaeaf5;
    height:110px;
    width: 160px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
    text-align: center;
    padding-top: 40px;
  }
  .branch{
    background-color: #eaeaf5;
    height:110px;
    width: 160px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
    text-align: center;
    padding-top: 40px;
  }

  .bottomSchoolFlex{
    display: flex;
    flex: 1;
}

  .schoolBranch{
    background-color: #eaeaf5;
    height:224px;
    width: 165px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
  }

  .schoolBranchInsideBox{
    background-color: white;
    height:95px;
    width: 135px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
  }
  .branchNo{
    background-color: white;
    height:95px;
    width: 135px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
    text-align: center;
  }

  .schoolBranchInsideBox2{
    background-color: white;
    height:25px;
    width: 135px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 5px;
    padding: 3px ;
    font-size: 14px;
    overflow: scroll;
  }

  .schoolBranchLabel{
    background-color: #eaeaf5;
    height:25px;
    width: 135px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 5px;
    padding: 3px ;
    font-size: 14px;
    overflow: scroll;
  }
  .schoolBranch1{
    background-color: white;
    height:224px;
    width: 165px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
  }
 
  .cityData{
    height: 25px;
    flex: 1;
    background-color:#fff3f3;
    border-radius: 5px;
    margin: 5px;
  }

  .schoolData{
    height: 25px;
    flex: 1;
    background-color: rgb(195 238 172 / 53%);
    border-radius: 5px;
    margin: 5px;
  }

  .cityCount{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #fff3f3;
    padding: 0px 6px;
    cursor: pointer;
}

   .schoolCount{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: rgb(163 244 120 / 79%);
    padding: 0px 6px;
    cursor: pointer;
   }

   .noOfBranches{
      display: flex;
      /* justify-content: space-around; */
      padding: 10px;
   }

   @media (max-width: 1440px) {
    .boardBox{
      justify-content: space-between;
      height:12.9rem;
      width: 90%;
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
      margin: 5px;
      padding: 0px ;
      overflow: hidden;
      font-size: 13px;
    }
    .applicationBox1{
      display: flex;
    flex-direction: column;
    justify-content: space-between;
      height: 11.9rem;
      width: 90%;
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
      margin: 5px;
      padding: 10px ;
    }
    .applicationBox2{
      display: flex;
    flex-direction: column;
    justify-content: space-between;
      height: 11.9rem;
     width: 90%;;
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
      margin: 5px;
      padding: 10px ;
    }
    .noOfBranches{
      display: flex;
      justify-content: space-around;
      padding: 5px;
   }
  }
  