.Quiz_Main_div_MCQ {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 10px;
  padding: 31px;
  opacity: 0.9;
}
.Paragraph_title {
  font-size: 18px;
}

.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

/*-------| Quiz QUESTION Style Starts |------*/
.Qstn_and_Ans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.Qstn_div {
  max-height: 400px;
  overflow: auto;
  text-align: justify;
  border-right: 1px solid #faf9ff;
  padding-right: 20px;
}

/*-----| Scroll Bar Starts |-----*/
.Qstn_div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Qstn_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Qstn_div::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.Mcq_Head {
  font-size: 15px;
  opacity: 0.8;
}

.Qstn_content_1 {
  font-size: 13px;
  margin-top: 10px;
  line-height: 1.7;
  color: #333333;
  opacity: 0.9;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.Qstn_content_1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Qstn_content_1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Qstn_content_1::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.MCQ_Qstn_Img {
  width: 170px;
  height: 110px;
}

/*-------| Quiz QUESTION Style Ends |-------*/

/*-------| Quiz ANSWER Style Starts |-------*/
.Ans_div {
  max-height: 400px;
  overflow-x: hidden;
}

/*-----| Scroll Bar Starts |-----*/
.Ans_div::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Ans_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Ans_div::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.Ans_Head_and_Count {
  display: flex;
  justify-content: space-between;
}

.Head {
  font-size: 16px;
  color: #1b1b1b;
}

.Ans1 {
  width: 520px;
  height: auto;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 11px;
  line-height: 24px;
  padding: 10px;
  border-radius: 5px;
}

/*-------| Quiz ANSWER Style Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Qstn_and_Ans {
    column-gap: 3vw;
  }

  .Mcq_Head {
    font-size: 1.3vw;
  }

  .Qstn_content_1 {
    font-size: 1.3vw;
  }

  .Head {
    font-size: 1.5vw;
  }

  .Ans1 {
    width: 35vw;
    height: auto;
    font-size: 1.4vw;
    margin-top: 1vw;
    padding: 0.2vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1024px) and (max-width: 1430px) {
  .Qstn_and_Ans {
    column-gap: 3vw;
  }

  .Mcq_Head {
    font-size: 1.1vw;
  }

  .Qstn_content_1 {
    font-size: 1.1vw;
  }

  .Head {
    font-size: 1.3vw;
  }

  .Ans1 {
    width: 38vw;
    font-size: 1.3vw;
    margin-top: 1.3vw;
    padding: 0.4vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1440px) and (max-width: 3072px) {
  .Qstn_and_Ans {
    column-gap: 3vw;
  }

  .Mcq_Head {
    font-size: 1vw;
  }

  .Qstn_content_1 {
    font-size: 0.9vw;
  }

  .Head {
    font-size: 1vw;
  }

  .Ans1 {
    width: 35vw;
    font-size: 1vw;
    margin-top: 1.3vw;
    padding: 0.6vw;
  }
}
