.thumbsUP {
  margin-top: 20px;
}
.star1{
  transform: translate(3px, 15px);
  position: relative;
}
.star2{
  transform: translate(0px, 5px);
  position: relative;
}
.star3{
  transform: translate(0px, 0px);
  position: relative;
}
.star4{
  transform: translate(0px, 5px);
  position: relative;
}
.star5{
  transform: translate(-3px, 15px);
  position: relative;
}

.Wrapper {
  width: 100%;
  height: 88vh;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  background: #f4f4f4;
}

/*---------------------------| DropDown Style Starts Here |---------------------------*/
.dropdown__div {
  display: flex;
  margin: 55px 0 5px 25px;
  gap: 20px;
}

/*---------------------------| Underline Style |---------------------------*/
.u__line {
  border-bottom: 1px solid #e9e7f6;
  margin: 12px 0 0 10px;
  width: 100%;
}

.h__line {
  width: 1px;
  height: 77vh;
  background: #e9e7f6;
  position: relative;
  top: -20px;
}

/*---------------------------| CR Score-Card & Charts Style starts Here |---------------------------*/
.CR_Wrapper {
  margin: 20px 20px 0;
}

.Score_and_Charts {
  display: flex;
  justify-content: space-around;
}

.Score_Cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
  max-height: 550px;
  padding-right: 15px;
  width: 22%;
  overflow-y: auto;
}

/*------------------------------| Scroll Bar For CR Score-Card Div Style Starts |-----------------------------*/
.Score_Cards::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #c4c3c7;
  position: relative;
  display: block;
}

.Score_Cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c4c3c7;
  height: 10px;
}

.Score_Cards::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
/*------------------------------| Scroll Bar For CR Score-Card Div Style Ends |-----------------------------*/

/*----------------------------| Score-Cards Style Starts Here |------------------------*/
.Score_Card {
  width: 100%;
  max-height: 170px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.Score_Card div:first-child {
  font-size: 14px;
  font-weight: bold;
  color: #2f395b;
  opacity: 0.9;
  margin-bottom: 5px;
  max-height: 65px;
  overflow: auto;
}

.score {
  font-size: 13px;
  color: #9093a8;
  margin-top: 10px;
}

.Progress_Bar_div span {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.Score_Card div:last-child {
  font-size: 13px;
  color: #fd8c00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
/*----------------------------| Score-Cards Style Ends Here |------------------------*/

/*----------------------------| CR Charts Style Starts Here |------------------------*/
.CR_Charts_div {
  width: 73%;
  height: 63vh;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: auto;
}

/*-----------| Header Style Starts Here |---------*/
.CR_Header_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 82px;
}

.CR_Header_div h5:first-child {
  font-size: 16px;
  color: #333333;
  opacity: 0.8;
}

.CR_Header_Img_Time_Score {
  display: flex;
  align-items: center;
}

.CR_Img_Score_div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px 40px 15px 0;
  opacity: 0.9;
}

.CR_Img_Score_div h6:nth-child(2) {
  font-size: 16px;
  color: #333333;
  opacity: 0.9;
}

.CR_clock_img {
  display: flex;
  margin-right: 10px;
}

.CR_Time_div h6:first-child {
  font-size: 16px;
  color: #333333;
  opacity: 0.8;
}

.CR_Time_div h6:nth-child(2) {
  font-size: 13px;
  color: #afafaf;
}
/*-----------| Header Style Ends Here |---------*/

/*-----------| Charts Style Starts Here |---------*/
.CR_Chart_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  margin-top: 20px;
  padding: 10px;
}

.CR_DNut_details {
  display: flex;
}

.CR_details div:nth-child(1) h5,
.CR_details div:nth-child(2) h5,
.CR_details div:nth-child(3) h5 {
  font-size: 18px;
  color: #333333;
}

.CR_details div:nth-child(2),
.CR_details div:nth-child(3) {
  margin: 25px 0 0 0;
}

.CR_details div:nth-child(1) h6,
.CR_details div:nth-child(2) h6,
.CR_details div:nth-child(3) h6 {
  font-size: 11px;
  color: #54585a;
}

.CR_dot1 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00ce95;
  margin-right: 5px;
}

.CR_dot2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #eb3e68;
  margin-right: 5px;
}
.CR_dot3 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #959aad;
  margin-right: 5px;
}
/*-----------| Charts Style Ends Here |---------*/

/*-----------| Analysis Style Starts Here |---------*/
.CR_Analysis_div {
  background-color: #c3ffda48;
  border-radius: 10px;
  padding: 20px;
  max-width: 95%;
  height: 130px;
  margin: 20px auto;
}

.Analysis_div_parent {
  max-height: 135px;
  overflow: auto;
  width: 90%;
  margin: auto;
}
.Analysis_div {
  width: 92%;
  margin: auto auto 2vh;
}

.CR_Analysis_head {
  font-size: 22px;
  color: #333333;
  font-weight: normal;
  opacity: 0.9;
}

.CR_Analysis_Details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.CR_detail_1 {
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.CR_detail_1 h6:nth-child(2) {
  font-size: 16px;
  color: #0470cc;
}
/*-----------| Analysis Style Ends Here |---------*/

/*-----------| Button Style Starts Here |---------*/
.CR_Analysis_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.CR_Analysis_Btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 40px;
  background-color: #22af42;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  font-size: 12px;
  outline: none;
  box-shadow: 0 4px #999;
}

.CR_Analysis_Btn button:hover {
  background-color: #3e8e41;
}

.CR_Analysis_Btn button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
/*-----------| Button Style Ends Here |---------*/

/*----------------------------| CR Charts Style Starts Here |------------------------*/

/*------------------------| Message Icon Style Starts Here |-------------------------*/
.msg__Icon_style {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.msg__Icon_style img {
  border: 5px solid blue;
  border-radius: 50px;
  padding: 5px;
  background-color: #1436c3;
  width: 48px;
  height: 48px;
}
/*------------------------| Message Icon Style Starts Here |-------------------------*/

.viewDetailsBtn:hover {
  cursor: pointer;
}

.msgOuterDiv {
  position: fixed;
  bottom: 0;
  right: 0;
}
