.quizBox {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e8e8e8;
    padding: 2vw;
    height: 10vw;
    width: 18vw;
    background-color: white;
    margin: 8px
}

.quizBox > p {
    color :#354052 ;
    font-size : 1.7vw
}
.quizBox > button {
    background-color:orange ;
    color : white ;
    padding : 3px 2vw ;
    border : none ;
    border-radius : 8px
}
/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 29px;
  }
  
  .active_switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .active_slider {
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 46px;
    border-radius: 5px;
    height: 10px;
  
    background-color: #d9dcfe;
    transition: 0.4s;
  }
  
  .active_slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 14px;
    right: 25px;
    border-radius: 15px;
    bottom: 0;
    top: -6px;
  
    border: 3px solid #d9dcfe;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked+.active_slider {
    background-color: orange;
  }
  
  input:focus+.active_slider {
    box-shadow: 0 0 1px #d9dcfe;
  }
  
  input:checked+.active_slider:before {
    -webkit-transform: scale3d;
    -ms-transform: translateX(26px);
    transform: translateX(25px);
    border: 3px solid orange;
  }
  
  /* Rounded sliders */
  .active_slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 0%;
  }
  