/* 
// --- ❗❕❗ FIB-> FILL IN THE BLANKS ❗❕❗ --- //
*/

.Quiz_Main__div {
  width: 100%;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.9;
  padding: 31px;
  user-select: none;
}
.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}
/*-------| Quiz FIB QUESTION Styles |-------*/
.Qstn_and__Ans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.Qstn__div {
  max-height: 400px;
  overflow: auto;
  text-align: justify;
  border-right: 1px solid #faf9ff;
  padding-right: 20px;
}

/*---| Scroll Bar Starts |---*/
.Qstn__div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Qstn__div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Qstn__div::-webkit-scrollbar-track {
  background-color: white;
}
/*---| Scroll Bar Ends |---*/

.Fib_Head {
  font-size: 16px;
}

.Fib_Qstn_Img {
  width: 170px;
  height: 110px;
  margin-top: 20px;
}

.Qstn_content__1 {
  font-size: 1.4vw;
  margin-top: 20px;
  line-height: 1.7;
  color: #333333;
  opacity: 0.9;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.Qstn_content__1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Qstn_content__1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Qstn_content__1::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

/*-------| Quiz FIB QUESTION Style Ends |-------*/

/*-------| Quiz FIB ANSWER Styles |-------*/
.Ans__div {
  max-height: 400px;
  overflow-x: hidden;
}

/*---| Scroll Bar Starts |---*/
.Ans__div::-webkit-scrollbar {
  width: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Ans__div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Ans__div::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

._Head {
  font-size: 16px;
  color: #1b1b1b;
}

.Ans_inp {
  width: 470px;
  height: 4vh;
  border: 1px solid #dbdee6;
  font-size: 14px;
  color: #706f6f;
  opacity: 0.9;
  margin-top: 11px;
  line-height: 2;
  padding: 10px;
  border-radius: 50px;
}

.Option_and_Ans {
  display: flex;
  align-items: baseline;
  gap: 40px;
  margin-left: 20px;
}

.Option_and_Ans > input {
  box-shadow: 0px 3px 6px #00000029;
}

.Option {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  width: 14px;
  height: 28px;
}
/*-------| Quiz FIB ANSWER Styles Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Qstn_and__Ans {
    column-gap: 3vw;
  }

  .Fib_Head {
    font-size: 1.5vw;
  }

  .Fib_img > img {
    width: 23.47vw;
    height: 20.31vh;
    margin-top: 2vw;
  }

  .Qstn_content__1 {
    font-size: 1.4vw;
  }

  ._Head {
    font-size: 1.5vw;
  }

  .Ans_inp {
    width: 30vw;
    height: 6vh;
    font-size: 1.5vw;
    margin-top: 1.5vw;
    padding: 1.5vw;
  }

  .Option_and_Ans {
    gap: 3vw;
    margin-left: 2vw;
  }

  .Option {
    font-size: 2vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1024px) and (max-width: 1430px) {
  .Qstn_and__Ans {
    column-gap: 3vw;
  }

  .Fib_Head {
    font-size: 1.3vw;
  }

  .Fib_img > img {
    width: 24.47vw;
    height: 26.31vh;
    margin-top: 2.5vw;
  }

  .Qstn_content__1 {
    /* b4 - 1vw */
    font-size: 1.4vw;
  }

  ._Head {
    font-size: 1.3vw;
  }

  .Ans_inp {
    width: 33vw;
    height: 6vh;
    font-size: 1.3vw;
    margin-top: 1.7vw;
    padding: 1.7vw;
  }

  .Option_and_Ans {
    gap: 2.5vw;
    margin-left: 1vw;
  }

  .Option {
    font-size: 1.5vw;
  }
}

/*----------| Media Query for Extra Large Screens |-----------*/
@media screen and (min-width: 1440px) and (max-width: 3072px) {
  .Qstn_and__Ans {
    column-gap: 3vw;
  }

  .Fib_Head {
    font-size: 1vw;
  }

  .Fib_img > img {
    width: 24.47vw;
    height: 26.31vh;
    margin-top: 2.5vw;
  }

  .Qstn_content__1 {
    /*  b4 - 0.9 */
    font-size: 1.4vw;
  }

  ._Head {
    font-size: 1vw;
  }

  .Ans_inp {
    width: 30vw;
    height: 6vh;
    font-size: 1.3vw;
    margin-top: 1.7vw;
    padding: 1.5vw;
  }

  .Option_and_Ans {
    gap: 2.5vw;
    margin-left: 1vw;
  }

  .Option {
    font-size: 1.3vw;
  }
}
