* {
    box-sizing: border-box;
    font-family: Poppins,sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}

.radio_active:before {
    background: white;
    position: relative;
    box-shadow: inset 0px 0px 0 4px orange;
    top: -3px;
    width: 17px !important;
    height: 17px !important;
  }



 /* ===============radio button starts================== */

  
 .radio_teacherid >input[type="radio"]{
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 0px;
}

.radio_teacherid >label{
  color: gray;
  font-size: 16px;
  font-family:'Poppins',sans-serif;
  font-weight: 600;
  padding-left: 10px;
}

.radio_teacherid >input[type="radio"]::after{
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}


.radio_teacherid >input[type="radio"]:checked{
  background-color:blue;
}

.radio_teacherid >input[type="radio"]:checked::after{
  display: block;
}



/* ===================.radio button ends here=============== */

  .myText {
    color : green ;
    font-size: 69px ;
  }

  /* upload input starts */
.upload_logo_container>span {
    padding: 0px 10px 10px 20px;
      font-size: 0.9rem;
      display: flex;
      grid-gap: 1rem;
      gap: 1rem;
}
.custom_file_container>span {
  position: relative;
  top: -2rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}
.custom_file_container>span >span > a {
 cursor: pointer;
}

.custom_file_input {
  width: 8rem;
  margin: -10px 0px 0px 15px;
  color: transparent;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom_file_input::-webkit-textfield-decoration-container {
  visibility: hidden;
}


.custom_file_input::before {
  content: 'Choose File';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 18px;
  padding: 11px 30px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}



.custom_file_input:hover::before {
  border-color: black;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.select_input {
    font-family: 'poppins' !important;
    color:#B5B6B6;
    font-weight: 300;
}
.table_head {
  color: blue !important;
  font-family: 'poppins' !important;
  font-weight: 600;
  background: #d9dcfe !important;
}
.accordion_detail {
  border-bottom: 1px solid grey; 
  display: flex; 
  justify-content: space-around
}
.accordion_detail > i {
  color: grey; 
  font-size: 22px; 
  margin-left: 8px

}


/* grip inputs - start */
.input_element {
  position: relative;
  width: 95%;
  height: 68%;
}

.input_element input,
.input_element select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 12px 20px;
}

.input_element label {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

.input_element:hover label {
  top: -7px;
  color: var(--utilityColor);
}

.form_input_element:not(:placeholder-shown)~label,
.form_input_element:focus~label {
  top: -7px;
  color: var(--utilityColor);
}
.form_select_element:not(:placeholder-shown)~label,
.form_select_element:focus~label {
  top: -7px;
  color: var(--utilityColor);
}

/* grid inputs -end */
