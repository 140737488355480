.chart-card {
    height: 250px;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
}

.chart-card-inner {
    width: 194px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
}

.chart-label {
    color: #060606;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 100% */

}

.chart-cardv2-title {
    color: #6A6A6A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
}

.chart-cardv2-container-legend {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.chart-cardv2-container-indicator {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.chart-cardv2-container-sub-heading {
    color: #6A6A6A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
}

.clr-box {
    width: 7px;
    height: 7px;
    border-radius: 2px;
}

.indicator-label {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 200% */
    /* 166.667% */
}