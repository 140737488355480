/*--------------------------------------------| For Viewing The Image & Pdf Full Screen Styles Starts Here |-------------------------------------*/
iframe:-webkit-full-screen {
  height: 100%;
}

iframe:-ms-fullscreen {
  height: 100%;
}

iframe:fullscreen {
  height: 100%;
}
/*--------------------------------------------| For Viewing The Image & Pdf Full Screen Styles Ends Here |-------------------------------------*/

/*--------------------------------------------| Full Screen Button Styles Starts Here |-------------------------------------*/
.FullScrnBtn {
  color: white;
  background-color: #18ba24;
  padding: 8px;
  font-size: 13px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 15px;
}

/*-------------| Full Screen Button Hover Effect |------------*/
.hvr-bubble-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}

.hvr-bubble-top:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  border-style: solid;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  left: calc(50% - 10px);
  top: 0;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #18ba24 transparent;
}

.hvr-bubble-top:hover:before,
.hvr-bubble-top:focus:before,
.hvr-bubble-top:active:before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
/*--------------------------------------------| Full Screen Button Styles Ends Here |-------------------------------------*/

.Main_Container {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  user-select: none;
}

/*---------------------------| Bread Crumbs Style Starts Here |---------------------------*/
.Bread_Crumbs_itms {
  display: flex;
 
}

/* .Bread_Crumbs_itms p {
  font-size: 12px;
}

.Bread_Crumbs_itms > img {
  margin: 1px 0 0 5px;
  width: 15px;
} */

.ChapPage:hover {
  color: #1912bf;
}
/*---------------------------| Bread Crumbs Style Ends Here |---------------------------*/

/*---------------------------| StartDate & Enrich Btn Style Starts Here |---------------------------*/

/*❗❗ ❗❗ SD = Start Date ❗❗ ❗❗*/

.SD_and_btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 15px 0 20px 10px;
}

.StartAndEndDates {
  display: flex;
  gap: 20px;
  align-items: center;
}

.SD_heading1,
.SD_heading2 {
  font-size: 15px;
  color: #2d3962;
  font-weight: bold;
  opacity: 0.9;
}

.S-date {
  font-size: 13px;
  color: #0088ff;
  opacity: 0.9;
  padding-left: 10px;
}

.E-date {
  font-size: 13px;
  opacity: 0.9;
  padding-left: 10px;
}

.Enrich_btn,
.End_btn {
  font-size: 13px;
  color: #ffffff;
  background-color: #707070;
  width: 75px;
  height: 32px;
  border-radius: 2px;
  opacity: 0.8;
  border: 0;
  margin-right: 20px;
}

/* .Enrich_btn:hover {
  color: #ffffff;
  background-color: #fd8c00;
  opacity: 1;
} */

/*---------------------------| StartDate & Enrich Btn Style Ends Here |---------------------------*/

.image_iframe {
  height: 83.1vh;
  width: 100%;
}

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/
@media (min-width: 768px) and (max-width: 1023px) {
  .Bread_Crumbs_itms {
    display: flex;
    margin: 0.9vw 0 0 0.6vw;
  }

  .Bread_Crumbs_itms p {
    font-size: 0.78vw;
  }

  .Bread_Crumbs_itms > img {
    margin: 0.015vw 0 0 0.32vw;
    width: 0.98vw;
  }

  .SD_and_btn {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0.6vw 0 1vw 0.55vw;
  }

  .StartAndEndDates {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .SD_heading1,
  .SD_heading2 {
    font-size: 0.97vw;
  }

  .S-date {
    font-size: 0.84vw;
    padding-left: 10px;
  }

  .E-date {
    font-size: 0.84vw;
    padding-left: 10px;
  }

  .Enrich_btn {
    font-size: 0.84vw;
    width: 3.88vw;
    height: 3.43vh;
    border-radius: 0.3vw;
    margin-right: 1.3vw;
  }

  .image_iframe {
    height: 41.1vh;
    width: 100%;
  }

  .FullScrnBtn {
    padding: 0.62vw;
    font-size: 1.2vw;
    border-radius: 0.5vw;
    margin-top: 1.5vw;
  }
}

/* hide pdf icons */
/* .rpv-open-button{
 
  display: none;
} */

/* .rpv-default-layout-sidebar-headers 
  {
  display: none;
} */



.az_menu_icon{
  width: 24px;
  height: 24px;
  color: #828282;
  vertical-align: middle;
}

.az_menu_text{
  color: #828282;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
  font-weight: bold;
}

.az_selected_menu_text{
  color: #ffa500;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
  font-weight: bold;
}

.az_double_arrow{
  color: #828282;
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  margin-right: 16px;
}

.az_menu_text:hover {
  color: black;
  font-weight:bold;
  text-decoration: underline;
  text-decoration-color: orange;
  text-underline-offset: 4px;
  text-decoration-thickness: 4px;
}

@media print {
  .rpv-core__display--block-medium .example-modal {
      display: none;
  }
}