/*-----------| Header Style Starts Here |---------*/
.Header_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.Header_div > div:first-child > h5 {
  font-size: 14px;
  color: #333333;
  opacity: 0.8;
}

.Header_Img_Time_Score {
  display: flex;
  align-items: baseline;
  gap: 40px;
}

.Img_Score_div {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0.9;
  text-align: center;
}

.Img_Score_div > img:first-child {
  width: 54px;
  height: 54px;
}

.Img_Score_div > h6:nth-child(2) {
  position: absolute;
  bottom: -28%;
  font-size: 13px;
  white-space: nowrap;
  color: #333333;
  opacity: 0.9;
}

.Time_and_details {
  display: flex;
  flex-direction: row;
}

.clock_img {
  display: flex;
  margin-right: 10px;
}

.Time_div h6:first-child {
  font-size: 13px;
  color: #333333;
  opacity: 0.8;
}

.Time_div h6:nth-child(2) {
  font-size: 11px;
  font-weight: normal;
  color: #afafaf;
}

/*-----------| Header Style Ends Here |---------*/

/*-----------| Charts Style Starts Here |---------*/
.Charts_Wrapper {
  padding: 20px 30px 0 30px;
}

.Charts_Headings {
  display: flex;
  justify-content: space-between;
  padding: 0 7rem 0 3.5rem;
  font-size: 13px;
  font-weight: 600;
  color: #333333;
}

.Chart_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
}

.DNut_details {
  display: flex;
  gap: 20px;
}

.details div:nth-child(1) h5,
.details div:nth-child(2) h5,
.details div:nth-child(3) h5 {
  font-size: 15px;
  color: #333333;
  margin-top: 15px;
}

.details div:nth-child(1) h6,
.details div:nth-child(2) h6,
.details div:nth-child(3) h6 {
  font-size: 10px;
  color: #54585a;
  opacity: 0.7;
}

.dot1 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00ce95;
  margin-right: 5px;
}

.dot2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #eb3e68;
  margin-right: 5px;
}

.dot3 {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #959aad;
  margin-right: 5px;
}

/*-----------| Charts Style Ends Here |---------*/

/*-----------| Analysis Style Starts Here |---------*/
.Analysis_div_parent {
  height: 21vh;
  overflow-y: scroll;
}

.Analysis_div_parent::-webkit-scrollbar {
  display: block;
  width: 7px;
}

.Analysis_div_parent::-webkit-scrollbar-track {
  background-color: white;
}
.Analysis_div_parent::-webkit-scrollbar-thumb {
  background-color: gray;
  border-Radius: 4px;
}

.Analysis_div_parent::-webkit-scrollbar-thumb:hover {
  background-color: darkgray;
}

.Analysis_div {
  background-color: #c3ffda48;
  border-radius: 10px;
  padding: 20px;
  height: auto;
  margin-bottom: 2vh;
}

.Analysis_head {
  font-size: 20px;
  color: #333333;
  font-weight: normal;
  opacity: 0.9;
}

.Analysis_Details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.detail_1 {
  font-size: 16px;
  color: #333333;
  text-align: center;
}

.detail_1 h6:nth-child(2) {
  font-size: 16px;
  color: #0470cc;
}

/*-----------| Analysis Style Ends Here |---------*/

/*-----------| Button Style Starts Here |---------*/
.Analysis_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.Analysis_Btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 40px;
  background-color: #22af42;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  font-size: 12px;
  outline: none;
  box-shadow: 0 4px #999;
}

.Analysis_Btn button:active {
  background-color: #22af42;
  transform: translateY(4px);
}

/*-----------| Button Style Ends Here |---------*/