.subMenuIcon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7a7a7a;
}
/* .activeSubMenu .subMenuIcon{
  background-color: #ffa500;
  color: #ffa500;
}


.activeSubMenu .subMenuIcon:hover{
  background-color: #ffa500;
  color: black;
} */

.activeSubMenu {
  background-color: orange;
}

.reports {
  padding-left: 20px;
  
}

.sidenav_parent {
  width: 100%;
  height: auto;
  user-select: none;
}

/*-------| SideNav-Items Icon & Text Styles |-------*/
.icon_and_text {
  height: 45px;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  /* border-radius: 0 30px 30px 0; */
  transform: perspective(1px) translateZ(0);
  color: white;
  background-color: #ffa500;

  transition-duration: 0.3s;
  font-family: "poppins" !important;
}




.reports {
  height: 45px;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  /* border-radius: 0 30px 30px 0; */
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  font-family: "poppins" !important;
  

  
}

/*-------| Hover effect for SideNav-Items Starts |-------*/
.icon_and_text:before,
.reports:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  /* background: white; */
  /* border-radius: 0 30px 30px 0; */
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-in-out;
  font-family: "poppins" !important;
}

.icon_and_text:hover:before,
.icon_and_text:focus:before,
.icon_and_text:active:before,
.reports:hover:before,
.reports:focus:before,
.reports:active:before {
  transform: scaleX(1);
  color: black;
  
  /* border-radius: 0 30px 30px 0; */
}
/*-------| Hover effect for SideNav-Items Ends |-------*/

/*-------| Highlight the selected Option & Icon Styles |-------*/

.icon_and_text_active {
  background-color: white; 
  font-family: "poppins" !important;
}
/*.icon_and_text_active .sidenav_icons > img {
  filter: invert(100%);
}*/

/* div.sidenav_text:hover, div.reports_text:hover {
  color: orange;
} */




.icon_and_text_active:hover,
.reports:hover {
  /* background-color: #ffa500; */
  /* color: black; */
  background-color: #ffa500;
  font-family: "poppins" !important;
}


.sidenav_icons_active {
  filter: invert(100%) brightness(2) contrast(1);
 
  /* color: #ffa500; */
  /* filter: brightness(0) saturate(100%) invert(10%) sepia(100%) saturate(7497%)
    hue-rotate(241deg) brightness(87%) contrast(123%); */
}

 /* .sidenav_icons:hover {
  filter: invert(100%) brightness(2) contrast(1);
}  */



.subMenuIcon_active{
  background-color: #ffa500;
  /* color: #ffa500; */
}
/*-------| Highlight the selected Option & Icon Style Ends |-------*/

/*--| Old One |--*/
/* .sidenav_icons,
.reports_icons {
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727272;
  padding: 5px 6px;
  margin-left: 5px;
} */

.sidenav_icons,
.reports_icons {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 22px;
  
}

.sidenav_icons > img,
.reports_icons > img {
  width: 1.4vw;
  height: 3vh;
}

/* .icon_and_text:hover .sidenav_icons,
.reports:hover .reports_icons {
   filter: brightness(0) saturate(100%) invert(10%) sepia(100%) saturate(7497%)
    hue-rotate(241deg) brightness(87%) contrast(123%); 
 
    filter:  invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%) ;

    filter: invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%) grayscale(100%);
} */

.sidenav_text {
  font-size: 15px;
  margin: 5px 4px;
  color: #1b1b1b;
  font-family: "poppins" !important;
}

/* .sidenav_text:hover,.reports_text:hover{
  color: white;
}  */

.sidenav_text_selected {
  font-size: 15px;
  margin: 5px 4px;
  font-family: "poppins" !important;
  
  
}

.reports_text {
  font-size: 0.9vw;
  margin: 5px 4px;
  color: #1b1b1b;
  font-family: "poppins" !important;
}

.reports_text_selected {
  font-size: 0.9vw;
  margin: 5px 4px;
  color: white;
  
  font-family: "poppins" !important;
 
}

.reports_text_selected:hover {
  font-size: 0.9vw;
  margin: 5px 4px;
  
  font-family: "poppins" !important;
}
/*-------| SideNav-Items Icon & Text Style Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/

@media (min-width: 768px) and (max-width: 1023px) {
  .icon_and_text,
  .reports {
    height: 4.5vw;
  }

  .sidenav_text {
    font-size: 1.4vw;
  }

  .reports_text {
    font-size: 1.1vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media (min-width: 1024px) and (max-width: 1430px) {
  .icon_and_text,
  .reports {
    height: 3.5vw;
  }

  .sidenav_text {
    font-size: 1.1vw;
  }

  .reports_text {
    font-size: 0.9vw;
  }
}


.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0 !important;
}