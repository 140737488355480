.cqk_CS_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  background: #ffffff;
  width: 100%;
  height: 355px;
  padding: 20px;
  border-radius: 10px;
  user-select: none;
}

/*-------| CS Quiz QUESTION Styles |-------*/
.cqk_Para_and_img {
  overflow: auto;
  max-height: 672px;
  width: 100%;
  padding-right: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk_Para_and_img::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  max-height: 178px;
  background-color: white;
  position: relative;
  display: block;
}

.cqk_Para_and_img::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_Para_and_img::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
/*---| Scroll Bar Ends |---*/

.cqk_head_1 {
  font-size: 16px;
  color: #00be66;
  opacity: 0.9;
}

.cqk_Para_div_1 {
  font-size: 13px;
  text-align: justify;
  overflow-y: scroll;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk_Para_div_1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_Para_div_1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_Para_div_1::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk_Para {
  margin-top: 20px;
  text-align: justify;
  font-size: 1.3 vw;
}

.cqk_Cs_Explanation {
  margin-top: 20px;
}

.cqk_Cs_img {
  margin-top: 20px;
}

.cqk_Cs_img {
  width: 170px;
  height: 110px;
}
/*-------| CS Quiz QUESTION Styles Ends |-------*/

/*-------| CS Quiz ANSWER Styles |-------*/
.cqk_SubQuestion_Section {
  overflow-y: scroll;
  max-height: 672px;
  padding-right: 10px;
}

.cqk_Qstns_and_Ans {
  width: 100%;
}

/*---| Scroll Bar Starts |---*/
.cqk_SubQuestion_Section::-webkit-scrollbar {
  width: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk_SubQuestion_Section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk_SubQuestion_Section::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk_Img_and_Para {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cqk_Q_para {
  font-size: 13px;
  text-align: justify;
  width: 100%;
  margin-top: 10px;
}

.cqk_sub_Qustn_img {
  width: 100px;
  height: 60px;
}

.cqk_Cs_Ans_Fields {
  margin-top: 10px;
}

.cqk_title_ {
  font-size: 16px;
}

.cqk_CsFib_Option_and_Ans {
  display: flex;
  align-items: baseline;
  gap: 40px;
  margin-left: 20px;
}

.cqk_CsFib_Option {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  width: 14px;
  height: 28px;
}

.cqk_CsFib_Ans_div {
  width: 500px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  border: 1px solid #dbdee6;
  border-radius: 5px;
}

.cqk_CsFib_tick_And_Cancel_Icon {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.cqk_CsFib_CorrectAnsLabel {
  margin: 8px 0 0 100px;
  font-size: 13px;
  font-weight: bold;
}

/*-------| CS Quiz ANSWER Styles Ends |-------*/
