.button-submit-assign {
  border-radius: 12px;
  border: none;
  color: #ffffff;
  width: 145px;
  height: 45px;
  background-color: #001af3;
}

.modal-head {
  position: relative;
  padding: 40px 72px 17px 72px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
}

.modal-close {
  position: absolute;
  top: 25px;
  right: 20px;
}

.modal-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  padding-left: 72px;
  padding-right: 72px;
}

.modal-left {
  width: 50%;
  /* background-color: #001af3; */
  height: 400px;
  border-right: 1px solid #cecece;
  overflow-y: "auto";
  padding-right: 28px;
}

.modal-right {
  width: 50%;
  /* background-color: red; */
  height: 400px;
  overflow-y: "auto";
  padding-left: 28px;
}

.modal-scroll {
  height: 375px;
  overflow-y: auto;
  overflow-x: hidden;
}

.flexed {
  display: flex;
  align-items: center;
}

.school-text {
  color: #faa220;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.branch-text {
  color: #0d70b8;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title {
  color: #faa220;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-bottom {
  width: 100%;
  padding: 26px 0px;
}

.modal-btn {
  border: none;
  margin: 0 auto;
  width: 156px;
  height: 47px;
  display: grid;
  place-content: center;
  background-color: #001af3;
  border-radius: 12px;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
