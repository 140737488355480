.MTF_Box {
  width: 100%;
  height: 600px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.MTF_Qstn_And_Field_div {
  overflow-x: hidden;
  /* overflow: scroll; */
  width: 800px;
  max-height: 500px;
}

/*------------------------------| Scroll Bar For MTF Qstn And Field Style Starts |-----------------------------*/
.MTF_Qstn_And_Field_div::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.MTF_Qstn_And_Field_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.MTF_Qstn_And_Field_div::-webkit-scrollbar-track {
  background-color: white;
}
/*------------------------------| Scroll Bar For MTF Qstn And Field Style Ends |-----------------------------*/

.MTF_Div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
}

/*------------------| Qstn And Field Style Starts Here |-----------------*/
.MTF_Fields_1 {
  display: flex;
  justify-content: space-evenly;
  width: 800px;
}

.MTF_Fields_2,
.MTF_Fields_3,
.MTF_Fields_4 {
  display: flex;
  justify-content: space-evenly;
  width: 800px;
  margin-top: 20px;
}

.MTF_Qstn_Field_1_B1,
.MTF_Qstn_Field_1_B2,
.MTF_Qstn_Field_2_B1,
.MTF_Qstn_Field_2_B2,
.MTF_Qstn_Field_3_B1,
.MTF_Qstn_Field_3_B2,
.MTF_Qstn_Field_4_B1,
.MTF_Qstn_Field_4_B2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 276px;
  height: 159px;
  border-radius: 6px;
  position: sticky;
  /*To give gap in between the dash border*/
  background: linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;
}

.MTF_Qstn_Field_1_B1::after,
.MTF_Qstn_Field_2_B1::after,
.MTF_Qstn_Field_3_B1::after,
.MTF_Qstn_Field_4_B1::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5px dashed #707070;
  border-collapse: separate;
  border-spacing: 150px;
}

.MTF_Qstn_Field_1_B1::after,
.MTF_Qstn_Field_2_B1::after,
.MTF_Qstn_Field_3_B1::after,
.MTF_Qstn_Field_4_B1::after {
  /* right: -125px;
  width: 125px; */
  right: -85px;
  width: 85px;
}
/*------------------| Qstn And Field Style Ends Here |-----------------*/

/*------------------| MTF Ans IMages Div |-----------------*/
.MTF_Ans_Imgs {
  overflow-x: hidden;
  /* overflow: scroll; */
  /* width: 800px; */
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MTF_Ans_Imgs img {
  width: 276px;
  height: 159px;
  border: 0.5px dashed #707070;
}

/*------------------------------| Scroll Bar For MTF Ans Div Style Starts |-----------------------------*/
.MTF_Ans_Imgs::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.MTF_Ans_Imgs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.MTF_Ans_Imgs::-webkit-scrollbar-track {
  background-color: white;
}
/*------------------------------| Scroll Bar For MTF Ans Div Style Ends |-----------------------------*/

/* .btn_DIV {
  display: flex;
  justify-content: flex-end;
}

.Qstn___Btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  border: 1px solid #faf9ff;
  background-color: #f8f4fc;
  width: 666px;
  height: 85px;
}

.Exit___btn {
  background-color: #2aa571;
  padding: 10px;
  margin-right: 20px;
}

.Exit___btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.Prev___btn {
  display: flex;
  padding: 10px;
  background-color: #9ea3d4;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.Prev___btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
}

.Nxt___btn {
  display: flex;
  padding: 10px 5px 10px 10px;
  background-color: #fd8c00;
  color: #ffffff;
  border: none;
  margin-right: 20px;
  border-radius: 3px;
}

.Nxt___btn h6 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0.9;
} */
