* {
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
.data_row {
    display : flex ;
    border-bottom: 1px solid lightgrey; 
    height : 48px ; 
    width : 324px;
    justify-content: space-around

}
.data_row:hover {
    box-shadow: 3px 3px 3px 3px #888888;
    background-color: #f8f4fc;
  }

  .data_row {
    /* margin: 6px 0;  */
    height: 48px;
    padding: 4px; 
  }
  .data_row > p {
    color : black ; 
    display : inline-flex ; 
    align-items : center ; 
    font-size : 17px;
    font-family: 'poppins' !important;
  }

  .data_row > i:hover{
    color: white; 
    /* margin: 6px 0;  */
    background-color: orange;
    padding: 4px 4px; 
    border-radius: 50%
  }

  .table_cell {
    font-family: 'poppins' !important;
}
