* {
    box-sizing: border-box;
    font-family: 'Poppins' ,sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
.reasonbtn {
    padding : 10px ;
    color : #FD8C00 ;
    border : 1px solid #FD8C00 ;
    border-radius: 6px;
    background-color: transparent;
}
.reasonbtn:hover {
    color : white ;
    background-color: #FD8C00;
}

/* modal  */
.flex_container {
    display: flex;
    background-color: hsla(0, 0%, 0%, 0.5);
    align-items: center;
    justify-content: center;
  }

  .main_container{
    width : 929px ;
    margin-top: 255px;
    /* height : 890px;  */
    border-radius: 20px;
    background-color: white;
  }
  .main_container p {
    font-size: 0.5em;
    color: orange;
    display: flex;
    justify-content: center;
  }

  #modal_id {
     /* display: none; */
     position: fixed;
     z-index: 1;
     left: 0;
     top: 0;
     overflow: auto;
     width: 100%;
     height: 100%;
     background-color: rgb(0, 0, 0); /* Fallback color */
     background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }