/* article.article_add_school_plan starts from here */
article.article_add_school_plan {
    width: 100%;
    position: absolute;
    top: 25px;
    height: 100%;
}
/* .Grid.school_name_branch  starts */
.Grid_school_name_branch {
  display: flex;
  justify-content: 
  space-between;
  padding: 13px;
  background-color: #f8f4fc;
  }

.Grid .school_name_branch {
  display: flex;
  justify-content: 
  space-between;
  padding: 9px;
  margin-right: px;
}

button.btn_branch_name {
    width: 220px;
    height: 35px;
    border-radius: 15px;
    background-color: blue;
    color: #f8f4fc;
    border: none;
    
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}
.school_name {
    FONT-WEIGHT: 550;
    font-size: 0.9rem;
    color: #000000b8;
    padding-top: 10px;
    margin-left: 15px;
}

/* .Grid.school_name_branch ends here */
/* grid_container_header_plan starts from here */

.grid_container_header_plan {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    text-align: center;
    /* width: 100%; */
    height: 40px;
    grid-column-gap: 2px;
    column-gap: 2px;
    margin: 0px 10px 0px 10px;
    background: white;
    /* border-left: 15px solid var(--backgroundColor); */
    /* border-right: 45px solid var(--backgroundColor); */
}


.item_plan1{
    color: orange;
  font-size: 0.9rem;
  border-bottom: 4px solid orange;
  /* margin-right: 12px; */
  padding-top: 9px;
  box-shadow:0.1px 0.1px gray;
}
.grid_container_header_plan > div{
    font-size: 0.9rem;
  padding-top: 9px;
  box-shadow:0.1px 0.1px gray;
}

.grid_container_header_plan > div:hover{
  color: orange;
  border-bottom: 4px solid orange;
}

.plan_blank{
    background: white;
    /* border-left: 7px solid var(--backgroundColor); */
    /* margin-right: 18px; */
    color: white;
}

  /* ===============radio button starts================== */

  
  .plan_items >input[type="radio"]{
    appearance: none;
    -webkit-appearance: none;
    height: 13px;
    width: 13px;
    display: inline-flex;
    background-color: #f8f4fc;
    border-radius: 17px;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
    outline: none;
    box-shadow: 1px 1px 2px 3px;
    color: orange;
    position: relative;
    left: 0px;
    top: -2px;
  }
  
  .plan_items >label{
    color: gray;
    font-size: 16px;
    font-family:'Poppins',sans-serif;
    font-weight: 600;
    padding-left: 10px;
  }
  
  .plan_items >input[type="radio"]::after{
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    content: "\f192";
    font-size: 18px;
    color: orange;
    background-color: #f8f4fc;
    display: none;
    border-radius: 56%;
  }
  
  
  .plan_items >input[type="radio"]:checked{
    background-color:blue;
  }
  
  .plan_items >input[type="radio"]:checked::after{
    display: block;
  }


  
  /* ===================.radio button ends here=============== */

  /* ===============choose plan starts======================= */
  .Your_plan{
    background: white;
    margin: 0px 10px 0px 10px;
    padding: 20px 0px 0px 0px;
    height: 80%;
  }
  .Your_plan_head{
    color: orange;
    margin: 0px 0px 0px 30px;
  }

  .Your_plan_list{
    font-size: 0.9rem;
    list-style: none;
    margin: 0px 0px 0px 10px;
  }

  .plan_items{
    padding: 14px 0px 10px 25px;
  }

  .View{
    color: blue;
    padding-left: 40px;
    text-decoration: underline;
  }

  .change_notic{
      float: left;
  }
  .change_notic > i{
     color: orange; 
  }
  .change_Yellow{
      color: orange;
  }

  .select{
    display: grid;
    float: right;
    padding: 0px 20px 0px 0px;
    grid-gap: 10px;
    gap: 20px;
    
    margin-top: 70px;

  }




  .ctn {
    position: relative;
  }
  
  
  .select_button {     width: 170px;
    height: 40px;
    border-radius: 20px;
    color: white;
    background: blue;
    float: right;
    position: absolute;
    right: 0px;}
  
  
  .select_button:hover {
    top: -7px;
    box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
  }

/* ====================modal starts ===================== */

.flex_container_teacherid {
    display: flex;
    background-color: hsla(0, 0%, 0%, 0.5);
    align-items: center;
    justify-content: center;
  }
  
  .flex_container_teacherid > div {
    background-color: white;
    padding: 43px;
    font-size: 30px;
    align-items: center;
    border-radius: 8px;
    width: 80%;
    height: 90%;

    z-index: 1;
  }

  #modal_teacherid {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .plan_select_list{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid grey;
  }
  .plan_select_head{
    padding: 5px;
    color: orange;
  }

  .plan_select_btn_box{
    display: flex;
    grid-gap: 44px;
    gap: 90px;
  }

  .select_plan_button{
    width: 170px;
    height: 40px;
    border-radius: 20px;
    color: white;
    background: orange;
    float: right;
    position: absolute;
    border: none;
    /* right: 0px; */
  }

  .plan_select_btn_box i{
    position: relative;
    top: 5px;
  }

  .plan_select_btn{
    width: 140px;
  }

  .plan_links {
    overflow: auto;
    overflow-x: hidden;
    height: 93%;
    /* padding-right: 20px; */
    /* margin: 0px 25px 0px 0px; */
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 100%;
    gap: 10px;
    /* margin-left: 8px; */
  
}

::-webkit-scrollbar {
  width: 0px; 
}/* remove scrollbar space /
  background: transparent; / optional: just make scrollbar invisible /
  }
  / optional: show position indicator in red */
 

.plan_container_link{
  display: flex;
  text-decoration: none;
  margin: 10px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}


#science_container {
  
  margin-top: 10px;
  grid-template-columns: auto auto; 
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  padding-left: 10px;
  border-left-width: 8px;
  border-left-color: blue;
  margin-right: 10px;
  margin-left: 6px;
  background-color: white;  


}
#science_container>.plan_container_link>.plan_link_text {
  color:orange;
}



.plan_links_items{border: 1px solid;
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  /* padding-left: 18px; */
  /* margin-right: 10px; */
  background-color: white;
  box-shadow: 1px 1px grey;
  /* margin-left: 6px; */
}

.plan_links_items:hover{
    border-left-width: 8px;
    border-left-color: blue;
    box-shadow: 2px 1.5px 7px grey;
}
.plan_links_items:hover .plan_link_text{
  
  color: orange;

}



.plan_link_text {
  color: black;
  margin: 5px 4px;
  width: fit-content;
  font-size: 0.8rem;
  padding-left: 20px;
}

i.fa.fa-angle-down {
    padding: 10px;
}

  /* plan unorder listends  ends here */


  /* ===========list table starts from here ============== */
.table_plan_div{
  font-size: 0.9rem;
  margin: -10px 0px  0px 0px;

  }
.table_plan{
  width: revert;
  font-size: 0.9rem;
  text-align: center;
  color: blue;
  border-collapse: collapse;
}

/* .table_plan, */
.table_plan thead tr td,
.table_plan tbody tr td{
  
  border: 0.1px solid var(--fadedColor);
  padding: 2px;
  width: 5%;
}

.table_plan thead{
  background: var(--headerColor);
}



.Table_head1{

  height: 47px;
}

#Table_head_items1{
  height: 47px;
}
#Table_head_items2{
  height: 47px;
}
#Table_head_items3{
  height: 47px;
}
.Table_head2{
  height: 47px;
}
.Table_head_items{
  height: 47px;
}
/* .plan_body{
  color: aqua;
}
.body_data{
  height: 47px;
} */
.body_data1{
  color: #000000b8;

}
.checkbox_data{
  color: aqua;
  height: 47px;
}

/* ==============checkbox starts============== */

.checkbox_data>wrapper{
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top : 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items:center;
  justify-content: space-around;

}

.checkbox_data>input[type="checkbox"]{
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  outline: none;
  box-shadow: 0px 0px 0px 3px;
  color: var(--fadedColor);
  /* position: relative; */
  /* left: 28px; */

}

.checkbox_data>label{
  color: #4c4c4c;
  font-size: 20px;
  font-family:'Poppins',sans-serif;
  font-weight: 600;
}

.checkbox_data>input[type="checkbox"]::after{
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    content: "\2714";
    font-size: 15px;
    color: white;
    display: none;


}
.checkbox_data>input[type="checkbox"]:hover{
  background-color: #f8f4fc;

}

.checkbox_data>input[type="checkbox"]:checked{
  background-color: var(--fadedColor);
}

.checkbox_data>input[type="checkbox"]:checked::after{
  display: block;
}



/* ==========select modal starts ========================= */

.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container > div {
  background-color: white;
  padding: 43px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 500px;
}

.main_content p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  
}

.main_content span {
  display: block;
  width: 100px;
  margin: auto;
}
.main_content img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 15px auto;
  color: gray;
}

.modal_buttons {
  display: flex;
  justify-content: space-around;
}

.choose_button_2 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 16px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

#choose_button_id_2 {
  background-color: #ff9100;
  color: white;
}

.buttons_box_1 :hover {
  box-shadow: 0px 0px 6px 1px orange !important;
  top: -7px;
  position: relative;
    
}



.choose_button_1 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 14px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: 0.1px solid;
  transition-duration: 0.4s;
  cursor: pointer;
}



#choose_button_id_1 {
  background-color: white;
  color: grey;

}

/* modal CSS - start*/
#modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/
