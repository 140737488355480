.msg-modal-body {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  padding-right: 17px;
  position: relative;
}

.msg-modal-close-btn {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.grade-modal-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(3, 3, 3);
}

.ember {
  font-weight: 700;
  margin-top: 15px;
  font-size: 16px;
  color: #346aff;
}

.container {
  display: flex;
}

.left {
  flex: 75%;
}

.sentence {
  padding-left: 5px;
  margin: 2px;
  font-size: 12px;
  line-height: 1.5;
  color: #6a6a6a;
}

.table-wrap {
  padding-left: 60px;
  padding-right: 55px;
  margin-top: 20px;
}

.table {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
}

.wrap-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.right {
  flex: 25%;
}

/* .circle.per-75 {
    background-image: conic-gradient(#EE5D45 70%, #D9D9D9 0);

} */

.circle .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #2d3962;
}

.circle .per {
  position: absolute;
  top: 55%;
  left: 42%;
  font-size: 10px;
  color: #2d3962;
}

.students {
  padding-left: 12px;
  text-align: center;
  font-size: 10px;
  line-height: 1.5;
}

.circle {
  position: relative;
  /* width: 62px;
  height: 62px;
  margin: 0.5rem;
  border-radius: 50%;
  overflow: hidden; */
}

.ember-content-wrap {
  display: flex;
}

.ember-content {
  display: flex;
  justify-content: space-between;
}
