.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 14px !important;
  align-items: center;
  border-radius: 150px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  /* border-radius: 150px !important; */
  background: white !important;
  border-radius: 30px !important;

  /*padding: 0 !important;*/
}

.slider-card {
  width: 200px;
  height: 300px;
}

.slick-arrow {
  display: block;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 42%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
}

.slick-next {
  right: -60px;
}

.slick-prev {
  left: -60px;
  z-index: 300;
}

.slick-prev:before,
.slick-next:before {
  color: rgba(0, 0, 0, 0);
}

.slider-backward-arrow {
  font-size: xx-large;
  color: rgb(168, 167, 167);
  position: absolute;
  margin-top: 1.2rem;
  left: 30px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1 !important;
  width: 30px;
  background-color: #8480e4;
  color: white;
  text-align: center;
  padding: 5px;
  height: 30px;
  border-radius: 3px;
}

.slick-prev {
  display: none;
  z-index: 0 !important;
  left: 60px;
}

.slick-next {
  display: none;
  z-index: 0 !important;
}

.slider-forward-arrow {
  font-size: xx-large;
  color: rgb(168, 167, 167);
  position: absolute;
  margin-top: 1rem;
  right: 78px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1 !important;
  width: 30px;
  background-color: #8480e4;
  color: white;
  text-align: center;
  padding: 5px;
  height: 30px;
  border-radius: 3px;
}

@media only screen and (max-width: 600px) {
  .slider-card {
    width: 100px;
    height: 150px;
  }

  .slider-backward-arrow {
    font-size: x-large;
    color: rgb(204, 202, 202);
    margin-top: -35px;
    left: 80vw;
  }

  .slider-forward-arrow {
    font-size: x-large;
    color: rgb(204, 202, 202);
    margin-top: -35px;
    right: 7vw;
  }

  .slick-next {
    right: 0px;
    top: -20px;
  }

  .slick-prev {
    left: 72vw;
    top: -20px;
    z-index: 300;
  }

  .slick-prev,
  .slick-next {
    color: rgba(0, 0, 0, 0);
  }
}

@media only screen and (min-width: 1900px) {
  .slider-card {
    width: 250px;
    height: 350px;
  }
}

.controls {
  visibility: hidden;
  opacity: 0.5;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -200px;
  background-color: black;
  box-shadow: 3px 3px 5px black;
  transition: 1s all;
  display: flex;
}

.player:hover .controls,
.player:focus-within .controls {
  opacity: 1;
}

/*thermometer css*/
/*.thermometer ul.thermometer__statistics {*/
/*  left: -60px !important;*/
/*}*/
/*.thermometer .thermometer__percent-current {*/
/*  display: none !important;*/
/*}*/
/*.thermometer--normal ul.thermometer__statistics li {*/
/*  padding-left: 0 !important;*/
/*}*/
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: rgb(0 0 0 / 65%) !important;
  background: rgba(129, 129, 129, 0.31) !important;
  border-radius: 0 !important;
} */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1 !important;
  /* -webkit-text-fill-color: rgb(0 0 0 / 65%) !important; */
  /* background: rgba(129, 129, 129, 0.31) !important; */
  border-radius: 0 !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  color: rgb(0 0 0 / 65%) !important;
  cursor: default !important;
  background: rgba(129, 129, 129, 0.31) !important ;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.css-11yukd5-MuiTabs-indicator {
  background: rgb(75, 70, 137) !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 14px !important;
  align-items: center !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #ffa700 !important;
  /*font-weight: 500 !important;*/
  background: #fff !important;
}

.css-jv5tn0-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.css-1306eg9-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.buttonColor {
  color: #111fee;
}

.style1 {
  border-radius: "150px";
  padding: "4px";
  background: "white";
  height: '32px';
  color: '#FD8C00'
}