.submit-button-home-work {
  background-color: #001af3;
  color: #ffffff;
  width: 176px;
  height: 40px;
  border-radius: 21px;
  border: none;
}

.cancel-button-home-work {
  background-color: #ffffff;
  color: #ffffff;
  width: 176px;
  height: 40px;
  border-radius: 21px;
  border: 1px solid #7a7a7a;
  color: #7a7a7a;
}

.home-work-table-data-head {
  background-color: #d4d9ff;
  height: 60px;
  color: #2d3962;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 5px !important;
}
