.article {
  width: 100%;
  position: absolute;
  top: 25px;
  height: 100%;
  background-color: #f8f4fc;
  /* outline: 3px solid #f8f4fc; */
}

.city_head {
  display: flex;
  margin: 8px 30px 0px 10px;
  padding: 5px;
  /* background-color: white; */
  justify-content: space-between;
  /* margin: 0px 10px 0px 10px; */
  align-items: center;
  border-top: 1px solid var(--headerColor);
}

.school_list_search_container_32{
  display: flex;
}

.school_list_search_container {
  width: 250px;
  position: relative;
  margin-right: 15px;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 1px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

.add_school_button_32:hover {
  top: -2px;
  box-shadow: 0px 3px 5px rgba(0, 128, 0, 0.5);
}

input.add_school_button_32 {
  background-color: rgba(0, 128, 0, 0.698);
    position: relative;
    width: 151px;
    padding: 5px;
    border-radius: 40px;
    color: white;
    border: none;
    cursor: pointer;
}

/* ==========table starts from here ========================= */

.table_section {
  width: 100%;
  text-align: center;
}

.table_section td {
  border-collapse: collapse;
  color: var(--primaryColor);
}

.table_section,
.table_section th,
.table_section td {
  border-collapse: collapse;
}

.table_section th,
.table_section td {
  /* width: 10px; */
  font-size: 0.9rem;
  padding: 3px;
  font-weight: normal !important;
}

.table_section thead {
  background-color: var(--headerColor);
  color: blue;
}
td.data_35 {
  color: gray;
  font-size: 0.9rem;
}
td.data1_35 {
  color: gray;
  font-size: 0.9rem;
}
td.data2_35 {
  color: gray;
  font-size: 0.9rem;
}
.table_section th i {
  font-size: 0.9rem;
  position: relative;
  left: 4px;
}
.table_section tr {
  height: 47px;
  border: 1px groove #8080802e;
  font-size: 0.9rem;
}

.data_row:hover td:first-child {
  border-left: 10px solid blue;
}
.data_row {
  border-left: 10px solid white;
}
tbody tr:hover td.city_name {
  color: orange;
}
.section_list_table {
  margin: 0px 10px 0px 10px;
  height: 85%;
  background: white;
  overflow: auto;
  border-radius: 10px;
}

.city_name{
  text-align: left;
}
/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.active_slider {
  background-color: orange;
}

input:focus+.active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked+.active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */

/* =======================active slider ends============ */

/* ===================popup starts============================ */

.wrapper {
  align-items: baseline;
  margin-top: -15px;
}
.wrapper .icon {
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span i {
  line-height: 50px;
  font-size: 1vw;
  color: grey;
}
.wrapper .icon .tooltip {
  position: absolute;
  top: 0;
  width: 60px;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 10px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip {
  top: -35px;
  opacity: 1;
  pointer-events: auto;
}
.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover span {
  color: #fff;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}
.wrapper .add:hover i {
  color: orange;
}

.wrapper .edit:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.wrapper .remove:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.blank {
  margin-left: 50px;
}

#orange-add {
  box-shadow: 2px 2px 2px orange;
}

/*==========pagination start==========*/



.pagination {

  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;

}

.rows>select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/