.MTF_DD_Box {
    width: 100%;
    height: auto;
    /* background-color: #ffffff; */
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
  }
  
  .MTF_DD_Qstn_And_Field_div {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    /* overflow: scroll; */
    width: 51vw;
    max-height: 500px;
  }
  
  /*------------------------------| Scroll Bar For MTF DD Qstn And Field Style Starts |-----------------------------*/
  .MTF_DD_Qstn_And_Field_div::-webkit-scrollbar {
    width: 6px;
    max-height: 178px;
    background-color: #4384ff;
    position: relative;
    display: block;
  }
  
  .MTF_DD_Qstn_And_Field_div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4384ff;
  }
  
  .MTF_DD_Qstn_And_Field_div::-webkit-scrollbar-track {
    background-color: white;
  }
  /*------------------------------| Scroll Bar For MTF Qstn And Field Style Ends |-----------------------------*/
  
  .MTF_DD_Div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
  }
  
  /*------------------| Qstn And Field Style Starts Here |-----------------*/
  .MTF_DD_Fields_1 {
    display: flex;
    justify-content: space-between;
    width: 50vw;
  }
  
  .MTF_DD_Fields_2,
  .MTF_DD_Fields_3,
  .MTF_DD_Fields_4,
  .MTF_DD_Fields_5,
  .MTF_DD_Fields_6,
  .MTF_DD_Fields_7,
  .MTF_DD_Fields_8,
  .MTF_DD_Fields_9,
  .MTF_DD_Fields_10 {
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    margin-top: 20px;
  }
  
  .MTF_DD_Qstn_Field_1_B1,
  .MTF_DD_Qstn_Field_2_B1,
  .MTF_DD_Qstn_Field_3_B1,
  .MTF_DD_Qstn_Field_4_B1,
  .MTF_DD_Qstn_Field_5_B1,
  .MTF_DD_Qstn_Field_6_B1,
  .MTF_DD_Qstn_Field_7_B1,
  .MTF_DD_Qstn_Field_8_B1,
  .MTF_DD_Qstn_Field_9_B1,
  .MTF_DD_Qstn_Field_10_B1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18vw;
    height: 60px;
    border: 0.5px solid #DBDEE6;
    border-radius: 5px;
    position: sticky;
  }
  
  .MTF_DD_Qstn_Field_1_B2,
  .MTF_DD_Qstn_Field_2_B2,
  .MTF_DD_Qstn_Field_3_B2,
  .MTF_DD_Qstn_Field_4_B2,
  .MTF_DD_Qstn_Field_5_B2,
  .MTF_DD_Qstn_Field_6_B2,
  .MTF_DD_Qstn_Field_7_B2,
  .MTF_DD_Qstn_Field_8_B2,
  .MTF_DD_Qstn_Field_9_B2,
  .MTF_DD_Qstn_Field_10_B2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 60px;
    border-radius: 5px;
    position: sticky;
    /*To give gap in between the dash border*/
    background: linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, #707070 70%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(#707070 70%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 10px 1px, 1px 10px;
  }
  
  .MTF_DD_Qstn_Field_1_B1::after,
  .MTF_DD_Qstn_Field_2_B1::after,
  .MTF_DD_Qstn_Field_3_B1::after,
  .MTF_DD_Qstn_Field_4_B1::after,
  .MTF_DD_Qstn_Field_5_B1::after,
  .MTF_DD_Qstn_Field_6_B1::after,
  .MTF_DD_Qstn_Field_7_B1::after,
  .MTF_DD_Qstn_Field_8_B1::after,
  .MTF_DD_Qstn_Field_9_B1::after,
  .MTF_DD_Qstn_Field_10_B1::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0.5px dashed #707070;
    opacity: 0.7;
  }
  
  .MTF_DD_Qstn_Field_1_B1::after,
  .MTF_DD_Qstn_Field_2_B1::after,
  .MTF_DD_Qstn_Field_3_B1::after,
  .MTF_DD_Qstn_Field_4_B1::after,
  .MTF_DD_Qstn_Field_5_B1::after,
  .MTF_DD_Qstn_Field_6_B1::after,
  .MTF_DD_Qstn_Field_7_B1::after,
  .MTF_DD_Qstn_Field_8_B1::after,
  .MTF_DD_Qstn_Field_9_B1::after,
  .MTF_DD_Qstn_Field_10_B1::after {
    left: 18vw;
    width: 9vw;
  }
  /*------------------| Qstn And Field Style Ends Here |-----------------*/
  
  .MTF_DD_Ans {
    overflow-x: hidden;
    /* overflow: scroll; */
    /* width: 800px; */
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
  }
  
  .MTF_DD_Ans_Field_1 {
    border: 1px solid #DBDEE6;
    border-radius: 5px;
    width: 300px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 29px;
  }
  
  .MTF_DD_Ans_Field_2,
  .MTF_DD_Ans_Field_3,
  .MTF_DD_Ans_Field_4,
  .MTF_DD_Ans_Field_5,
  .MTF_DD_Ans_Field_6,
  .MTF_DD_Ans_Field_7,
  .MTF_DD_Ans_Field_8,
  .MTF_DD_Ans_Field_9,
  .MTF_DD_Ans_Field_10 {
    border: 1px solid #707070;
    width: 300px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /*------------------------------| Scroll Bar For MTF Ans Div Style Starts |-----------------------------*/
  .MTF_DD_Ans::-webkit-scrollbar {
    width: 6px;
    max-height: 178px;
    background-color: #4384ff;
    position: relative;
    display: block;
  }
  
  .MTF_DD_Ans::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4384ff;
  }
  
  .MTF_DD_Ans::-webkit-scrollbar-track {
    background-color: white;
  }
  /*------------------------------| Scroll Bar For MTF Ans Div Style Ends |-----------------------------*/
  
  /* .btn-DIV {
    display: flex;
    justify-content: flex-end;
  }
  
  .Qstn-Btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    border: 1px solid #faf9ff;
    background-color: #f8f4fc;
    width: 666px;
    height: 85px;
  }
  
  .Exit-btn {
    background-color: #2aa571;
    padding: 10px;
    margin-right: 20px;
  }
  
  .Exit-btn h6 {
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
    opacity: 0.9;
  }
  
  .Prev-btn {
    display: flex;
    padding: 10px;
    background-color: #9ea3d4;
    color: #ffffff;
    border: none;
    margin-right: 20px;
    border-radius: 3px;
  }
  
  .Prev-btn h6 {
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
    opacity: 0.9;
  }
  
  .Nxt-btn {
    display: flex;
    padding: 10px 5px 10px 10px;
    background-color: #fd8c00;
    color: #ffffff;
    border: none;
    margin-right: 20px;
    border-radius: 3px;
  }
  
  .Nxt-btn h6 {
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
    opacity: 0.9;
  } */
  