.WholePagee {
  width: 100%;
  height: auto;
}

.DashWrapper {
  width:80vw;
  /* height: 120vh; */
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: 50px;
  padding-right: 0%;
  /* overflow-y: auto; */
  /* background: rgb(234, 234, 245); */
}


.top-section {
  display: flex;
  /* column-gap: 35px; */
  justify-content: space-between;
  margin-bottom: 23px;

}

.dashboard-top-section-card {
  height: 160px;
  width: 32%;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px 5px 10px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboard-card-count {
  color: #6A6A6A;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin-top: 10px;
  opacity: 0.8;
}

.dcard-left-side{
width: 40%;
} 
.dcard-right-side {
  width: 60%;

}

.dcard-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
  color:#2e3962

}

.dcard-body {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #060606;

}

.dashboard-heading {
  color: #6A6A6A;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}