.mainContainer{
    width: 100%;
    height: auto;
    margin: 10px;
    overflow: auto;
    font-family: Poppins;
    /* padding: 15px; */
}
  .link_text {
    margin-right: 0px !important;
    font-size: 12px;
   color: rgb(122, 122, 122);
  }
  .schoolContainer{
    display: flex;
    width: 100%; 
    margin: 5px;
    /* padding-left: 8px  ;   */
    /* height: 120px */
}
  .countBox{
    /* margin-left: 70px; */
    /* height: 10%; */
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    box-sizing: border-box;
    margin: 5px;
    padding: 15px ;
    /* margin-left: 25px; */
  }

  .totalNoText{
    margin-top: -35px;
    color: #2D3962;
  }

  .boardsContainer{
    display: flex;
    width: 100%;
    font-size: 14px;
    margin: 5px;
    /* padding: 10px; */
    /* margin-left: 20px; */
}

  .boardBox{
    justify-content: space-between;
    height:11rem;
    width:26%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    /* margin: 10px; */
    padding: 10px ;
    margin-right: 12px;
    overflow: scroll;
  }

  .cityBox{
    justify-content: space-between;
    height:11rem;
    width:26%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    /* margin: 5px; */
    margin-right: 14px;
    padding: 10px ;
    overflow: scroll;
  }

  .applicationBox1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:11rem;
    width: 25%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    margin-right: 14px;
    padding: 10px ;
  }
  .applicationBox2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:11rem;
    width: 25%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
  }

  .schoolOverviewContainer{
    display: flex;
    width: 100%; 
/* height: 300px; */
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
    margin: 5px;
    /* margin-left: 29px; */
  }
  .schoolRow{
    display: flex;
    margin-bottom: 10px;
    box-sizing: border-box;
    flex: 1;
    justify-content: space-between;
  }

  .overviewText{
    padding: 15px 5px 0px 25px;
  }

  .school{
    background-color: #eaeaf5;
    height:100px;
    width: 165px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
    text-align: center;
    padding-top: 40px;
  }
  .branch{
    background-color: #eaeaf5;
    height:100px;
    width: 165px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
    text-align: center;
    padding-top: 40px;
  }

  .bottomSchoolFlex{
    display: grid;
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
    grid-gap: 1px;
}

  .schoolBranch{
    background-color: #eaeaf5;
    height:94%;
    width: 175px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    margin: 7px;
    /* padding: 10px ; */
    overflow: hidden;
    padding-left: 2px;
  }

  .schoolBranchInsideBox{
    background-color: white;
    height:94px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
    overflow: scroll;
    text-align: center;
    font-size: 13px;
    padding-top: 20px;
    margin-left: 8px;
  }
  .branchNo{
    background-color: white;
    height:94px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    padding: 10px ;
    text-align: center;
    padding-top: 35px;
    margin-left: 8px;
    text-decoration: underline;
  }

  .schoolBranchInsideBox2{
    background-color: white;
    height:24px;
    width: 94%;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    padding: 3px ;
    font-size: 13px;
    overflow: scroll;
  
  }

  .schoolBranchLabel{
    background-color: #eaeaf5;
    height:23px;
    width: 139px;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    padding: 3px ;
    font-size: 13px;
    overflow: scroll;
  }
  .schoolBranch1{
    background-color: white;
    height:224px;
    width: 165px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    margin-left: 30px;
    padding: 10px ;
  }
 
  .cityData{
    height: 25px;
    width:190px ;
    background-color:#fff3f3;
    border-radius: 5px;
    margin: 5px;
    overflow: scroll;
    font-size: 12px;
  }

  .schoolData{
    height: 25px;
    width:190px ;
    background-color: #F5FFED;
    border-radius: 5px;
    margin: 5px;
    overflow: scroll;
    font-size: 12px;
  }

  .cityCount{
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #fff3f3;
    /* padding: 2px 8px; */
    cursor: pointer;
    text-align: center;
    padding-top: 2px;

}

   .schoolCount{
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: rgb(163 244 120 / 79%);
    /* padding: 2px 7px; */
    padding-top: 2px;
    cursor: pointer;
    text-align: center;
   }
  @media (max-width: 1440px) {
    .schoolContainer{
      display: flex;
      width: 100%; 
      margin: 5px;
      height: auto;
      /* padding-left: 6px  ;   */
      /* height: 120px */
  }
  .countBox{
    /* margin-left: 70px; */
    height: 3.8rem;
    width: 42%;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    box-sizing: border-box;
    margin: 5px;
    padding: 15px ;
    /* margin-left: 27px; */
  }
  .boardsContainer{
    display: flex;
    width: 100%;
    font-size: 14px;
   margin: 5px;
    /* margin-left: 20px; */
}
  .schoolOverviewContainer{
    display: flex;
    width: 100%; 
    height: 290px;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
    /* margin-left: 29px; */
  }
    .bottomSchoolFlex{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
        grid-gap: 10px;
        padding-bottom: 10px;
    }
    .schoolBranch{
        background-color: #eaeaf5;
        height:89%;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        border-radius: 10px;
        margin: 5px;
        /* padding: 5px ; */
        overflow: hidden;
      }
      .boardBox{
        justify-content: space-between;
        /* height:9rem; */
        width:26%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        /* margin: 10px; */
        margin-right: 14px;
        /* padding: 10px ; */
        overflow: scroll;
      }
    
      .cityBox{
        justify-content: space-between;
        /* height:9rem; */
        width:26%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        /* margin: 5px; */
        padding: 10px ;
        margin-right: 12px;
        overflow: scroll;
      }
    
      .applicationBox1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* height:9rem; */
        width: 21%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        margin: 5px;
        padding: 10px ;
        margin-right: 14px;

      }
      .applicationBox2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* height:9rem; */
        width: 21%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        margin: 5px;
        padding: 10px ;
      }
      .schoolBranchInsideBox{
        background-color: white;
        height:4.5rem;
        width: 93%;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* padding: 10px ; */
        overflow: scroll;
        font-size: 12px;
        text-align: center;
        padding-top: 25px;
        margin-right: 5px;

      }
      .branchNo{
        background-color: white;
        height:4.2rem;
        width: 93%;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* padding: 10px ; */
        text-align: center;
        padding-top: 35px;
        font-size: 14px;
        margin-bottom: 15px;

      }
      .schoolBranchInsideBox2{
        background-color: white;
        height:23px;
        width: 94%;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 5px;
        padding: 3px ;
        font-size:10px;
        overflow: hidden;
      
      }
      .school{
        background-color: #eaeaf5;
        height:43%;
        width:140px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* margin-left: 30px; */
        padding: 10px ;
        text-align: center;
        padding-top: 27px;
      }
      .branch{
        background-color: #eaeaf5;
        height:43%;
        width: 140px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* margin-left: 30px; */
        padding: 10px ;
        text-align: center;
        padding-top: 27px;
      }
      .schoolBranchLabel{
        background-color: #eaeaf5;
        height:22px;
        width: 139px;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 5px;
        padding: 3px ;
        font-size: 10px;
        overflow: hidden;
      }
}

@media (max-width: 728px) {
    .bottomSchoolFlex{
        grid-template-columns: repeat(2, 0.4fr);
        grid-gap: 5px;
        height: auto;
    }
    .schoolContainer{
      display: flex;
      flex-direction: column;
      width: 100%; 
      margin: 5px;
      height: auto;
      /* padding-left: 6px  ;   */
      /* height: 120px */
  }
  .countBox{
    /* margin-left: 70px; */
    height: 3.8rem;
    width: 42%;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    box-sizing: border-box;
    margin: 5px;
    padding: 15px ;
    /* margin-left: 27px; */
  }
  .boardsContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
   margin: 5px;
    /* margin-left: 20px; */
}
  .schoolOverviewContainer{
    display: flex;
    flex-direction: column;
    width: 100%; 
    height: 290px;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
    /* margin-left: 29px; */
  }
    .bottomSchoolFlex{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        padding-bottom: 10px;
    }
    .schoolBranch{
        background-color: #eaeaf5;
        height:75%;
        width: 80%;
        box-sizing: border-box;
        align-items: center;
        border-radius: 10px;
        margin: 5px;
        /* padding: 5px ; */
        overflow: hidden;
      }
      .boardBox{
        justify-content: space-between;
        /* height:9rem; */
        width:26%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        /* margin: 10px; */
        margin-right: 14px;
        /* padding: 10px ; */
        overflow: scroll;
      }
    
      .cityBox{
        justify-content: space-between;
        /* height:9rem; */
        width:26%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        /* margin: 5px; */
        padding: 10px ;
        margin-right: 12px;
        overflow: scroll;
      }
    
      .applicationBox1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* height:9rem; */
        width: 21%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        margin: 5px;
        padding: 10px ;
        margin-right: 14px;

      }
      .applicationBox2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* height:9rem; */
        width: 21%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        margin: 5px;
        padding: 10px ;
      }
      .schoolBranchInsideBox{
        background-color: white;
        height:4.5rem;
        width: 93%;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* padding: 10px ; */
        overflow: scroll;
        font-size: 12px;
        text-align: center;
        padding-top: 25px;
        margin-right: 5px;

      }
      .branchNo{
        background-color: white;
        height:4.2rem;
        width: 93%;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* padding: 10px ; */
        text-align: center;
        padding-top: 35px;
        font-size: 14px;
        margin-bottom: 15px;

      }
      .schoolBranchInsideBox2{
        background-color: white;
        height:23px;
        width: 94%;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 5px;
        padding: 3px ;
        font-size:10px;
        overflow: hidden;
      
      }
      .school{
        background-color: #eaeaf5;
        height:33%;
        width:80px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* margin-left: 30px; */
        padding: 10px ;
        text-align: center;
        padding-top: 15px;
      }
      .branch{
        background-color: #eaeaf5;
        height:33%;
        width: 80px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        /* margin-left: 30px; */
        padding: 10px ;
        text-align: center;
        padding-top: 15px;
      }
      .schoolBranchLabel{
        background-color: #eaeaf5;
        height:22px;
        width: 139px;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 5px;
        padding: 3px ;
        font-size: 10px;
        overflow: hidden;
      }

}