/* article.article_add_school_plan starts from here */
article.article_add_school_plan16 {
  width: 100%;
  position: absolute;
  /* top: 35px; */
  height: 100%;
}

/* .Grid.school_name_branch  starts */
.Grid_school_name_branch16 {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  background-color: #f8f4fc;
}

.Grid16 .school_name_branch16 {
  display: flex;
  justify-content: space-between;
  padding: 9px;
  margin-right: 16px;
}

button.btn_branch_name16 {
  width: 190px;
  height: 29px;
  /* border-radius: 76px; */
  background-color: blue;
  color: #f8f4fc;
  border: none;
  box-shadow: 1px 1px gray;
  /* margin-left: 13px;  */
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name16 {
  color: #000000b8;
  font-weight: bold;
  padding-top: 9px;
}

/* .Grid.school_name_branch ends here */
/* grid_container_header_plan starts from here */

.grid_container_header_plan16 {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  /* width: 100%; */
  height: 40px;
  column-gap: 2px;
  /* border-left: 15px solid var(--backgroundColor);
  border-right: 45px solid var(--backgroundColor); */
  margin: 0px 10px 0px 10px;
}

.item_plan116 {
  color: orange;
  border-bottom: 4px solid orange;
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
  font-size: 0.9rem;
}

.item_plan216 {
  font-size: 0.9rem;

  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
}

.item_plan316 {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
}

.item_plan416 {
  font-size: 0.9rem;
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
}

.item_plan516 {
  font-size: 0.9rem;
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
}

.item_plan616 {
  font-size: 0.9rem;
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
}

.item_plan716 {
  font-size: 0.9rem;
  /* margin-right: 12px; */
  padding-top: 9px;
  box-shadow: 0.1px 0.1px gray;
}

.grid_container_header_plan16 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

.plan_blank {
  background: white;
  /* border-left: 7px solid var(--backgroundColor); */
  /* margin-right: 18px; */
  /* margin: 0px 10px 0px 10px; */
  color: white;
}

/* Start - plan list container */
.plan_links_container {
  height: 100%;
  background-color: white;
}
/* End - plan list container */

/* plan unorder list starts from here */

.plan_links16 {
  overflow: auto;
  overflow-x: hidden;
  height: 68%;
  /* padding-right: 20px; */
  /* margin: 0px 25px 0px 0px; */
  display: flex;
  flex-direction: column;
  list-style-type: none;
  /* width: 100%; */
  /* margin-left: 8px; */
}

::-webkit-scrollbar {
  width: 0px;
}

/* remove scrollbar space /
  background: transparent; / optional: just make scrollbar invisible /
  }
  / optional: show position indicator in red */

.plan_container_link16 {
  display: flex;
  text-decoration: none;
  margin: 10px 0px;
}

#science_container16 {
  margin-top: 10px;
  grid-template-columns: auto auto;
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  padding-left: 10px;
  border-left-width: 8px;
  border-left-color: blue;
  margin-right: 10px;
  margin-left: 6px;
  background-color: white;
}

#science_container16 > .plan_container_link16 > .plan_link_text16 {
  color: orange;
}

/* .plan_link_icon16 {
  color: blue;
  align-self: center;
  justify-self: flex-start;
  padding: 0px 0px;
  border-radius: 4px;
  border-style: inset;
  box-shadow: 0.1px 0.1px;
  width: 17px;
  height: 16px;
} */

/* .plan_link_icon16 > input {
  width: 20px;
  height: 1px;
}

.plan_link_icon16 > input:hover {
  left: 5px;
  right: 5px;
} */

/* .plan_links_items16{border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  padding-left: 18px;
  margin-right: 10px;
  background-color: white;
  box-shadow: 1px 1px grey;
  margin-left: 6px;
} */

/* .plan_links_items16:hover {
  border-left-width: 8px;
  border-left-color: blue;
  box-shadow: 2px 1.5px 7px grey;
}

.plan_links_items16:hover .plan_link_text16 {
  color: orange;
} */

.plan_link_text16 {
  color: black;
  margin: 5px 4px;
  width: fit-content;
  font-size: 0.8rem;
  padding-left: 20px;
}

i.fa.fa-angle-down {
  padding: 10px;
}

/* plan unorder listends  ends here */
/* save button starts here */

/* .plan_save_container16 {
  width: 100%;
  text-align: end;
  padding-right: 12px;
  padding-top: 16px;
} */

/* .plan_container_save_btn16 {
  width: 140px;
  height: 35px;
  color: white;
  background-color: blue;
  border-radius: 15px;
  border: none;
}

.plan_container_save_btn16:hover {
  top: -50px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
} */

/* save button ends here for plan */

/*page 17 start  here*/

.main_container16 {
  min-width: 1076px;
}

/* article.article-add-school-plan starts from here */
article.article_add_school_plan16 {
  width: 100%;
  position: absolute;
  top: 35px;
  height: 100%;
  background: var(--backgroundColor);
}

/* .Grid.school_name_branch  starts */

.Grid16 .school_name_branch16 {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  padding-top: 0px;
}

button.btn_branch_name16 {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: white;
  float: right;
  /* margin-right: 17px; */
}

i.fa-solid.fa-chevron-down {
  margin-left: 10px;
}

.down_arrow_icon {
  padding-right: 10px;
}

.down_arrow_icon {
  padding-left: 10px;
}

.school_name16 {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan16 > div {
  background-color: white;
}

.grid_container_header_plan16 > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}
/* plan unorder list starts from here */

.plan_links16 {
  overflow: auto;
  height: 80%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 10px;
  margin: 0px 10px;
}

::-webkit-scrollbar {
  width: 0px;
}

/* remove scrollbar space /
  background: transparent; / optional: just make scrollbar invisible /
  }
  / optional: show position indicator in red */

.flex_container_teacherid > div {
  background-color: white;
  padding: 43px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 80%;
  height: 90%;

  z-index: 1;
}

#modal_teacherid {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.plan_select_list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid grey;
}

.plan_select_head {
  padding: 5px;
  color: orange;
}

.plan_select_btn_box {
  display: flex;
  grid-gap: 44px;
  gap: 90px;
}

.select_plan_button {
  width: 170px;
  height: 40px;
  border-radius: 20px;
  color: white;
  background: orange;
  float: right;
  position: absolute;
  border: none;
  /* right: 0px; */
}

.plan_select_btn_box i {
  position: relative;
  top: 5px;
}

.plan_select_btn {
  width: 140px;
}

.plan_links {
  overflow: auto;
  overflow-x: hidden;
  height: 93%;
  /* padding-right: 20px; */
  /* margin: 0px 25px 0px 0px; */
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  gap: 10px;
  /* margin-left: 8px; */
}

::-webkit-scrollbar {
  width: 0px;
}

/* remove scrollbar space /
      background: transparent; / optional: just make scrollbar invisible /
      }
      / optional: show position indicator in red */

.plan_container_link {
  display: flex;
  text-decoration: none;
  margin: 10px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}

#science_container {
  margin-top: 10px;
  grid-template-columns: auto auto;
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  padding-left: 10px;
  border-left-width: 8px;
  border-left-color: blue;
  margin-right: 10px;
  margin-left: 6px;
  background-color: white;
}

#science_container > .plan_container_link > .plan_link_text {
  color: orange;
}

.plan_links_items {
  border: 1px solid;
  border-radius: 10px;
  border-color: darkgray;
  background-color: white;
  box-shadow: 1px 1px grey;
}

.plan_links_items:hover {
  border-left-width: 8px;
  border-left-color: blue;
  box-shadow: 2px 1.5px 7px grey;
}

.plan_links_items:hover .plan_link_text {
  color: orange;
}

.plan_link_text {
  color: black;
  margin: 5px 4px;
  width: fit-content;
  font-size: 0.8rem;
  padding-left: 20px;
}

i.fa.fa-angle-down {
  padding: 10px;
}

/* plan unorder listends  ends here */

/* ===========list table starts from here ============== */
.table_plan_div {
  font-size: 0.9rem;
  margin: -10px 0px 0px 0px;
  box-shadow: 1px 1px grey;
}

.table_plan {
  width: revert;
  font-size: 0.9rem;
  text-align: center;
  color: blue;
  border-collapse: collapse;
}

/* .table_plan, */
.table_plan thead tr td,
.table_plan tbody tr td {
  border: 0.1px solid var(--fadedColor);
  padding: 2px;
  width: 5%;
}

.table_plan thead {
  background: var(--headerColor);
}

.Table_head1 {
  height: 47px;
}

#Table_head_items1 {
  height: 47px;
}

#Table_head_items2 {
  height: 47px;
}

#Table_head_items3 {
  height: 47px;
}

.Table_head2 {
  height: 47px;
}

.Table_head_items {
  height: 47px;
}

/* .plan_body{
      color: aqua;
    }
    .body_data{
      height: 47px;
    } */
.body_data1 {
  color: #000000b8;
}

.checkbox_data {
  color: aqua;
  height: 47px;
}

/* ==============checkbox starts============== */

.checkbox_data > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.checkbox_data > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  outline: none;
  box-shadow: 0px 0px 0px 3px;
  color: var(--fadedColor);
  /* position: relative; */
  /* left: 28px; */
}

.checkbox_data > label {
  color: #4c4c4c;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.checkbox_data > input[type="checkbox"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 15px;
  color: white;
  display: none;
}

.checkbox_data > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.checkbox_data > input[type="checkbox"]:checked {
  background-color: var(--fadedColor);
}

.checkbox_data > input[type="checkbox"]:checked::after {
  display: block;
}
