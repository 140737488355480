.Whole_DashPage {
  width: 100%;
  margin: 1rem;
  user-select: none;
}

.Teacher_dropdown_div {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/*-------| Dashboard Grid Style Starts Here |-------*/
.Teacher_Grid {
  display: grid;
  grid-template-areas:
    "num_of_sec num_of_stud formative_assess stud_level stud_level"
    "chapCom_vs_quizRele chapCom_vs_quizRele chapCom_vs_quizRele up_tasks up_tasks"
    "unit_quiz_perform unit_quiz_perform unit_quiz_perform unit_quiz_perform unit_quiz_perform";
  gap: 1rem;
}

/*-------| Number of Section GRID-CARD Style Starts here |-------*/
.Num_of_Sec {
  grid-area: num_of_sec;
  height: 174px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.Num_of_Sec > div:first-child {
  display: flex;
  justify-content: space-between;
}

.Num_of_Sec > div:first-child > img:nth-child(2) {
  width: 58px;
  height: 58px;
}

.Num_of_Sec > div:first-child > h6:nth-child(1) {
  font-size: 15px;
  color: #2d3962;
}

.Num_of_Sec > div:nth-child(2) > h6:nth-child(1) {
  position: absolute;
  top: 105px;
  left: 20px;
  font-size: 36px;
  color: #2d3962;
}

.Num_of_Sections {
  grid-area: num_of_sec;
  height: 174px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}



.Num_of_Sections > div:first-child > img:nth-child(2) {
  width: 58px;
  height: 58px;
}

.Num_of_Sections > div:first-child > h6:nth-child(1) {
  font-size: 15px;
  color: #2d3962;
}

.Num_of_Sections > div:nth-child(2) > h6:nth-child(1) {
  position: absolute;
  top: 105px;
  left: 20px;
  font-size: 36px;
  color: #2d3962;
}
/*-------| Number of Section GRID-CARD Style Ends here |-------*/

/*-------| Number of Students GRID-CARD Style Starts here |-------*/
.Num_of_Stud {
  grid-area: num_of_stud;
  height: 174px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.Num_of_Stud > div:first-child {
  display: flex;
  justify-content: space-between;
}

.Num_of_Stud > div:first-child > img:nth-child(2) {
  width: 58px;
  height: 58px;
}

.Num_of_Stud > div:first-child > h6:nth-child(1) {
  font-size: 15px;
  color: #2d3962;
}

.Num_of_Stud > div:nth-child(2) > h6:nth-child(1) {
  position: absolute;
  top: 105px;
  left: 20px;
  font-size: 36px;
  color: #2d3962;
}
/*-------| Number of Students GRID-CARD Style Ends here |-------*/

/*-------| Formative Assessment GRID-CARD Style Starts here |-------*/
.Formative_Assess {
  grid-area: formative_assess;
  height: 174px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.Formative_Assess > div:first-child {
  display: flex;
  justify-content: space-between;
}

.Formative_Assess > div:first-child > img:nth-child(2) {
  width: 58px;
  height: 58px;
}

.Formative_Assess > div:first-child > h6:nth-child(1) {
  font-size: 15px;
  color: #2d3962;
}

.Formative_Assess > div:nth-child(2) {
  font-size: 12px;
  color: #707070;
}

.viewBtn {
  width: 50px;
  height: 21px;
  position: absolute;
  top: 19vh;
  left: 15vw;
  font-size: 13px;
  color: #fd8c00;
  background-color: #ffffff;
  border: 1.5px solid #fd8c00;
  border-radius: 5px;
}
/*-------| Formative Assessment GRID-CARD Style Ends here |-------*/

/*-------| Students Level GRID-CARD Style Starts here |-------*/
.Stud_Level {
  grid-area: stud_level;
  height: 174px;
  padding: 10px;
  border-radius: 5px;
  background: transparent linear-gradient(128deg, #498d7d 0%, #116ae3 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.Stud_Level div:first-child {
  font-size: 15px;
  color: #ffffff;
}

.Doughnut_and_Legend {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3.5rem;
}

.DLegend {
  display: flex;
  gap: 6rem;
}

.Legend_Names > div {
  display: flex;
  align-items: center;
}

.Legend_Names > div > p {
  font-size: 13px;
  color: #ffffff;
  line-height: 2;
}

.Legend_Names > div > img {
  width: 18px;
  height: 18px;
}

.Legend_Percentage > p {
  font-size: 13px;
  color: #ffffff;
  line-height: 2;
}
/*-------| Students Level GRID-CARD Style Ends here |-------*/

/*-------| Chapters Completed vs Quizzes Releases GRID-CARD Style Starts here  |-------*/
.ChapCom_vs_QuizRele {
  grid-area: chapCom_vs_quizRele;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

.ChapCom_vs_QuizRele > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ChapCom_vs_QuizRele > div:first-child > h6 {
  font-size: 16px;
  color: #3268e7;
}

.Legendss {
  display: flex;
  gap: 10px;
}

.Legendss div:first-child,
.Legendss div:nth-child(2),
.Legendss div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Legendss div:first-child > img,
.Legendss div:nth-child(2) > img,
.Legendss div:nth-child(3) > img {
  width: 15px;
  height: 15px;
}

.Legendss div:first-child > h6,
.Legendss div:nth-child(2) > h6,
.Legendss div:nth-child(3) > h6 {
  font-size: 10;
  color: #989898;
  font-weight: normal;
}

/*-------| Custom ProgressBar Styles Starts Here |-------*/
.Pb_Main_Sec {
  width: 48vw;
  height: auto;
  border-radius: 20px;
}

.Pb_Main_Sec .slick-prev:before,
.Pb_Main_Sec .slick-next:before {
  color: #1c2ce6;
  font-size: 24px;
}

.Pb_Main_Sec .slick-prev {
  left: -20px;
  z-index: 1;
}

.Pb_Main_Sec .slick-next {
  right: -25px;
  z-index: 1;
}

.Pb_container {
  width: 100% !important;
  height: 36vh;
  display: flex !important;
  justify-content: center;
  gap: 20px;
}

/*------------------| PB-1 |------------------*/
.containerr__PB1 {
  height: auto;
  position: relative;
  cursor: pointer;
}

.barcontainer__PB1 {
  background-color: #e6e6e6;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  width: 11px;
  height: 150px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
}

.barr__PB1 {
  background-color: #014fcc;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.circleeee__PB1 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #014fcc;
  position: absolute;
  bottom: -11px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

.circleeee2__PB1 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  bottom: -9px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.percentage-container__PB1 {
  position: absolute;
  top: -30px;
  left: 57%;
  transform: translateX(-50%);
}

.percentage__PB1 {
  font-size: 10px;
  font-weight: bold;
  color: #2d3962;
}

/*------------------| PB-2  |------------------*/
.containerr__PB2 {
  height: auto;
  position: relative;
  cursor: pointer;
}

.barcontainer__PB2 {
  background-color: #e6e6e6;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  width: 11px;
  height: 150px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
}

.barr__PB2 {
  background-color: #01cc9b;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.circleeee__PB2 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #01cc9b;
  position: absolute;
  bottom: -11px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

.circleeee2__PB2 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  bottom: -9px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.percentage-container__PB2 {
  position: absolute;
  top: -30px;
  left: 57%;
  transform: translateX(-50%);
}

.percentage__PB2 {
  font-size: 10px;
  font-weight: bold;
  color: #2d3962;
}

/*------------------| PB-3  |------------------*/
.containerr__PB3 {
  height: auto;
  position: relative;
  cursor: pointer;
}

.barcontainer__PB3 {
  background-color: #e6e6e6;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  width: 11px;
  height: 150px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
}

.barr__PB3 {
  background-color: #7de314;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.circleeee__PB3 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #7de314;
  position: absolute;
  bottom: -11px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

.circleeee2__PB3 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  bottom: -9px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.percentage-container__PB3 {
  position: absolute;
  top: -30px;
  left: 57%;
  transform: translateX(-50%);
}

.percentage__PB3 {
  font-size: 10px;
  font-weight: bold;
  color: #2d3962;
}

@-webkit-keyframes grow {
  from {
    transform: scaleY(0);
  }
}

@keyframes grow {
  from {
    transform: scaleY(0);
  }
}
/*-------| Custom ProgressBar Styles Ends Here |-------*/

.Subject_and_Section {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Subject_name {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 200px;
  font-size: 11px;
  color: #5d5d5d;
  margin-top: 20px;
  padding: 5px;
  border-top: 2px solid #05a8e3;
}

.Section_name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 210px;
  font-size: 15px;
  color: #1b8be3;
  margin-top: 35px;
  padding: 5px;
}
/*-------| Chapters Completed vs Quizzes Releases GRID-CARD Style Ends here  |-------*/

/*-------| Upcoming Tasks GRID-CARD Style Starts here |-------*/
.Up_Tasks {
  grid-area: up_tasks;
  height: 304px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.Up_Tasks > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 10px 12px 20px;
}

.Up_Tasks > div:first-child > h6:nth-child(1) {
  font-size: 15px;
  color: #2d3962;
}

.Up_Tasks > div:first-child > img:nth-child(2) {
  width: 12px;
  height: 11px;
  cursor: pointer;
}

.UP_TaskSec {
  max-height: 210px;
  overflow: scroll;
  margin-right: 10px;
}

/*-------| Scroll Bar Style Starts Here |-------*/
.UP_TaskSec::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #c1bdcc;
  position: relative;
  display: block;
}

.UP_TaskSec::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1bdcc;
}

.UP_TaskSec::-webkit-scrollbar-track {
  background-color: white;
}
/*-------| Scroll Bar Style Ends Here |-------*/

.The_Tasks_and_Priority {
  display: flex;
  justify-content: space-between;
  padding: 0 40px 0 20px;
  border-left: 3.5px solid red;
  border-radius: 3px;
  margin-top: 15px;
}

.The_Task h6:nth-child(1) {
  font-size: 14px;
  color: #354052;
  font-weight: normal;
}

.The_Task h6:nth-child(2) {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: normal;
}

.Priority {
  display: flex;
  gap: 5px;
  align-items: center;
}

.Priority > img {
  width: 11px;
  height: 11px;
}

.Priority > h6 {
  font-size: 10px;
  color: #7e84a3;
  font-weight: normal;
}

.ULine_and_Heading {
  width: 100%;
  position: absolute;
  top: 17vw;
}

.ULine {
  margin: 0 15px 0 15px;
  border-bottom: 2px solid #2f57ec;
}

.VAT_heading {
  font-size: 14px;
  font-weight: normal;
  color: #9249e8;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*-------| Upcoming Tasks GRID-CARD Style Ends here |-------*/

.Unit_Quiz_Perform {
  grid-area: unit_quiz_perform;
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 40px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px;
}

.Unit_Quiz_Details {
  border-right: 1px solid #e1e4ef;
  margin: 30px 0 30px 0;
}

.Heading_and_DD {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
}

.Heading_and_DD > h6 {
  font-size: 15px;
  color: #2d3962;
}

.DropDowns {
  display: flex;
  gap: 15px;
}

/*-------| CustomBar & Slider Style Starts |-------*/
.BarCharts_Wrapperr {
  width: 48vw;
  padding: 10px;
  margin: 0 3.5vw 0 2vw;
}

.BarCharts_Wrapperr .slick-prev:before,
.BarCharts_Wrapperr .slick-next:before {
  color: #1c2ce6;
  font-size: 25px;
}

.BarCharts_Wrapperr .slick-prev {
  left: -10px;
  z-index: 1;
}

.BarCharts_Wrapperr .slick-next {
  right: -13px;
  z-index: 1;
}

.MainDivv {
  width: 185px !important;
  margin-top: 20px;
}

.BarCharts_Divv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BarContainerr1,
.BarContainerr2 {
  width: 30%;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #c9c9c9;
  cursor: pointer;
}

.BarChartt1 {
  position: absolute;
  background-color: blue;
  width: 38px;
  bottom: 0;
  box-sizing: border-box;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.BarChartt2 {
  position: absolute;
  background-color: #1b8be3;
  width: 38px;
  bottom: 0;
  box-sizing: border-box;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.progressTextt {
  position: absolute;
  bottom: 100%;
  left: 4px;
  color: #2d3962;
  font-size: 14px;
  font-weight: bolder;
  box-sizing: border-box;
  -webkit-animation: grow 1.5s ease-out forwards;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.BarChart_Namee {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #87879b;
  margin-top: 8px;
}

@-webkit-keyframes grow {
  from {
    transform: scaleY(0);
  }
}

@keyframes grow {
  from {
    transform: scaleY(0);
  }
}
/*-------| CustomBar & Slider Style Ends |-------*/

.BarCharts_legends {
  display: flex;
  justify-content: space-between;
  margin: 2vh 4vw 1vh 5vw;
}

.BarCharts_legends div:first-child,
.BarCharts_legends div:nth-child(2),
.BarCharts_legends div:nth-child(3) {
  display: flex;
  align-items: center;
}

.BarCharts_legends div:first-child > h6,
.BarCharts_legends div:nth-child(2) > h6,
.BarCharts_legends div:nth-child(3) > h6 {
  font-size: 13px;
  color: #626268;
  font-weight: normal;
}

.BarCharts_legends div:first-child > img,
.BarCharts_legends div:nth-child(2) > img,
.BarCharts_legends div:nth-child(3) > img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.sectionScroll {
  width: 100%;
  max-height: 400px;
  overflow: scroll;
  padding: 30px 20px 20px 20px;
}

/*-------| Scroll Bar Style Starts Here |-------*/
.sectionScroll::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #c1bdcc;
  position: relative;
  display: block;
}

.sectionScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1bdcc;
}

.sectionScroll::-webkit-scrollbar-track {
  background-color: white;
}
/*-------| Scroll Bar Style Ends Here |-------*/

.Section_Details {
  display: flex;
  justify-content: space-between;
}

.Section_Details > div:first-child > h6 {
  font-size: 17px;
  color: #2d3962;
  font-weight: 500;
  margin-bottom: 10px;
}

.Section_Details > div:first-child > p > span {
  font-size: 14px;
  color: #87879b;
  font-weight: bold;
}

.Section_Details > div:first-child > p {
  font-size: 14px;
  color: #87879b;
}

.Section_Details > div:nth-child(2) > p {
  font-size: 15px;
  color: #87879b;
}

.SectionDiv > div:nth-child(2) {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 10px;
}

.SectionDiv div:nth-child(2) > button {
  width: 100px;
  height: 30px;
  font-size: 13px;
  color: #ffffff;
  background-color: #fd8c00;
  border: none;
  margin: 20px 0 20px 0;
}
/*-------| Dashboard Grid Style Ends Here |-------*/
