* {
    box-sizing: border-box;
    font-family: Poppins,sans-serif;
    margin: 0;
    padding: 0;
}
.flex  {
    display : flex ;
    flex-direction: row;
}
.icon {
    margin: 0px 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
.icon span i {
    line-height: 50px;
      font-size: 25px;
      color: grey;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
}
.icon span {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
.icon .tooltip {
    position: absolute;
    top: 0;
    width: 70px;
    z-index: 1;
    background: #fff;
    color: gray;
    /* padding: 9px 5px; */
    font-size: 10px;
    font-weight: 500;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .icon:hover .tooltip {
    top: -35px;
    opacity: 1;
    pointer-events: auto;
  }
  
.icon .tooltip:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .icon:hover span  i{
    color: #ff000099;
  }
  .icon:hover span , .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  }

  .select_input {
    font-family: 'poppins' !important;
    color:#B5B6B6;
    font-weight: 300;
}

.table_head {
  color: #fff !important;
  font-family: 'poppins' !important;
  font-weight: 600;
  background: #403E75 !important;
}
.table_cell {
  font-family: 'poppins' !important;
}


/* pagination */
.pagination {
  background: white;
box-shadow: 1px 2px 2px grey;
border-radius: 5px;
/* width: 100%; */
padding: 10px;
display: flex;
justify-content: space-between;
align-items: center;
margin: 0px 10px 0px 10px ;
}
.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}
/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}
/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not() {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;

}

.rows>select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

 /* btns  */

 .green_btn {
  color: #fff;
  background-color:#4CAF50;
  border-radius: 150px;
  border-color:#4CAF50;
  padding: 8px 21px;
  /* width:140px;
  height : 43px; */

}
.green_btn:hover {
  color: #fff;
  background-color:#4CAF50;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #78be20;
}
.red_btn {
  color: #fff;
  background-color: #EF0F0F;
  border-radius: 150px;
  border-color: #EF0F0F;
  padding: 8px 21px;
}
.red_btn:hover {
  color: #fff;
  background-color: #EF0F0F;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #E57373;
}
.white_btn {
  color: #7A7A7A;
  background-color: white;
  border-radius: 150px ;
  border : 1px solid #7A7A7A,;
  /* // width : 100px, */
  padding:7px;
  min-width : 100px;
  font-size : 16px;
  /* height : 32px; */
  margin:3px auto;
  /* // padding: 8px 21px, */
}
.white_btn:hover {
  color: black;
  background-color: white;
  border : 1px solid #7A7A7A;
  top: -2;
  box-shadow: 0px 3px 5px grey;
}
.blue_btn{
  color: #fff;
  width: 150px;
  padding:7px;
  background-color: blue;
  border-radius: 150px;
  border-color: grey;

}
.blue_btn:hover{
  color:#fff;
  background-color: blue;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px blue;

}

/* modal  */
.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.main_container{
  width : 500px ;
  height : 250px ; 
  border-radius: 20px;
  background-color: white;
}
.main_container p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

#modal_id {
   /* display: none; */
   position: fixed;
   z-index: 1;
   left: 0;
   top: 0;
   overflow: auto;
   width: 100%;
   height: 100%;
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* modal end  */

.white_btn {
  color : grey ;
  padding : 20px ;
  padding : 8px 13px ;
  margin : auto 3px ;
  background-color: transparent;
  border-color: grey;
}