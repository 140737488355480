.bp-table-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  column-gap: 17px;
}

.bp-table-head-cell {
  border-radius: 8px;
  background: #d9ddfd;
  display: grid;
  place-content: center;
  padding: 26px 0;
  color: #252525;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bp-table-body-row {
  display: flex;
  align-items: center;
  column-gap: 17px;
  margin-top: -12px;
}

.bp-table-body-cell {
  background: #d9ddfd;
  padding-bottom: 18px;
  color: #252525;
  font-family: Poppins;
  text-align: center;
}

.bp-table-first-row {
  padding-top: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bp-table-last-row {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 10px;
}

.bp-configuration-count {
  display: flex;
  justify-content: space-between;
  color: #252525;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 5px;
}

.bp-config-text {
  margin-left: 10px;
  width: 70px;
  height: 50px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #6f7fff;
  text-align: center;
  outline: none;
  color: #252525;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bp-table-text {
  /* border: none; */
  outline: none;
  background-color: #d9ddfd;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bp-cancel-btn {
  width: 145px;
  height: 45px;
  color: #7e7e7e;
  border-radius: 12px;
  border: 1px solid #7e7e7e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bp-submit-btn {
  width: 145px;
  height: 45px;
  color: #7e7e7e;
  border-radius: 9px;
  background: #001af3;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.bp-table-body {
  margin-top: 35px;
}
