/* dashboard -start */
.quizErrorMsg {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #9b9fa7;
  opacity: 0.8;
}

.quizErrorMsgPosition {
  top: 30vh;
  left: 60vh;
}

.dashboard {
  background: var(--backgroundColor);
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dashboard_placeholder {
  margin: auto;
  width: fit-content;
  align-self: center;
  justify-self: center;
  color: var(--primaryColor);
  font-size: 1.5rem;
  text-transform: uppercase;
}

.cityCreateerror {
  color: red;
  padding: 5px;
}

.dashboard_link {
  font-size: 1vw;
  position: absolute;
  top: 15px;
  left: 15px;
}

.dashboard_link_master {
  font-size: 1vw;
  position: absolute;
  top: 3px;
  left: 15px;
}

.dashboard_link_master span {
  margin: 0 2px;
  color: rgb(122, 122, 122);
  font-size: 12px;
  vertical-align: middle;
}

.dashboard_link_master span i {
  font-size: 12px;
}

/* .dashboard_link span {
  margin: 0 2px;
  color: rgb(122, 122, 122);
  font-size: 12px;
  vertical-align: middle;
} */

.dashboard_link span i {
  font-size: 12px;
}

.link_text {
  margin-right: 3px !important;
}

.subTitle {
  margin: 0 5px !important;
  color: yellow
}

.dashboard_link a {
  color: #403e75;
  text-decoration: none;
  font-size: 12px;
  vertical-align: middle;
}

.dashboard_link_master a {
  color: #403e75;
  text-decoration: none;
  font-size: 12px;
  vertical-align: middle;
}

.dashboard_last_updated {
  position: absolute;
  top: 15px !important;
  right: 0 !important;
  margin: 4px 10px !important;
  font-size: 16px !important;
}

.dashboard_last_updatedv2 {
  position: absolute;
  top: 4px !important;
  right: 0 !important;
  margin: 4px 10px !important;
}

.dashboard_last_updated_master {
  position: absolute;
  top: 1px !important;
  right: 0 !important;
  margin: 4px 1px !important;
}

.dashboard_last_updatedv2>p {
  width: 220px;
  height: 17px;
  /* text-align: left; */
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

.dashboard_last_updated>p {
  width: 220px;
  height: 17px;
  /* text-align: left; */
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

.dashboard_last_updated_master>p {
  width: 220px;
  height: 17px;
  font: normal normal normal 11px/18px Poppins;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

/* dashboard - end */
/* table */
.table_head {
  color: #ffffff !important;
  font-family: "poppins" !important;
  font-weight: 400 !important;
  background: #403E75 !important;
  font-size: 1vw !important;
  text-align: center;
}

.table_cell,
.table_cell_name {
  font-family: "poppins" !important;
  /* font-size: 1vw !important; */
  font-size: 14px !important;
  color: #354052;
}

.table_celll {
  font-family: "poppins" !important;
  font-size: 14px !important;
  /* color: #354052; */
}

/* .table_cell_name:hover {
  color: #fd8c00;
} */



/* MA
*Coordinator Type,
*SubjectAndSubtopic,
*SubjectMapping,
*Chapter,
*
-> Table Cell */
.table_rw:hover {
  border: 1px solid #fd8c00;
  /* Border color on hover */
}

.table_rw {
  display: flex;
  gap: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  position: relative;

}


/* MA
*Admin Role,
*Grade,
*Taxonomy Category,
*Taxonomy,
*Academic Year
-> Table Cell*/
.table_cellll {
  font-family: "poppins" !important;
  font-size: 1vw !important;
  /* color: #354052; */
}



/*this css-className is linked to both AdminUsers and EditAdminUsers*/

.dropdowns {
  /* text-decoration: underline red; */
  /* text-underline-offset: 12px; */
  width: 100%;
  display: block;
  border-bottom: 1px solid #f5f5f5;
  padding: 2px 2px 0px 2px !important;
}

.dropdowns:hover,
.listitemtext:hover {
  border-bottom: none;
}

.dropdowns .listitemtext {
  border-bottom: 1px solid #f5f5f5;
  padding: 3px 16px !important;
}

/* action btns (password,edit,share,delete) hover effect */
/*this css-className is linked to both AdminUsers & EditAdminUsers*/
.btnHoverEffect:hover {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.btnHoverEffect {
  margin: 5px;
}

.title {
  width: 100%;
  border: none;
  border-radius: 0.8vw;
  padding: 1.2vw;
}

.title:focus-visible {
  outline-color: #ffffff;
}

.title:hover {
  border: none;
}

/*
  *adminRole,
  *Board,
  *Grade,
  *SubjectAndSubtopic,
  *SubjectMapping,
  *Chapter,
  *DocumentCategory,
  *planTemplateClone,
  *TaxonomyCategory,
  *Taxonomy,
  *City,
  *AcademicYear
  action btn Edit & Dlt
*/
.aRoleActionBtn:hover {
  filter: invert(18%) sepia(99%) saturate(7432%) hue-rotate(11deg) brightness(100%) contrast(100%);
}

.css-k7myp5 .MuiInputBase-root {
  height: 36px !important;
  border-radius: 50px !important;
  background: #fff !important;
  line-height: 21px !important;
}

/* .MuiInputBase-input, .MuiOutlinedInput-input, .MuiInputBase-inputAdornedEnd {
    height: 26px !important;
   border-radius: 50px !important;
  background: #fff !important;
   line-height: 11px !important;
  } */
/* Plan Template grade hover effect */
.grade_style:hover {
  color: #fd8c00 !important;
}

.menu_item {
  border-bottom: 1px solid #e8e8e8 !important;
  /* border-bottom: 1px solid #e8e8e8 !important; */
  width: 100% !important;
}

.menu_item:last-child {
  border-bottom: none !important;
}

.global_list_search_container_1 {
  display: flex;
  gap: 25px;
  width: 247px;
  height: 32px;
}

.global_list_search_container {
  width: 250px;
  position: relative;
}

.global_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.global_list_search_container input::placeholder {
  color: #b5b6b6;
}

.global_list_search_container span {
  position: absolute;
  top: 7px;
  left: 215px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

/*AssociateConcept.js Image Hover Effects -> Float Shadow */
.Ac_Image {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.Ac_Image:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center,
      ellipse,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}

.Ac_Image:hover .Ac_Image_P {
  color: #fd8c00;
}

.Ac_Image:hover,
.Ac_Image:focus,
.Ac_Image:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}

.Ac_Image:hover:before,
.Ac_Image:focus:before,
.Ac_Image:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

/*AssociateConcept.js Image Hover Effects -> Float Shadow [ ENDS HERE ] */

.lastUpdated_text {
  width: 220px;
  height: 17px;
  /* text-align: left; */
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

.Score_and_Charts {
  margin: 20px 20px 0;
}

/*-------| Chapter card styles for all Academic Staffs |-------*/
.img_Chapter_Parent_div {
  height: 129px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.img_Chapter_Parent_div>img {
  width: 200px;
  height: 278px;
  border: 1px solid grey;
  /* object-fit: contain; */
  /* border: 1px solid; */
}

.chapterName {
  font-size: 12px;
  font-weight: 600;
  color: #423d5c;
}

.truncated_paragraph {
  background-color: white;
  padding: 9px;
  height: 50px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 1.1vw;
  word-wrap: break-word;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: flex;
  line-height: 1.5vw;
  align-items: center;
  justify-content: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
}

.add_Chapter_Btn {
  width: 140px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #4CAF50 0% 0% no-repeat padding-box;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 1px 2px #01C60769;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform, box-shadow;
}

.add_Chapter_Btn>img {
  width: 19px;
  height: 19px;
}

.add_Chapter_Btn>div:nth-child(2) {
  font-size: 12px;
  color: #FFFFFF;
  text-transform: capitalize;
}

.add_Chapter_Btn:hover,
.add_Chapter_Btn:focus,
.add_Chapter_Btn:active {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px #01C60769;
}

/* last update */
.lastUpdate_para {
  font: normal normal normal 12px/18px Poppins !important;
  color: #1b1b1b !important;
  opacity: 1 !important;
}

.az_select_menu_selected {
  border-radius: 150px;
  padding: 4px;
  color: #ffa500;
}

.az_select_menu {
  border-radius: 150px;
  padding: 4px;
  color: black;
}

.az_subTitle {
  color: #ffa500;
  font-size: 16px;
}

.small_font {
  font-size: 16px !important;
  font-family: "Poppins";
}

.az_menu_icon {

  width: 24px;
  height: 24px;
  color: #828282;
  vertical-align: middle;
}


.az_menu_text {
  color: #828282;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
  /* font-weight: bold; */
}

.az_selected_menu_text {
  color: #ffa500;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
  /* font-weight: bold; */
}

.az_double_arrow {
  color: #828282;
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  margin-right: 16px;
}

.az_menu_text:hover {
  color: black;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: orange;
  text-underline-offset: 4px;
  text-decoration-thickness: 4px;
}


.az_text_color {
  color: var(--textColor) !important;
}

.az_card_img {
  top: '90%';
  left: '30%';
  text-align: 'center';
  padding: '1px';
  box-sizing: 'border-box';
  font-size: "10px" !important;
}

.az_card {
  width: '200px';
  height: '332px';
  border: '1px solid #ddd';
  border-radius: '8px';
  overflow: 'hidden';
  margin: '16px';
  position: 'relative';
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
  background-color: "white"
}

.az_full_quiz_card {
  background-color: #fff;
  font-size: 1rem;
  /* Replace with your desired font size */
  color: #000;
  /* Replace with your desired text color */
  box-shadow: 0px 2px 1px -1px rgba(138, 203, 249, 0.77),
    0px 1px 1px 0px rgba(138, 203, 249, 0.77),
    0px 1px 3px 0px rgba(138, 203, 249, 0.77);
  display: flex;
  flex-direction: column;
  align-items: start;
}

.az_full_quiz_card_customText {
  padding: 15px 10px;
  color: #f19e00;
  font-weight: 600;
  font-size: 0.93vw;
  word-wrap: break-word;
  max-width: 300px;
}

.az_full_quiz_card_customContainer {
  background: rgba(138, 203, 249, 0.77);
  width: 100%;
  padding: 20px;
  font-size: 0.93vw;
}


.az_full_quiz_card_customContainer1 {
  display: "flex" !important;
  flex-direction: "row" !important;
  justify-content: "space-between" !important;
  padding: "5px" !important;
}

.az_blue_card {
  background: "rgba(4,59,189,0.91)";
  width: "100%";
  padding: "20px";
  color: "white";
  font-size: "0.93vw";
}

.formControl {
  width: 100%;
}

.formControl .MuiInputLabel-root {
  color: #FD8C00 !important;
  font-size: 14px !important;
  opacity: 0.9;
}

.formControl .MuiInputBase-root {
  background-color: #FFFFFF !important;
  border-radius: 50px;
  width: 148px;
  height: 32px;
}

#az_card_style {
  background-color: #ffffff;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.az_card_style {
  background-color: #ffffff;
  text-align: center;
  color: #000;
  display: flex;
  width: 200px;
  height: 337px;
  justify-content: center;
  align-items: center;
  cursor: "pointer";
}

.custom_card_padding {
  padding: 20px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  /* Adjust the padding as needed */
}

.cardContainer2 {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}

.cardContainer2::-webkit-scrollbar {
  width: 10px;
}

.cardContainer2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cardContainer2::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.cardContainer2::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cardContainer1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.az_quiz_card_img {
  width: 200px;
  height: 278px;
  border-radius: 15px !important;
  padding: 10px !important;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 166px;
  width: 200px;
  height: 337px;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.card:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 166px;
  width: 200px;
  height: 337px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid var(--utilityColor);
  background-color: #fcedc2;
  border-radius: 10px;
}

.az_dd_styling {
  margin-top: "55px";
  padding-right: "33px";
  position: "absolute";
  right: "15px";
  left: "15px";
  width: "100%";
  display: "flex";
  flex-direction: "column";
  row-gap: "1rem";
}

.cursor_hover {
  cursor: pointer;
}

.css-87ivo5-MuiInputBase-root-MuiOutlinedInput-root.MuiOutlinedInput-root.Mui-focused:hover {
  background-color: #fcedc2 !important;
}

.css-newoq5-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 0% !important;
}




.container {
  background-color: #f2f2f2;
  /* Grey background color */
  padding: 10px;
}

.row {
  display: flex;
}

.column {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  /* Add border for better visibility */
}

.header {
  font-weight: bold;
}


.pdf_preview_practice {
  width: 210mm;
  height: 297mm;
}

.pdf_preview_periodic {
  width: 210mm;
  height: 297mm;
}

.pdf_preview_term {
  width: 210mm;
  height: 297mm;
}

/*-------| Hover Effect for tables |-------*/

.hover_row:hover {
  border-color: #ff9702;
  border-style: solid; 
  border-width: 1px; 

  
  border-top: 1px solid #ff9702;
  border-bottom: 1px solid #ff9702;
}

.hover_row:hover th:first-of-type,
.hover_row:hover td:first-of-type {
  border-left: 1px solid #ff9702;
}

.hover_row:hover th:last-of-type,
.hover_row:hover td:last-of-type {
  border-right: 1px solid #ff9702; 
}


.hover_row th:last-of-type,
.hover_row td:last-of-type {
  border-right: none;
}

.hover_row th:first-of-type,
.hover_row td:first-of-type {
  border-left: none; 
}

.hover_row:last-child:hover td,
.hover_row:last-child:hover th {
  border-bottom: 1px solid #ff9702 !important
}


