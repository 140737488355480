#az_Subtitle {
    color: orange;
    font-size: 16px;

}

.az_small {
    font-size: 16px;
}

.az_selected {
    color: orange;
}

.azSubtitle {
    color: rgb(130, 238, 177);
    font-size: 15px;
}

.subTitle {
    color: orange;
    font-size: 16px;
}

#demo-simple-select {
    color: orange;
    font-size: 16px;
}


.selectClass {
    border-radius: 150px;
    padding: 40px;
    color: orange
}

.selectClass:hover {
    border-radius: "150px";
    padding: "4px";
    color: orange;
    border: 2px solid orange;
}

.formControlinput {
    width: 100%;
}

.formControlinput .MuiInputLabel-root {
    color: #FD8C00 !important;
    font-size: 14px !important;
    opacity: 0.9;
}

.formControlinput .MuiInputBase-root {
    background-color: #FFFFFF;
    border-radius: 50px;
    width: 148px;
    height: 32px;
}

#formControlinput {
    width: 100%;
}

#formControlinput .MuiInputLabel-root {
    color: #FD8C00 !important;
    font-size: 14px !important;
    opacity: 0.9;
}

#formControlinput .MuiInputBase-root {
    background-color: #FFFFFF;
    border-radius: 50px;
    width: 148px;
    height: 32px;
}

#az_level1 {
    margin-top: "55px";
    padding-top: "33px";
    position: "absolute";
    right: "15px";
    left: "15px";
    width: "100%";
    display: "flex";
    flex-direction: "column";
}

.az_level2 {
    margin-top: "55px";
    padding-top: "33px";
    position: "absolute";
    right: "15px";
    left: "15px";
    width: "100%";
    display: "flex";
    flex-direction: "column";
}