.breadcrumbs {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  color: #354052;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}


.breadcrumbs p{
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  color: #354052;
  text-transform: capitalize;
}
.root_item_container {
  width: 80%;
}
.accordion_listitems {
  font-size: 13px;
  font: normal normal normal 13px/23px Poppins;
  color: #354052;
}

.root_topics_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #D9D9D9;
  padding-left: 50px ;
  padding-right: 20px;
  padding-top:14px;
  padding-bottom:14px;
}

.topic_select_btn {
  padding: 5px 15px;
  background-color: #fd8c00;
  border-radius: 16px;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  list-style-type: none;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border:0
}

.topic_selected_btn{
    padding: 5px 15px;
    background-color: #9B9FA7;
    border-radius: 16px;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
    list-style-type: none;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  border:0

}

/* 
.cellItemsStyle{
    font-family: "Poppins";
    font-size: 15px;
    color: #354052;
} */

.dropdown_container{
    padding-top:-30px;
}

.css-o4b71y-MuiAccordionSummary-content{
  margin:0 !important
}

.css-15v22id-MuiAccordionDetails-root{
  padding:0 !important
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin:0 !important
}

.css-15v22id-MuiAccordionDetails-root{
  padding: 8px !important
}

.items_link{
  list-style-type: none;
  border: 1px solid;
  /* border-radius: 10px; */
  border-color: #7070706B;
  background-color: #fff;
  /* box-shadow: 1px 1px grey; */
  margin: 6px;
}
.accordian_main{
  list-style-type: none;
  border: 1px solid;
  /* border-radius: 10px; */
  border-color: #7070706B;
  background-color: #fff;
  /* box-shadow: 1px 1px grey; */
  margin: 6px;
}
.sublink_text{
  color: black;
  /* margin: 5px 4px; */
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.8rem;
  /* padding-left: 20px; */
}

.totalScore{
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  color: #FD8C00;
  position: absolute;
  right:0
}