/* ==========main container starts ================ */

.main_container {
  width: 100vw;
  height: 100vh;
  min-width: 650px;
  margin: auto;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
  background-position: center;
  background-size: cover;
  min-height: 900px;
  min-width: 900px;
}


/* navbar -Start */
.navbar {
  position: relative;
  top: 3%;
  left: 4%;
  width: 80%;
}

.icon {
  position: relative;
}
.logo img {
  width: 19%;
  margin: 24px 55px;
}
/* navbar - end */
/* content -start */
.content {
  width: 80%;
  margin: auto;
  height: auto;
  color: #141010;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.login_img {
  width: 48%;
  height: fit-content;
}

/* form -begin */
.login_form {
  position: relative;
  width: 30%;
  height: 60%;
  min-width: 450px;
  background: white;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  gap: 50px;
  min-height: 550px;
  box-shadow: 0px 0px 13px 0px rgb(122 122 122 / 50%);
}

.login_form_title {
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  gap: 5px;
}

.login_form_title>h3 {
  font-weight: 100;
  font-size: large;
}

.login_form h2 {
  font-size: large;
  font-weight: 900;
  color: hsla(0, 5%, 7%, 0.937);
}

.login_form h3 {
  font-size: medium;
  font-weight: 500;
  margin-top: -5px;
}

.login_form>input,
.password_container>input {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
}

.password_container {
  position: relative;
  bottom: 55px;
  height: 40px;
}

.password_fieldset {
  border: none;
  position: relative;
}

.password_container legend {
  position: relative;
  left: 15px;
  top: 16px;
  display: inline-table;
  padding: 5px;
  background-color: white;
  color: #fb9d2a;
  font-size: medium;
  font-weight: 600;
  z-index: 1;
}
.password_container input {
  width: 100%;
  height: 50px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid gray;
  border-radius: 7px;
}

/* ============================ */

.setPassword_login_form {
  position: relative;
  width: 40%;
  min-width: 450px;
  background: white;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: 550px;
  box-shadow: 0px 0px 13px 0px rgb(122 122 122 / 50%);
}

/* error span -start */
.error_span {
  padding: 15px;
  color: red;
  font-size: 0.8rem;
}

/* error span -emd */

.login_container {
  justify-content: end;
  display: flex;
  position: relative;
  top: 95px;

}

#togglePassword {
  position: absolute;
  bottom: 15px;
  left: 92%;
}
.sp_login_btn {
  width: 50%;
  margin-right: 15px;
  height: 2.1rem;
  background: #111fee;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 3px 5px 0px blue;
  font-size: 0.8rem;
  text-decoration: none;
  color: white;
  margin: 2px 0px;
  text-transform: uppercase;
}

/* 
.sp_login_btn > a {
} */

@media screen and (max-width: 1100px) {
  .login_form>div {
    margin: 5px 0;
  }

  .login_form h2 {
    font-size: 0.9rem;
  }

  .login_form h3 {
    font-size: 0.7rem;
  }

  .login_form>input,
  .password_container>input {
    font-size: 1.2rem;
  }

  .password_container legend {
    font-size: 0.7rem;
  }

  .login_btn {
    width: 61%;
    font-size: 0.5rem;
    padding: 0 7px;
  }
}

/* validation strat here */

.container p {
  margin: 10px -10px 10px;
}


/* it's appear when password field is not match the condition */
.container p .close {
  text-align: center;
  line-height: 20px;
  font-size: large;
  border-radius: 50%;
  font-size: 1.1rem;
  color: red;
  border: 2px solid red;
}

.text {
  color: red;
}

.close i {
  opacity: 1;
  width: 1rem;
  opacity: 1;
  padding: 0px 0px 4px 0px;
}

.text {
  margin: 10px 10px 10px 10px;
}

/* it would be appear when password field would be match the condition */
.container p .check {
  text-align: center;
  line-height: 20px;
  font-size: large;
  border-radius: 50%;
  color: green;
  border: 2px solid green;
}

.text_right {
  color: green;
}

.check i {
  width: 1.1rem;
  opacity: 1;
  padding: 0px 0px 4px 0px;
}

.text_right {
  margin: 10px 10px 10px 10px;
}

.text_gap {
  margin: 10px 10px;
}

/* Eye icon showing password or text when hovers the icon*/
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  cursor: pointer;
  text-transform: none;
  line-height: 1;
  vertical-align: text-bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
