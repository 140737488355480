.head_title {
  margin: 20px;
  font-size: 22px;
  color: #fffcfc;
}

.handWaveImg {
  height: auto;
  margin: 0 0 0 6px;
}

.subHead_title {
  font-size: 10px;
  color: #fffcfc;
  font-weight: normal;
}

.profileSection {
  display: flex;
  column-gap: 1rem;
  padding: 10px;
  align-items: center;
}

.std_badge {
  border-radius: 150px;
}

.std_badge > img {
  width: 2.86vw;
  height: auto;
  margin-top: 10px;
}

.profile_Namee {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;
  color: #fffcfc;
}

.std_name {
  font-size: 13px;
  color: #fffcfc;
  font-weight: bold;
}

.role_std {
  color: #fffcfc;
  display: flex;
  font-size: 13px;
  justify-content: start;
  width: 100%;
}

.down_Arrow {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
}

.triangle-bottom{
  width: 0;
  height: 0;
  margin: 30px auto;
  border-left:
    10px solid transparent;
  border-right:
    10px solid transparent;
    border-top:
    10px solid white;
  justify-content: center;
}

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/
@media (min-width: 768px) and (max-width: 1023px) {
  .head_title {
    margin: 1.5vw;
    font-size: 1.45vw;
  }

  .handWaveImg {
    height: auto;
  }

  .subHead_title {
    font-size: 1vw;
  }

  .profileSection {
    display: flex;
    column-gap: 0.7vw;
    align-items: center;
  }

  .std_badge {
    border-radius: 9.76vw;
    margin-top: 0.2vh;
  }

  .profile_Namee {
    display: flex;
    flex-direction: column;
    row-gap: 0.13vw;
    align-items: center;
    color: #fffcfc;
  }

  .std_name {
    font-size: 1vw;
    color: #fffcfc;
  }

  .role_std {
    display: flex;
    justify-content: start;
    width: 100%;
    font-size: 1vw;
    color: #fffcfc;
  }

  .down_Arrow {
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media (min-width: 1024px) and (max-width: 1430px) {
  .head_title {
    margin: 1.2vw;
    font-size: 1.35vw;
  }

  .handWaveImg {
    height: auto;
  }

  .subHead_title {
    font-size: 0.8vw;
  }

  .profileSection {
    display: flex;
    column-gap: 0.2vw;
    align-items: center;
  }

  .std_badge {
    margin-top: 0.3vh;
  }

  .profile_Namee {
    display: flex;
    flex-direction: column;
    row-gap: 0.15vw;
    align-items: center;
    color: #fffcfc;
  }

  .std_name {
    font-size: 1.1vw;
    color: #fffcfc;
  }

  .role_std {
    display: flex;
    justify-content: start;
    width: 100%;
    font-size: 1.1vw;
    color: #fffcfc;
  }

  .down_Arrow {
    display: flex;
    flex-direction: column;
    row-gap: 1.1vw;
  }

  /* notification list css */
  /* .avatar {
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    margin-right: 10px;
  }
   */
  /* .dropdown-menu {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    background-color: white;
    min-width: 400px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 10px;
  }
  
  .drop-item {
    background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    border-radius: 10px;
    padding: 0px 20px;
  }
  
  .drop-item:hover {
    background-color: grey;
    color: white;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 10px;
  }
  
  .text {
    margin: 0;
    text-align: left;
  }
  .drop-item .small{
    font-size: 12px;
  } */
  
  @media (max-width: 768px) {
    /* .dropdown-menu {
        max-height: 200px;
    } */
  }
}
