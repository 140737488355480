.select-teacher-dropdown {
  width: 200px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.option-wrapper {
  display: flex;
  align-items: center;
}
