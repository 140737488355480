/* navbar - start */
.nav {
  /* border-radius: 0 15px 15px 0; */
  box-shadow: 1px 0px 5px 1px var(--primaryColor);
  width: 20%;
  min-width: 170px;
  min-height: 640px;
  overflow: scroll;
  margin-bottom: 30px;
}

/* navbar logo - start */
.nav_logo {
  display: flex;
  justify-content: space-evenly;
}
.nav_logo img {
  width: 56%;
  margin: 15px 0 15px -10px;
  height: 50px;
}
/* navbar logo -end */

/* navbar links - start*/
.nav_title {
  color: var(--utilityColor);
  margin: 0 15px;
}

.user_link {
  display: flex;
  transition: none;
}

.nav_links {
  /* position: relative; */
  /* height: 88%; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav_links li {
  list-style-type: none;
  /* border-radius: 0 40px 40px 0; */
  background: linear-gradient(to left, white 50%, #FFA500 50%) right;
  background-size: 200%;
  transition: 0.1s ease-out;
  margin: 1px 0;
}
/* logout link positioning -start*/
.logout_container a {
  position: fixed;
  list-style-type: none;
  background: linear-gradient(to left, white 50%, #FFA500 50%) right;
  background-size: 200%;
  transition: 0.1s ease-out;
  margin: 0;
  padding: 5px;
  cursor: pointer;
}

.logout_container:hover i {
  color: #ffa500;
}

.logout_container a:hover {
  background-position: left;
}
/* logout link positioning -end*/

.nav_links li:hover {
  background-position: left;
}

.home_link_icon {
  color: var(--primaryColor);
}

.nav-links li:hover span:first-child {
  color: var(--primaryColor);
}

.nav_links li p {
  display: flex;
  text-decoration: none;
  /* margin: 10px 0px; */
  margin: 5px 0px;
}
.link_icon {
  align-self: center;
  color: #727272;
  justify-self: flex-start;
  padding: 5px 6px;
  width: 40px;
}
.link_text {
  justify-content: center;
  width: fit-content;
  font-size: 0.8rem;
  margin: 5px 4px;
  color: #ffa500;
}

.link_dropdown {
  display: block;
  align-self: center;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 15px;
}

.nav_links li span {
  color: #1b1b1b;
  /* font-size: 1.1vw; */
  display: flex;
  justify-content: center;
  font-size: 15px;
}

/* excluding user link from hover effect */
.user_link {
  background: none !important;
  transition: none !important;
  margin: 10px 0 !important;
}
/* .user_icon {
  color: blue !important;
} */

/* home link color is always active */
.home_link {
  background: var(--hoverColor);
}

/* Schools link dropdown - start */
.schools_sublink_container {
  /* display: none; */
  transform: none !important;
  background: none !important;
}
.schools_sublink {
  transition: none !important;
  /* background: none !important; */
  margin: 1px 0px;
  padding: 3px 14px;
}

.schools_sublink_icon {
  visibility: hidden !important;
}

.registered_schools_link {
  background: var(--secondaryColor) !important;
}

.navbar_schools_sublink:hover {
  background: #FFA500 !important;
}

/* Schools link dropdown - end */

/* navbar links - end */
/* navbar -end */

.home_link:hover i {
  color: var(--primaryColor);
}

.toggle_btn:hover img {
  filter: invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%);
  /* filter: invert(11%) sepia(100%) saturate(6489%) hue-rotate(240deg)
    brightness(86%) contrast(126%); */
}

.is-active {
  color: red;
  background: var(--secondaryColor) !important;
}

.icon_link {
  align-self: center;
  color: #727272;
  justify-self: flex-start;
  padding: 0 6px;
  width: 40px;
}
.school_img {
  filter: invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%);
  /* filter: invert(59%) sepia(21%) saturate(0%) hue-rotate(178deg) brightness(97%)
    contrast(99%); */
}

.subMenuIcon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7a7a7a;
  margin-right: '45px';
}

.activeSubMenu .subMenuIcon{
  background-color: var(--primaryColor);
}

/*-------| Sidenav Menu HighLight Styles Starts |-------*/
.highlight_SideNav{
  background-color: var(--primaryColor);
}

.highlight_SideNav_Icon{
  filter: invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%);
  /* filter: invert(11%) sepia(100%) saturate(6489%) hue-rotate(240deg)
  brightness(86%) contrast(126%); */
}

.highlight_SideNav_Submenu{
background-color: rgba(211, 211, 211, 0.4);
}
/*-------| Sidenav Menu HighLight Styles Ends |-------*/

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0 !important;
}
