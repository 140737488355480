.msg-modal-body {
    background-color: #fff;
    border-radius: 10px;
    padding:40px;
    padding-right: 17px;
    position: relative;

}

.msg-modal-title {
    color: #51BF9F;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.msg-modal-content {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    /* 142.857% */
}

.msg-modal-close-btn{
    width:10px;
    height: 10px;
    position: absolute;
    top:15px;
    right:15px;
    cursor:pointer
}