.login_form::before {
  content: url("./../img/Login_element01.png");
  position: absolute;
  top: -140px;
  left: -55px;
  z-index: 1;
  width: 80%;
}
.login_form::after {
  content: url("./../img/Login_element02.png");
  position: absolute;
  top: 95%;
  left: 85%;
  z-index: 1;
  width: 80%;
}
.navbar::before {
  content: url("./../img/Login_element07.png");
  position: absolute;
  top: 419%;
  left: 59%;
  z-index: 1;
  width: 80%;
}
.navbar::after {
  content: url("./../img/Login_element03.png");
  position: absolute;
  top: 520%;
  left: 76%;
  z-index: 1;
  width: 80%;
}
.main::before {
  content: url("./../img/Login_element04.png");
  position: absolute;
  top: 18%;
  left: 78%;
  z-index: 1;
  width: 80%;
}
.main::after {
  content: url("./../img/Login_element05.png");
  position: absolute;
  top: 30%;
  left: 51%;
  z-index: 1;
  width: 80%;
}
.main::after {
  content: url("./../img/Login_element05.png");
  position: absolute;
  top: 30%;
  left: 51%;
  z-index: 1;
  width: 80%;
}
.main::after {
  content: url("./../img/Login_element06.png");
  position: absolute;
  top: 29%;
  left: 84%;
  z-index: 1;
  width: 80%;
}

@media screen and (max-width: 1000px) {
  .login_form::before {
    transform: scale(0.8);
  }
  .login_form::after {
    top: 90%;
    left: 80%;
    transform: scale(0.8);
  }
  .navbar::before {
    top: 416%;
    left: 44%;
    transform: scale(0.8);
  }
}
