/* modal CSS - start*/
#modal_teacherid {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/
.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  padding: 30px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 389px;
  z-index: 1;
  position: relative;
  top: 91px;
}

.main_content_teacherid input {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 0px 20px;
}

.main_content_teacherid label {
  position: relative;
  margin-left: 10px;
  left: -10px;
  bottom: 45px;
  font-size: small;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

.main_content_teacherid :hover label {
  top: -64px;
  color: var(--utilityColor);

  transition: all 0.2s;
}
.main_content_teacherid :hover i#togglePassword-teacher_teacherid {
  visibility: hidden;
  transition: all 0.2s;
  color: var(--utilityColor);
}

.form_input_element_teacher_teacherid:not(:placeholder-shown) ~ label,
.form_input_element_teacher_teacherid:focus ~ label {
  top: -64px;
  color: var(--utilityColor);
}

.main_content_teacherid h5 {
  font-size: smaller;
  display: flex;
  position: relative;
  bottom: 20px;
  color: black;
  margin: 15px 1px 16px 1px;
  justify-content: space-between;
}

.modal_buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}

.choosefile_button-2_teacherid {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 16px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

#choose_button_id_2_teacherid {
  background-color: #ff9100;
  color: white;
}

.choosefile_button_1_teacherid {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 14px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: 0.1px solid;
  cursor: pointer;
}

#choose_button_id_1_teacherid {
  background-color: orange;
  color: white;
  width: 185px;
  height: 45px;
  border: none;
  border-radius: 20px;
}

/* modal CSS - start*/
#modal_teacherid {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

i.fa-solid.fa-xmark {
  padding-left: 90px;
  padding-bottom: 13px;
  font-size: 30px;
  color: grey;
}

#togglePassword_teacher_teacherid {
  position: relative;
  left: 283px;
  bottom: 45px;
  font-size: small;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}

/* ==========main container starts ================ */

.main_container {
  width: 100vw;
  height: 100vh;
  min-width: 650px;
  margin: auto;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
  background-position: center;
  background-size: cover;
  min-height: 900px;
}
/* navbar -Start */
.navbar {
  position: relative;
  top: 3%;
  left: 4%;
  width: 80%;
}

.icon {
  position: relative;
}
.logo img {
  width: 19%;
  margin: 24px 55px;
}
/* navbar - end */
/* content -start */
.content {
  width: 80%;
  margin: auto;
  height: auto;
  color: #141010;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.login_img {
  width: 48%;
  height: fit-content;
}

/* form -begin */
.login_form {
  position: relative;
  width: 40%;
  height: 450px;
  min-width: 300px;
  background: white;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0px 0px 13px 0px rgb(122 122 122 / 50%);
}

.login_form_title {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.login_form_title > h3 {
  font-weight: 100;
  font-size: large;
}

.login_form h2 {
  font-size: large;
  font-weight: 900;
  color: hsla(0, 5%, 7%, 0.937);
}

.login_form h3 {
  font-size: medium;
  font-weight: 500;
  margin-top: -5px;
}

.login_form > input,
.password_container > input {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
}

.password_container {
  position: relative;
  bottom: 30px;
  height: 60px;
}

.password_fieldset {
  border: none;
  position: relative;
}

.password_container legend {
  position: relative;
  left: 15px;
  top: 16px;
  display: inline-table;
  padding: 5px;
  background-color: white;
  color: #fb9d2a;
  font-size: medium;
  font-weight: 600;
  z-index: 1;
}
.password_container input {
  width: 100%;
  height: 50px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
}
/* ============================ */

.setPassword_login_form {
  position: relative;
  width: 334px;
  height: 320px;
  background: white;
  min-width: 360px;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-shadow: 0px 0px 13px 0px rgb(122 122 122 / 50%);
}

/* error span -start */
.error_span {
  padding: 15px;
  color: red;
  font-size: 10px;
}
/* error span -emd */

.login_container {
  justify-content: center;
  display: flex;
  position: relative;
  top: -5px;
}

#togglePassword {
  position: absolute;
  bottom: 15px;
  left: 92%;
}
.sp_login_btn {
  background-color: #fd8c00;
  color: white;
  padding: 11px;
  width: 135px;
  border-radius: 20px;
  border: 0.1px solid #fd8c00;
  cursor: pointer;
}

.login_container :hover {
  top: -7px;
  box-shadow: 0px 3px 5px 0px orange;
}
/* 
.sp_login_btn > a {
} */

@media screen and (max-width: 1100px) {
  .login_form > div {
    margin: 5px 0;
  }
  .login_form h2 {
    font-size: 0.9rem;
  }
  .login_form h3 {
    font-size: 0.7rem;
  }
  .login_form > input,
  .password_container > input {
    font-size: 1.2rem;
  }
  .password_container legend {
    font-size: 0.7rem;
  }
  .login_btn {
    width: 61%;
    font-size: 0.5rem;
    padding: 0 7px;
  }
  /* .login_btn a {
  } */
}

/* modal CSS - end*/
