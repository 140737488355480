.cqk__Quiz_Main_div_MCQ {
  width: 100%;
  height: 355px;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  opacity: 0.9;
}

/*-------| Quiz QUESTION Style Starts |------*/
.cqk__Qstn_and_Ans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.cqk__Qstn_div {
  max-height: 400px;
  overflow: auto;
  text-align: justify;
  border-right: 1px solid #faf9ff;
  padding-right: 20px;
}

/*-----| Scroll Bar Starts |-----*/
.cqk__Qstn_div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Qstn_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Qstn_div::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk__Mcq_Head {
  font-size: 15px;
  opacity: 0.8;
}

.cqk__Qstn_content_1 {
  font-size: 13px;
  margin-top: 10px;
  line-height: 1.7;
  color: #333333;
  opacity: 0.9;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.cqk__Qstn_content_1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Qstn_content_1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Qstn_content_1::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk__MCQ_Qstn_Img {
  width: 170px;
  height: 110px;
}
/*-------| Quiz QUESTION Style Ends |-------*/

/*-------| Quiz ANSWER Style Starts |-------*/
.cqk__Ans_div {
  max-height: 320px;
  overflow-x: hidden;
}

/*-----| Scroll Bar Starts |-----*/
.cqk__Ans_div::-webkit-scrollbar {
  width: 6px;
  max-height: 178px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.cqk__Ans_div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.cqk__Ans_div::-webkit-scrollbar-track {
  background-color: white;
}
/*-----| Scroll Bar Ends |-----*/

.cqk__Ans_Head_and_Count {
  display: flex;
  justify-content: space-between;
}

.cqk__Head {
  font-size: 16px;
  color: #1b1b1b;
}

.cqk__Ans1 {
  width: 520px;
  height: auto;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 11px;
  line-height: 24px;
  padding: 10px;
  border-radius: 5px;
}
/*-------| Quiz ANSWER Style Ends |-------*/
