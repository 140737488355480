.main_container {
    width: 100vw;
    height: 100vh;
    min-width: 650px;
    margin: auto;
  }
  .main {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
    background-position: center;
    background-size: cover;
  }
  /* navbar -Start */
  .navbar {
    position: relative;
    top: 3%;
    left: 4%;
    width: 80%;
  }
  
  .icon {
    position: relative;
  }
  .logo img {
    width: 19%;
    margin: 24px 55px;
  }
  /* navbar - end */
  /* content -start */
  .content {
    width: 80%;
    margin: auto;
    height: auto;
    color: #141010;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
  }

  .login_container {
      justify-content: end;
      display: flex;
      position: relative;
      }
  
  .login_img {
    width: 48%;
    height: fit-content;
  }
  
  /* form -begin */
  .login_form {
    position: relative;
    width: 40%;
    min-width: 300px;
    background: white;
    border-radius: 18px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .login_form_title {
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .login_form h2 {
    font-size: 1rem;
    font-weight: 900;
    color: hsla(0, 5%, 7%, 0.937);
  }
  
  .login_form h3 {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: -5px;
  }
  
  .login_form > input,
  .password_container > input {
    width: 100%;
    font-size: 1rem;
    border-radius: 5px;
    padding: 5px;
    margin: 10px 0;

  }

.updatePassword_login_form {
    height: 35vh;
    align-self: center;
    position: relative;
    width: 40%;
    min-width: 300px;
    background: white;
    border-radius: 18px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0px 0px 4px 0px grey;
  }
  
  .up_login_btn {
    width: 45%;
    margin-right: 15px;
    height: 2.1rem;
    background: #111fee;
    border: 1px solid black;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px grey;
  }
  
  .login_form_title  >h2{
    font-size:1.3rem;
  }
  .up_login_btn > a {
    font-size: 0.8rem;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
  }
  
  .login_form_title h3 {
    margin-top: 3px;
    font-size: 0.8rem;
    font-weight: normal;
  }

  @media screen and (max-width: 1100px) {
    .login_form {
      height: 50vh;
    }
    .login_btn {
      width: 35%;
      border-radius: 15px;
    }
    .login_btn a {
      font-size: 0.6rem;
      padding: 0 5px;
    }
  }
  