.main_container {
  width: 100vw;
  height: 100vh;
  min-width: 650px;
  margin: auto;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
  background-position: center;
  background-size: cover;
}

.navbar {
  left: 4%;
  width: 80%;
}

.icon {
  position: relative;
}

.logo img {
  width: 19%;
  margin: 24px 55px;
}

/* navbar - end */
/* content -start */
.content {
  width: 80%;
  margin: auto;
  height: auto;
  color: #141010;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.login_img {
  width: 48%;
  height: fit-content;
}

/* form -begin */
.login_form {
  position: relative;
  width: 40%;
  min-width: 300px;
  background: white;
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0px 0px 13px 0px rgb(122 122 122 / 50%);
}

.login_form_title {
  padding: 5px;
  display: grid;
  gap: 10px;
  margin-top: -20px;
}

.login_form h2 {
  font-size: larger;
  font-weight: 900;
  color: hsla(0, 5%, 7%, 0.937);
}

.login_form h3 {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: -5px;
}

.login_form>input,
.password_container>input {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
}

/* =============================== */
.content {
  height: 75%;
}

.login_form {
  padding: 47px;
}

.mobile_email span {
  margin: 0 10px;
}

.password_container {
  position: relative;
  bottom: 20px;
}

.password_container fieldset {
  border-radius: 5px;
  position: relative;
}

.password_container legend {
  position: relative;
  display: inline-table;
  padding: 5px;
  background-color: white;
  color: #fb9d2a;
  font-size: 0.8rem;
}

.password_container input {
  width: 100%;
  height: 50px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.input_elementx {
  /* text-indent:30px; */
  background: url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" width="40" height="30"><text x="5" y="30" style="font: bold 18px Arial;">+91</text></svg>') no-repeat;

}

.input_elementx::placeholder {
  padding-left: 10px;
}

.input_element::placeholder {
  color: rgba(128, 128, 128, 0.472);
}

.verification_link {
  font-size: 0.8rem;
  margin-left: 10px;
}

.verification_link_danger {
  font-size: 0.8rem;
  margin-left: 10px;
  color: red;
}

.login_container {
  justify-content: end;
  display: flex;
}

.fp-login_btn {
  width: 40%;
  height: 2.1rem;
  background: #111fee;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}

.verify_btn {
  width: 40%;
  height: 2.1rem;
  background: orange;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}

.verify_url {
  display: inline-block;
  width: 40%;
  height: 2.1rem;
  background: #e7ee11;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  text-align: center;
  line-height: 2.1rem;
  text-decoration: none;
  color: black;
 
}

.fp-login-btn>a {
  font-size: 0.8rem;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
}

.login_btn {
  width: 40%;
  height: 2.1rem;
  background: #111fee;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 3px 5px 0px blue;
}

.login_btn>a {
  color: white;
  text-decoration-line: none;
}

/* responsive styles */
@media screen and (max-width: 1100px) {
  .login_form h2 {
    font-size: 0.8rem;
  }

  .login_form h3 {
    font-size: 0.6rem;
  }

  .login_form>input,
  .password-container>input {
    font-size: 1rem;
  }

  .mobile_email {
    font-size: 0.7rem;
  }

  .login_form>input::placeholder,
  .password-container>input::placeholder {
    font-size: 0.7rem;
  }

  .login_container {
    flex-direction: column;
  }

  .login_btn {
    align-self: flex-end;
    width: 40%;
    cursor: pointer;
  }

  .login_btn a {
    font-size: 0.6rem;
    padding: 0 5px;
  }

  .verification_link {
    font-size: 0.7rem;
    margin-top: 7px;
  }
}

/* =================radio starts ============= */
.radio_funcionality>input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid orange;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality>input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality>input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

/* input[type='radio']:checked:before {
  background: white;
  position: relative;
  box-shadow: inset 0px 0px 0 4px orange;
  top: -3px;
  width: 17px;
  height: 17px;
} */
.radio_active:before {
  background: white;
  position: relative;
  box-shadow: inset 0px 0px 0 4px orange;
  top: -3px;
  width: 17px !important;
  height: 17px !important;
}

/* =============radio button ends ============== */