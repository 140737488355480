.main_container {
  width: 100vw;
  height: 100vh;
  min-width: 650px;
  margin: auto;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
  background-position: center;
  background-size: cover;
}

.login_form_title h2,
.login_form_title h3 {
  margin-left: 40px;
}

.login_form_title > h3 {
  font-size: 0.8rem;
  font-weight: 400;
}

/* navbar -Start */
.navbar {
  position: relative;
  top: 3%;
  left: 4%;
  width: 80%;
}

.icon {
  position: relative;
}
.logo img {
  width: 19%;
  margin: 24px 55px;
}
/* navbar - end */
/* content -start */
.content {
  width: 80%;
  margin: auto;
  height: auto;
  color: #141010;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.login_img {
  width: 48%;
  height: fit-content;
}

/* form -begin */
.mobileOTP_login_form {
  height: 50vh;
  position: relative;
  width: 40%;
  margin-left: 8%;
  min-width: 300px;
  background: white;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  box-shadow: 0px 0px 5px 0px grey;
  flex-direction: column;
  justify-content: space-evenly;
}

.digit_group {
  height: fit-content;
  width: fit-content;
  margin: 0 15%;
  display: flex;
  justify-content: space-evenly;
}

.digit_group input {
  width: 17%;
  height: 2.5rem;
  background-color: orange;
  border: none;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: white;
  margin: 0 2px;
  border-radius: 5px;
}

.mo_login_btn {
  width: 40%;
  margin-right: 15px;
  height: 2.1rem;
  background: #111fee;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  margin-bottom: -25px;
}

.mo_login_btn > a {
  font-size: 0.8rem;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
}

.resend_otp {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin: 0 21%;
}
.resend_otp span {
  color: gray;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 20%;
}

.resend_otp a {
  text-decoration: underline;
  color: grey;
}
.resend_otp a:hover {
  color: blue;
}

@media screen and (max-width: 1200px) {
  .login_form {
    height: 55vh;
  }
  .login_form_title h3 {
    margin-top: 0px;
  }
  .digit_group input {
    height: 1.5rem;
  }
  .resend_otp {
    font-size: 0.5rem;
    margin-top: 5px;
  }
  .resend_otp span {
    font-size: 0.6rem;
  }
  .login_btn {
    border-radius: 15px;
  }
}
.login_container {
  justify-content: end;
  display: flex;
  position: relative;
}
