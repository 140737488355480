article.article_Acadmicid {
  width: 100%;
  position: absolute;
  top: 25px;
  height: 100%;
  background-color: #f8f4fc;
  outline: 3px solid #f8f4fc;
}

/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_Acadmicid {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_Acadmicid {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}

.school_name_Acadmicid {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_Acadmicid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 100%;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}

.grid_container_header_plan_Acadmicid > div {
  font-size: 0.9rem;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.list_table_teacher_list_Acadmicid {
  font-size: 0.9rem;
  font-weight: normal;
}

.item_plan3_Acadmicid {
  color: orange;
  border-bottom: 4px solid orange;
}

.grid_container_header_plan_Acadmicid > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */
.create_teacher_Acadmicid {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  /* outline: 3px solid #f8f4fc; */
  align-items: center;
  gap: 20px;
}

.teacher1_Acadmicid {
  gap: 50px;
  display: flex;
}

.teacher2_Acadmicid {
  grid-column: 4;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: auto auto auto;
}

.create_HOD_box1_Acadmicid {
  display: flex;
  gap: 30px;
}

.input_form_teacher_Acadmicid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: 200px;
  margin-top: 15px;
}

.input_element_teacher_Acadmicid {
  position: relative;
  width: 95%;
  height: 45px;
}

.input_element_teacher_Acadmicid input,
.input_element_teacher_Acadmicid select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 0 20px;
}

.input_element_teacher_Acadmicid label {
  position: absolute;
  left: 15px;
  /* top: 19px; */
  font-size: 0.8rem;
  font-weight: bold;
  /* color: var(--primaryColor); */
  background: white;

  transition: all 0.2s;
  top: -7px;
  color: var(--utilityColor);
}

/* .input_element_teacher_Acadmicid:hover label {
    top: -7px;
    color: var(--utilityColor);
  } */
.form_input_element_teacher_Acadmicid:not(:placeholder-shown) ~ label,
.form_input_element_teacher_Acadmicid:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

.input_block_element_teacher_Acadmicid {
  display: block;
}

p.remove_Acadmicid {
  font-size: x-small;
  padding-bottom: 10px;
}

.logo_space_1 {
  margin: 5px 5px;
}

.chevron_space {
  margin: 5px 5px;
}

#submit_Acadmicid {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10p;
  border: none;
}

.teacher5_Acadmicid {
  position: relative;
}

.create_button_teacher_Acadmicid {
  width: 150px;
  height: 40px;
  border-radius: 17px;
  color: white;
  background: blue;
  /* float: right; */
  position: absolute;
  right: 40px;
}

.create_button_teacher_Acadmicid:hover {
  top: -7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.error_box {
  color: red;
}

.type_of_school {
  text-align: start;
}

.list_role {
  text-align: start;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}

.userId {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}

/* ======================popup starts here =================== */
.wrapper_Acadmicid > label {
  align-items: center;
  display: flex;
}
.wrapper_Acadmicid > i {
  line-height: 50px;
  font-size: 25px;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

/* =============================teachers-list-starts-===================== */
/* .teacher_list_Acadmicid {
  border-right: 10px solid #f8f4fc;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  background: white;
  border-left: 10px solid #f8f4fc;
} */

.teacher_list_Acadmicid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 10px;
  padding: 7px 10px;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

i.fa-solid.fa-chalkboard-user {
  padding-right: 15px;
}

i.fa-solid.fa-magnifying-glass {
  color: orange;
  position: relative;
  top: 8px;
  font-size: 22px;
  background: white;
}

/* ====================table starts from here=================== */
.list_table_teacher_div_Acadmicid {
  height: 25%;
  background-color: white;
  margin: 0px 10px 10px 10px;
  overflow: auto;
  /* border-radius: 5px; */
}

table.table_section_teacher_list_Acadmicid {
  width: 100%;
  height: auto;
  background: #d9dcfe;
  text-align: center;
  border-collapse: collapse;
}

.table_section_teacher_list_Acadmicid > tbody {
  background-color: white;
  color: var(--primaryColor);
}

.table_section_teacher_list_Acadmicid > tbody > tr,
.table_section_teacher_list_Acadmicid > thead > tr {
  height: 47px;
  font-weight: normal !important;

  border: 1px groove #8080802e;
  font-size: 0.9rem;
}

#email_td {
  width: 15% !important;
}

.email_id {
  text-align: start;
}

#email {
  text-align: start !important;
}

.list_role {
  text-align: start !important;
  width: -5px !important;
  padding-left: 36px;
  margin: 0p;
  padding: 0px;
}

.table_head_section_teacher_list_Acadmicid {
  color: blue !important;
}

p.data_placeholder_Acadmicid {
  margin: auto;
  width: fit-content;
  align-self: center;
  justify-self: center;
  color: var(--primaryColor);
  font-size: medium;
  text-transform: uppercase;
}

.data_row:hover td:first-child {
  border-left: 10px solid blue;
}

.data_row {
  border-left: 10px solid white;
}

tbody tr:hover td#email {
  color: orange;
}

.data_available_Acadmicid {
  background: white;
  width: 100%;
  height: 285px;
  display: flex;
  position: relative;
  border-right: 10px solid #f8f4fc;
  border-left: 10px solid #f8f4fc;
  border-bottom: 10px solid #f8f4fc;
  border-radius: 20px;
}

/* =================table ends here============= */
/* modal CSS - start*/
.flex_container_Acadmicid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_Acadmicid > div {
  background-color: white;
  padding: 30px 30px 30px 30px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 400px;
  height: 300px;
}

.main_content_Acadmicid p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content_Acadmicid span {
  display: block;
  width: 100px;
  margin: auto;
}

.main_content_Acadmicid img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_Acadmicid h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal_buttons_Acadmicid {
  display: flex;
  justify-content: space-around;
  margin: 25px 10px 10px 10px;
}

.ok_btn {
  background-color: orange;
  color: white;
  padding: 10px;
  border-radius: 35px;
  width: 75px;
  font-weight: bold;
  border: 0.1px solid orange;
  cursor: pointer;
}

.ok_btn:hover {
  top: -7px;
  position: relative;
  box-shadow: 1px 2px 5px orange;
}

#ok_btn {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal_Acadmicid {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* modal CSS - end*/

/* modal CSS - end*/

/* ==============popup starts================ */
.wrapper_Acadmicid {
  display: inline-flex;
}

.wrapper_Acadmicid .icon {
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* z-index: 2; */
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_Acadmicid .icon span {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_Acadmicid .icon span i {
  line-height: 50px;
  font-size: 25px;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.wrapper_Acadmicid .icon .tooltip {
  position: absolute;
  top: 0;
  width: 145px;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 20px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_Acadmicid .icon:hover .tooltip {
  top: -35px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper_Acadmicid .icon:hover span {
  color: #fff;
}

.wrapper_Acadmicid .icon:hover span,
.wrapper_Acadmicid .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper_Acadmicid .add:hover i {
  color: orange;
}

.wrapper_Acadmicid .edit:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.wrapper_Acadmicid .remove:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.blank {
  margin-left: 50px;
}

#orange-add {
  box-shadow: 2px 2px 2px orange;
}

/* ===============custom-file-input starts================= */

.custom_file_input {
  width: 310px;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom_file_input::before {
  content: "Choose File";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 15px;
  padding: 5px 24px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}

.custom_file_input:hover::before {
  border-color: black;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/*=========Section 2 for updation 2 more fields and buttons ==========*/
.create_teacher_Acadmicid_sectiondiv1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 23px;
  background: white;
  border-left: 10px solid #f8f4fc;
  border-right: 10px solid #f8f4fc;
  align-items: center;
  grid-gap: 20px;
  padding-bottom: 14px;
  gap: 20px;
  border-radius: 5px 5px gray;
  margin: 2px 0px 2px 0px;
}

.teacher1_Acadmicid_sectiondiv1 {
  grid-gap: 50px;
  gap: 17px;
  display: flex;
}

.input_element_teacher_Acadmicid_sectiondiv1 {
  position: relative;
  width: 24%;
  height: 45px;
}

.input_element_teacher_Acadmicid_sectiondiv1 input,
.input_element_teacher_Acadmicid_sectiondiv1 select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 0 20px;
}

.input_element_teacher_Acadmicid_sectiondiv1 label {
  position: absolute;
  left: 15px;
  /* top: 19px; */
  font-size: 0.8rem;
  font-weight: bold;
  /* color: var(--primaryColor); */
  background: white;

  transition: all 0.2s;
  top: -7px;
  color: var(--utilityColor);
}

.form_input_element_teacher_Acadmicid_sectiondiv1:not(:placeholder-shown)
  ~ label,
.form_input_element_teacher_Acadmicid_sectiondiv1:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

.teacher1_Acadmicid_sectiondiv1 {
  position: relative;
}

.create_button_teacher1_Acadmicid_sectiondiv1 {
  width: 150px;
  height: 40px;
  border-radius: 17px;
  color: black;
  background: white;
  border: 1px solid black;
}

.create_button_teacher1_Acadmicid_sectiondiv1:hover {
  top: -7px;
  background-color: #8080806b;
}

.teacher2_Acadmicid_sectiondiv1 {
  position: relative;
}

.create_button_teacher2_Acadmicid_sectiondiv1 {
  width: 150px;
  height: 40px;
  border-radius: 17px;
  color: white;
  background: blue;
  border: none;
}

.create_button_teacher2_Acadmicid_sectiondiv1:hover {
  top: -7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.create_button_teacher1_Acadmicid_sectiondiv1:hover {
  top: -9px;
  box-shadow: 0px 3px 5px gray;
}

.teacher1_Acadmicid_button_sectiondiv1 {
  gap: 50px;
  display: flex;
  position: relative;
}

.input_form_teacher_Acadmicid_sectiondiv1 {
  grid-template-columns: 1fr 1fr;
}

.teacher1_Acadmicid_1_sectiondiv1 {
  grid-gap: 50px;
  gap: 17px;
  display: flex;
  margin: -42px 1px 4px 0px;
  float: right;
}

/*==========pagination start==========*/

.pagination {
  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/
