.wholePage {
  width: 100%;
  height: auto;
  user-select: none;
}

/*-------| DropDown & Search Style Starts |-------*/
.dropdown_And_search_div {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0 20px;
  width: 81vw;
}

.dropdown_div {
  display: flex;
  gap: 3.58vw;
}
/*-------| DropDown & Search Style Ends |------*/

.u_line {
  border-bottom: 1px solid #e9e7f6;
  margin: 12px 0 0 10px;
}

/*-------| LMS Cards Style Starts |-------*/
.Lms_Cards_Container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-right: 4em;
  position: absolute;
}

.Nochapter {
  position: relative;
  top: 32vh;
  left: 38vw;
  font-size: 20px;
  font-weight: 500;
  color: #9b9fa7;
  opacity: 0.8;
}

.Lms_Card {
  width: 200px;
  height: 300px;
  background-color: #fff;
  padding: 10px;
  border-radius: 0.32vw;
  border: 1px solid #fff;
  /* padding: 0.8vw; */
  transition: box-shadow 0.3s ease;
  position: relative;
  cursor: pointer;
}

.Lms_Card .chap_Img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 11vh;
  object-fit: contain;
}

.altImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 10vh;
  object-fit: contain;
}

.Lms_Card p {
  font-size: 0.67vw;
  color: #2f395b;
  text-align: center;
  position: absolute;
  bottom: 3px;
  top: 85px;
  left: 0;
  width: 100%;
  margin-top: 5px;
  padding: 5px;
}

.Lms_Card:hover {
  /* background-color: #ffffff;
  box-shadow: 0 0 10px #d6d5df; */
  border: 2px solid orange;
}

.Lms_Card:hover p {
  /* color: #ffffff;
  opacity: 0.9; */
}
/*-------| LMS Cards Style Ends |-------*/

/*-------| Message Icon Style Starts |-------*/
.msg_Icon_style {
  display: flex;
  justify-content: flex-end;
}

.msg_Icon_style img {
  border: 5px solid blue;
  border-radius: 50px;
  padding: 5px;
  background-color: #1436c3;
  width: 48px;
  height: 48px;
}
/*-------| Message Icon Style Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/

@media (min-width: 768px) and (max-width: 1023px) {
  .dropdown_And_search_div {
    justify-content: normal;
  }

  .dropdown_div {
    gap: 6.5vw;
  }

  .Lms_Cards_Container {
    grid-template-columns: repeat(4, 1fr);
  }

  .Lms_Card {
    width: 134px;
    height: 93px;
    margin: 2.3vw 0 0 1.3vw;
    border-radius: 0.6vw;
  }

  .Lms_Card p {
    font-size: 1vw;
    top: 62px;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/

@media (min-width: 1024px) and (max-width: 1430px) {
  .dropdown_And_search_div {
    width: 95%;
  }

  .dropdown_div {
    gap: 6.5vw;
  }

  .Lms_Card {
    width: 150px;
    height: 100px;
    margin: 1.3vw 0 0 1.3vw;
    border-radius: 0.5vw;
  }

  .Lms_Card p {
    font-size: 0.9vw;
    top: 65px;
  }
}

/*----------| Media Query for Extra Large Screens |-----------*/

@media (min-width: 1500px) and (max-width: 3072px) {
  .Lms_Card {
    margin: 1.3vw 0 0 1.3vw;
    border-radius: 0.5vw;
  }

  .Lms_Card p {
    top: 84px;
  }
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover{
  /* background-color: rgb(247, 229, 196); */
  border-color: orange !important;
  color: orange !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:hover{
  /* background-color: rgb(247, 229, 196); */
  border-color: orange !important;
  color: orange !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select:hover {
  border-color: orange !important;
  color: orange !important;
}

.css-wrwlcd-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
background-color: #e9e7f6 !important;
}

.MuiInputBase-root:hover {
  borderColor: #FD8C00 !important;
  backgroundColor: rgb(247, 229, 196) !important;
}
