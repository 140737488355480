.card {

    background: transparent linear-gradient(128deg, #4B498D 0%, #0F0B6A 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
}

.count-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px #0000000A;
}

.icon-bg {
    width: 58px;
    height: 58px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-bg-1 {
    background: transparent linear-gradient(180deg, #FFC11E 0%, #F85025 100%) 0% 0% no-repeat padding-box;
}

.icon-bg-2 {
    background: transparent linear-gradient(180deg, #4AA8F8 0%, #2340DC 100%) 0% 0% no-repeat padding-box;
}

.icon-bg-3 {
    background: transparent linear-gradient(180deg, #00D8B4 0%, #0079FA 100%) 0% 0% no-repeat padding-box;
}

.breadCrumbs {
    font-size: 12px;
    font-family: 'Poppins';
    color: '#7A7A7A';
    display: flex;
}

.last-update {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #1B1B1B;
}

.vss-card {
    border-radius: 5px;
    padding: 12px 11px;
    height: 100px;
    width: 24%
}

.vss-card-1 {
    background: transparent linear-gradient(244deg, #1DA0FF 0%, #117BC9 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #0000000A;
}

.vss-card-2 {
    background: transparent linear-gradient(244deg, #401DFF 0%, #8911C9 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #0000000A;
}

.cardCount {
    font: normal normal bold 32px/48px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.vs {
    font: normal normal bold 22px/33px Poppins;
    letter-spacing: 0px;
    color: #29047D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardheading {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.cardSubheading {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.rows-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width:100%;
}

.columns-container {
    min-width: 64%;
    width:64%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 770px;

}


.tasks {
    width:33%;
    background: #fff;
    border-radius: 5px;
    height: 625px;
    padding: 10px 0px
}

.task-indicator {
    width: 3px;
    height: 40px;
    /* UI Properties */
    border-radius: 0px 3px 3px 0px;
}

.task-priority {
    width: 11px;
    height: 11px;
    border-radius: 12px;
    /* UI Properties */
}

.task-heading {
    font-family: Poppins;
    letter-spacing: 0px;
    color: #354052;
    font-size: 14px;
}

.task-subheading {
    font-family: Poppins;
    font-size: 12px;

    letter-spacing: 0px;
    color: #9E9E9E;
}

.task-priority-text {
    letter-spacing: 0px;
    color: #7E84A3;
    font-family: Poppins;
    font-size: 10px;
    text-transform: capitalize;
}

.task-header {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2D3962;
}

.tasks-bottom {
    display: flex;
    justify-content: center;
    width: '100%';
}

.tasks-bottom-text {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #9249E8;
    cursor: pointer;
    /* border-top: 1px solid #2F57EC; */

}



.sections-list {
    border-left: 1px solid #E1E4EF;
    height: 100%;
    padding-left: 32px;
    overflow-y: auto;
    padding-right: 16px;



}

.scroll {
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;
}

/* .scroll::-webkit-scrollbar {
    width:5px;
}
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius:5px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px red; 
} */

.section-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 16px;
    padding-top: 10px;
}

.section-left {
    width: 60%;
}

.section-right {
    width: 40%;
}

.section-title {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0px;
    color: #2D3962;
    margin-bottom: 6px;
}

.section-text {
    font-size: 11px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 0px;
    color: #87879B;
    margin-bottom: 2px;

}


.task-modal-container {
    background-color: #fff;
    width: 60%;
    margin: 0 auto;
    margin-top: 10%;
    height: 70vh;
    border: none
}

.task-modal-header {
    background: #545282;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 21px;
    color: #FFFFFF;


}

.tasks-title {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.sub-heading {
    font-family: Poppins;
    letter-spacing: 0px;
    color: #354052;
    font-size: 16px;
}


.row-two-top {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    justify-content: space-between;
}

.sections-scroll-box {
    height: 100px;
    width: 100%;
    overflow-x: scroll;
    /* border: 1px solid #0079FA; */
    border-bottom: 1px solid #F2F0FF;
    margin-top: 12px;
    display: flex;

}


.sections-scroll-box-head {
    height: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #62E3D4;

}

.table-label {
    color: '#fff';
    height: 25px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #FFFFFF;
    position: relative;
    width:120px;
    text-align: center;
}

.table-value{
position: absolute;
bottom: -40px;
left:0;
right:0;
letter-spacing: 0px;
color: #354052;
font-family: Poppins;
font-size: 12px;
font-weight: 700;

}
/* // bar graph  */
.bar-chart-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.round-btn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #C8C9D6;
    cursor: pointer;
}

.bar-chart {
    display: flex;
    align-items: center;
    column-gap: 4;
    width: 90%;
    margin-top: 16px;
    overflow-x: auto;
    height: 90%;
    margin: 0 auto;
    scroll-behavior: smooth
}

.bar-pair {
    display: flex;
    align-items: flex-end;
    /* background-color: red; */
    height: 174px;
    margin-right: 70px;
    position: relative;
}

.pair-label {

    font-size: 13px;
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0px;
    color: #87879B;
    position: absolute;
    left:0;
    right: 0;
    bottom: -30px;
    text-align: left;
    width:101px
}

.bar {
    width: 29px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: relative;

}

.bar-label {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2D3962;

    position: absolute;
    top: -20px
}