.add_schools {
  margin: 35px 0px 0px 16px;
  width: 100%;
  height: 87%;
}

.add_school_title {
  margin: 10px 0px;
}

.input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 99%;
  height: 140px;
  margin-top: 30px;
  margin-left: 10px;
}
.input_grid_rc {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 1fr 1fr;
  width: 99%;
  height: 140px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

/* grip inputs - start */
.input_element {
  position: relative;
  width: 95%;
  height: 68%;
}

.input_element input,
.input_element select {
  width: 94%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--primaryColor);
  padding: 0 20px;
}

.input_element label {
  position: absolute;
  left: 15px;
  top: 15px;
  /* font-size: 0.8rem; */
  font-size: 13px;
  /* font-weight: bold; */
  /* color: var(--primaryColor); */
  color: #B5B6B6;
  opacity: 1;
  background: white;
  transition: all 0.2s;
  padding-right: 30px;
}

.input_element:hover label {
  top: -7px;
  /* color: var(--utilityColor); */
  color:#FD8C00;
  padding-right: 0;
}

.form_input_element:not(:placeholder-shown)~label,
.form_input_element:focus~label {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}
/* .form_select_element:not(:placeholder-shown)~label,
.form_select_element:focus~label {
  top: -7px;
  color: var(--utilityColor);
} */

.form_select_element:valid + label,
.form_select_element option:checked  {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.std_cat {
  visibility: hidden;
}

/* grid inputs -end */

.upload_logo_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.upload_logo_container span {
  justify-self: center;
  margin: 1px 14px;
  font-weight: 500;
}

.artical_button_container {
  position: relative;
  left: -170px;
}

.artical_button {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 15px;
}

.artical_button_cancel {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 15px;
}

#button_id {
  float: right;
  background-color: blue;
  font-weight: 400;
  color: var(--backgroundColor);
  position: absolute;
  top: 20px;
}

#button_id:hover {
  top: 18px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

#button_id_cancel {
  background-color: white;
  color: black;
  position: absolute;
  top: 20px;
  font-weight: 400;
  border: 1px solid gray;
  left: -151px;
}

#button_id_cancel:hover {
  top: 18px;
  box-shadow: 0px 3px 5px rgba(76, 76, 76, 0.233);
  background-color: #bdb4b4ee;
  color: white;
}

.add_schools_form {
  background-color: white;
  padding-top: 2px;
  border: 1px solid gray;
  border-radius: 15px;
  width: 99%;
  box-shadow: 0px 5px 5px 0px #80808012;
}

.add_schools_btn {
    height: 80px;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
}

.choosefile_button {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.choosefile_button_container {
  margin: 20px;
}

#choose_button_id {
  background-color: #ff9100;
  color: var(--backgroundColor);
}

.school_list_container {
  
  display: flex;
    justify-content: space-between;
    align-items: center;
  
    margin: 10px 10px 10px 10px;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
    height: 30px;
    padding: 0px 34px 0px 10px;
    border-radius: 22px;
    border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

.magnifying_glass_icon {
  float: right;
  margin-right: 35px;
  font-size: 1.2rem;
  color: var(--primaryColor);
}

.error_box {
  color: red;
  align-self: center;
  font-size: 0.9rem;
}

/* logo image -start */
.image_item {
  position: absolute;
  /* top: 20px; */
  left: 195px;
  display: flex;
  align-items: center;
}

.image_item__btn_wrapper {
  margin: 0px 20px;
}

.image_item__btn_wrapper p {
  font-size: 0.8rem;
}

.image_item__btn_wrapper button {
  background: none;
  border: none;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

/* logo inage - end  */

.school_list_table {
  background: white;
  height: 50%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  width: 82vw;
  overflow: auto;
  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari and Opera */
.school_list_table::-webkit-scrollbar {
  display: none;
}

.table {
  width: 100%;
  /* height: 80%; */
  text-align: center;
  border-radius: 2px;
  border-radius: 2px;
}

.table,
.table th,
.table td {
  border-bottom: 1px solid rgba(141, 137, 137, 0.281);
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 5px;
  font-size: 0.8rem;
}

.table thead {
  background-color: var(--headerColor);
  position: sticky;
  top: 0;
  color: blue;
  z-index: 1;
}

.table th i {
  font-size: 0.8rem;
}

.table tr {
  height: 47px;
}

/* tabel row hover effect -start */
.data_row:hover {
  box-shadow: 1px 1px 1px 1px #888888;
  background-color: #f8f4fc;
}

.tbody>tr:hover>td:first-child {
  /* border-left: 8px solid blue; */
}

.tbody>tr:hover>td:last-child input {
  background-color: var(--utilityColor);
  color:white;
}

.tbody tr:hover td:nth-child(2){
  color: #ff9100;
}

.name{
    white-space: nowrap;
      width: 7rem;
      overflow: clip;
      text-overflow: clip;
      /* margin: 0px 10px; */
      text-align: left;
}

/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.active_slider {
  background-color: orange;
}

input:focus+.active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked+.active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */
/* tabel row hover effect -end */

.create_branch_btn {
  width: 70%;
  min-width: fit-content;
  border: 2px solid rgba(128, 128, 128, 0.233);
  border-radius: 15px;
  padding: 5px;
  background: none;
}







/* upload input starts */
.upload_logo_container>span {
    /* padding: 0px 10px 10px 20px; */
      font-size: 0.9rem;
      display: flex;
      grid-gap: 1rem;
      gap: 1rem;
}
.custom_file_container>span {
  position: relative;
  top: 0rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}
.custom_file_container>span >span > a {
 cursor: pointer;
}

.custom_file_input {
  width: 8rem;
  margin: -10px 0px 0px 15px;
  color: transparent;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom_file_input::-webkit-textfield-decoration-container {
  visibility: hidden;
}


.custom_file_input::before {
  content: 'Choose File';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 18px;
  padding: 11px 30px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}



.custom_file_input:hover::before {
  border-color: black;
}

.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* upload input ends */

/*==========pagination start==========*/



.pagination {

  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  width: 82vw;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;

}

.rows>select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/

/*Input Label Choose File Btn Style*/
.chooseFile_btn_wrapper {
  position: relative;
  margin-bottom: 40px;
  left: 2px;
  /* right: 18px; */
}

.chooseFile_btn_wrapper > img {
  position: absolute;
  top: 12px;
  left: 14px;
}

.chooseFile_btn {
  font-size: 13px;
  color: white;
  background-color: #FD8C00;
  border-radius: 25px;
  width: 123px;
  height: 37px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

/*Input Label Choose File Btn Style Hover Effect*/
.chooseFile_btn_wrapper {
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}


.chooseFile_btn_wrapper:hover, .chooseFile_btn_wrapper:focus, .chooseFile_btn_wrapper:active {
  bottom: 1px;
  box-shadow: 0 11px 11px -10px #FD8C0063;
}

/* "Add Teacher Files" & "Add Student Files" Input Label Choose File Btn Style*/
.chooseFile_btn_wrapper_ATF {
  position: relative;
  margin-bottom: 40px;
  left: 2px;
  /* right: 18px; */
}

.chooseFile_btn_wrapper_ATF > img {
  position: absolute;
  top: 10px;
  left: 14px;
}

.chooseFile_btn_ATF{
  font-size: 12px;
  color: white;
  background-color: #FD8C00;
  border-radius: 25px;
  width: 118px;
  height: 32px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

/*Input Label Choose File Btn Style Hover Effect*/
.chooseFile_btn_wrapper_ATF {
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.chooseFile_btn_wrapper_ATF:hover, .chooseFile_btn_wrapper_ATF:focus, .chooseFile_btn_wrapper_ATF:active {
  bottom: 1px;
  box-shadow: 0 11px 11px -10px #FD8C0063;
}