.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container > div {
  background-color: white;
  padding: 10px 0 0 20px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  /*width: 25%;*/
  width: 356px;
  height: 361px;
}

.main_content_head {
  display: flex;
  justify-content: space-between;
  font-size: large;
  padding: 10px 0px;
}

.main_content_box_head {
  font-size: initial;
  padding: 15px 0px 25px 0px;
  border-top: 1px solid var(--fadedColor);
  color: #354052;
}

.main_content_box {
  display: grid;
  /* grid-template-columns: auto auto; */
  width: 100%;
  grid-gap: 25px;
  gap: 25px;
  margin-bottom: 40px;
}

.modal_buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  padding-right: 30px;
}

.choosefile_button_2 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  /* padding: 16px; */
  border-radius: 35px;
  width: 160px;
  height: 40px;

  font-weight: bold;
  border: none;
  cursor: pointer;
}

#choose_button_id_2 {
  background-color: #ff9100;
  color: white;
}

.choosefile_button_1 {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 14px;
  border-radius: 35px;
  width: 175px;
  font-weight: bold;
  border: 0.1px solid;
  cursor: pointer;
}

#choose_button_id_1 {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal {
  /* display: none; */
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/

.input_element input,
.input_element select {
  width: 90%;

  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 15px 0 12px 16px;
  /* margin-top: -11px; */
}

.input_element {
  position: relative;
  /* width: 95%; */
  width: 80%;
  /* height: 68%; */
  height: 78%;
}

.input_element label {
  position: absolute;
  left: 15px;
  top: 16px;
  /* top: -9px; */
  font-size: 13px;
  /* font-size: 0.8rem; */
  /* font-weight: bold; */
  color: #b5b6b6;
  opacity: 1;
  background: white;
  transition: all 0.2s;
  /* color: #ff9100; */
}

.input_element:hover label {
  top: -7px;
  color: #fd8c00;
  padding-right: 0;
}

.form_select_element:valid + label,
.form_select_element option:checked {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.form_select_element {
  width: 270px;
  height: 48px;
}

.std_cat {
  visibility: hidden;
}
