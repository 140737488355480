.studyHeading.borderLeft {
  border-left: 6px solid #ffcf09;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #1912bf;
}

.listItemIcon {
  display: flex;
  margin-right: 10px;
}
