/*-----------------------------| Mind-Map Pdf Styles Starts Here |------------------------------*/
.Mind_map_pdf {
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin: 20px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 0 10px 0 #0000000f;
}

/*----------| Chapter Heading Style |------------*/
.Chap_Name {
  margin: 20px 0 10px 20px;
  opacity: 0.9;
  font-weight: bolder;
}

/*-------------| Underline Style |---------------*/
.U_Line {
  width: 100%;
  margin-bottom: 20px;
  border-top: 1px solid #707070;
  opacity: 0.4;
}

/*-----------------| Pdf Style |-----------------*/
.Mind_map_pdf iframe {
  width: 100%;
  height: 740px;
  border-radius: 6px;
}
/*-----------------------------| Mind-Map Pdf Styles Ends Here |------------------------------*/
