.dashboard_placeholder {
  display: none;
}

.add_schools {
  margin: 55px 15px 0px 15px;
  width: 100%;
  height: 90%;
}

.Add_school {
  margin: 5px 5px;
}

.grid_container {
  /* display: grid; */
  /* grid-template-columns: 7rem 55rem; */
  display: flex;
}

.grid_item {
  background-color: rgba(255, 255, 255, 0.8);
  grid-area: span;
  grid-row-start: auto;
  box-shadow: 1px 1px var(--utilityColor2);
  padding: 15px;
  font-size: 18px;
  text-align: -webkit-left;
  margin-right: 4px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: 3.1px solid rgba(128, 128, 128, 0.233);
  border-right: 3.1px solid rgba(128, 128, 128, 0.233);
  border-radius: 10px;
  margin: 2px 5px 0 5px;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.grid_container > div > p {
  font-weight: 500;
  padding-left: 10px;
  padding: 5px;
}

.column {
  /* width: 50%; */
  width: 15%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.17vw;
}

.column img{
  margin-top: 20px;
}

.link_icon_item1,
.link_icon_item2 {
  font-size: 1.8rem;
}

.link_icon_item1 {
  color: #ff9100;
}

.link_icon_item2 {
  color: blue;
}

.school_data {
  display: flex;
  flex-direction: column;
  font-size:15px;
 }

.school_data span:first-child {
  font-weight: bold;
}

.school_data span:last-child {
  color: rgba(128, 128, 128, 0.753);
}

.table_school_list {
  width: 100%;
  text-align: center;
  box-shadow: 3px 3px #888888;
  border-radius: 10px;
}

.school_list_container {
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0px 10px 10px;
}

.school_list_btn_container {
  display: flex;
  align-items: center;
  width: 440px;
  position: relative;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 32px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  /* color: rgba(128, 128, 128, 0.753); */
  color:#7A7A7A;
  opacity: 60%;
  font-size: 13px;
  padding: 12px;
}

.school_list_search_container span {
  position: absolute;
  /* top: 5px; */
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

/* add school button after search bar start here*/
.add_school_button {
  margin-right: 4%;
  height: 30px;
  width: 130px;
  cursor: pointer;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-radius: 22px;
  background-color: rgba(0, 128, 0, 0.698);
  color: rgb(255, 255, 255);
  font-weight: 500;
  position: absolute;
  left: 277px;
  top: 0px;
}

/* Hover over button */
.add_school_button:hover {
  top: -2px;
  box-shadow: 0px 3px 5px rgba(0, 128, 0, 0.5);
}

/* table of RegisteredSchool start*/
.school_list {
  margin-left: 0px 12px;
}

.main_container {
  height: 92%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.school_list_table {
  background: white;
  height: 70%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  width: 82vw;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari and Opera */
.school_list_table::-webkit-scrollbar {
  display: none;
}

.table {
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 2px;
  border-radius: 2px;
}

.table,
.table th,
.table td {
  border-bottom: 1px solid rgba(141, 137, 137, 0.281);
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 5px;
  font-size: 0.9rem;
  font-weight: 100;
}

.table thead {
  background-color: var(--headerColor);
  position: sticky;
  top: 0;
  color: blue;
  z-index: 1;
}

.table th i {
  font-size: 0.8rem;
}

.table tr {
  height: 47px;
}

/* tabel row hover effect -start */
.data_row:hover {
  box-shadow: 3px 3px 3px 3px #888888;
  background-color: #FFFFFF !important;
}

.table tr:hover td:first-child {
  /* border-left: 7px solid blue; */
}
.table_h tr:hover td:first-child {
  border-left: 0px solid blue;
}
.table_h tr{
  position: relative;
}

.table_h tr:hover td:first-child::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: blue;
  z-index: 1;
}

.table td {
  color: #354052;
}
.table_h td{
letter-spacing: var(--unnamed-character-spacing-0);
text-align: left;
font: normal normal normal 13px/20px Poppins;
letter-spacing: 0px;
color: #354052;
opacity: 1;
}

.table tr:hover td:last-child input {
  background-color: var(--utilityColor);
  color: white;
}
.table_h tr td:hover{
cursor: pointer;
}

.table tr:hover td:nth-child(2){
color: #ff9100;
}
.table_h tr:hover td:nth-child(6){
color: #ff9100;
}
.table_h tr:nth-last-child():hover{
color: #ff9100;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;

  /* margin: 0px 10px; */
  text-align: left;
}

/* tabel row hover effect -end */
/*table last column Create branch button start*/
.create_branch input {
  width: 85%;
  height: 85%;
  border-radius: 22px;
}

.create_branch_btn {
  min-width: fit-content;
  border: 2px solid rgba(128, 128, 128, 0.233);
  padding: 5px;
  background: none;
}

/*table last column Create branch button end*/
/*table of Registered School end*/

/*Dropdown button Start*/
.dropbtn {
  color: white;
  padding: 25px;
  font-size: 16px;
  border: none;
  position: static;
  cursor: pointer;
  background-color: #1f1fd5f5;
  border-radius: 6px;
  margin-top: 5px;
  /* height: 70px; */
  height: 90px;
  align-items: center;
  width: 121px;
}

.dropbtn .checked ::after {
  color: white;
  padding: 31px 6px 23px 8px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  background-color: #1f1fd5f5;
  border-radius: 6px;
  margin-top: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  display: block;
  z-index: 2;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  border-radius: 10px;
  background-color: white;
  margin: 4px 0px 0px 0px;
}

.dropdown_content .checked {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
}

.text {
  margin-left: 1.5rem;
}

.dropbtn i {
  margin: 4px 2px 0px 13px;
}

/* Dropdown Button end here*/

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .active_slider {
  background-color: orange;
}

input:focus + .active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */
/* =============== blue  checkbox starts============= */

.checked {
  display: flex;
  justify-content: start;
  position: relative;
  align-items: center;
}

.checked > wrapper {
  height: 200px;
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dropdown_content input {
  position: absolute;
  display: flex;
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 0px 3px;
  color: blue;
}

.checked > input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f8f4fc;
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid;
  box-shadow: 2px 1px 6px 1px;
  color: blue;
  position: relative;
}

.checked > label {
  color: gray;
  font-size: 0.9rem;
  font-family: sans-serif;
  font-weight: 500;
  padding: 0px;
}

.checked > input[type="checkbox"]:checked::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\2714";
  font-size: 15px;
  color: white;
  display: none;
}

.checked > input[type="checkbox"]:hover {
  background-color: #f8f4fc;
}

.checked > input[type="checkbox"]:checked {
  background-color: blue;
}

.checked > input[type="checkbox"]:checked::after {
  display: block;
}

/* ============blue checkbox ends================= */

/*==========pagination start==========*/

.pagination {
  background: white;
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  width: 82vw;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*==========pagination End==========*/
