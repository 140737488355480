* {
	box-sizing: border-box;
	font-family: Poppins, sans-serif;
	margin: 0;
	padding: 0;
}

.flex {
	display: flex;
	flex-direction: row;
}

.chapter_container {
	/* min-width: 1385px; */
	width: 100%;
}

.concept:hover {
	color: blue !important;
}

.table_head {
	font-family: "poppins" !important;
	font-weight: 600;
	border-top: 1px solid darkgray !important;
	border-right: 1px solid darkgray !important;
	color: #0000ff !important;
	font-size: 1vw !important;
	text-align: left !important;
	border-bottom: 1px solid darkgray !important;
}

.table_head:last-child {
	border-top: 1px solid darkgray !important;
	border-right: none !important;
}

tbody tr:last-child td {
	border-bottom: 1px solid darkgray !important;
}

tbody tr:hover {
	/* border-bottom: none !important;
	box-shadow: none !important; */
}

/* tr:first-child .table_cell:first-child,
tr:first-child .table_cell:nth-child(2) {
  background: #c9e9fd !important;
} */

.table_cell:last-child {
	border-right: none !important;
}

.table_cell:first-child {
	border-left: none !important;
}

.table_cell {
	font-family: "poppins" !important;
	font-size: 1vw !important;
	/* text-align: left !important; */
	vertical-align: baseline !important;
	padding: 0px 0px 0px 15px !important;
	color: #354052 !important;
	padding-left: "16px !important";
}

.select_input {
	/* color: #B5B6B6 !important; */
	font: normal normal normal 1vw/21px Poppins !important;
	opacity: 0.8;
}

.select_menu {
	color: #354052 !important;
	font: normal normal normal 0.9vw/22px Poppins !important;
	opacity: 0.7;
}

.select_selected {
	color: #1b1b1b !important;
	font: normal normal normal 1vw/21px Poppins !important;
	opacity: 0.8;
}

.cell {
	height: 40px;
	align-items: center;
	display: flex;
	padding: 5px 20px;
	background-color: #c9e9fd;
}

.sub_cell {
	height: 40px;
	align-items: center;
	display: flex;
	padding: 5px 35px;
	background-color: #f5f5f5;
	border-top: 1px solid lightgray;
}

.root_cell {
	height: 40px;
	align-items: center;
	display: flex;
	padding: 5px 35px;
	border-top: 1px solid lightgray;
}

/* pagination */
.pagination {
	background: white;
	box-shadow: 1px 2px 2px grey;
	border-radius: 5px;
	/* width: 100%; */
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 10px 0px 10px;
}

.pagination a {
	color: #888888;
	padding: 3px 6px;
	text-decoration: none;
	transition: background-color 0.3s;
}

.pagination a span {
	font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
	background-color: dodgerblue;
	color: white;
	float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
	background-color: #ddd;
}

.pagination_active:hover:not(.active) {
	background-color: blue;
}

.pagination_active {
	color: black;
	border: none;
	padding: 3px 6px;
	text-decoration: none;
	transition: background-color 0.3s;
}

.rows {
	font-size: 0.9rem;
	color: #888888;
	padding-left: 10px;
}

.rows > select {
	text-align: center;
	margin: 5px;
	height: 25px;
	color: #888888;
	border-color: #888888;
	border-radius: 5px;
}

/* btns  */

.green_btn {
	color: #fff;
	background-color: #4caf50;
	border-radius: 150px;
	/* border-color:#4CAF50; */
	/* padding: 8px 18px; */
	padding: 0.1vw 1vw;
	font-size: 1vw;
	border: none;

	/* width:140px;
    height : 43px; */
}

.green_btn:hover {
	color: #fff;
	background-color: #4caf50;
	/* border: 0; */
	top: -2;
	box-shadow: 0px 3px 5px #78be20;
}

.red_btn {
	color: #fff;
	background-color: #ef0f0f;
	border-radius: 150px;
	border-color: #ef0f0f;
	padding: 0.1vw 0.5vw;
	font-size: 1vw;
}

.red_btn:hover {
	color: #fff;
	background-color: #ef0f0f;
	/* border: 0; */
	top: -2;
	box-shadow: 0px 3px 5px #e57373;
}

.orange_btn {
	padding: 7px 14px;
	margin: auto 5px;
	color: #fd8c00;
	border: 1px solid #fd8c00;
	border-radius: 6px;
	background-color: transparent;
}

.orange_btn:hover {
	color: white;
	background-color: #fd8c00;
}

.white_btn {
	color: #7a7a7a;
	background-color: white;
	border-radius: 150px;
	border: 1px solid #7a7a7a;
	width: 12vw;
	padding: 5px;
	min-width: 100px;
	font-size: 1vw;
	margin: 3px auto;
}

.white_btn:hover {
	color: black;
	background-color: white;
	border: 1px solid #7a7a7a;
	top: -2;
	box-shadow: 0px 3px 5px grey;
}

.blue_btn {
	color: #fff;
	width : 100px;
    height : 32px;
	padding: 7px;
	background-color: #403e75;
	border-radius: 150px;
	border-color: grey;
	height: 50px;
	cursor: pointer;
}

.blue_btn:hover {
	color: #fff;
	background-color: #403e75;
	/* border: 0; */
	top: -2;
	box-shadow: 0px 3px 5px #403e75;
}

.blue_btn:disabled {
	opacity: 0.5;
}

.blueWhite_btn {
	color: blue;
	width: 175px;
	padding: 7px;
	background-color: #fff;
	border-radius: 150px;
	border-color: blue;
	height: 50px;
	cursor: pointer;
}

.blueWhite_btn:hover {
	color: #fff;
	background-color: blue;
	/* border: 0; */
	top: -2;
	box-shadow: 0px 3px 5px blue;
}

/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 29px;
}

.active_switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.active_slider {
	position: absolute;
	cursor: pointer;
	top: 10px;
	left: 0;
	right: 0;
	bottom: 0;
	width: 46px;
	border-radius: 5px;
	height: 10px;

	background-color: #d9dcfe;
	transition: 0.4s;
}

.active_slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 14px;
	right: 25px;
	border-radius: 15px;
	bottom: 0;
	top: -6px;

	border: 3px solid #d9dcfe;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .active_slider {
	background-color: orange;
}

input:focus + .active_slider {
	box-shadow: 0 0 1px #d9dcfe;
}

input:checked + .active_slider:before {
	-webkit-transform: scale3d;
	-ms-transform: translateX(26px);
	transform: translateX(25px);
	border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 0%;
}

/* Edit Sub & Rroot Concepts Hover Style */
.concept_list_itm li {
	border: 1px solid #f7f7f7;
	background: transparent linear-gradient(180deg, #fcfcfc 0%, #f7f7f7 100%) 0%
		0% no-repeat padding-box;
	opacity: 1;
}

.concept_list_itm li:hover {
	color: #001af3 !important;
	border: 1px solid #f7f7f7;
	background: transparent linear-gradient(180deg, #fcfcfc 0%, #e7f1fa 100%) 0%
		0% no-repeat padding-box;
	opacity: 1;
}

.SubConcept_list_itm li {
	border: 1px solid #f7f7f7;
	background: transparent linear-gradient(180deg, #fcfcfc 0%, #f7f7f7 100%) 0%
		0% no-repeat padding-box;
	opacity: 1;
}

.SubConcept_list_itm li:hover {
	color: #001af3 !important;
	border: 1px solid #f7f7f7;
	background: transparent linear-gradient(180deg, #fcfcfc 0%, #e7f1fa 100%) 0%
		0% no-repeat padding-box;
	opacity: 1;
}

.RootConcept_list_itm li {
	border: 1px solid #f7f7f7;
	background: transparent linear-gradient(180deg, #fcfcfc 0%, #e7f1fa 100%) 0%
		0% no-repeat padding-box;
	opacity: 1;
}

/* .RootConcept_list_itm li:hover{
  color:#001AF3 !important;
  border: 1px solid #F7F7F7;
  background: transparent linear-gradient(180deg, #FCFCFC 0%, #E7F1FA 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
} */
