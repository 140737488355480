
  
  article.article_36 {
    width: 83vw;
    position: absolute;
    top: 38px;
    /* height: 100%; */
    background-color: #f8f4fc;
    outline: 3px solid #f8f4fc;
  }
  /* .Grid.school-name-branch  starts */
  
  .Grid_school_name_branch_36 {
    display: flex;
    justify-content: space-between;
    padding: 13px; /* background: rgba(211, 211, 211, 0.4); */
  }
  
  button.btn_branch_name_36 {
    width: 220px;
    height: 35px;
    border-radius: 15px;
    background-color: blue;
    color: #f8f4fc;
    border: none;
  }
  
  i.fa-solid.fa-house-user {
    padding-right: 10px;
  }
  
  i.fa-solid.fa-chevron-down {
    padding-left: 10px;
  }
  .school_name_36 {
    FONT-WEIGHT: 550;
    font-size: 0.9rem;
    color: #000000b8;
    padding-top: 10px;
    margin-left: 15px;
  }
  
  /* .Grid.school-name-branch ends here */
  /* grid-container-header-plan starts from here */
  
  .grid_container_header_plan_36 {
    display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 83vw;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
  }
  .grid_container_header_plan_36 > div {
    font-size: 0.9rem;
    box-shadow: 0.1px  0.1px gray;
    padding-top: 9px;
    background-color: white;
  }
  
  .item_plan6_36 {

    color: orange;
    border-bottom: 4px solid orange;
  }
  .grid_container_header_plan_36 > div:hover {
    color: orange;
    border-bottom: 4px solid orange;
  }
  
  /* ================token grid starts===================== */

  .schoollist_32 {
        padding: 10px 5px;
          display: flex;
          background-color: white;
          justify-content: space-between;
          margin: 0px 10px 0px 10px;
          align-items: center;
          text-align: center;
}
/* .section_list_table {
          
  margin: 0px 10px 0px 10px;
  overflow: auto;
  height: 68%;
  background: white;
} */

.school_list_btn_container_32 {
display: flex;
align-items: center;
/* width: 438px; */
position: relative;
padding-left: 690px;
/* top: -19px; */
}

    .teacher_list_teacherid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      margin: 0px 10px;
      padding: 7px 10px;
    }
  
    .school_list_search_container {
      width: 250px;
      position: relative;
    }
  
    .school_list_search_container input {
      width: 100%;
      height: 30px;
      padding: 0px 34px 0px 10px;
      border-radius: 22px;
      border: 0.1px solid rgba(128, 128, 128, 0.233);
    }
  
    .school_list_search_container input::placeholder {
      color: rgba(128, 128, 128, 0.753);
    }
  
    .school_list_search_container span {
      position: absolute;
      top: 5px;
      left: 220px;
      color: var(--utilityColor);
      font-size: 1.2rem;
      cursor: pointer;
    }

.school_list_search_container_32 {
display: flex;
gap: 25px;
height: 36px;
}

.school_list_search_container_32 input::placeholder {
color: rgba(128, 128, 128, 0.753);
}

.search_box > span {
    position: relative;
      bottom: 1.5rem;
      float: right;
      margin: 0px 10px;
      color: var(--utilityColor);
      font-size: 1.2rem;
}

.add_school_button_32:hover {
top: -2px;
box-shadow: 0px 3px 5px rgba(0, 128, 0, 0.5);
}

input.add_school_button_32 {
    background-color: rgba(0, 128, 0, 0.698);
      position: relative;
      width: 151px;
      padding: 5px;
      border-radius: 40px;
      color: white;
      border: none;
      cursor: pointer;
}
  .create_teacher_36 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    background: white;
    border-left: 10px solid #f8f4fc;
    border-right: 10px solid #f8f4fc;
    outline: 3px solid #f8f4fc;
    align-items: center;
    gap: 20px;
  }
  
  .teacher1_36 {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    gap: 5px;
  }
  
  .teacher2_36 {
    grid-column: 4;
    grid-row: 1 / span 2;
    display: grid;
    grid-template-rows: auto auto auto;
  }
  
  .create_HOD_box1_36 {
    display: flex;
    gap: 30px;
  }
  
  .input_element_teacher_36 {
    position: relative;
    width: 95%;
    height: 68%;
  }
  .input_element_teacher_36 input,
  .input_element_teacher_36 select {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 2px solid var(--primaryColor);
    padding: 0 20px;
  }
  
  .input_element_teacher_36 label {
    position: absolute;
    left: 15px;
    top: 19px;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--primaryColor);
    background: white;
    transition: all 0.2s;
  }
  .input_element_teacher_36:hover label {
    top: -7px;
    color: var(--utilityColor);
  }
  .form_input_element_teacher_36:not(:placeholder-shown) ~ label,
  .form_input_element_teacher_36:focus ~ label {
    top: -7px;
    color: var(--utilityColor);
  }
  
  p.remove_36 {
    font-size: x-small;
    padding-bottom: 10px;
  }
  
  #submit_36 {
    width: 130px;
    height: 30px;
    border-radius: 20px;
    background-color: #69ca69;
    padding: 10px;
    border: none;
  }
  
  .teacher5_36 {
    padding-right: 100px;
    padding-top: 18px;
    padding-bottom: 13px;
  }
  
  .create_button_teacher_36 {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    color: white;
    background: blue;
    float: right;
    position: relative;
    right: 44px;
  }
  
  .create_button_teacher_36:hover {
    top: 2px;
    box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
  }
  
  .btn_branch_name_36 {
    width: 220px;
    height: 35px;
    border-radius: 15px;
    background-color: blue;
    color: #f8f4fc;
    border: none;
  }
  
  .grid_container_col {
    display: grid;
    column-gap: 50px;
    grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
    background-color: white;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .grid_item_col1 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 15px;
  
    box-shadow: 0px 0px 2px 0px grey;
    text-align: left;
    color: orange;
  }
  
  .grid_item_col2 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 15px;
  
    box-shadow: 0px 0px 2px 0px grey;
    text-align: left;
    color: orange;
  }
  
  .input_element_36 input,
  .input_element_36 select {
    width: 100%;
    /* height: 100%; */
    border-radius: 8px;
    border: 1px solid var(--primaryColor);
    padding: 14px 0 11px 16px;
    /* margin-top: -11px; */
  }
  
  .input_element_36 {
    position: relative;
    width: 95%;
    height: 68%;
    top: 10px;
  }
  
  .input_element_36 label {
    position: absolute;
    left: 15px;
    top: 15px;
    /* font-size: 0.8rem; */
    font-size: 13px;
    /* font-weight: bold; */
    /* color: var(--primaryColor); */
    color: #B5B6B6;
    opacity: 1;
    background: white;
    transition: all 0.2s;
    padding-right: 25px;
  }
  
  .input_element_36:hover label {
    /* top: -17px; */
    top: -7px;
    /* color: var(--utilityColor); */
    color:#FD8C00;
    padding-right: 0;
  }
  /* .form_select_element_36:not(:placeholder-shown) ~ label,
  .form_select_element_36:focus ~ label {
    top: -7px;
    color: var(--utilityColor);
  } */

.form_select_element_36:valid + label,
.form_select_element_36 option:checked  {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.std_cat {
  visibility: hidden;
} 
  
  .grid_container_row {
    display: grid;
  }
  .grid_item_row {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
  
  .grid_container_row_1 {
    display: grid;
  }
  
  .grid_item_row_1 {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
  
  
  
  .table_section {
    width: 81vw;
    text-align: center;
  }
  
  .table_section td {
    border-collapse: collapse;
    color: var(--primaryColor);
  }
  
  .table_section,
  .table_section th,
  .table_section td {
    border-collapse: collapse;
    
  }
  
  .table_section th,
  .table_section td {
    padding: 5px;
    /* width: 10px; */
    font-weight: normal;
  }
  .table_section thead {
    background-color: var(--headerColor);
    color: blue;
    
  }
 
  td.data_36 {
    color: gray;
    font-size: 0.9rem;
  }
  td.data1_36 {
    color: gray;
    font-size: 0.9rem;
  }
  td.data2_36 {
    color: gray;
    font-size: 0.9rem;
  }
  .table_section th i {
    font-size: 0.9rem;
    position: relative;
    left: 4px;
  }
  .table_section tr {
    height: 47px;
    border: 1px groove #8080802e;
    font-size: 0.9rem;
  }
  .data_row:hover td:first-child {
    border-left: 10px solid blue;
  }
  .data_row  {
    border-left: 10px solid white ;
  }

                .name {
                  white-space: nowrap;
                  width: 7rem;
                  overflow: scroll;
                  text-overflow: clip;
                  margin: 0px -23px 0px 10px;
                  text-align: left;
                }
        
                .userId {
                  white-space: nowrap;
                  width: 7rem;
                  overflow: scroll;
                  text-overflow: clip;
                  margin: 0px -23px 0px 10px;
                  /* text-align: left; */
                }

  #Email{
    width: 10%;
  }
  #Email_data{
    text-align: left;
  }
  #table_36{
    background-color: white;
  }
 
  
  .schoollist_36 {
    padding: 20px;
    background-color: white;
    top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
  }
  .section-list-table {
    margin-top: -32px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  tbody tr:hover td.data1_36 {
    color: orange;
  }
  
  tbody tr:hover td.data2_36 {
    color: orange;
  }
  
  tbody tr:hover {
    box-shadow: 2px 2px gray;
  }
  
  .section_list_table {
    margin: 0px 10px 0px 10px;
    height: 363px;
    overflow: auto;
    background-color: white;
  }
  
  
  .school_list_btn_container_36 {
    display: flex;
    align-items: center;
    /* width: 438px; */
    position: relative;
    padding-left: 690px;
    top: -19px;
  }
  .school_list_search_container_36 {
    width: 250px;
    position: relative;
    
    float: right;
    top: -6px;
  }
  .school_list_search_container_36 input {
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    border-radius: 22px;
    border: 0.1px solid rgba(128, 128, 128, 0.233);
  }
  .school_list_search_container_36 input::placeholder {
    color: rgba(128, 128, 128, 0.753);
  }
  
    .school_list_search_container_32 > span {
    position: relative;
      bottom: 1.5rem;
      color: var(--utilityColor);
      font-size: 1.2rem;
      float: right;
      margin: 0px 10px;
  }
  
  .grid_item_36 {
        background-color: rgba(255, 255, 255, 0.8);
          grid-row-start: auto;
          box-shadow: 1px 1px var(--utilityColor);
          padding: 15px;
          font-size: 15px;
          text-align: -webkit-left;
          border: 0.1px solid rgba(128, 128, 128, 0.233);
          border-radius: 7px;
          margin: 0px 10px 0px 10px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          gap: 20px;
  }
  .column_36 {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .link_icon_item1_36 {
    font-size: 2rem;
    color: orange;
  }
  .link_icon_item_36 {
    color: #ff9100;
  }
  
  .school_data_36 {
    display: flex;
    flex-direction: column;
  }
  .school_data_36 span:first-child {
    font-weight: bold;
  }
  .school_data_36 span:last-child {
    color: rgba(128, 128, 128, 0.753);
  }
  
  /* .input_element_36 {
      position: relative;
      width: 95%;
      height: 68%;
    } */
    /* .input_element_36 input,
    .input_element_36 select {
      width: 95%;
      
      padding: 12px 8px;
      border-radius: 4px;
      border: 1px solid gray;
      margin-left: 0px;
  } */
    
    
    /* .input_element_36 label {
      position: absolute;
      left: 10px;
      top: 6px;
      font-size: 0.8rem;
      font-weight: bold;
      color: var(--primaryColor);
      background: white;
      transition: all 0.2s;
      top: -17px;
      color: var(--utilityColor);
    } */
      /* padding-left: 76px;
    }
    .input_element_36:hover label {
      top: -7px;
      color: var(--utilityColor);
    }*/
   
.add_school_button_36:hover {
  top: -2px;
  box-shadow: 0px 3px 5px rgba(0, 128, 0, 0.5);
}

input.add_school_button_36 {
    width: 50%;
    background-color: rgba(0, 128, 0, 0.698);
    position: relative;
    margin-left: 263px;
    top: -30px;
}

/* Pagination links starts here */
.pagination {
  align-items: center;

  border: 1.1px solid rgba(128, 128, 128, 0.233);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background: white;
  height: 35px;
  margin: 0px 10px 0px 10px;
  border-radius: 4px;
}

.pagination a {
  color: #888888;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;

}

.rows>select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/* Pagination links  ends here */