.dropDowns {
  display: flex;
  margin: 50px 0 0 20px;
}

.Heading_div {
  margin: 12px 0 0 30px;
}

.Heading p {
  font-size: 1vw;
  color: #1b1b1b;
  font-weight: bolder;
}

/*---------------------------| Quiz Cards Styles Starts Here |---------------------------*/
.Quiz_Cards_Wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 45px;
  margin: 70px 0 30px 90px;
  row-gap: 45px;
  user-select: none;
  padding-bottom: 50px;
}

/*---------------------------| Quiz Card-1 Style Starts Here |---------------------------*/
.Quiz_Card_1 {
  /* on 8/12 */
  /* flex-basis: calc(28% - 8px);
  border: 1px solid #5ab0de;
  width: 100%;
  height: auto;
  border-radius: 2px;
  position: relative; */

  flex-basis: calc(28% - 8px);
  /* border: 1px solid #5ab0de; */
  width: 100%;
  height: fit-content;
  border-radius: 2px;
  position: relative;
}

.Card_Mask {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 16px #00000029;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 2;
  cursor: not-allowed;
  user-select: none;
}

.Quiz_Tittle_Box {
  border: 1px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Quiz_Tittle_Box p {
  font-size: 1vw;
  font-weight: bold;
  color: #ff7f00;
  word-wrap: break-word;
  max-width: 300px;
}

.Quiz_Tittle_Box p:nth-child(2) {
  font-size: 12px;
  font-weight: bold;
  color: #4e4e4e;
  word-wrap: break-word;
  max-width: 300px;
}

.Quiz_Box_1 {
  background-color: #b9dcff;
  width: 331px;
  height: 113px;
  /* padding: 20px; */
}

.Quiz_Box_1_Practice {
  background-color: #8ed4d3;
  width: 331px;
  height: 113px;
  /* padding: 20px; */
}

.Quiz_Box_Detail_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Quiz_Box_Detail_1 p {
  font-size: 0.9vw;
  color: #333333;
  opacity: 0.9;
}



.Quiz_Box_2 {
  background-color: #1348ad;
  padding: 25px;
}

.Quiz_Box_2_Practice {
  background-color: #3ba5a3;
  padding: 25px;
}

.Quiz_Box_Detail_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Quiz_Box_Detail_2 p {
  font-size: 0.9vw;
  color: #ffffff;
  opacity: 0.7;
}

.Quiz_Box_Detail_2 .percentage_val {
  margin-left: 63px;
}

.Quiz_Box_3 {
  background-color: #06206c;
  padding: 14px;
}

.Quiz_Box_3_Practice {
  background-color: #23706f;
  padding: 14px;
}

.Quiz_Box_3 p {
  text-align: center;
  font-size: 0.9vw;
  color: #ffffff;
  opacity: 0.8;
}

.Quiz_Box_3_Practice p {
  text-align: center;
  font-size: 0.9vw;
  color: #ffffff;
  opacity: 0.8;
}

.Quiz_Box_4 {
  display: flex;
  padding: 5px;
}

.lock_icon {
  margin: 5px 15px 0 10px;
}

.info_icon {
  margin: 4px 0 0 0;
}

.Quiz_Box_4 button {
  font-size: 0.9vw;
  padding: 2px 20px 2px 20px;
  border-radius: 15px;
  color: #f4f4f4;
  background-color: #fd8c00;
  border: 1px solid #fd8c00;
  margin: 5px 0 0 50px;
}

.Time_btns_div {
  /* width: 331px;
  height: 45px;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  column-gap: 5px; */
  /* commented on 8/12 */
  width: 331px;
  /* height: 45px; */
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  /* width: fit-content; */
  /* background: #d9dcfe; */
  padding: 10px 0;
  column-gap: 5px;
  justify-content: center;
}

.time-btn-item {
  box-sizing: border-box;
  max-width: calc(10% - 20px);
  /* margin: 10px; */
  /* width: 100%; */
  margin-bottom: 2px;
}

.Time_Btn_Box_info_icon {
  margin: 8px 0 0 5px;
}

.time_btn1 {
  font-size: 13px;
  border-radius: 15px;
  color: #f4f4f4;
  background-color: #fd8c00;
  border: 1px solid #fd8c00;
  width: 120px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.time_btn2 {
  font-size: 13px;
  border-radius: 15px;
  color: #f4f4f4;
  background-color: #fd8c00;
  border: 1px solid #fd8c00;
  width: 135px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  gap: 5px;
}

.reportButton {
  font-size: 13px;
  border-radius: 15px;
  color: #f4f4f4;
  background-color: white;
  border: 1px solid grey;
  width: 105px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  gap: 5px;
  color: black;
}

.Quiz_Box_5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #d2dbf8;
  width: 329px;
  height: 39px;
}

.Box_5_Report {
  width: 85px;
  height: 19px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Box_5_Report h6 {
  font-size: 12px;
  color: #4e4e4e;
  margin-left: 5px;
}

.Box_5_Report h6:nth-child(2) {
  width: 21px;
  height: 17px;
  font-size: 12px;
  color: white;
  background-color: #fd8c00;
  border-radius: 10px;
  text-align: center;
  margin-left: 17px;
}

/*---------------------------| Quiz Cards Styles Ends Here |---------------------------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/

@media (min-width: 768px) and (max-width: 1023px) {
  .Quiz_Cards_Wrapper {
    margin: 3vw 0 1vw 4vw;
  }

  .Quiz_Box_1 {
    width: 20vw;
    height: auto;
    padding: 1.5vw;
  }

  .Quiz_Box_1_Practice{
    width: 20vw;
    height: auto;
    padding: 1.5vw;
  }

  .Quiz_Box_Detail_1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 7vw;
  }

  .Quiz_Box_2 {
    padding: 1.1vw;
  }

  .Quiz_Box_2_Practice {
    padding: 1.1vw;
  }

  .Quiz_Box_Detail_2 .percentage_val {
    margin-left: 3.5vw;
  }

  .Quiz_Box_3 {
    padding: 0.9vw;
  }

  .Quiz_Box_3_Practice {
    padding: 0.9vw;
  }

  .Quiz_Box_4 {
    padding: 0.1vw;
  }

  .lock_icon {
    height: 1.5vw;
    width: 1.5vw;
    margin: 0.5vw 0.5vw 0 1vw;
  }

  .info_icon {
    height: 1.3vw;
    width: 1.4vw;
    margin: 1vw 0 0 0;
  }

  .Quiz_Box_4 button {
    padding: 0.2vw 1.6vw 0.2vw 1.6vw;
    border-radius: 0.9vw;
    margin: 0.1vw 0 0.4vw 2.2vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/

@media (min-width: 1024px) and (max-width: 1430px) {
  .Quiz_Box_1 {
    width: 20vw;
    height: auto;
    padding: 2vh 0 2vh 1vw;
  }

  .Quiz_Box_1_Practice {
    width: 20vw;
    height: auto;
    padding: 2vh 0 2vh 1vw;
  }

  .Quiz_Box_Detail_1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 7vw;
  }

  .Quiz_Box_2 {
    padding: 1.1vw;
  }

  .Quiz_Box_2_Practice{
    padding: 1.1vw;
  }

  .Quiz_Box_Detail_2 .percentage_val {
    margin-left: 3.5vw;
  }

  .Quiz_Box_3 {
    padding: 0.9vw;
  }

  .Quiz_Box_3_Practice {
    padding: 0.9vw;
  }

  .Quiz_Box_4 {
    padding: 0.2vw;
  }

  .lock_icon {
    height: 1.5vw;
    width: 1.5vw;
    margin: 0.5vw 0.5vw 0 1vw;
  }

  .info_icon {
    height: 1.3vw;
    width: 1.4vw;
    margin: 1vw 0 0 0;
  }

  .Quiz_Box_4 button {
    padding: 0.2vw 1.6vw 0.2vw 1.6vw;
    border-radius: 0.9vw;
    margin: 0.2vw 0 0.4vw 2.2vw;
  }
}

/*----------| Media Query for Extra Large Screens |-----------*/

@media (min-width: 1431px) and (max-width: 3072px) {
  .Quiz_Tittle_Box {
    border: 1px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Quiz_Tittle_Box p {
    font-size: 1vw;
    word-wrap: break-word;
    max-width: 400px;
  }

  .Quiz_Tittle_Box p:nth-child(2) {
    font-size: 12px;
    word-wrap: break-word;
    max-width: 400px;
  }

  .Quiz_Box_1 {
    width: 22vw;
    height: auto;
    padding: 1.1vw;
  }

  .Quiz_Box_1_Practice {
    width: 22vw;
    height: auto;
    padding: 1.1vw;
  }

  .Quiz_Box_Detail_1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 7vw;
  }

  .Quiz_Box_2 {
    padding: 1.1vw;
  }

  .Quiz_Box_2_Practice {
    padding: 1.1vw;
  }

  .Quiz_Box_Detail_2 .percentage_val {
    margin-left: 3.5vw;
  }

  .Quiz_Box_3 {
    padding: 0.9vw;
  }

  .Quiz_Box_3_Practice {
    padding: 0.9vw;
  }

  .Quiz_Box_4 {
    padding: 0.1vw;
  }

  .lock_icon {
    height: 1.5vw;
    width: 1.5vw;
    margin: 0.5vw 0.5vw 0 1vw;
  }

  .info_icon {
    height: 1.3vw;
    width: 1.4vw;
    margin: 1vw 0 0 0;
  }

  .Quiz_Box_4 button {
    padding: 0.2vw 1.6vw 0.2vw 1.6vw;
    border-radius: 0.9vw;
    margin: 0.5vw 0 0.4vw 2.9vw;
  }
}

#demo-simple-select-label {
  /* color: rgb(21, 20, 20) !important; */
  color: #ffa500 !important;
}

#selected_orange {
  color: #ffa500 !important;
}

#selected_black {
  color: #000000 !important;
}

.az_card_img {
  top: '90%';
  left: '30%';
  text-align: 'center';
  padding: '1px';
  box-sizing: 'border-box';
  font-size: "10px" !important;
}

.az_card {
  width: '200px';
  height: '332px';
  border: '1px solid #ddd';
  border-radius: '8px';
  overflow: 'hidden';
  margin: '16px';
  position: 'relative';
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
  background-color: "white"
}