.container{
    padding: 10px;
    width: 100%;
    
}
.blue_print_list{
    margin: 20px;
    
}

.heading{
    background-color: #d9dcfe;
    padding: 30px;
    display:flex;
    font-weight: 550;
    
    
}


.blue_print_list{
        background-color: #f3f2ff;
       padding: 15px;
       margin: 2px;
       display: flex;
       width: 100%;
      
       
}
    
    .table_container{
       border-radius: 15px;
    }
  
  #blueprint_table td, #blueprint_table th {
    padding: 30px;
 
  }
    
  #blueprint_table tr:hover {background-color: #ddd;}
  
  #blueprint_table th {
    padding-top: 12px;
    padding-bottom: 8px;
    background-color: #8cccf3;
    color: rgb(3, 100, 245);
    

    
}

