.faq_division{
    width: 100%;
    height: auto;
    margin: 20px;
}

.faq_dropdown_div{
    display: flex;
    gap: 20px;
    margin: 30px 0 10px 0;
}

.faq_name_division{
    margin: 0 0 40px 0;
}

.no_faq {
    position: relative;
    top: 25vh;
    left: 28vw;
    font-size: 18px;
    font-weight: 500;
    color: #9b9fa7;
    opacity: 0.8;
  }
