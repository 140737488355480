.Warning_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Warning_text {
  margin-top: 20px;
  text-align: center;
}

.Warning_Btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.Btn1 {
  width: 100px;
  height: 40px;
  border-radius: 21px;
  border: 1px solid #7a7a7a;
  color: #7a7a7a;
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  text-transform: uppercase;
}

.Btn2 {
  width: 100px;
  height: 40px;
  border-radius: 21px;
  border: none;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: #fd8c00;
  outline: none;
  border: none;
  box-shadow: 0 5px #fd8c0080;
}

.Btn2:hover {
  background-color: #ec7b23;
}

.Btn2:active {
  background-color: #fd8c00;
  box-shadow: 0 5px #fd8c0080;
  transform: translateY(4px);
}
