* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  flex-direction: row;
}
.orange_btn {
  width: 100px;
  padding: 7px 14px;
  margin: 3px 5px;
  color: #fd8c00;
  border: 1px solid #fd8c00;
  border-radius: 6px;
  background-color: transparent;
}
.orange_btn:hover {
  color: white;
  background-color: #fd8c00;
}

/* modal  */
.flex_container {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.main_container {
  width: 500px;
  height: 250px;
  border-radius: 20px;
  background-color: white;
}
.main_container p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

#modal_id {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.table_head {
  color: blue !important;
  font-family: "poppins" !important;
  font-weight: 600;
  background: #d9dcfe !important;
}
.table_cell {
  font-family: "poppins" !important;
}

.table_cellll {
  font-family: "poppins" !important;
  font-size: 1vw !important;
  /* color: #354052; */
}
 .table_cellll {
  color: #354052 !important;
}

.table_rw:hover {
  color: #354052 !important;
  border: 4px solid orange;
}

/* pagination */
.pagination {
  background: white;
  box-shadow: 1px 2px 2px grey;
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}
.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/* btns  */

.green_btn {
  color: #fff;
  background-color: #4caf50;
  border-radius: 150px;
  border-color: #4caf50;
  padding: 8px 21px;
  /* width:140px;
  height : 43px; */
}
.green_btn:hover {
  color: #fff;
  background-color: #4caf50;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #78be20;
}
.red_btn {
  color: #fff;
  background-color: #ef0f0f;
  border-radius: 150px;
  border-color: #ef0f0f;
  padding: 8px 21px;
}
.red_btn:hover {
  color: #fff;
  background-color: #ef0f0f;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #e57373;
}
.white_btn {
  color: #7a7a7a;
  background-color: white;
  border-radius: 150px;
  border: 1px solid #7a7a7a;
  /* // width : 100px, */
  padding: 7px;
  min-width: 100px;
  font-size: 16px;
  /* height : 32px; */
  margin: 3px auto;
  /* // padding: 8px 21px, */
}
.white_btn:hover {
  color: black;
  background-color: white;
  border: 1px solid #7a7a7a;
  top: -2;
  box-shadow: 0px 3px 5px grey;
}
.blue_btn {
  color: #fff;
  width: 150px;
  padding: 7px;
  background-color: blue;
  border-radius: 150px;
  border-color: grey;
}
.blue_btn:hover {
  color: #fff;
  background-color: blue;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px blue;
}
