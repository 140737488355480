.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  font-size: 14px;
  margin-top: 90px;
  padding: 20px;
  align-items: center;
  border-radius: 8px;
  max-width: 94%;
  max-height: 94%;
  width: fit-content;
  height: fit-content;
}

.EditHeadContainer {
  display: flex;
  justify-content: space-between;
  color: #354052;
  margin-left: 30px;
}

.EditBox {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 25px;
}

.Edithead {
  margin: 0px 40px 0px 30px;
  display: grid;
  gap: 8px;
}

.Input_head {
  color:#fd8c00;
}
.Input {
  color: #272727;
  opacity: 1;
}

#Addressbox {
  grid-column: 2 / span 3;
}

.EditHeadContainer > i {
  font-size: x-large;
}

.main_content_teacherid span {
  display: block;
  width: 100px;
  margin: 50px;
}
.main_content_teacherid img {
  width: 20px;
  height: 20px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_teacherid h5 {
  font-size: inherit;
  width: fit-content;
  margin: 0px 0px 35px 0px;
}

.modal_buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin: 25px 10px 10px 10px;
}

.Edit_btn {
  background-color: #fd8c00;
  color: white;
  padding: 11px;
  width: 135px;
  border-radius: 20px;
  border: 0.1px solid #fd8c00;
  cursor: pointer;
}

.Edit_btn:hover {
  top: -7px;
  position: relative;
  box-shadow: 1px 2px 5px orange;
}

#Edit_btn {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal_teacherid {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/
