.filter-wrap-blueprint {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-dropdown {
  display: flex;
}

.form-select-blue-print {
  background: #fff;
}

.select-option-blue-print {
  padding: 5px;
}

.filter-search {
  background-color: #fff;
  margin-left: 10px;
  border-radius: 10px;
}

.filter-input {
  border: none;
  outline: none;
  padding-left: 10px;
  border-radius: 10px;
}
.filter-input::-webkit-search-cancel-button {
  cursor: pointer; /* Set cursor to pointer on the 'x' icon */
}

.blue-print-tab-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
  cursor: pointer;
}

.blue-print-tab {
  padding-top: 20px;
  text-align: center;
  color: #2d3962;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 73px;
  width: 30%;
}

.table-wrapper {
  width: 100%;
  align-items: center;
  text-align: center;
  border-collapse: collapse;
}

.table-data-head {
  background-color: #403e75;
  height: 60px;
  color: white;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.table-data {
  color: #2d3962;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #ffffff;
  height: 50px;
}

.action-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.preview-container {
  border-radius: 15px;
  border: 1px solid #faa220;
  padding: 5px;
  margin-left: 5px;
  color: #faa220;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* .tableFixHead {
  overflow: auto;
  height: 200px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #d4d9ff;
  z-index: 1;
} */
