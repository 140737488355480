article.article_teacherid {
  width: 100%;
  position: absolute;
  top: 35px;
  height: 100%;
  background-color: #f8f4fc;
}
/* .Grid.school-name-branch  starts */

.Grid_school_name_branch_teacherid {
  display: flex;
  justify-content: space-between;
  padding: 13px; /* background: rgba(211, 211, 211, 0.4); */
}

button.btn_branch_name_teacherid {
  width: 220px;
  height: 35px;
  border-radius: 15px;
  background-color: blue;
  color: #f8f4fc;
  border: none;
}

i.fa-solid.fa-house-user {
  padding-right: 10px;
}

i.fa-solid.fa-chevron-down {
  padding-left: 10px;
}
.school_name_teacherid {
  font-weight: 550;
  font-size: 0.9rem;
  color: #000000b8;
  padding-top: 10px;
  margin-left: 15px;
}

/* .Grid.school-name-branch ends here */
/* grid-container-header-plan starts from here */

.grid_container_header_plan_teacherid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  text-align: center;
  width: 84vw;
  height: 40px;
  column-gap: 2px;
  border-left: 10px solid var(--backgroundColor);
  border-right: 10px solid var(--backgroundColor);
}
.grid_container_header_plan_teacherid > div {
  /* font-size: 0.9rem; */
  font-size: 14px;
  box-shadow: 0.1px 0.1px gray;
  padding-top: 9px;
  background-color: white;
}

.item_plan3_teacherid {
  color: orange;
  border-bottom: 4px solid orange;
}
.grid_container_header_plan_teacherid > div:hover {
  color: orange;
  border-bottom: 4px solid orange;
}

/* ================teacher grid starts===================== */
.create_teacher_teacherid {
  background: white;
  margin: 0px 10px;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--hoverColor);
}

.teacher1_teacherid {
  gap: 3rem;
  display: flex;
  padding: 1rem;
}

.teacher2_teacherid {
  grid-column: 4;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-rows: auto auto auto;
  padding: 0rem 1rem;
}

.create_HOD_box1_teacherid {
  display: flex;
  gap: 30px;
}

.input_form_teacher_teacherid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: 100%;
  margin: 10px;
  gap: 1.3rem;
}

.input_element_teacher_teacherid p {
  font-size: 52%;
  font-weight: bold;
  color: gray;
}

.input_element_teacher_teacherid {
  position: relative;
  width: 95%;
  height: 45px;
}
.input_element_teacher_teacherid input,
.input_element_teacher_teacherid select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #9e9e9e;
  padding: 0 20px;
}

.input_element_teacher_teacherid label {
  position: absolute;
  left: 15px;
  /* top: 19px; */
  top: 15px;
  /* font-size: 1vw; */
  font-size: 13px;
  /* font-weight: 400; */
  background: white;
  color: #b5b6b6;
  opacity: 1;
  transition: all 0.2s;
  /* padding-right: 45px; */
  /* top: -10px; */
  /* color: var(--utilityColor); */
}
.input_element_teacher_teacherid:hover label {
  top: -7px;
  /* color: var(--utilityColor); */
  color: #fd8c00;
  padding-right: 0;
}
.form_input_element_teacher_teacherid:not(:placeholder-shown) ~ label,
.form_input_element_teacher_teacherid:focus ~ label {
  /* top: -15px; */
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.form_input_element_teacher_teacherid_s:valid + label,
.form_input_element_teacher_teacherid_s option:checked {
  top: -7px;
  color: var(--utilityColor);
  padding-right: 0;
}

.std_cat {
  visibility: hidden;
}

.form_input_element_teacher_teacherid_s[type="date"] {
  font-size: 13px;
}

.input_element_teacher_teacherid p {
  visibility: hidden;
}
.input_element_teacher_teacherid:hover p {
  visibility: visible;
}
.form_input_element_teacher_teacherid:not(:placeholder-shown) ~ p,
.form_input_element_teacher_teacherid:focus ~ p {
  visibility: visible;
}

.input_block_element_teacher_teacherid {
  display: block;
}

p.remove_teacherid {
  font-size: x-small;
  padding-bottom: 10px;
}

#submit_teacherid {
  width: 130px;
  height: 30px;
  border-radius: 20px;
  background-color: #69ca69;
  padding: 10p;
  border: none;
  color: white;
}

.teacher5_teacherid {
  position: relative;
}
/* Start to cancle button */

.create_button_teacher_teacherid_2 {
  width: 60%;
  height: 40px;
  border-radius: 20px;
  color: white;
  background: blue;
  float: right;
  position: absolute;
  border: none;
  text-transform: uppercase;
  position: relative;
  right: 8px;
}

.create_button_teacher_teacherid_2:hover {
  top: -7px;

  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}
/* End cancle button */

.create_button_teacher_teacherid {
  width: 60%;
  height: 37px;
  border-radius: 17px;
  color: gray;
  background: white;
  border: none;
  outline: 1px solid gray;
  padding: 8px;
  border-radius: 20px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  /* float: right; */
  position: absolute;
  right: -240%;
  left: 254%;
  margin: -68px 60px 0px 55px;
}

.create_button_teacher_teacherid:hover {
  top: 7;
  background-color: gray;
  color: white;
}

.error_box {
  color: red;
}

/* ===============radio button starts================== */

.radio_teacherid > input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  display: inline-flex;
  background-color: #f8f4fc;
  border-radius: 17px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  outline: none;
  box-shadow: 1px 1px 2px 3px;
  color: orange;
  position: relative;
  left: 0px;
}

.radio_teacherid > label {
  color: gray;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  padding-left: 10px;
}

.radio_teacherid > input[type="radio"]::after {
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  content: "\f192";
  font-size: 18px;
  color: orange;
  background-color: #f8f4fc;
  display: none;
}

.radio_teacherid > input[type="radio"]:checked {
  background-color: blue;
}

.radio_teacherid > input[type="radio"]:checked::after {
  display: block;
}

/* ===================.radio button ends here=============== */

/* ======================popup starts here =================== */
.wrapper_teacherid > label {
  align-items: center;
  display: flex;
}

/* =============================teachers-list-starts-===================== */
.teacher_list_teacherid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 0px 10px;
  padding: 7px 10px;
}

.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_container input {
  width: 100%;
  height: 30px;
  padding: 0px 34px 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}

.school_list_search_container input::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_container span {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

#search_button_teacherid {
  border: none;
  font-size: 20px;
  background: white;
  color: orange;
}

i.fa-solid.fa-chalkboard-user {
  padding-right: 15px;
}

i.fa-solid.fa-magnifying-glass {
  color: orange;
  position: relative;
  top: 8px;
  font-size: 22px;
  background: white;
}

/* ====================table starts from here=================== */
.list_table_teacher_div_teacherid {
  height: 60%;
  background-color: white;
  margin: 0px 10px 0px 10px;
  overflow: scroll;
}

table.table_section_teacher_list_teacherid {
  width: 82vw;
  height: auto;
  background: #d9dcfe;
  text-align: center;
  border-collapse: collapse;
}

.table_section_teacher_list_teacherid > tbody {
  background-color: white;
  color: var(--primaryColor);
}

.table_section_teacher_list_teacherid > tbody > tr,
.table_section_teacher_list_teacherid > thead > tr {
  height: 47px;
  font-weight: normal !important;

  border: 1px groove #8080802e;
  font-size: 0.9rem;
}

#email_td {
  width: 15% !important;
}
#email {
  text-align: start !important;
}
.list_role {
  text-align: start !important;
  width: 140px !important;
  padding-left: 36px;
}

.table_head_section_teacher_list_teacherid {
  color: blue !important;
}
p.data_placeholder_teacherid {
  margin: auto;
  width: fit-content;
  align-self: center;
  justify-self: center;
  color: var(--primaryColor);
  font-size: medium;
  text-transform: uppercase;
}

.data_row:hover td:first-child {
  border-left: 10px solid blue;
}
.data_row {
  border-left: 10px solid white;
}

tbody tr:hover td#email {
  color: orange;
}

.data_available_teacherid {
  background: white;
  width: 100%;
  height: 285px;
  display: flex;
  position: relative;
  border-right: 10px solid #f8f4fc;
  border-left: 10px solid #f8f4fc;
  border-bottom: 10px solid #f8f4fc;
  border-radius: 20px;
}

.name {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  text-align: left;
}
.userId {
  white-space: nowrap;
  width: 7rem;
  overflow: overlay;
  text-overflow: clip;
  margin: 0px -23px 0px 10px;
  /* text-align: left; */
}
/* =================table ends here============= */
/* modal CSS - start*/
.flex_container_teacherid {
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  align-items: center;
  justify-content: center;
}

.flex_container_teacherid > div {
  background-color: white;
  padding: 30px 30px 30px 30px;
  font-size: 30px;
  align-items: center;
  border-radius: 8px;
  width: 400px;
  height: 300px;
}

.main_content_teacherid p {
  font-size: 0.5em;
  color: orange;
  display: flex;
  justify-content: center;
}

.main_content_teacherid span {
  display: block;
  width: 100px;
  margin: auto;
}
.main_content_teacherid img {
  width: 100px;
  height: 100px;
  filter: invert(70%) sepia(98%) saturate(3112%) hue-rotate(357deg)
    brightness(99%) contrast(110%);
}

.main_content_teacherid h5 {
  font-size: 0.5em;
  width: fit-content;
  margin: 20px auto;
  color: gray;
}

.modal_buttons_teacherid {
  display: flex;
  justify-content: space-around;
  margin: 25px 10px 10px 10px;
}

.ok_btn {
  background-color: orange;
  color: white;
  padding: 10px;
  border-radius: 35px;
  width: 75px;
  font-weight: bold;
  border: 0.1px solid orange;
  cursor: pointer;
}

.ok_btn:hover {
  top: -7px;
  position: relative;
  box-shadow: 1px 2px 5px orange;
}

#ok_btn {
  background-color: white;
  color: grey;
}

/* modal CSS - start*/
#modal_teacherid {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* modal CSS - end*/

/* modal CSS - end*/

/* ==============popup starts================ */
.wrapper_teacherid {
  display: inline-flex;
}
.wrapper_teacherid .icon {
  margin: 0px -3px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* z-index: 2; */
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon span {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon span i {
  line-height: 50px;
  font-size: 1.2vw;
  color: grey;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}
.wrapper_teacherid .icon .tooltip {
  position: absolute;
  top: 0;
  width: 145px;
  z-index: 1;
  background: #fff;
  color: gray;
  padding: 9px 20px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon:hover .tooltip {
  top: -35px;
  opacity: 1;
  pointer-events: auto;
}
.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #FFA500;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper_teacherid .icon:hover span {
  color: #fff;
}
.wrapper_teacherid .icon:hover span,
.wrapper_teacherid .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}
.wrapper_teacherid .add:hover i {
  color: orange;
}

.wrapper_teacherid .edit:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.wrapper_teacherid .remove:hover span {
  color: #e1306c;
  box-shadow: 2px 2px 2px orange;
}

.blank {
  margin-left: 50px;
}

#orange-add {
  box-shadow: 2px 2px 2px orange;
}

/* ===============custom-file-input starts================= */

.custom_file_input {
  width: 310px;
  margin: 0px 0px 10px 1px;
  padding: 0px 0px 0px 0px;
}

.custom_file_input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom_file_input::before {
  content: "Choose File";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 15px;
  padding: 5px 24px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  font-weight: 700;
  font-size: 10pt;
  background: orange;
  color: white;
}
.custom_file_input:hover::before {
  border-color: black;
}
.custom_file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.teacher5_teacherid_2 {
  position: relative;
}

.logo3 {
  color: red;
}
.logo4 {
  color: red;
}

/* Pagination links */
.pagination {
  overflow: auto;
  background: white;
  border: 1.1px solid rgba(128, 128, 128, 0.233);
  box-shadow: 1px 2px 2px var(--fadedColor);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px;
}
.pagination a {
  color: #888888;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}
.pagination_active {
  color: black;
  border: none;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}
.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/*Input Label Choose File Btn Style*/
.chooseFile_btn_wrapper {
  position: relative;
  margin-bottom: 20px;
}

.chooseFile_btn_wrapper > img {
  position: absolute;
  top: 12px;
  left: 14px;
}

.chooseFile_btn {
  font-size: 13px;
  color: white;
  background-color: #fd8c00;
  border-radius: 25px;
  width: 123px;
  height: 37px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

/*Input Label Choose File Btn Style Hover Effect*/
.chooseFile_btn_wrapper {
  display: inline-flex;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.chooseFile_btn_wrapper:hover,
.chooseFile_btn_wrapper:focus,
.chooseFile_btn_wrapper:active {
  bottom: 1px;
  box-shadow: 0 11px 11px -10px #fd8c0063;
}
