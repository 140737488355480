.CS_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  user-select: none;
}

/*-------| CS Quiz QUESTION Styles |-------*/
.Para_and_img {
  overflow: auto;
  max-height: 672px;
  width: 100%;
  padding-right: 20px;
}

/*---| Scroll Bar Starts |---*/
.Para_and_img::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  max-height: 178px;
  background-color: white;
  position: relative;
  display: block;
}

.Para_and_img::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Para_and_img::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

/*---| Scroll Bar Ends |---*/

.head_1 {
  font-size: 16px;
  color: #00be66;
  opacity: 0.9;
}

.Para_div_1 {
  /* font-size: 13px; */
  text-align: justify;
  overflow-y: scroll;
  padding-bottom: 20px;
}

/*---| Scroll Bar Starts |---*/
.Para_div_1::-webkit-scrollbar {
  height: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.Para_div_1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.Para_div_1::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.Para {
  margin-top: 20px;
  text-align: justify;
  font-size: 1.4vw;
}

.Paragraph {
  margin-top: 1px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 2px;
}

.Cs_Explanation {
  margin-top: 20px;
  font-size: 1.2vw;
}

.Cs_img {
  margin-top: 20px;
  cursor: pointer;
}

.Cs_img {
  width: 100%;
  height: 110px;
}

/*-------| CS Quiz QUESTION Styles Ends |-------*/

/*-------| CS Quiz ANSWER Styles |-------*/
.SubQuestion_Section {
  overflow-y: scroll;
  max-height: auto;
  padding-right: 10px;
}

.Qstns_and_Ans {
  width: 100%;
}

/*---| Scroll Bar Starts |---*/
.SubQuestion_Section::-webkit-scrollbar {
  width: 6px;
  background-color: #4384ff;
  position: relative;
  display: block;
}

.SubQuestion_Section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4384ff;
}

.SubQuestion_Section::-webkit-scrollbar-track {
  background-color: white;
}

/*-----| Scroll Bar Ends |-----*/

.Img_and_Para {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Q_para {
  font-size: 13px;
  text-align: justify;
  width: 100%;
  margin-top: 10px;
}

.sub_Qustn_img {
  width: 100px;
  height: 60px;
}

.Cs_Ans_Fields {
  margin-top: 10px;
}

.title_ {
  font-size: 16px;
}

.Option_and_Ans_cs {
  display: flex;
  align-items: baseline;
  gap: 40px;
}

.Ans1_FIB_cs {
  width: 500px;
  height: 60px;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 9px;
  line-height: 2;
  padding: 10px;
  border-radius: 5px;
}

.Ans1_MSQ_cs {
  width: 600px;
  height: auto;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 9px;
  line-height: 24px;
  padding: 8px;
  border-radius: 5px;
}

.Ans_MCQ_cs {
  width: 600px;
  height: auto;
  border: 1px solid #dbdee6;
  font-size: 18px;
  color: #1b1b1b;
  opacity: 0.9;
  margin-top: 9px;
  line-height: 24px;
  padding: 8px;
  border-radius: 5px;
}

/*-------| CS Quiz ANSWER Styles Ends |-------*/

/*~~~~~~~| Media Query |~~~~~~~*/

/*----------| Media Query for Small Screens & Laptops |-----------*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .CS_div {
    column-gap: 2vw;
  }

  .head_1 {
    font-size: 2.2vw;
  }

  .Para {
    margin-top: 1.8vw;
    font-size: 1.3vw;
  }

  .Cs_Explanation {
    margin-top: 1.8vw;
    font-size: 1.2vw;
  }

  .Cs_img {
    margin-top: 1.8vw;
  }

  .Cs_img {
    width: 100%;
    height: 18vh;
  }

  .Q_para {
    font-size: 1.5vw;
    margin-top: 1vw;
  }

  .Img_and_Para {
    gap: 1.5vw;
  }

  .sub_Qustn_img {
    width: 9.76vw;
    height: 12.85vh;
  }

  .Cs_Ans_Fields {
    margin-top: 2vw;
  }

  .title_ {
    font-size: 1.5vw;
  }

  .Ans1_FIB_cs {
    width: 41.55vw;
    height: 8.31vh;
    font-size: 1.5vw;
    margin-top: 0.58vw;
    padding: 1.5vw;
  }

  .Ans1_MSQ_cs {
    width: 41vw;
    font-size: 1.3vw;
    margin-top: 2vw;
    padding: 0.5vw;
  }

  .Ans_MCQ_cs {
    width: 41vw;
    font-size: 1.3vw;
    margin-top: 2vw;
    padding: 0.5vw;
  }
}

/*----------| Media Query for Laptops & Desktops |-----------*/
@media screen and (min-width: 1024px) and (max-width: 1430px) {
  .CS_div {
    column-gap: 2.5vw;
  }

  .head_1 {
    font-size: 1.8vw;
  }

  .Para {
    margin-top: 1.7vw;
    font-size: 1.4vw;
  }

  .Cs_Explanation {
    margin-top: 1.7vw;
  }

  .Cs_img {
    margin-top: 1.7vw;
  }

  .Cs_img {
    width: 100%;
    height: 16vh;
  }

  .Q_para {
    font-size: 1.3vw;
    margin-top: 1.1vw;
  }

  .Img_and_Para {
    gap: 1.6vw;
  }

  .sub_Qustn_img {
    width: 10.76vw;
    height: 13.85vh;
  }

  .Cs_Ans_Fields {
    margin-top: 2.2vw;
  }

  .title_ {
    font-size: 1.3vw;
  }

  .Ans1_FIB_cs {
    width: 42.55vw;
    height: 8.4vh;
    font-size: 1.6vw;
    margin-top: 1.5vw;
    padding: 1.4vw;
  }

  .Ans1_MSQ_cs {
    width: 41.5vw;
    font-size: 1.6vw;
    margin-top: 1.2vw;
    padding: 0.6vw;
  }

  .Ans_MCQ_cs {
    width: 41.5vw;
    font-size: 1.6vw;
    margin-top: 1.2vw;
    padding: 0.6vw;
  }
}

/*----------| Media Query for Extra Large Screens |-----------*/
@media screen and (min-width: 1440px) and (max-width: 3072px) {
  .CS_div {
    column-gap: 2.5vw;
  }

  .head_1 {
    font-size: 1.2vw;
  }

  .Para {
    margin-top: 0.5vw;
    font-size: 1.1vw;
  }

  .Cs_Explanation {
    margin-top: 1.3vw;
  }

  .Cs_img {
    margin-top: 1vw;
  }

  .Cs_img {
    width: 100%;
    height: 16vh;
  }

  .Q_para {
    font-size: 0.9vw;
    margin-top: 1vw;
  }

  .Img_and_Para {
    gap: 1vw;
  }

  .sub_Qustn_img {
    width: 8.76vw;
    height: 13.85vh;
  }

  .Cs_Ans_Fields {
    margin-top: 2.2vw;
  }

  .title_ {
    font-size: 1vw;
  }

  .Ans1_FIB_cs {
    width: 42.55vw;
    height: 8vh;
    font-size: 1.1vw;
    margin-top: 1vw;
    padding: 1.4vw;
  }

  .Ans1_MSQ_cs {
    width: 37vw;
    font-size: 0.8vw;
    margin-top: 1vw;
  }

  .Ans_MCQ_cs {
    width: 37vw;
    font-size: 0.8vw;
    margin-top: 1vw;
  }
}
