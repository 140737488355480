:root {
  font-size: 16px;
  --primaryColor: #403e75;
  --utilityColor: #ffa500;
  --fadedColor: rgba(122, 122, 122, 0.5);
  --globalFont: sans-serif;
  --hoverColor: #d9dcfe;
  --headerColor: #d9dcfe;
  --backgroundColor: #f8f4fc;
  --secondaryColor: rgba(211, 211, 211, 0.4);
  --textColor: #000000
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* global font */
  font-family: 'Poppins', sans-serif;
}

body {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main_container {
  width: 100vw;
  height: 100vh;
  min-width: 650px;
  /* margin: auto; */
}

button:hover{
cursor: pointer;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #f8f4fc 60%, #e8e4fc 60%);
  background-position: center;
  background-size: cover;
}

/* navbar -Start */
.navbar {
  position: relative;
  top: 3%;
  left: 4%;
  width: 80%;
}

.icon {
  position: relative;
}

.logo img {
  width: 19%;
  margin: 24px 55px;
}

/* navbar - end */
/* content -start */
.content {
  width: 80%;
  margin: auto;
  height: auto;
  color: #141010;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.login_img {
  width: 48%;
  height: fit-content;
}

/* form -begin */
.login_form {
  position: relative;
  /* width: 30%; */
  width: 31%;
  min-width: 300px;
  background: white;
  border-radius: 18px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login_form_title {
  padding: 5px;
  margin-bottom: 10px;
}

.login_form h2 {
  font-size: 1rem;
  font-weight: 900;
  color: hsla(0, 5%, 7%, 0.937);
}

.login_form h3 {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: -5px;
}

.login_form>input,
.password_container>input {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
}

/* input filed -legend CSS -start */
.username_container,
.password_container {
  height: 70px;
}

.password_container fieldset,
.username_container fieldset {
  position: relative;
  border: none;
}

.password_container input,
.username_container input {
  width: 90%;
  height: 60%;
  border-radius: 5px;
  padding: 15px;
  color: var(--primaryColor);
  font-size: 1rem;
  border-color: var(--fadedColor);
  transition: 0.2s;
  font-size: 0.8rem;
}

.password_container input:hover,
.username_container input:hover {
  border-color: black;
}

.input_element:not(:placeholder-shown) {
  border-color: black;
}

.password_container span,
.username_container span {
  position: absolute;
  left: 15px;
  top: 12px;
  background-color: white;
  color: var(--primaryColor);
  font-size: 0.8rem;
  transition: 0.2s;
}

.password_container fieldset:hover .input_legend,
.username_container fieldset:hover .input_legend {
  top: -10px;
  color: var(--utilityColor);
}

.input_element:focus~.input_legend,
.input_element:not(:placeholder-shown)~.input_legend {
  top: -10px;
  color: var(--utilityColor);
}

/* error span - start */
.error_legend {
  left: 15px !important;
  top: 51px !important;
  color: red !important;
}

/* error span - emd */

/* input fieled legend CSS -end */

.login_form>input::placeholder,
.password_container>input::placeholder {
  padding: 10px;
  font-size: 0.8rem;
}

/* eye icon -start */
.bi_eye_slash {
  margin: -35px;
  cursor: pointer;
  visibility: hidden;
  transition: visibility 0.2s;
}

.input_element:hover~i,
.input_element:focus~i,
.input_element:not(:placeholder-shown)~i {
  visibility: visible;
}

/* eye icon -end */

.login_container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.remember_checkbox {
  align-self: center;
  flex-basis: 100%;
}

.remember_checkbox input {
  outline: 2px solid #fb9d2a;
  outline-offset: -2px;
  transform: scale(1.5);
  margin-right: 10px;
}

.remember_checkbox label {
  font-size: 0.8rem;
}

/* .remember_checkbox label:before {
  content: '';
  border: 3px solid #fb9d2a;
  padding: 4px;
  margin-top: 5px;
  border-radius: 3px;
  margin-left: -14px;
  position: absolute;
} */

.login_btn {
  width: 50%;
  height: 2.1rem;
  background: #111fee;
  border: 1px solid black;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 2px 2px #1120ee83;
  font-size: 0.8rem;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  margin-right: 50px;
}

.forgot_password {
  text-align: right;
  font-size: 0.7em;
  margin-right: 50px;
}

.forgot_password a {
  position: relative;
  z-index: 2;
  color: black;
}

/* responsive styles */
@media screen and (min-width: 1100px) {
  .login_form h2 {
    font-size: 1.2rem;
  }

  .login_form h3 {
    font-size: 0.9rem;
  }

  .login_form>input,
  .password-container>input {
    font-size: 1.2rem;
  }

  .login_form>input::placeholder,
  .password_container>input::placeholder {
    font-size: 1rem;
  }

  .remember_checkbox label {
    font-size: 1rem;
  }

  .forgot_password {
    font-size: 0.7rem;
  }
}

/* login-end */
/* content-end */