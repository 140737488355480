* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  flex-direction: row;
}

.radio_active:before {
  background: white;
  position: relative;
  box-shadow: inset 0px 0px 0 4px orange;
  top: -3px;
  width: 17px !important;
  height: 17px !important;
}

/* =================radio starts ============= */
.radio_funcionality > input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid orange;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality > input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

.radio_funcionality > input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.myText {
  color: green;
  font-size: 69px;
}
.table_head {
  color: #fff !important;
  font-family: "poppins" !important;
  font-weight: 600;
  background: #403E75 !important;
}
.table_cell {
  font-family: "poppins" !important;
}
.select_input {
  font-family: "poppins" !important;
  color: #b5b6b6;
  font-weight: 300;
}

.err_box {
  position: absolute;
  right: 256px;
  margin-top: 42px;
}
.err_box > p {
  display: flex;
  float: right;
  color: red;
}
/* pagination */
.pagination {
  background: white;
  box-shadow: 1px 2px 2px grey;
  border-radius: 5px;
  /* width: 100%; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
}
.pagination a {
  color: #888888;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
  float: right;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;
}

.rows > select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/* btns  */

.green_btn {
  color: #fff;
  background-color: #4caf50;
  border-radius: 150px;
  border-color: #4caf50;
  padding: 8px 21px;
  /* width:140px;
  height : 43px; */
}
.green_btn:hover {
  color: #fff;
  background-color: #4caf50;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #78be20;
}
.red_btn {
  color: #fff;
  background-color: #ef0f0f;
  border-radius: 150px;
  border-color: #ef0f0f;
  padding: 8px 21px;
}
.red_btn:hover {
  color: #fff;
  background-color: #ef0f0f;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px #e57373;
}
.white_btn {
  color: #7a7a7a;
  background-color: white;
  border-radius: 150px;
  border: 1px solid #7a7a7a;
  /* // width : 100px, */
  padding: 7px;
  min-width: 100px;
  font-size: 16px;
  /* height : 32px; */
  margin: 3px auto;
  /* // padding: 8px 21px, */
}
.white_btn:hover {
  color: black;
  background-color: white;
  border: 1px solid #7a7a7a;
  top: -2;
  box-shadow: 0px 3px 5px grey;
}
.blue_btn {
  color: #fff;
  width: 150px;
  padding: 7px;
  background-color: blue;
  border-radius: 150px;
  border-color: grey;
}
.blue_btn:hover {
  color: #fff;
  background-color: blue;
  /* border: 0; */
  top: -2;
  box-shadow: 0px 3px 5px blue;
}
