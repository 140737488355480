.add_schools {
      margin: 35px 10px 0px 10px;
      width: 100%;
}
.add_school_title {
  margin: 10px 0px;
}
.input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 99%;
  height: 140px;
  margin-top: 30px;
  margin-left: 10px;
}
/* grip inputs - start */
.input_element {
  position: relative;
  width: 95%;
  height: 68%;
}
.input_elementinput,
.input_elementselect {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--primaryColor);
  padding: 0 20px;
}

.input_elementlabel {
  position: absolute;
  left: 15px;
  top: 19px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primaryColor);
  background: white;
  transition: all 0.2s;
}
.input_element:hover label {
  top: -7px;
  color: var(--utilityColor);
}
.form_input_element:not(:placeholder-shown) ~ label,
.form_input_element:focus ~ label {
  top: -7px;
  color: var(--utilityColor);
}

/* grid inputs -end */

.upload_logo_container {
  display: flex;
  flex-direction: column;
}
.upload_logo_containerspan {
  justify-self: center;
  margin: 5px 17px;
  font-weight: 500;
}
.artical_button_container {
  position: relative;
  left: -170px;
}

.artical_button {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 15px;
}

#button_id {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
  position: absolute;
  top: 20px;
}
#button_id:hover {
  top: 18px;
  box-shadow: 0px 3px 5px rgba(0, 0, 255, 0.5);
}

.add_schools_form {
  background-color: white;
  padding-top: 2px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);

  border-radius: 15px;
  width: 99%;
}
.add_schools_btn {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.choosefile_button {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.choosefile_button_container {
  margin: 20px;
}
#choose_butto_id {
  background-color: #ff9100;
  color: var(--backgroundColor);
}
.schoo_lis_container {
  margin: 28px 0px 15px 0;
}
.magnifying_glass_icon {
  float: right;
  margin-right: 35px;
  font-size: 1.2rem;
  color: var(--primaryColor);
}

.school_list_table {
  background: white;
  height: 40%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  width: 99%;
  overflow: auto;
  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari and Opera */
.school_list_table ::-webkit-scrollbar {
  display: none;
}




.table {
  width: 100%;
  
  text-align: center;
  border-radius: 2px;
  border-collapse: collapse;
}


.table thead {
  background-color: var(--headerColor);
  position: sticky;
  top: 0;
  color: blue;
  border-bottom: 1px solid #888888a3;
  border-collapse: collapse;
  font-size: small;
  width: 1251px;
  margin-top: 0px;
  border-radius: 3px;
  font-size: 0.8rem;
  height: 47px;
  z-index: 1;
}
.particular_hover{
  border-bottom: 1px solid rgba(141, 137, 137, 0.281);
  border-collapse: collapse;
}

.col_size{
  height: 47px;
  font-size: 00.9rem;
  color: var(--primaryColor);
}
col_size th {
  text-align: center;
}

.table > th  > i {
  font-size: 0.8rem;
}


.create_branch_btn {
  width: 65%;
  border: 2px solid rgba(128, 128, 128, 0.233);
  border-radius: 15px;
  padding: 5px;
  background: none;
}

/*create school css used here for 14 page purpose ina diffrent way*/
#td_3rd_schoolName {
  color: var(--utilityColor);
}
#td_3rd {
  width: 70%;
  border-radius: 22px;
  background-color: var(--utilityColor);
}

#table _row3th {
  border-left: 15px solid blue;
}

#table _row3 {
  box-shadow: 3px 3px 3px 3px #888888;
}

.name {
    white-space: nowrap;
      width: 7rem;
      overflow: clip;
      text-overflow: clip;
      margin: 0px -23px 0px 10px;
      text-align: left;
}

.school_list_search_containerinput {
  width: 100%;
  height: 30px;
  padding: 0px 10px;
  border-radius: 22px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
}


.school_list_btn_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 282px;
}
.school_list_search_container {
  width: 250px;
  position: relative;
}

.school_list_search_containerinput::placeholder {
  color: rgba(128, 128, 128, 0.753);
}

.school_list_search_containerspan {
  position: absolute;
  top: 5px;
  left: 220px;
  color: var(--utilityColor);
  font-size: 1.2rem;
}

.add_school_button {
  margin-right: 4%;
  height: 30px;
  width: 130px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-radius: 22px;
  background-color: rgba(0, 128, 0, 0.698);
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.school_list {
  margin-left: 10px;
}

#button_id {
  float: right;
  background-color: blue;
  color: var(--backgroundColor);
}

.type_of_school {
  text-align: left;
}

.create_school_list {
  background: white;
  height: 50%;
  border: 0.1px solid rgba(128, 128, 128, 0.233);
  border-bottom: none;
  border-radius: 10px 10px 0px 0;
  width: 99%;
  overflow: auto;
  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/*add branch 13 css concept use here for  school branch 14 html page*/



header {
  background: var(--headerColor);
  width: 100%;
  height: 92px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 99%;
  height: 140px;
  margin-top: 30px;
  margin-left: 20px;
}

.add_schools_form {
  background-color: white;
  padding-top: 0px;
  border: 0.1px solid rgba(128, 128, 128, 0.233);

  border-radius: 15px;
  width: 99%;
  padding-right: 23%;
  height: 35%;
}

.radio_lablelabel {
  color: #ff9100;
  padding-left: 12px;
  font-weight: bold;
}

.school_logo_jpg {
  grid-row: 1/3;
}

.radio_button_1 input {
  padding-top: 0px;
  border-radius: 15px;
}
.radio_button_2input {
  padding-top: 0px;
  margin-left: 45px;

  border-radius: 15px;
}
.radio_button_2 {
  margin-left: 3%;

  color: black;
}

.radio_button_1 {
  margin-left: 3%;
  color: black;
}
.radio_container {
  margin-top: 3px;
}
#button_id {
  float: right;
  background-color: rgb(40, 40, 212);
  color: var(--backgroundColor);
  position: absolute;
  top: -54px;
  margin-left: 380%;
}
.add_school_title {
  margin: 10px 0px;
  font-weight: bold;
}

.education_container {
  background-color: aquamarine;
}

.edit_schools {
      display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        font-size: smaller;
        color: #ff9100;
        background-color: rgba(225, 226, 245, 0);
        padding-top: 0px;
        border-radius: 6px;
}

.school_logo {
  grid-row: 1 / 3;
  width: 75px;
}

.schoolName {
  font-size: 0.9rem;
  font-weight: bold;
}

.cityName {
  font-size: 0.9rem;
  font-weight: bold;
}

.pocEmail {
  font-size: 0.9rem;
  font-weight: bold;
}

.website {
  font-size: 0.9rem;
  font-weight: bold;
}

.SignatoryName {
  font-size: 0.9rem;
  font-weight: bold;
}

.SignatoryRole {
  font-size: 0.9rem;
  font-weight: bold;
}

.phoneNumber {
  font-size: 0.9rem;
  font-weight: bold;
}


.schoolType {
  font-size: 0.9rem;
  font-weight: bold;
}

p {
  color: rgba(14, 13, 13, 0.63);
}
#button_id:hover {
  top: -56px;
  box-shadow: 0px 3px 5px rgb(0 0 255 / 40%);
}

.school_logo_jpgimg {
  width: 160px;
  padding-bottom: 25px;
}

.edit_button {
      color: white;
        background-color: #ff9100;
        /* width: 7rem; */
        border-radius: 30px;
        padding: 5px;
        border: none;
        font-size: 1vw;
}
.taken_width {
      background-color: #f6f6fc;
        border-radius: 4px;
        margin-right: 18px;
        border-top: 1px solid var(--hoverColor);
        border-bottom: 1px solid var(--headerColor);
}
.mobile_emailspan {
  margin: 0px 10px;
}
.mobile_emaillabel {
  color: black;
  padding-bottom: 20px;
}
.mobile_emailspan {
  text-decoration: none;
}
.mobile_emailspan img {
  position: relative;
  top: 5px;
}

tbody p {
  text-align: center;
}

.dashboard {
  height: 91%;
  background-color: #f6f6fc;
  min-width: fit-content;
  min-height: fit-content;
  display: flex;
  position: relative;
}



.edit_schools > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 1vw;
  width: 8vw;
  margin: 0.2rem 0.2rem;
}

.edit_schools > div > label{
  white-space: nowrap;
    width: 9rem;
    overflow: scroll;
    text-overflow: clip;
    margin: 0px 10px;

}
.edit_schools > div > p {
  white-space: nowrap;
    width: 9rem;
    overflow: scroll;
    text-overflow: clip;
    margin: 0px 10px;
  
}

#button_id_edit:hover {
  top: -56px;
  box-shadow: 0px 3px 5px rgba(166, 166, 175, 0.4);
}
.radio_button_2 > img {
  margin-left: 15%;
}

.radio_button_2 > label {
  padding-top: 15%;
}

.main_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1110px;
}

/*creatte school 14*/



.dashboard {
  height: 91%;
  background: #f6f6fc;
  width: 100%;
  height: 98%;
  display: flex;
  position: relative;
}



.create_branch_list {
      background: white;
        height: 65%;

        overflow:scroll;
        
}
.school_logo_jpgimg {
  margin: 11px auto;
}

#button_id {
  float: right;
  background-color: rgba(0, 150, 0, 0.698);
  color: var(--backgroundColor);
  position: absolute;
  top: -16px;
  font-weight: 400;
  margin-left: 390%;
  height: 33px;
}
.school_list_container {
      display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
}

.data_row{
  height: 14px;
}



#button_id:hover {
  top: -17px;
}
tbody tr:hover td.hover_table {
  color: orange;
}

tbody tr:hover td span.circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  width: 100px;
  height: 70px;
  background-color: orange;
}
tbody tr:hover {
  box-shadow: #ccc 3px 3px;
}

.edit_button:hover {
  top: 10px;
}
.artical_button {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  padding: 10px;
  border-radius: 20px;
  width: 145px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

#button_id_edit:hover {
  top: -56px;
  box-shadow: 0px 3px 5px rgba(166, 166, 175, 0.4);
}

/* Pagination links starts */
.pagination {
  align-items: center;

  border: 1.1px solid rgba(128, 128, 128, 0.233);
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  background: white;
  height: 35px;
  /* margin: 0px 10px 0px 10px; */
  border-radius: 4px;
}

.pagination a {
  color: #888888;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination a span {
  font-weight: 100;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination_active:hover:not(.active) {
  background-color: blue;
}

.pagination_active {
  color: black;
  border: none;
  /* float: right; */
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.rows {
  font-size: 0.9rem;
  color: #888888;
  padding-left: 10px;

}

.rows>select {
  text-align: center;
  margin: 5px;
  height: 25px;
  color: #888888;
  border-color: #888888;
  border-radius: 5px;
}

/* /--------------pagination ends here --------------- */
/*Slider*/
/* ----------------slider- round--active----------- */
.active_switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 29px;
}

.active_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.active_slider {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 46px;
  border-radius: 5px;
  height: 10px;

  background-color: #d9dcfe;
  transition: 0.4s;
}

.active_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  right: 25px;
  border-radius: 15px;
  bottom: 0;
  top: -6px;

  border: 3px solid #d9dcfe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.active_slider {
  background-color: orange;
}

input:focus+.active_slider {
  box-shadow: 0 0 1px #d9dcfe;
}

input:checked+.active_slider:before {
  -webkit-transform: scale3d;
  -ms-transform: translateX(26px);
  transform: translateX(25px);
  border: 3px solid orange;
}

/* Rounded sliders */
.active_slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 0%;
}

/* ============================================================================== */