.container {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.box > h3 {
  font-weight: 600;
  font-size: 1.3vw;
  color: orange;
}
.d_flex {
  display: flex;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.flex_center > p {
  color: blue;
  padding-right: 20px;
}
.d_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid lightgrey;
  border-top: 2px solid lightgrey;
}
.concept_col > p {
  color: blue;
  border-top: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
  padding: 8px;
  text-align: center;
}

/* assCptDetail */
.searchOptBox {
  width: 40%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 224px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.Dd_itm_style:hover{
background:#C9E9FD;
}